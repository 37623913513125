//
//
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { CTAButton } from '../Common/CTAButton'
import { DisplayableListPage } from '../Common/DisplayableListPage'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { LargeCTAButtonsGroup } from '../Common/LargeCTAButtonsGroup'
import { TitledEducationalTextCell } from '../Common/TitledEducationalTextCell'
import { themes } from '../themes'
import { CreateNewWallet_View } from './CreateNewWallet_View'
import { UseExistingWallet_View } from './UseExistingWallet_View'
//
//
//
export class LandingView extends DisplayableListPage
{
    //
    //
    //
    constructor(ctx: App_View_Context)
    {
        super(ctx)
        const self = this
    }
    setup(): void 
    {
        super.setup()
        const self = this
        self.setup_views()
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self) // key so as not to cause a strong ref cycle
        //
        self.layer.style.display = "flex"
        self.layer.style.flexDirection = "row"
        self.layer.style.justifyContent = "center"
        //
        let container = new View_Web({ }).init()
        container.layer.style.display = "flex"
        container.layer.style.flexDirection = "column"
        container.layer.style.justifyContent = "center"
        container.layer.style.height = "100%"
        self.addSubview(container)
        //
        let icon_c_v = new View_Web({}).init()
        icon_c_v.layer.style.display = "flex"
        icon_c_v.layer.style.flexDirection = "row"
        icon_c_v.layer.style.justifyContent = "center"
        container.addSubview(icon_c_v)
        //
        {
            //
            let v = new Icon_Symbolic.View().init()
            v.set_props({
                svg: Icon_Symbolic.SVG_Icons.crossroadsLogo_white_48
            })
            icon_c_v.addSubview(v)
        }
        {
            let view = new TitledEducationalTextCell({
                themeC: self.ctx.themeC,
                contextInUI: themes.ElementContextInUI.SplashHeader
            }).init()
            view.set_props({
                title: "Welcome to Crossroads",
                subtitle: "A new kind of wallet for Monero",
                description: "Select an option below to begin"
            })
            container.addSubview(view)
        }
        {
            let view = new LargeCTAButtonsGroup.View({ 
                themeC: self.ctx.themeC,
                layoutDirection: LargeCTAButtonsGroup.FlowDirection.row, // due to multiple
                contextInUI: themes.ElementContextInUI.SplashHeader,
            }).init()
            view.set_props({
                descs: [
                    {
                        title: "Use Existing Wallet", 
                        symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.walletSimpleSolid },
                        is_default: false,
                        variant: CTAButton.Variant.main,
                        arrangement: CTAButton.ArrangementAndGeometry.inline_fixed_240w,
                        //
                        pressed_fn: () => {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            optl_self.ctx.rootNavigationView.push(new UseExistingWallet_View(self.ctx, { 
                                is_within_onboarding: true
                            }).init())
                        }
                    },
                    {
                        title: "or",
                        variant: CTAButton.Variant.textOnly
                    },
                    {
                        title: "Create New Wallet", 
                        symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.key },
                        variant: CTAButton.Variant.main,
                        arrangement: CTAButton.ArrangementAndGeometry.inline_fixed_240w,
                        is_default: false,
                        //
                        pressed_fn: () => {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            optl_self.ctx.rootNavigationView.push(new CreateNewWallet_View(self.ctx, { 
                                is_within_onboarding: true
                            }).init())
                        }
                    }
                ]
            })
            container.addSubview(view)
        }
    }
    //
    // Interface - NavigableView
    navigationBar_hidden(): boolean
    {
        // return true
        return true
    }
    //
    //
    viewDidAppear(): void 
    {
        super.viewDidAppear()
        const self = this
        //
        // so user has something to start navigating from? ideally we'd be able to select the first or last ones when the user hits the 'down' or 'up' keys
        // setTimeout(() => { // await .render
        //     self.useExistingWallet.layer.focus()
        // }, 20)
    }
}