import InboxClient, { AtomicDocRetryableModOp, AtomicDocRetryableModOpAction, AtomicDocRetryableModOpActionType, DecrdMsgAuthProgressState, Inbox, Inbox_ToAddress_Validation, MessageSubmissionLocalStatus, New_AtomicDocRetryableModOp_UUID, ObtainServerLockAndWriteDocAtomically_Status, StorableMessage } from "@xrds/inbox-client-ts/src/client/client";
import { Monero_Tx } from "@xrds/payments-client-ts/src/monero-wallet-clients/WalletScannerClient_Base";
import { EventEmitter } from "events";
//
import { BigInteger, monero_amount_format_utils__instance } from '@xrds/monero-utils-ts';
import AppWalletMetadataClient from "./AppWalletMetadataClient";
import { IndexInterval, IndexIntervals } from "@xrds/monero-utils-ts";
//
import { StructuredMessage_TypeKeyValues, StructuredMessageJSON_xmrRequest__dualSpecific, StructuredMessageJSON_shared__c__ccyTypes, StructuredMessageJSON_xmrRequest__recipient } from './StructuredMessages'
import { WalletsListController } from "./WalletsListController";
//
export namespace WalletDisplayable
{
    export namespace Deposits
    {
        export interface DepositAccountBalances_RetVals
        {
            pend_recv_xmr_sum: BigInteger,
            confd_recv_xmr_sum: BigInteger,
            pend_hashes: string[], // misc value - for convenience
            confd_hashes: string[] // misc value - for convenience
        }
        export function depositAccountBalancesFromDepositAccount(
            dep_acct: XMRDepositAccount_Base
        ): DepositAccountBalances_RetVals {
            let pend_recv_xmr_sum = BigInteger(0)
            let pend_hashes = dep_acct.pend__tx_frags_by_hash ? Object.keys(dep_acct.pend__tx_frags_by_hash) : []
            for (var i = 0 ; i < pend_hashes.length ; i++) {
                let h = pend_hashes[i]
                let tx_frag = dep_acct.pend__tx_frags_by_hash![h]
                pend_recv_xmr_sum = pend_recv_xmr_sum.add(tx_frag.tx_recv_sum)
            }
            //
            let confd_recv_xmr_sum = BigInteger(0)
            let confd_hashes = dep_acct.confd__tx_frags_by_hash ? Object.keys(dep_acct.confd__tx_frags_by_hash) : []
            for (var i = 0 ; i < confd_hashes.length ; i++) {
                let h = confd_hashes[i]
                let tx_frag = dep_acct.confd__tx_frags_by_hash![h]
                confd_recv_xmr_sum = confd_recv_xmr_sum.add(tx_frag.tx_recv_sum)
            }
            //
            // TODO: uncomment this for debugging stale Pending row in Chat payment
            // console.log("dep_acct" , dep_acct.maj_i.toString(), dep_acct.min_i.toString(), dep_acct.addr_str)
            // console.log("pend_hashes" , pend_hashes)
            // console.log("confd_hashes" , confd_hashes)
            //
            return {
                pend_recv_xmr_sum, 
                confd_recv_xmr_sum,
                pend_hashes,
                confd_hashes
            }
        }
    }
}
//
interface XMRDepositTx_Base
{
    tx_recv_sum: BigInteger
}
//
export interface DepositMonitorPersistable
{
    this_client__maj_idx?: string // major index of the account index that we want to use on this client for making new subaddrs
    last_known_scanner_originating_rngs?: { [maj_idx__key: string]: string[][] } // string[] as in a stringifyPrepped IndexInterval (bigint -> str)
}
//
enum WalletAtomicDoc_RootKeys
{
    used_maj_idx_intvls = "!u_j_i_is", // an atomically synchronized array of used maj idx intervals per IndexIntervals.ts; j i as in maJ Idx
    scnr_rngs = "!s_r" // this explicitly shall be set to the union of itself with the .PUT resp from the scanner
}
//
//
export interface XMRDepositAccount_Base
{
    //
    // initial params
    maj_i: BigInteger
    min_i: BigInteger
    addr_label?: string // recoverable from metadata
    //
    is_merely_scnr_rng_originating: boolean
    //
    // can be generated
    addr_str?: string // a cache - this is a uuid among the XMRDepositAccounts - wallet main addr when 0,0; else the subaddr 
    //
    // Inbox-discovered vals:
    reqs?: XMRDepositRequest[]
    //
    // from scanner txs
    confd__tx_frags_by_hash?: { [key: string]: XMRDepositTx_Base }
    pend__tx_frags_by_hash?: { [key: string]: XMRDepositTx_Base }
}
export interface XMRDepositRequest
{
    of_addr: string // this is who the request was sent to by the deposit account base .addr_str 
    req_uuid_in_UI: string // this is the pkey - it would be the .msg_uuid of the dual OR the .ephem_uuid of the original - but it would not be the .msg_uuid of the twin
    req_msg_twin_has_been_sent: boolean // even if there is a uuid it doesn't mean it's a .msg_uuid 
    //
    xmr?: BigInteger // this is expected to be positive
}
interface DepositAccountProvider
{
    startDiscoveringAccounts: () => Promise<void>
    didDiscoverAccounts_fn: (accounts: XMRDepositAccount_Base[]) => void // this will be called continuously
    //
    teardown: () => Promise<void>
}
//
export enum GenerateAndSaveAccountAddress_RetVal_Status
{
    bailedAfterNilWeakDeref = "bailedAfterNilWeakDeref",
    //
    invalidAmount = "invalidAmount",
    //
    invalidToAddr_malformed = "invalidToAddr_malformed",
    invalidToAddr_subaddrNotSupported = "invalidToAddr_subaddrNotSupported",
    invalidSendReqToSelfInbox = "invalidSendReqToSelfInbox",
    destAddrRequiredIfNoteOrAmountSpecified = "destAddrRequiredIfNoteOrAmountSpecified",
    //
    failToGetMajIdx_retryLater = "failToGetMajIdx_retryLater",
    failToGetMajIdx_noRetry = "failToGetMajIdx_noRetry",
    failToObtainMinIdx = "failToObtainMinIdx",
    //
    success = "success",
}
export function _new_human_readable_err_msg_from(status: GenerateAndSaveAccountAddress_RetVal_Status): string
{
    switch (status) {
        case GenerateAndSaveAccountAddress_RetVal_Status.invalidToAddr_malformed:
            return "That does not appear to be a valid Monero wallet address to send a payment request."
        case GenerateAndSaveAccountAddress_RetVal_Status.invalidAmount:
            return "Entered request amount was not a valid amount number."
        case GenerateAndSaveAccountAddress_RetVal_Status.invalidToAddr_subaddrNotSupported:
            return "Sending encrypted mail to Monero sub-addresses is not currently supported."
        case GenerateAndSaveAccountAddress_RetVal_Status.invalidSendReqToSelfInbox:
            return "Sending a payment request to oneself is not currently supported."
        case GenerateAndSaveAccountAddress_RetVal_Status.failToGetMajIdx_retryLater:
            return "There was an error connecting to the server. Please try again shortly."
        case GenerateAndSaveAccountAddress_RetVal_Status.failToGetMajIdx_noRetry:
            return "There was a fatal error while creating a new address. Please update Crossroads or try again later."
        case GenerateAndSaveAccountAddress_RetVal_Status.failToObtainMinIdx:
            return "An error was encountered while generating a new address. Please try again shortly."
        case GenerateAndSaveAccountAddress_RetVal_Status.destAddrRequiredIfNoteOrAmountSpecified:
            return "A payment request destination address is required for sending a payment request."
    }
    return "Unrecognized error while creating a new wallet account."
}
//
export interface GenerateAndSaveAccountAddress_RetVal
{
    status: GenerateAndSaveAccountAddress_RetVal_Status
    //
    info?: GenerateAndSaveAccountAddress_SuccessInfo
}
export interface GenerateAndSaveAccountAddress_SuccessInfo
{
    maj_idx: BigInteger
    min_idx: BigInteger
    exists__amount: boolean // accessory / convenience 
    //
    // info enough to navigate to the right UI
    optl__nav_to_addr?: string
    optl__req_uuid_in_UI?: string
}
//
export enum DepositMonitor_Events
{
    UpdatedPersistableDataAndNeedsSave = "UpdatedPersistableDataAndNeedsSave",
    UpdatedThisClientMajIdx = "UpdatedThisClientMajIdx",
    UpdatedAccountsListMembership = "UpdatedAccountsListMembership", // added accounts themselves
    UpdatedAccountProperties = "UpdatedAccountProperties"
}
//
export enum ScannerRes_StatusCode
{
    connectionFail = -100,
    depositMonTornDown = -99,
    walletTornDown = -98,
    //
    success = 200,

    server_err = 500,
    bad_request = 400, 
    unauthorized = 403, // ? 
}
export interface ProvisionedMajIdxRes
{
    status: ScannerRes_StatusCode
    //
    maj_idx?: BigInteger
}
export interface AddScannerSubaddrIdcsRes
{
    status: ScannerRes_StatusCode

    scnr_rngs?: { [maj_idx__str: string]: IndexInterval[] } // these are the ranges that the scanner knows about - we should union them with what we've backed up independently so that if we switch servers we can PUT the independently backed up copies to the scanner
}
export interface GETScannerSubaddrIdcsRes
{
    status: ScannerRes_StatusCode
    min_idx_intervals_by_maj_idx?: { [maj_idx__str: string]: IndexInterval[] }
}
//
type AccountIdxKeyPath = string
enum AccountIdxKeyPath_Pieces
{
    delimiter = "-"
}
function AccountIdxKeyPathFromDepositAccount(a: XMRDepositAccount_Base): AccountIdxKeyPath
{
    return AccountIdxKeyPathFromIdcs(a.maj_i, a.min_i)
}
export function AccountIdxKeyPathFromIdcs(maj_i: BigInteger, min_i: BigInteger): AccountIdxKeyPath
{
    return AccountIdxKeyPathFromIdcs_strings(maj_i.toString(), min_i.toString())
}
export function AccountIdxKeyPathFromIdcs_strings(maj_i: string, min_i: string): AccountIdxKeyPath
{
    return `${maj_i}${AccountIdxKeyPath_Pieces.delimiter}${min_i}`
}
export function sortedArray_subaddrAcctKeypaths_from(arr: string[])
{ // adapted from https://stackoverflow.com/a/8107614
    let tempArr: number[][] = arr.map(keypath => (keypath.split("-").map(n_str => (parseInt(n_str)))))
    var numsArrays: number[][] = tempArr as number[][]
    numsArrays.sort((x, y) => {
        let x_len = x.length
        for (var i = 0 ; i < x_len ; i++) {
            if (y.length < i || x[i] < y[i]) {
                return -1 // x is longer
            }
            if (x[i] > y[i]) {
                return 1
            }
        }
        return 0
    })
    //
    return numsArrays.map(numsArray => numsArray.join('-'))
}
//
interface ProvisionSubaddrMaj_RetVals
{
    status: ObtainServerLockAndWriteDocAtomically_Status
    next_unused_subaddr_maj_idx?: BigInteger
}
//
function _parsed_or_initial__writebackable__used_maj_idcs__intervals__from_atomic_doc(
    json_to_modify__copy: { [key: string]: any }
): IndexInterval[] {
    let raw__used_maj_idx_intvls = json_to_modify__copy[WalletAtomicDoc_RootKeys.used_maj_idx_intvls] || []
    let parsed__used_maj_idx_intvls = raw__used_maj_idx_intvls.map(str_ii => IndexIntervals.parseStringifyPrepped(str_ii))
    let final__used_maj_idcs__intervals: IndexInterval[]
    if (parsed__used_maj_idx_intvls.length > 0) { 
        final__used_maj_idcs__intervals = parsed__used_maj_idx_intvls
    } else { // this covers the case of someone (erroneously) writing an empty array to the doc
        final__used_maj_idcs__intervals = [ IndexIntervals.newWith(new BigInteger(0), new BigInteger(0)) ] // the main addr is always allocated
    }
    //
    return final__used_maj_idcs__intervals
}
//
export class DepositMonitor extends EventEmitter
{
    // Set at init:
    initParams!: DepositMonitor_InitParams
    //
    // ephemeral
    has_set_initial_data: boolean = false
    accountProviders: DepositAccountProvider[] = []
    accountsByIdxKeyPath: { [acct_keypath: string]: XMRDepositAccount_Base } = {}
    accounts__by__min_idx__by__maj_idx: { [maj_idx_str: string]: { [min_idx_str: string] : XMRDepositAccount_Base } } = {}
    //
    // these get set from persistable data:
    wallet_public_addr?: string
    proper_oper_addr?: string
    view_key_sec?: string
    ephem__all_sorted_txs?: Monero_Tx[]
    //
    // persistable
    this_client__maj_idx?: BigInteger // server gives the client a unique one of these
    last_known_scanner_originating_rngs?: { [maj_idx__key: string]: IndexInterval[] }
    cached_subaddrhexes_by_majminskeys?: { [keypath: string]: string }
    //
    is_torn_down = false
    //
    constructor(initParams: DepositMonitor_InitParams)
    {
        super()
        const self = this
        self.initParams = initParams
    }
    //
    // Imperatives - Setup/Loading/Init
    public set_initial_from_persisted_for(
        wallet_public_addr: string,
        proper_oper_addr: string,
        view_key_sec: string,
        optl__data?: DepositMonitorPersistable,
        all_sorted_txs?: Monero_Tx[]
    ) {
        const self = this
        self.wallet_public_addr = wallet_public_addr
        self.proper_oper_addr = proper_oper_addr
        self.view_key_sec = view_key_sec
        self.ephem__all_sorted_txs = all_sorted_txs
        //
        if (optl__data) {
            if (optl__data.this_client__maj_idx) {
                self.this_client__maj_idx = new BigInteger(optl__data.this_client__maj_idx)
            } else {
                self.this_client__maj_idx = undefined
            }
            if (optl__data.last_known_scanner_originating_rngs) {
                self.last_known_scanner_originating_rngs = IndexIntervals.parseStringifyPrepped_complex(optl__data.last_known_scanner_originating_rngs)
            } else {
                self.last_known_scanner_originating_rngs = undefined
            }
        }
        self.cached_subaddrhexes_by_majminskeys = {} // initialize
        //
        self.is_torn_down = false // revert
        self.has_set_initial_data = true
    }
    //
    has_called__start_observing_and_try_initial_setup: boolean = false
    public async start_observing_and_try_initial_setup()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        if (self.has_called__start_observing_and_try_initial_setup) {
            console.warn("start_observing_and_try_initial_setup already called so not re-entering")
            return // this is important for many reasons
        }
        self.has_called__start_observing_and_try_initial_setup = true
        //
        if (!self.proper_oper_addr) {
            throw new Error("Expected .proper_oper_addr by the time this is called")
        }
        {
            let p = new EnsureBaseAcct_DepositAccountProvider()
            await self.add_accountProvider(p) // probably does not need to be awaited 
        }
        // Commenting this for now since we actually (a) dont want the min idx padded ranges from the server but rather want to know of the ones we've really used, and (b) because we're going to get this .PUT information anyway, (c) because we do store the ranges in the atomic doc anyway (though, we are not actually using that presently), and (d) (minor) we do keep a cached .last_known_scanner_originating_rngs - though that would be out of date without a poll/subscr
        // {
        //     let p = new ScannerAccountLookupFns_DepositAccountProvider(
        //         self.initParams.get_scanner_known_idcs__fn
        //     )
        //     await self.add_accountProvider(p) // this will await the scanner replying with what it knows about ... which may not be necessary
        // }
        {
            let p = new Metadata_DepositAccountProvider(
                self.proper_oper_addr!, // the oper addr! not the .addr 
                self.initParams.metadata_client,
                () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    let atomic__plain_json = optl_self.initParams.inbox_client.decr_atomic_doc__plain_json__for(optl_self.proper_oper_addr!)
                    if (atomic__plain_json) {
                        return (atomic__plain_json[WalletAtomicDoc_RootKeys.used_maj_idx_intvls] || []).map(str_ii => IndexIntervals.parseStringifyPrepped(str_ii))
                    } else {
                        return undefined // still waiting on this
                    }
                }
            )
            await self.add_accountProvider(p) // 
        }
        {
            let p = new Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider(
                self.proper_oper_addr!,
                () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    let atomic_doc_plain_json = self.initParams.inbox_client.decr_atomic_doc__plain_json__for(optl_self.proper_oper_addr!)
                    let atomic_doc__scnr_rngs = atomic_doc_plain_json ? atomic_doc_plain_json[WalletAtomicDoc_RootKeys.scnr_rngs]: undefined
                    //
                    return atomic_doc__scnr_rngs ? IndexIntervals.parseStringifyPrepped_complex(atomic_doc__scnr_rngs) : undefined // first we must convert the strings to BigIntegers
                }
            )
            await self.add_accountProvider(p)
        }
        { // This will discover subaddr accounts based on the txs that are scanned
            let p = new ScannedTxs_DepositAccountProvider(
                () => (self.ephem__all_sorted_txs || []) // lookup scanned tx for discovering the accounts 
            )
            self.add_accountProvider(p)
        }
        { // searches the inbox for sent requests
            let p = new InboxRequests_DepositAccountProvider(self.proper_oper_addr!, self.initParams.inbox_client)
            self.add_accountProvider(p)
        }
        //
        for (let p of self.accountProviders) { // start observing/discovering each accountProvider
            await p.startDiscoveringAccounts() // now that we're observing it, we can trigger the initial discover
        }
        // 
        // TODO: is there any reason we'd want to rethink how we're storing metadata info locally? we wouldn't need to actually store the min and maj idx in the object itself since it's in the keys


        // And we may as well kick off an attempt at getting the maj_idx as quickly as possible here
        // But a problem is, we dont have anything telling metadata that this is now used .. it would be nicer if we could do the import from the LWS too
        let r = await self._get_or_ensure_maj_idx_if_needed()
        if (!r) {
            // TODO: set some status on self that can be displayed in UI? 
            // 
            // TODO: this will be attempted again upon creating a new addr... should we attempt to wait for connectivity to come back and try again, pre-emptively? possibly not a big rush to do so except that it would be nice to have the maj_idx to provision offline later ... since we can provision via min idxs offline and set metadata to send offline
        } else {
            
        }
        // console.log("after _get_or_ensure_maj_idx_if_needed in start_observing.. self.this_client__maj_idx! ", self.this_client__maj_idx!, self.proper_oper_addr)
    }
    // Accessors - Persistence for reconstitution
    public persistable_data(): DepositMonitorPersistable
    { // save this to disk and pass it back to the client
        const self = this
        if (self.has_set_initial_data != true) {
            throw new Error("deposit_mon asked for persistable_data() but not yet set initial data")
        }
        let d: DepositMonitorPersistable = {}
        if (typeof self.this_client__maj_idx !== 'undefined' && self.this_client__maj_idx !== null) {
            d.this_client__maj_idx = self.this_client__maj_idx.toString()
        }
        if (self.last_known_scanner_originating_rngs && typeof self.last_known_scanner_originating_rngs !== 'undefined') {
            d.last_known_scanner_originating_rngs = IndexIntervals.stringifyPrep_complex(self.last_known_scanner_originating_rngs) // this turns BigIntegers into strings
        }
        //
        return d
    }
    //
    // Tear down on e.g. Wallet deconstruct
    teardown()
    {
        const self = this
        self.delete_inmem_vals()
    }
    delete_inmem_vals()
    {
        const self = this
        self.proper_oper_addr = undefined
        self.view_key_sec = undefined
        self.this_client__maj_idx = undefined
        self.last_known_scanner_originating_rngs = undefined
        self.cached_subaddrhexes_by_majminskeys = undefined // clearing for privacy
        //
        self.accountsByIdxKeyPath = {}
        self.accounts__by__min_idx__by__maj_idx = {}
        self.ephem__all_sorted_txs = []
        //
        self.is_torn_down = true // revert
        self.has_set_initial_data = false
        //
        self.teardownAndRemoveAll_accountProviders()
    }
    //
    // Accessors - Subaddr hex cache
    private _new_subaddr_hex_cachekey_from(maj_idx: BigInteger, min_idx: BigInteger)
    {
        return "sa-kp-"+maj_idx.toString()+"-"+min_idx.toString()
    }
    public lazy_subaddr_hex_from(maj_idx: BigInteger, min_idx: BigInteger)
    {
        const self = this
        if (maj_idx.compare(new BigInteger(0)) == 0 && min_idx.compare(new BigInteger(0)) == 0) {
            return self.wallet_public_addr! // NOTE: *not* the .proper_oper_addr
        }
        let subaddrhex_majminkey = self._new_subaddr_hex_cachekey_from(maj_idx, min_idx)
        let existing = self.cached_subaddrhexes_by_majminskeys![subaddrhex_majminkey]
        if (existing) {
            return existing
        }
        let str = self.initParams.monero_core_bridge_instance.subaddr_hex_from(
            self.wallet_public_addr!, // NOTE: *not* the .proper_oper_addr
            self.view_key_sec!, 
            maj_idx, min_idx,
            self.initParams.nettype
        )
        self.cached_subaddrhexes_by_majminskeys![subaddrhex_majminkey] = str
        //
        return str
    }
    //
    // Imperatives - Account providers
    private teardownAndRemoveAll_accountProviders()
    {
        const self = this
        for (let a of self.accountProviders) {
            a.teardown()
        }
        self.accountProviders = [] // flash
    }
    private async add_accountProvider(p: DepositAccountProvider)
    {
        const self = this
        let weakSelf = new WeakRef(self)

        p.didDiscoverAccounts_fn = (accounts: XMRDepositAccount_Base[]) => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            let did_update_acctmembership = false
            let did_update_acctproperties = false
            let added_accounts: XMRDepositAccount_Base[] = [];
            let added_account_keypaths: string[] = [] // used for checking if we ONLY updated the main addr - which would not need to be PUT needlessly to the server
            for (var i = 0 ; i < accounts.length ; i++) {
                let a = accounts[i]
                let keypath = AccountIdxKeyPathFromDepositAccount(a)
                let existing_a = optl_self.accountsByIdxKeyPath[keypath]
                if (existing_a && typeof existing_a !== 'undefined') {
                    // console.warn("Unexpected/unhandled: Somehow, the account with keypath", keypath, "was already being observed by the deposit monitor.. not redundantly storing but will check for prop updates")
                    if (a.addr_label) { // incoming does have .addr_label
                        if (!existing_a.addr_label 
                            || typeof existing_a.addr_label === 'undefined' 
                            || a.addr_label != existing_a.addr_label) {
                            // console.log("Updating the .addr_label for the existing a for that keypath")
                            existing_a.addr_label = a.addr_label! // then bring in the incoming .addr_label
                            did_update_acctproperties = true
                        }
                    } else/* if (existing_a.addr_label == '') */{
                        // but do not delete any existing .addr_label since a lack of an a.addr_label does not mean existing_a should not have the value - and perhaps we should just look for explicit non-undef empty string to cause 'deletion' in case the UI exposes an interface for editing
                    }
                    if (a.is_merely_scnr_rng_originating != true && existing_a.is_merely_scnr_rng_originating) {
                        // console.log("flipping account to !.is_merely_scnr_rng_originating")
                        existing_a.is_merely_scnr_rng_originating = false // any non-true is enough to make it false
                        did_update_acctproperties = true
                    }
                    if (a.addr_str) { // incoming does have .addr_str
                        if (!existing_a.addr_str 
                            || typeof existing_a.addr_str === 'undefined' 
                            || a.addr_str != existing_a.addr_str) {
                            console.log("Updating the .addr_str for the existing a for that keypath")
                            existing_a.addr_str = a.addr_str! // then bring in the incoming .addr_str
                            did_update_acctproperties = true
                        }
                    } else/* if (existing_a.addr_str == '') */{
                        // but do not delete any existing .addr_str since a lack of an a.addr_str does not mean existing_a should not have the value - and perhaps we should just look for explicit non-undef empty string to cause 'deletion' in case the UI exposes an interface for editing
                    }
                    if (a.reqs) { // incoming does have .addr_label
                        let needs_update = false
                        if (!existing_a.reqs || typeof existing_a.reqs === 'undefined') {
                            needs_update = true
                        } else { // then also check if all the members are the same
                            if (a.reqs.length != existing_a.reqs.length) {
                                needs_update = true
                            } else {
                                let arrays_have_same_members = a.reqs.every((o, idx) => {
                                    return o.req_uuid_in_UI == existing_a.reqs![idx].req_uuid_in_UI
                                })
                                needs_update = arrays_have_same_members != true
                            }
                        }
                        if (needs_update != true) {
                            // console.warn("[WARN] a.reqs but !needs_update. a.reqs and existing_a.reqs:", a.reqs, existing_a.reqs)
                        }
                        if (needs_update) {
                            // console.log("=======> Updating the .reqs for the existing a for that keypath")
                            existing_a.reqs = a.reqs! // for now, because there should only ever be one account provider providing all of the .reqs at a time, this is safe (but fragile - a merge in by pkey (uuid) would be better)
                            did_update_acctproperties = true
                        }
                    } else/* if (existing_a.req_to_addr == '') */{
                        // but do not delete any existing .req_to_addr since a lack of an a.req_to_addr does not mean existing_a should not have the value - and perhaps we should just look for explicit non-undef empty string to cause 'deletion' in case the UI exposes an interface for editing
                    }
                    continue
                }
                optl_self.accountsByIdxKeyPath[keypath] = a
                //
                let maj_i_str = a.maj_i.toString()
                let existing__accounts__by__min_idx = optl_self.accounts__by__min_idx__by__maj_idx[maj_i_str]
                if (typeof existing__accounts__by__min_idx == 'undefined' || existing__accounts__by__min_idx == null) {
                    optl_self.accounts__by__min_idx__by__maj_idx[maj_i_str] = {}
                }
                optl_self.accounts__by__min_idx__by__maj_idx[maj_i_str][a.min_i.toString()] = a
                //
                added_account_keypaths.push(keypath)
                added_accounts.push(a)
                did_update_acctmembership = true
            }
            if (did_update_acctproperties) {
                optl_self.emit(DepositMonitor_Events.UpdatedAccountProperties)
            }
            if (did_update_acctmembership) {
                optl_self.emit(DepositMonitor_Events.UpdatedAccountsListMembership)
                //
                let does_accounts_update_originate_from_scanner = p instanceof ScannedTxs_DepositAccountProvider // this one is less obvious 
                    // || p instanceof ScannerAccountLookupFns_DepositAccountProvider // this one is more obvious (NOTE: commented since we probably dont need it given the atomic_doc)
                if (does_accounts_update_originate_from_scanner != true) {
                    let is_merely_main_acct_added = added_account_keypaths.length == 1 && added_account_keypaths[0] == AccountIdxKeyPathFromIdcs(new BigInteger(0), new BigInteger(0))
                    // console.log("is_merely main acct added", is_merely_main_acct_added)
                    if (is_merely_main_acct_added != true) {
                        optl_self._PUT_toScanner_subaddrIdcs() // not awaiting this, just kicking it off, so as not to block accounting
                        // 
                        // Quite possible that provisioning a new maj idx being PUT to the server is covered by this call as well and 
                        // can thus be removed from ._get_or_ensure_maj_idx_if_needed() - even though that call is guarded by a local
                        // check as well and would end up being idempotent.
                        //
                        // Also nice about this call is that it bumps min idx ranges by nearest 500s so that we tell the scanner to consistently look ahead by a bit. - Server would end up scanning those ranges while we know about them without later connectivity to .PUT to the scanner
                        //
                    } else {
                        console.log("Skipping .PUT from this discovery since is_merely_main_acct_added")
                    }
                } else {
                    console.log("This account update originated from knowledge that the scanner has about subaddr accounts, therefore, not performing a PUT to the server with this info as it would ostensibly be redundant.")
                    //
                    // TODO: whenever accounts known to server changed from what's known to metadata, we need to add accounts into metadata for any missing with a min idx status of 1 (known but not utilized) - BUT this could easily race with other (even offline) clients who are setting then to 2 (known and utilized
                    // This would be useful in case the user is using an old LWS that already knows the subaddrs and just starts to use the metadata system .. we'd basically want to do an import of those subaddrs here... but in that case we'd never expect self.this_client__maj_idx to be useful for such an import ...
                    // ... this is just one more reason to move to a decentralized maj idx negotiation strategy
                    //
                    // ... e.g. the best we could do for now is to keep '1's rather than '2's for the maj_idx assigned to *this* installation ... since we dont assume any remote-originating changesets would touch those idcs ... for now...
                    // ... like this, but it wouldn't be likely to overlap with what the LWS is already scanning/using:
                    // if (typeof self.this_client__maj_idx !== 'undefined' && self.this_client__maj_idx != null) {
                    //     self.initParams.metadata_client.make_and_send_changes__xmrAddrAccount__setMinIdcsKnownUnlessKnownOrKnownAndUsed_underMajIdx(self.proper_oper_addr!, self.this_client__maj_idx, self.accountsByIdxKeyPath)
                    // }
                }
                //
                optl_self.setNeedsAccountingUpdate()
            }
        }
        self.accountProviders.push(p)
    }
    //
    public intentionallySelectNullThisClientSubaddrMajIdxToClaimNextAvailableOnProvision()
    {
        const self = this
        self.this_client__maj_idx = undefined
        //
        self.emit(DepositMonitor_Events.UpdatedPersistableDataAndNeedsSave) 
        self.emit(DepositMonitor_Events.UpdatedThisClientMajIdx) 
        //
        return true
    }
    //
    public async intentionallySelectAlreadyKnownSubaddrMajIdx(to__maj_i: BigInteger)
    {
        const self = this

        // find already known subaddrs to ensure we're selecting from within them
        let keypaths = Object.keys(self.accountsByIdxKeyPath)
        let found = false
        for (let k of keypaths) {
            let a = self.accountsByIdxKeyPath[k]
            if (a.maj_i.compare(to__maj_i) == 0) {
                found = true
                break
            }
        }
        if (!found) {
            throw new Error("Unexpected error: Somehow the to__maj_i passed to intentionallySelectAlreadyKnownSubaddrMajIdx was not found in the existing set of known maj indexes")
            return
        }
        // then, ok to claim.
        let can_avoid_claim = false
        let atomic__plain_json = self.initParams.inbox_client.decr_atomic_doc__plain_json__for(self.proper_oper_addr!)
        if (atomic__plain_json) {
            let claimed = (atomic__plain_json[WalletAtomicDoc_RootKeys.used_maj_idx_intvls] || []).map(str_ii => IndexIntervals.parseStringifyPrepped(str_ii)) as IndexInterval[]
            for (let II of claimed) {
                if (IndexIntervals.lowerOf(II).compare(to__maj_i) <= 0 && IndexIntervals.upperOf(II).compare(to__maj_i) >= 0) {
                    can_avoid_claim = true
                    break
                }
            }
        }
        // and then check if that maj i is within inbox known - if it's not, try claiming it anyway - so that other clients know
        if (!can_avoid_claim) { // it is actually possible that the metadata accounting of subaddrs does not yet know about the subaddr if it's known about from the scanner initially somehow
            let r = await self.initParams.inbox_client.obtainServerLockAndWriteDocAtomically(self.proper_oper_addr!, async (json_to_modify__copy) => {
                // obtain doc again just case there's no actual difference or there's an atomic update - we don't want to write the local cache, ever
                let final__used_maj_idcs__intervals = _parsed_or_initial__writebackable__used_maj_idcs__intervals__from_atomic_doc(json_to_modify__copy)
                // console.log("merge into:", json_to_modify__copy)
                let to__maj_i__as_II = IndexIntervals.newWith(to__maj_i, to__maj_i) // single index range
                // console.log("add index in interval:", IndexIntervals.stringifyPrep(to__maj_i__as_II))
                // insert index:
                final__used_maj_idcs__intervals = IndexIntervals.new_withUnionOf(final__used_maj_idcs__intervals, [ to__maj_i__as_II ])
                // serialize & write back
                json_to_modify__copy[WalletAtomicDoc_RootKeys.used_maj_idx_intvls] = final__used_maj_idcs__intervals.map(ii => IndexIntervals.stringifyPrep(ii))  // importantly, write back the expanded interval - but, prepped for stringify
                // console.log("writing back:", json_to_modify__copy[WalletAtomicDoc_RootKeys.used_maj_idx_intvls])
                //
                return {
                    should_abort: false,
                    successful: true,
                    or__replace_server_atomic_doc_with: json_to_modify__copy
                }
            })
            // console.log("r.status != ObtainServerLockAndWriteDocAtomically_Status.success" , r.status != ObtainServerLockAndWriteDocAtomically_Status.success )
            if (r.status != ObtainServerLockAndWriteDocAtomically_Status.success) {
                return false
            }
        }
        self.this_client__maj_idx = to__maj_i
        //
        self.emit(DepositMonitor_Events.UpdatedPersistableDataAndNeedsSave) // hopefully there's no crash before this but if there is, the app will provision yet another maj addr for this install and attempt to save it locally
        self.emit(DepositMonitor_Events.UpdatedThisClientMajIdx)
        //
        return true
    }
    //
    private async __globallyAtomic__provision_subaddr_maj(): Promise<ProvisionSubaddrMaj_RetVals>
    {
        // console.log("__globallyAtomic__provision_subaddr_maj")
        const self = this
        if (!self.proper_oper_addr) {
            throw new Error("Expected self.proper_oper_addr by the time this is called")
        }
        if (typeof self.this_client__maj_idx !== 'undefined' || self.this_client__maj_idx === null) {
            throw new Error("Expected self.this_client__maj_idx to be nil upon this call")
        }
        let next_unused_subaddr_maj_idx: BigInteger|undefined = undefined // we ought to always be able to get one if we're got a write lock on the server
        let r = await self.initParams.inbox_client.obtainServerLockAndWriteDocAtomically(self.proper_oper_addr!, async (json_to_modify__copy) => {
            let final__used_maj_idcs__intervals = _parsed_or_initial__writebackable__used_maj_idcs__intervals__from_atomic_doc(json_to_modify__copy)
            let allocd_iis_r = IndexIntervals.findNextGapInIntervalsAndAllocateOneIdx(final__used_maj_idcs__intervals)
            next_unused_subaddr_maj_idx = allocd_iis_r.expanded_into_idx
            final__used_maj_idcs__intervals = allocd_iis_r.modified_intervals // to be clear
            if (typeof next_unused_subaddr_maj_idx === 'undefined') {
                throw new Error("Expected to be able to definitely derive a next_unused_subaddr_maj_idx by here meaning selection loop must have a code fault")
            }
            json_to_modify__copy[WalletAtomicDoc_RootKeys.used_maj_idx_intvls] = final__used_maj_idcs__intervals.map(ii => IndexIntervals.stringifyPrep(ii))  // importantly, write back the expanded interval - but, prepped for stringify
            //
            return {
                should_abort: false,
                successful: true,
                or__replace_server_atomic_doc_with: json_to_modify__copy
            }
        })
        return {
            status: r.status, // .success, .errored_retryLater (like connectivity trouble), .rejected
            next_unused_subaddr_maj_idx: next_unused_subaddr_maj_idx === null 
                ? undefined 
                : next_unused_subaddr_maj_idx // null,undefined -> undefined
        }
    }
    //
    async _get_or_ensure_maj_idx_if_needed(): Promise<{success: boolean, retry: boolean}>
    { // an imperative
        const self = this
        // console.log("_get_or_ensure_maj_idx_if_needed ", self.wallet_public_addr, "self.this_client__maj_idx", self.this_client__maj_idx)
        // console.trace()
        if (self.this_client__maj_idx === null || typeof self.this_client__maj_idx === 'undefined') {
            // perhaps if we ever wanted to pull this out ...
            // let r = await self.initParams.synchd__provision_subaddr_maj__fn()
            // if (r.status != ScannerRes_StatusCode.success) {
            //     return false
            // }
            // self.this_client__maj_idx = r.maj_idx! // should exist 
            {
                let provision_r = await self.__globallyAtomic__provision_subaddr_maj()
                if (provision_r.status == ObtainServerLockAndWriteDocAtomically_Status.success) {
                    self.this_client__maj_idx = provision_r.next_unused_subaddr_maj_idx! // expected to exist upon .success
                    if (!self.this_client__maj_idx) {
                        // this is more like a code fault.. the optl unwrap just before would have thrown
                        return {
                            success: false, retry: false
                        }
                    }
                } else {
                    return { 
                        success: false, 
                        retry: provision_r.status == ObtainServerLockAndWriteDocAtomically_Status.errored_retryLater // or it'd be .rejected or .aborted
                    }
                }
            }
            self.emit(DepositMonitor_Events.UpdatedPersistableDataAndNeedsSave) // hopefully there's no crash before this but if there is, the app will provision yet another maj addr for this install and attempt to save it locally
            self.emit(DepositMonitor_Events.UpdatedThisClientMajIdx)
        }
        // this is placed outside so that in case there's a failure after provisioning above but before PUTing, we get another chance to PUT
        let scnr_orgntng_rngs_for__maj_idx = self.last_known_scanner_originating_rngs ? self.last_known_scanner_originating_rngs[self.this_client__maj_idx.toString()] : undefined;
        if (scnr_orgntng_rngs_for__maj_idx === null || typeof scnr_orgntng_rngs_for__maj_idx === 'undefined' || !scnr_orgntng_rngs_for__maj_idx.length) {
            console.log("[DepositMonitor::_get_or_ensure_maj_idx_if_needed()] scnr_orgntng_rngs lacks entry for that maj_idx so client will .PUT it to the scanner")
            /*await */self._PUT_toScanner_subaddrIdcs() // not awaiting so as not to block ret of maj idx
        }
        return {
            success: true,
            retry: false
        }
    }
    //
    find_that_maj__top_known_used_min_i(): BigInteger|undefined
    {
        const self = this
        if (!self.this_client__maj_idx || typeof self.this_client__maj_idx == 'undefined') {
            throw new Error("Expected self.this_client__maj_idx by the time find_that_maj__top_known_used_min_i was called")
            return undefined
        }
        let that_maj__top_min_i: BigInteger|undefined = undefined
        let accounts__by__mins = self.accounts__by__min_idx__by__maj_idx[self.this_client__maj_idx.toString()]
        if (accounts__by__mins && typeof accounts__by__mins !== 'undefined') {
            let min_strs = Object.keys(accounts__by__mins)
            for (let min_i_str of min_strs) {
                let this_min_i = new BigInteger(min_i_str)
                if (typeof that_maj__top_min_i == 'undefined' || that_maj__top_min_i == null) {
                    that_maj__top_min_i = this_min_i
                } else {
                    if (that_maj__top_min_i.compare(this_min_i) < 0) {
                        that_maj__top_min_i = this_min_i
                    }
                }
            }
        }
        return that_maj__top_min_i
    }
    //
    async _given_maj_idx__get_noSet_next_unused_min_idx(): Promise<BigInteger|undefined> // undefined means fail
    { // Accessor-only method
        const self = this 
        if (!self.this_client__maj_idx || typeof self.this_client__maj_idx == 'undefined') {
            throw new Error("Expected self.this_client__maj_idx by the time _given_maj_idx__get_noSet_next_unused_min_idx was called")
            return undefined
        }
        //
        // I'm going to assume metadata document has already been scanned for accounts by here since that happens after initialization and after waiting for the metadata doc to appear
        let that_maj__top_min_i: BigInteger|undefined = self.find_that_maj__top_known_used_min_i()
        console.log("that_maj__top_min_i:" , that_maj__top_min_i ? that_maj__top_min_i.toString() : '-undef-')
        //
        if (typeof that_maj__top_min_i === 'undefined' || that_maj__top_min_i == null) {
            return new BigInteger(0)
        }
        //
        return that_maj__top_min_i!.add(new BigInteger(1))
    }
    //
    setNeedsAccountingUpdate()
    {
        const self = this
        //
        let did_change__accounts_membership = false
        let did_change__totalsOrOtherProperties = false

        function _lazy_gen__addr_str_for(account: XMRDepositAccount_Base): boolean
        { // kind of messy to do it like this but the alternative is that we chuck another loop after the account since accounting can also generate accounts
            if (!account.addr_str || typeof account.addr_str == 'undefined') {
                if (account.maj_i.compare(new BigInteger(0)) == 0 && account.min_i.compare(new BigInteger(0)) == 0) {
                    account.addr_str = self.wallet_public_addr! // NOTE: not the oper_addr
                } else {
                    account.addr_str = self.lazy_subaddr_hex_from(account.maj_i, account.min_i)
                }
                // console.log("[DEBUG] Generated .addr_str for ", account.addr_str)
                return true
            }
            return false
        }
        let mutable__keypaths = Object.keys(self.accountsByIdxKeyPath)
        // NOTE: ^-- this gets modified below
        { // first, generate any missing addrs
            for (let k of mutable__keypaths) {
                let account = self.accountsByIdxKeyPath[k]
                if (_lazy_gen__addr_str_for(account)) {
                    did_change__totalsOrOtherProperties = true
                }
            }
        }
        //
        // Note: here, I opted not to go through all_sorted_txs to verify that all known txs are present in the list of accountsByAddr or else fabricating them since the scanned txs account provider is responsible for surfacing those
        //
        // however it is necessary to prune any hashes that the server claims no longer exist, even though it's kind of expensive
        { // even though this is unlikely, it is still true
            let _current__txs_by_hash: {[key: string]: Monero_Tx} = {};
            (self.ephem__all_sorted_txs||[]).forEach(tx => {
                _current__txs_by_hash[tx.hash] = tx
            })
            // TODO: memoize this at the scanner tx setter
            function __prune_missing_hash_total_recv_for(tx_frags_by_hash?: { [key: string]: XMRDepositTx_Base }): boolean
            {
                let changed_any = false
                if (tx_frags_by_hash) {
                    let hashes = Object.keys(tx_frags_by_hash)
                    for (let hash of hashes) {
                        let looked_up_tx = _current__txs_by_hash[hash]
                        if (looked_up_tx !== null && typeof looked_up_tx !== 'undefined') {
                            // keep it
                        } else {
                            console.log("WalletDisplayable_Deposits: Pruning an apparently stale tx_frags_by_hash for hash", hash)
                            delete tx_frags_by_hash[hash]
                            changed_any = true
                        }
                    }
                }
                return changed_any
            }
            for (let keypath of mutable__keypaths) {
                let a = self.accountsByIdxKeyPath[keypath]
                if (__prune_missing_hash_total_recv_for(a.confd__tx_frags_by_hash)) {
                    did_change__totalsOrOtherProperties = true
                }
                if (__prune_missing_hash_total_recv_for(a.pend__tx_frags_by_hash)) {
                    did_change__totalsOrOtherProperties = true
                }
            }
        }
        {
            if (self.ephem__all_sorted_txs) {
                let exists__by__tx_hash: { [hash: string]: boolean } = {}
                for (let tx of self.ephem__all_sorted_txs) {
                    exists__by__tx_hash[tx.hash] = true; // for cleanups below
                    if (tx.recv_outputs && tx.recv_outputs.length) { 
                        for (let recv_o of tx.recv_outputs) {
                            if (recv_o.ever_was_scanners != true) { // NOTE: txs which were created locally and not yet submitted to the scanner will have [] recv_outputs currently - so they wont be counted against nor for the balance, currently. That's not totally ideal from a certain POV but at least the transaction can appear in the list - it's mostly a UI thing. - although in reality it's best for txs that havent even made it to the pool yet not to be considered 'deposits'
                                console.warn("Skipping recv_o as it was !.ever_was_scanners")
                                continue
                            }
                            ////// if (recv_o.is_spent != true) { // PS: I have commented this for now since we still want deposits which have subsequently been spent to still be reflected as a deposit

                            let is_pending = recv_o.confd !== true || recv_o.unlockd !== true
                            if (recv_o.is_scanners != true) {
                                console.warn("Skipping tx in _update_assumed_account_with_tx_pmt as tx was !.is_known_to_scanner", tx)
                                return
                            }
                            // let is_marked_rejected = tx.optl__is_failed == true // we probably dont need to worry about these here since they'd all be outgoing ... 
                            //
                            let maj_i = new BigInteger(recv_o.sa_maj_i)
                            let min_i = new BigInteger(recv_o.sa_min_i)
                            //

// TODO: quite possibly move this into a provider? why wasn't it initially implemented as one?

                            let keypath = AccountIdxKeyPathFromIdcs(maj_i, min_i)
                            let a = self.accountsByIdxKeyPath[keypath]
                            if (typeof a === 'undefined' || a === null) {
                                // console.warn("Saw incoming transfer for account ("+maj_i.toString()+","+min_i.toString()+") but somehow the scanner tx account provider didn't create that account yet... Doing so here")
                                a = { 
                                    maj_i: maj_i, 
                                    min_i: min_i,
                                    is_merely_scnr_rng_originating: false
                                }
                                if (_lazy_gen__addr_str_for(a)) { // which, of course, will be true
                                    did_change__totalsOrOtherProperties = true
                                }
                                self.accountsByIdxKeyPath[keypath] = a
                                mutable__keypaths.push(keypath) // Important in case anything else wants to use it ... This is not great but should be fairly safe here given the naming
                                //
                                // just gonna assume self.accounts__by__min_idx__by__maj_idx remains consistent
                                let maj_i_str = a.maj_i.toString()
                                let existing__accounts__by__min_idx = self.accounts__by__min_idx__by__maj_idx[maj_i_str]
                                if (typeof existing__accounts__by__min_idx == 'undefined' || existing__accounts__by__min_idx == null) {
                                    self.accounts__by__min_idx__by__maj_idx[maj_i_str] = {}
                                }
                                self.accounts__by__min_idx__by__maj_idx[maj_i_str][a.min_i.toString()] = a
                                //
                                did_change__accounts_membership = true
                                // so we will emit(.UpdatedAccountsListMembership) below but since the update came from the scanner we wont worry about PUTing these idxs to the scanner below
                            }
                            if (!a.pend__tx_frags_by_hash) {
                                a.pend__tx_frags_by_hash = {}
                            }
                            if (!a.confd__tx_frags_by_hash) {
                                a.confd__tx_frags_by_hash = {}
                            }
                            let prior__pend__tx_recv_sum: BigInteger|undefined = a.pend__tx_frags_by_hash[tx.hash]?.tx_recv_sum
                            let prior__confd__tx_recv_sum: BigInteger|undefined = a.confd__tx_frags_by_hash[tx.hash]?.tx_recv_sum
                            //
                            // ^-- These are to make the following checks less verbose
                            if (is_pending) { // then it's pending 
                                if (!a.pend__tx_frags_by_hash[tx.hash] || typeof a.pend__tx_frags_by_hash[tx.hash] == 'undefined') {
                                    did_change__totalsOrOtherProperties = true
                                    a.pend__tx_frags_by_hash[tx.hash] = { tx_recv_sum: new BigInteger(recv_o.amount) }
                                } else {
                                    a.pend__tx_frags_by_hash[tx.hash].tx_recv_sum.add(new BigInteger(recv_o.amount))
                                    did_change__totalsOrOtherProperties = true
                                }
                            } else { // then it's confirmed
                                if (!a.confd__tx_frags_by_hash[tx.hash] || typeof a.confd__tx_frags_by_hash[tx.hash] == 'undefined') {
                                    did_change__totalsOrOtherProperties = true
                                    a.confd__tx_frags_by_hash[tx.hash] = { tx_recv_sum: new BigInteger(recv_o.amount) }
                                } else {
                                    a.confd__tx_frags_by_hash[tx.hash].tx_recv_sum.add(new BigInteger(recv_o.amount))
                                    did_change__totalsOrOtherProperties = true
                                }
                            }
                            let now__pend__tx_recv_sum: BigInteger|undefined = a.pend__tx_frags_by_hash[tx.hash]?.tx_recv_sum
                            let now__confd__tx_recv_sum: BigInteger|undefined = a.confd__tx_frags_by_hash[tx.hash]?.tx_recv_sum
                            //
                            if ((prior__pend__tx_recv_sum && now__pend__tx_recv_sum && prior__pend__tx_recv_sum.compare(now__pend__tx_recv_sum) != 0) 
                                || (prior__pend__tx_recv_sum && !now__pend__tx_recv_sum) || (!prior__pend__tx_recv_sum && now__pend__tx_recv_sum) ) {
                                did_change__totalsOrOtherProperties = true
                            }
                            if ((prior__confd__tx_recv_sum && now__confd__tx_recv_sum && prior__confd__tx_recv_sum.compare(now__confd__tx_recv_sum) != 0) 
                                || (prior__confd__tx_recv_sum && !now__confd__tx_recv_sum) || (!prior__confd__tx_recv_sum && now__confd__tx_recv_sum) ) {
                                did_change__totalsOrOtherProperties = true
                            }
                            ////// }
                        }
                    }
                }
                { // here, cleaning up stale tx frags in case any txs disappeared
                    let dep_acc_keypaths = Object.keys(self.accountsByIdxKeyPath)
                    for (let keypath of dep_acc_keypaths) {
                        let a = self.accountsByIdxKeyPath[keypath]
                        if (a.confd__tx_frags_by_hash) {
                            let hashes = Object.keys(a.confd__tx_frags_by_hash)
                            for (let hash of hashes) {
                                if (exists__by__tx_hash[hash] != true) {
                                    delete a.confd__tx_frags_by_hash[hash]
                                    did_change__totalsOrOtherProperties = true
                                }
                            }
                        }
                        if (a.pend__tx_frags_by_hash) {
                            let hashes = Object.keys(a.pend__tx_frags_by_hash)
                            for (let hash of hashes) {
                                if (exists__by__tx_hash[hash] != true) {
                                    delete a.pend__tx_frags_by_hash[hash]
                                    did_change__totalsOrOtherProperties = true
                                }
                            }
                        }
                    }
                    // not changing accounts membership here in case of deletions just above
                }
            }
        }
        if (did_change__accounts_membership) {
            self.emit(DepositMonitor_Events.UpdatedAccountsListMembership)
        }
        if (did_change__totalsOrOtherProperties) {
            self.emit(DepositMonitor_Events.UpdatedAccountProperties)
        }
    }
    //
    // Imperatives - 

    async just_generateAndSaveAccountAddress(args: {
        optl__xmr_amount_decimal_str: string, 
        optl__accountLabel?: string, 
        __did_validate_basics__will___get_or_ensure_maj_idx_if_needed?: (exists__amount: boolean) => Promise<null|GenerateAndSaveAccountAddress_RetVal> // use this to validate e.g. request sending stuff
    }): Promise<GenerateAndSaveAccountAddress_RetVal> {
        const self = this
        //
        if (!self.proper_oper_addr || typeof self.proper_oper_addr === 'undefined' || !self.view_key_sec || typeof self.view_key_sec === 'undefined') {
            throw new Error("DepositMonitor::generateAndSaveAccountAddress called without a .proper_oper_addr or .view_key_sec")
        }
        //
        // TODO: validate / block until inbox_client is connected ?
        //
        let exists__amount = (args.optl__xmr_amount_decimal_str != null && args.optl__xmr_amount_decimal_str != "" && typeof args.optl__xmr_amount_decimal_str !== 'undefined')
        if (exists__amount) { // the amount is probably able to be considered valid already since it came from a currency input component but we may as well validate it here for safety
            try {
                let parsed_am = monero_amount_format_utils__instance.parseMoney(args.optl__xmr_amount_decimal_str)
                if (typeof parsed_am === null || typeof parsed_am === 'undefined') {
                    return {
                        status: GenerateAndSaveAccountAddress_RetVal_Status.invalidAmount
                    }
                }
            } catch (e) {
                return {
                    status: GenerateAndSaveAccountAddress_RetVal_Status.invalidAmount
                }
            }
        }
        if (args.__did_validate_basics__will___get_or_ensure_maj_idx_if_needed) {
            let r = await args.__did_validate_basics__will___get_or_ensure_maj_idx_if_needed(exists__amount)
            if (r) {
                return r // error
            }
        }
        //
        let r = await self._get_or_ensure_maj_idx_if_needed() // critical to 'await'
        if (!r.success) {
            return {
                status: r.retry 
                    ? GenerateAndSaveAccountAddress_RetVal_Status.failToGetMajIdx_retryLater
                    : GenerateAndSaveAccountAddress_RetVal_Status.failToGetMajIdx_noRetry
            }
        }
        // console.log("after _get_or_ensure_maj_idx_if_needed in generateAndSave,..... self.this_client__maj_idx! ", self.this_client__maj_idx!, self.addr)

        let maj_idx = self.this_client__maj_idx!
        let optl__min_idx = await self._given_maj_idx__get_noSet_next_unused_min_idx()
        if (optl__min_idx === null || typeof optl__min_idx === 'undefined') { // but making sure not to do "!optl__min_idx" since it could be 0!!
            return {
                status: GenerateAndSaveAccountAddress_RetVal_Status.failToObtainMinIdx
            }
        }
        let min_idx = optl__min_idx!
        //
        let md_r = await self.initParams.metadata_client.make_and_send_changes__xmrAddrAccount__justAdded__bailIfExists(
            self.proper_oper_addr!, maj_idx, min_idx, 
            args.optl__accountLabel
        )
        if (md_r.err__apparently_already_used != false) {
            throw new Error("This should never happen - somehow the _given_maj_idx__get_next_unused_min_idx is inconsistent with state available to make_and_send_changes__xmrAddrAccount__justAdded__bailIfExists")
            // TODO: potentially just increment the min idx and try again but this would probably be indicative of something fundamentally problematic..
        }
        //

        let retVals: GenerateAndSaveAccountAddress_RetVal = 
        {
            status: GenerateAndSaveAccountAddress_RetVal_Status.success, // TODO
            info: {
                maj_idx,
                min_idx,
                exists__amount
            }
        }

        return retVals
    }
    async generateAndSaveAccountAddress__optl_send_req(
        optl__xmr_amount_decimal_str: string, 
        optl__accountLabel?: string, 
        optl__requestNote?: string, 
        optl__send_as_msg_to_addr?: string
    ): Promise<GenerateAndSaveAccountAddress_RetVal> {
        const self = this
        let weakSelf = new WeakRef(self)
        let retVals = await self.just_generateAndSaveAccountAddress({
            optl__xmr_amount_decimal_str,
            optl__accountLabel,
            __did_validate_basics__will___get_or_ensure_maj_idx_if_needed: async (exists__amount: boolean) => {

                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return {
                        status: GenerateAndSaveAccountAddress_RetVal_Status.bailedAfterNilWeakDeref
                    }
                }
                if (optl__send_as_msg_to_addr && typeof optl__send_as_msg_to_addr !== 'undefined' && optl__send_as_msg_to_addr.length) {
                    let v = optl_self.initParams.inbox_client.toAddrValidation(optl_self.proper_oper_addr!, optl__send_as_msg_to_addr) // note, because this is a simple == check, public facing addr must be passed instead of the proper oper addr
                    switch (v) {
                        case Inbox_ToAddress_Validation.invalidToAddr_subaddrNotSupported:
                            return {
                                status: GenerateAndSaveAccountAddress_RetVal_Status.invalidToAddr_subaddrNotSupported
                            }
                        case Inbox_ToAddress_Validation.invalidToAddr_malformed:
                            return {
                                status: GenerateAndSaveAccountAddress_RetVal_Status.invalidToAddr_malformed
                            }
                        case Inbox_ToAddress_Validation.invalidSendMsgToSelf:
                            return {
                                status: GenerateAndSaveAccountAddress_RetVal_Status.invalidSendReqToSelfInbox
                            }
                        case Inbox_ToAddress_Validation.valid:
                            break // do nothing
                        default:
                            throw new Error("Unhandled Inbox_ToAddress_Validation")
                    }
                } else {
                    if (exists__amount || (optl__requestNote && typeof optl__requestNote !== 'undefined' && optl__requestNote.length)) {
                        return {
                            status: GenerateAndSaveAccountAddress_RetVal_Status.destAddrRequiredIfNoteOrAmountSpecified
                        }
        
                    }
                }
                return null // ok
            }
        })
        if (retVals.status == GenerateAndSaveAccountAddress_RetVal_Status.success) {
            let { maj_idx, min_idx } = retVals.info!
            let optl__req_msg_dualOrOriginal: StorableMessage|undefined = undefined
            if (optl__send_as_msg_to_addr != null && optl__send_as_msg_to_addr != '' && typeof optl__send_as_msg_to_addr !== 'undefined') {
                // by now we've validated that optl__send_as_msg_to_addr is a good to_a
                let dest_addr_str = self.lazy_subaddr_hex_from(maj_idx, min_idx)
                let req: StructuredMessageJSON_xmrRequest__recipient = 
                {
                    t: StructuredMessage_TypeKeyValues.xmrRequest,
                    s: dest_addr_str,
                }
                // ^-- and v--- ... variations for both the sender and recipient - since sender one can contain maj/min idxs instead of the .s (dest_addr_str)
                let dual_spcfc_req: StructuredMessageJSON_xmrRequest__dualSpecific =
                {
                    t: StructuredMessage_TypeKeyValues.xmrRequest,
                    ji: maj_idx.toString(), // must BigInt -> string otherwise the message will contain an incorrect complex JSON structure
                    ni: min_idx.toString() // must BigInt -> string otherwise the message will contain an incorrect complex JSON structure
                }
                if (optl__requestNote != '' && optl__requestNote != null && typeof optl__requestNote !== 'undefined') {
                    req.n = optl__requestNote!
                    dual_spcfc_req.n = optl__requestNote!
                }
                if (retVals.info!.exists__amount) {
                    req.c = StructuredMessageJSON_shared__c__ccyTypes.XMR
                    req.am = optl__xmr_amount_decimal_str! // TODO: parse or transform this at all?
                    dual_spcfc_req.c = StructuredMessageJSON_shared__c__ccyTypes.XMR
                    dual_spcfc_req.am = optl__xmr_amount_decimal_str!
                }
                let i_r = await self.initParams.inbox_client.send_msgs([
                    {
                        from__address: self.proper_oper_addr!, // this used to be self.addr! but because a view only wallet is truly offline, and sending msgs online cant be supported here, it's better to ensure consistency of the addr, so the proper oper addr is used here 
                        to_a: optl__send_as_msg_to_addr!,
                        send_json: req,
                        dual_spcfc_send_json: dual_spcfc_req,
                        //
                        auto_retry: false // for now - since the user can just see it on the screen and retry it
                    }
                ])
                //
                // provided send prep phase actually finished...?
                //
                // obtain the msg_uuid of the dual (or the ephem_uuid of the original if prep failed, which can happen for legit reasons) so that it can be navigated to
                console.log("i_r" , i_r)
                if (i_r.enqueued_msg_uuids.length || i_r.failed_to_enqueue_ephem_uuids.length) {
                    // now search within these uuids for those messages within the inbox - and check which one of them is the dual - then navigate to that
                    //
                    // we'd also be able to narrow down this search to simply getting the messages directly by uuid or at least by getting the ones within the thread to the visible to-addr
                    let msgs = self.initParams.inbox_client.storable_messages__by__oper_addr[self.proper_oper_addr!]
                    if (msgs) { // since it can sometimes not exist yet 



                        // TODO: optimize these searches by having a cache in inbox_client of msgs by msg_uuid and by ephem_uuid  -- maybe we could call that an 'AddressableUUID' and 'EphemUUID' can inherit along with 'MsgHashUUID'


                        for (let msg_uuid of i_r.enqueued_msg_uuids) {
                            for (let m of msgs) {
                                if (m.msg_uuid && m.msg_uuid == msg_uuid) {
                                    if (m.to_a == self.proper_oper_addr!) {
                                        // this is the msg dual that should be navigated to 
                                        optl__req_msg_dualOrOriginal = m
                                        if (m.decr_t_a !== optl__send_as_msg_to_addr) {
                                            console.error("ERROR: A dual of the request msg was found but the dual did not have a .msg.t_a that equaled the destination addr of the original request message! Is this really the dual?")
                                            console.log(JSON.stringify(m, null, '  '))
                                            // should be fine if we don't throw here even though it's unexpected because navigating to the wrong sent message would land us on the chat thread anyway
                                        }
                                    }
                                }
                            }
                        }
                        if (!optl__req_msg_dualOrOriginal) {
                            for (let ephem_uuid of i_r.failed_to_enqueue_ephem_uuids) {
                                for (let m of msgs) {
                                    if (m.ephem_uuid == ephem_uuid) {
                                        optl__req_msg_dualOrOriginal = m // we'll expect this to be a failed-to-enqueue 
                                        if (m.to_a !== optl__send_as_msg_to_addr) {
                                            console.error("ERROR: An original of the request msg was found but it did not have a .msg.to_a that equaled the destination addr of the original request message! Is this really the original?")
                                            console.log(JSON.stringify(m, null, '  '))
                                            // should be fine if we don't throw here even though it's unexpected because navigating to the wrong sent message would land us on the chat thread anyway
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // console.log("~~~~~~~~~~~> optl__req_msg_dualOrOriginal" , optl__req_msg_dualOrOriginal)
            if (optl__req_msg_dualOrOriginal) {
                let to_addr = optl__send_as_msg_to_addr! 
                let req_ephem_uuid_or_msg_uuid!: string
                if (optl__req_msg_dualOrOriginal.ephem_uuid) { // then it's an original - and we already checked the .to_a above
                    // TODO: we could assert here that the .status is pre-enqueuement 
                    req_ephem_uuid_or_msg_uuid = optl__req_msg_dualOrOriginal.ephem_uuid
                } else { // then we're looking at the dual - and we already checked the .decr_t_a above
                    req_ephem_uuid_or_msg_uuid = optl__req_msg_dualOrOriginal!.msg_uuid!
                }
                // console.log(">>>>>>>>>>>>>>>>>>> req_ephem_uuid_or_msg_uuid" , req_ephem_uuid_or_msg_uuid)
                retVals.info!.optl__req_uuid_in_UI = req_ephem_uuid_or_msg_uuid
                retVals.info!.optl__nav_to_addr = to_addr
            }
        }

        return retVals
    }
    //
    // Imperatives - Scanner
    async _PUT_toScanner_subaddrIdcs()
    {
        const self = this
        let min_idxs_by_maj_idx_strs: { [key: string]: BigInteger[] } = {}
        let keypaths = Object.keys(self.accountsByIdxKeyPath)
        for (var i = 0 ; i < keypaths.length ; i++) {
            let keypath = keypaths[i]
            let a = self.accountsByIdxKeyPath[keypath]
            let maj_idx_str = a.maj_i.toString()
            if (!min_idxs_by_maj_idx_strs[maj_idx_str]) {
                min_idxs_by_maj_idx_strs[maj_idx_str] = []
            }
            min_idxs_by_maj_idx_strs[maj_idx_str].push(a.min_i)
        }
        let maj_idx_strs = Object.keys(min_idxs_by_maj_idx_strs)
        let min_idx_intvls_by_maj_idx_strs: { [maj_idx__str: string]: IndexInterval[] } = {}
        let atomic_doc_plain_json = self.initParams.inbox_client.decr_atomic_doc__plain_json__for(self.proper_oper_addr!)
        let atomic_doc__scnr_rngs = atomic_doc_plain_json ? atomic_doc_plain_json[WalletAtomicDoc_RootKeys.scnr_rngs]: undefined
        let dePrepped__atomic_doc__scnr_rngs = atomic_doc__scnr_rngs ? IndexIntervals.parseStringifyPrepped_complex(atomic_doc__scnr_rngs) : undefined // first we must convert the strings to BigIntegers
        for (let maj_idx_str of maj_idx_strs) {
            let min_idxs = min_idxs_by_maj_idx_strs[maj_idx_str]
            let intervals = IndexIntervals.newIntervalsFromIdcs(min_idxs)
            //
            min_idx_intvls_by_maj_idx_strs[maj_idx_str] = intervals
            // console.log("[DEBUG] Discovered accounts to produce .PUT intervals min_idx_intvls_by_maj_idx_strs["+maj_idx_str+"]", min_idx_intvls_by_maj_idx_strs[maj_idx_str].map(ii => IndexIntervals.stringifyPrep(ii)))
            //
            // and then union the accountsByIdxKeyPath with any .last_known_scanner_originating_rngs and any currently known atomic_doc scnr_rngs
            if (self.last_known_scanner_originating_rngs && self.last_known_scanner_originating_rngs[maj_idx_str]?.length) {
                min_idx_intvls_by_maj_idx_strs[maj_idx_str] = IndexIntervals.new_withUnionOf(
                    min_idx_intvls_by_maj_idx_strs[maj_idx_str], 
                    self.last_known_scanner_originating_rngs[maj_idx_str]! // which we expect to have non-zero length
                )
                // console.log("[DEBUG] Unioning with last_known_scanner_originating_rngs["+maj_idx_str+"] to yield", min_idx_intvls_by_maj_idx_strs[maj_idx_str].map(ii => IndexIntervals.stringifyPrep(ii)))
            }
            if (dePrepped__atomic_doc__scnr_rngs && dePrepped__atomic_doc__scnr_rngs[maj_idx_str]?.length) {
                
                // console.log("dePrepped__atomic_doc__scnr_rngs[" + maj_idx_str + "]!", dePrepped__atomic_doc__scnr_rngs[maj_idx_str]!.map(ii => IndexIntervals.stringifyPrep(ii)))

                min_idx_intvls_by_maj_idx_strs[maj_idx_str] = IndexIntervals.new_withUnionOf(
                    min_idx_intvls_by_maj_idx_strs[maj_idx_str], 
                    dePrepped__atomic_doc__scnr_rngs[maj_idx_str]! // which we expect to have non-zero length
                )
                // console.log("[DEBUG] Unioning with atomic_doc[.scnr_rngs]["+maj_idx_str+"] to yield", min_idx_intvls_by_maj_idx_strs[maj_idx_str].map(ii => IndexIntervals.stringifyPrep(ii)))
            }   
        }

        // console.log("~~~> within PUT self.this_client__maj_idx", self.this_client__maj_idx ? "(bigint) " + self.this_client__maj_idx.toString() : "(undefined)")

        if (self.this_client__maj_idx) { // there hopefully is one by this point but maybe there isn't
            // I'm going to assume metadata document has already been scanned for accounts by here since that happens after initialization and after waiting for the metadata doc to appear
            let that_maj__top_min_i: BigInteger|undefined = self.find_that_maj__top_known_used_min_i()
            // console.log("in _PUT_toScanner_subaddrIdcs ~~> that_maj__top_min_i:" , that_maj__top_min_i ? that_maj__top_min_i.toString() : '-undef-')
            //
            let maj_idx_str = self.this_client__maj_idx.toString()
            let used__min_i__or0 = that_maj__top_min_i && typeof that_maj__top_min_i !== 'undefined' ? that_maj__top_min_i : new BigInteger(0)
            let bracketSpan = new BigInteger(500)
            let one = new BigInteger(1)
            let roundingDenominator = bracketSpan.subtract(one)
            let divRem_ret = used__min_i__or0.divRem(roundingDenominator)
            let quotient = divRem_ret[0]
            let remainder = divRem_ret[1]
            let zero = new BigInteger(0)
            // console.log("in PUT... self.that_maj_idx__top__min_idx" , (self.that_maj_idx__top__min_idx ? "(bigint) " + self.that_maj_idx__top__min_idx.toString() : "(undefined)"), "; quotient " , quotient, "quotient.multiply(bracketSpan).add(remainder.compare(zero) > 0 ? bracketSpan : zero)", 
            //     quotient
            //         .multiply(bracketSpan)
            //         .add(remainder.compare(zero) > 0  // this is the round-up behavior; round up to nearest 500, plus round 500 to 1000, etc - mimicking the lookahead - in case the scanner doesn't have it?
            //             ? bracketSpan 
            //             : zero).toString()
            // )
            let needed_upper_min_i = typeof that_maj__top_min_i == 'undefined' 
                ? zero // PS: I'm just picking 'one' instead of 'zero' so as to have a tiny bit of info in the range as to whether it was caused by an undefined self.that_maj_idx__top__min_idx
                : used__min_i__or0.compare(zero) == 0 // then this means the first idx has been used
                    ? bracketSpan // if any subaddrs for this maj idx have been provisioned at all, then round up? I used to use 'one' here
                    : quotient
                        .multiply(bracketSpan)
                        .add(remainder.compare(zero) > 0  // this is the round-up behavior; round up to nearest 500, plus round 500 to 1000, etc - mimicking the lookahead - in case the scanner doesn't have it?
                            ? bracketSpan 
                            : zero)
            ;
            let base_needed_intervals = [ IndexIntervals.newWith(new BigInteger(0), needed_upper_min_i) ]
            // console.log(">> per provisioned, base_needed_intervals", base_needed_intervals.map(ii => IndexIntervals.stringifyPrep(ii)))
            if (!min_idx_intvls_by_maj_idx_strs[maj_idx_str] || typeof min_idx_intvls_by_maj_idx_strs[maj_idx_str] == 'undefined') {
                // console.log("!min_idx_intervals")
                min_idx_intvls_by_maj_idx_strs[maj_idx_str] = base_needed_intervals // at least [0,500] for used_min_i < 499, etc
            } else { // or if it exists, then union it, just in case some other provider source from above thinks the range should be even bigger
                // console.log("union")
                min_idx_intvls_by_maj_idx_strs[maj_idx_str] = IndexIntervals.new_withUnionOf(
                    min_idx_intvls_by_maj_idx_strs[maj_idx_str], 
                    base_needed_intervals
                )
            }
            // console.log("min_idx_intvls_by_maj_idx_strs[" + maj_idx_str + "]", min_idx_intvls_by_maj_idx_strs[maj_idx_str].map(ii => IndexIntervals.stringifyPrep(ii)))
        }
        //
        if (Object.keys(min_idx_intvls_by_maj_idx_strs).length > 0) { // assume that we have some to PUT if there are any maj idxs?
            // console.log("[DEBUG] [::_PUT_toScanner_subaddrIdcs()] min_idx_intvls_by_maj_idx_strs" , IndexIntervals.stringifyPrep_complex(min_idx_intvls_by_maj_idx_strs))
            //
            // Now, before we actually .PUT to the scanner, let's check if we can tell we don't need to do so - such as, if we already have .PUT before and the responded-with scnr_rngs already cover what's just been derived:
            // console.log("~~~> self.last_known_scanner_originating_rngs" , self.last_known_scanner_originating_rngs)
            // console.log("~~~> min_idx_intvls_by_maj_idx_strs" , min_idx_intvls_by_maj_idx_strs)
            if (self.last_known_scanner_originating_rngs) {
                if (IndexIntervals.isDeepEqual_complexIntervals(self.last_known_scanner_originating_rngs, min_idx_intvls_by_maj_idx_strs)) {
                    // So, locally, we don't know of any need to .PUT these ... and provided we have an active subaddr subscr to allow the scanner to back up to the atomic_doc, we don't need to worry about using a .PUT to get the subsequent backup of a .PUT 
                    console.warn("self.last_known_scanner_originating_rngs === min_idx_intvls_by_maj_idx_strs so skipping .PUT to scanner")
                    // console.log("self.last_known_scanner_originating_rngs appears to be deeply equal to min_idx_intvls_by_maj_idx_strs, so bailing before doing a .PUT of the latter to the server")
                    // console.log("self.last_known_scanner_originating_rngs", self.last_known_scanner_originating_rngs)
                    // console.log("min_idx_intvls_by_maj_idx_strs", min_idx_intvls_by_maj_idx_strs)
                    return
                }
            } else {
                // console.log("[DEBUG] [::_PUT_toScanner_subaddrIdcs()] no self.last_known_scanner_originating_rngs yet so not checking if we can avoid the .PUT by diffing it with the update")
            }
            //
            let r = await self.initParams.upsert_scanner_subaddr_min_idcs__fn(min_idx_intvls_by_maj_idx_strs)
            // console.log("r is ", IndexIntervals.stringifyPrep_complex(r.scnr_rngs!))
            if (r.status == ScannerRes_StatusCode.success) {
                if (!r.scnr_rngs) {
                    console.error("expected non-nil r.scnr_rngs on resp from .upsert_scanner_subaddr_min_idcs__fn, so cannot back them up, but this is likely a server or client code fault since an upsert requires a req which would end up appearing in the resp anyway")
                } else {
                    // now, just to be careful of malicious scanners getting us to write arbitrary stuff into the atomic doc... let's validate this a little?
                    let inc_scnr_rngs: { [key: string]: IndexInterval[] } = r.scnr_rngs!
                    if (typeof inc_scnr_rngs !== 'object') {
                        console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected an object back as .scnr_rngs")
                        return
                    }
                    let maj_idx_strs = Object.keys(inc_scnr_rngs)
                    for (let maj_idx_str of maj_idx_strs) {
                        if (typeof maj_idx_str !== 'string') {
                            console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected strings as keys of .scnr_rngs")
                            return
                        }
                        // not going to attempt new BigInteger(..) on maj_str since it could be CPU heavy
                        let intervals = inc_scnr_rngs[maj_idx_str]
                        if (Array.isArray(intervals) != true) {
                            console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected key for maj idx str to be an array")
                            return
                        }
                        for (let intvl of intervals) {
                            if (Array.isArray(intvl) != true) {
                                console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected maj idx key value element to be an array.")
                                return
                            }
                            if (intvl.length != 2) {
                                console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected maj idx key value element array to have 2 members.")
                                return
                            }
                            if (!(intvl[0] instanceof BigInteger) || !(intvl[1] instanceof BigInteger)) {
                                console.warn("WARNING: Possibly malicious scanner. In .PUT resp, expected maj idx key value element array members to both be numbers.")
                                return
                            }
                        }
                    }
                    // ok, looks valid..
                    //
                    self.last_known_scanner_originating_rngs = inc_scnr_rngs
// TODO: in case of untrusted or faulty server, quite possibly do a union on inc_scnr_rngs, but... it will be unioned anyway in ._try_backing_up_last_known_scanner_scanner_originating_ranges()
                    /*await */self._try_backing_up_last_known_scanner_scanner_originating_ranges()
                }
            } else {
                // console.error("Saw non-success status from .PUT of derived scnr_rngs to scanner. This should be retried later.")
            }
        } else {
            console.log("No subaddr idcs to PUT to the scanner - skipping")
        }
    }
    // Imperatives - Scanner -> Backup
    async _try_backing_up_last_known_scanner_scanner_originating_ranges()
    {
        const self = this
        // we do not need to bother trying to accomplish the union with the contents of the atomic doc since we can use the heavy-duty machinery of the atomic_doc retryable mod op to attempt the union against the remote doc when it is capable of obtaining a write-lock and persist the work until then (meaning we dont need to rely on .last_known_scanner_originating_rngs being persisted, despite it probably being a useful failsafe for atomic_doc .scnr_rngs availability/connectivity plus recency) - and it checks if the change is known redundant based on local data and upon obtaining the lock for writing 
        //
        if (!self.last_known_scanner_originating_rngs) {
            console.warn("Asked to _try_backing_up_last_known_scanner_scanner_originating_ranges but there were no .last_known_scanner_originating_rngs")
            return
        }
        let actions: AtomicDocRetryableModOpAction[] = []
        let maj_idxs = Object.keys(self.last_known_scanner_originating_rngs)
        for (let maj_idx_str of maj_idxs) {
            // console.log("maj_idx_str" , maj_idx_str)
            let mins = self.last_known_scanner_originating_rngs[maj_idx_str]
            let stringifyPrepped__mins_for_maj = mins.map(ii => IndexIntervals.stringifyPrep(ii))
            // console.log("stringifyPrepped__mins_for_maj" , stringifyPrepped__mins_for_maj)
            actions.push({
                operation: AtomicDocRetryableModOpActionType.unionIdxIntvls,
                keypath: [WalletAtomicDoc_RootKeys.scnr_rngs, maj_idx_str],
                value: stringifyPrepped__mins_for_maj // we must convert BigIntegers to strings, and back again on reads/usages
            })
        }
        await self.initParams.inbox_client.atomic_doc__add_retryable_op(self.proper_oper_addr!, {
            uuid: New_AtomicDocRetryableModOp_UUID(),
            actions: actions
        })
    }
    //
    // Delegates 
    public walletSays_didUpdateWalletTxs(all_sorted_txs: Monero_Tx[], new_and_changed_txs: Monero_Tx[])
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays_didUpdateWalletTxs called after self.is_torn_down... Bailing")
            return 
        }
        // console.log("~~~~~~~~~~~> deposit mon walletSays_didUpdateWalletTxs ", all_sorted_txs)
        self.ephem__all_sorted_txs = all_sorted_txs
        //
        let weakSelf = new WeakRef(self)
        let accountProviders_fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of optl_self.accountProviders) {
                if (a instanceof ScannedTxs_DepositAccountProvider) { // these look up all_sorted_txs via a callback the deposit mon passes them to look up the current state
                    await (a as ScannedTxs_DepositAccountProvider).deposit_mon_says__source_did_update_scanned_tx() // manual proxy
                }
            }
        }
        accountProviders_fn()
        //
        // and also have the accounting itself updated
        self.setNeedsAccountingUpdate()
    }
    public walletSays__metadataDoc_modifiedLocally()
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays__metadataDoc_modifiedLocally called after self.is_torn_down... Bailing")
            return 
        }
        let weakSelf = new WeakRef(self)
        let fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of self.accountProviders) {
                if (a instanceof Metadata_DepositAccountProvider) {
                    await (a as Metadata_DepositAccountProvider).walletSays__metadataDoc_modifiedLocally() // manual proxy
                }
            }
        }
        fn()
    }
    public walletSays__metadataDoc_remotelyUpdatedAfterInit()
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays__metadataDoc_remotelyUpdatedAfterInit called after self.is_torn_down... Bailing")
            return 
        }
        let weakSelf = new WeakRef(self)
        let fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of self.accountProviders) {
                if (a instanceof Metadata_DepositAccountProvider) {
                    await (a as Metadata_DepositAccountProvider).walletSays__metadataDoc_remotelyUpdatedAfterInit() // manual proxy
                }
            }
        }
        fn()
    }
    public walletSays__inbox_client__newly_decrd_or_authd_msgs()
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays__metadataDoc_remotelyUpdatedAfterInit called after self.is_torn_down... Bailing")
            return 
        }
        let weakSelf = new WeakRef(self)
        let fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of optl_self.accountProviders) {
                if (a instanceof InboxRequests_DepositAccountProvider) {
                    await (a as InboxRequests_DepositAccountProvider).walletSays__inbox_client__newly_decrd_or_authd_msgs() // manual proxy
                }
            }
        }
        fn()
    }
    public walletSays__atomicDoc_updated_serverOrigin()
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays__atomicDoc_updated_serverOrigin (server origin) called after self.is_torn_down... Bailing")
            return 
        }
        let weakSelf = new WeakRef(self)
        let fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of optl_self.accountProviders) {
                if (a instanceof Metadata_DepositAccountProvider) {
                    await (a as Metadata_DepositAccountProvider).walletSays__atomicDoc_updated_serverOrigin() // manual proxy
                } else if (a instanceof Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider) {
                    await (a as Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider).walletSays__atomicDoc_updated_serverOrigin() // manual proxy
                }
            }
        }
        fn()
    }
    public walletSays__atomicDoc_updated_localOrigin()
    {
        const self = this
        if (self.is_torn_down) {
            console.error("Possibly throw? walletSays__atomicDoc_updated_localOrigin called after self.is_torn_down... Bailing")
            return 
        }
        let weakSelf = new WeakRef(self)
        let fn = async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            for (let a of optl_self.accountProviders) {
                if (a instanceof Metadata_DepositAccountProvider) {
                    await (a as Metadata_DepositAccountProvider).walletSays__atomicDoc_updated_localOrigin() // manual proxy
                } else if (a instanceof Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider) {
                    await (a as Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider).walletSays__atomicDoc_updated_localOrigin() // manual proxy
                }
            }
        }
        fn()
    }
}
//
interface DepositMonitor_InitParams
{
    // for discovering requests sent and received, and subaddr annotations:
    inbox_client: InboxClient, 
    metadata_client: AppWalletMetadataClient,
    monero_core_bridge_instance: any,
    nettype: any,
    //
    // scanner proxies:
    upsert_scanner_subaddr_min_idcs__fn: (
        min_idx_intervals_by_maj_idx: { [maj_idx__str: string]: IndexInterval[] } 
    ) => Promise<AddScannerSubaddrIdcsRes>,
    // get_scanner_known_idcs__fn: () => Promise<GETScannerSubaddrIdcsRes> // commented for now since we get the response from the scanner and place it into the atomic_doc anyway and might not have any other local use for knowledge of what the scanner is scanning since maj idxs are also provisioned (separately) via the atomic_doc
    // synchd__provision_subaddr_maj__fn: () => Promise<ProvisionedMajIdxRes>, // other clients would want this but we dont need it
    //
    // and instantiators: call deposit_mon.didUpdateWalletTxs(...) when scanner informs of updates
}
//
class EnsureBaseAcct_DepositAccountProvider implements DepositAccountProvider
{ // the purpose of this provider is to ensure the existence of a (0,0) account in case no txs exist yet
    //
    // Interface - Properties
    didDiscoverAccounts_fn!: (accounts: XMRDepositAccount_Base[]) => void
    //
    // init
    //
    // state
    //
    constructor(
    ) {
        const self = this
    }
    async teardown()
    {
        // anything to do here?
    }
    //
    // Interface Implementation
    public async startDiscoveringAccounts()
    {
        const self = this
        if (!self.didDiscoverAccounts_fn) {
            throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
        }
        let a =
        {
            min_i: new BigInteger(0),
            maj_i: new BigInteger(0),
            is_merely_scnr_rng_originating: false
        }
        self.didDiscoverAccounts_fn([ a ])
    }
}
//
class InboxRequests_DepositAccountProvider implements DepositAccountProvider
{
    //
    // Interface - Properties
    didDiscoverAccounts_fn!: (accounts: XMRDepositAccount_Base[]) => void
    //
    proper_oper_addr?: string
    inbox_client!: InboxClient
    //
    constructor(
        proper_oper_addr: string,
        inbox_client: InboxClient,
    ) {
        const self = this
        self.proper_oper_addr = proper_oper_addr
        self.inbox_client = inbox_client
    }
    async teardown()
    {
        const self = this
        // stop observing inbox_client here not required since we're not observing it directly but relying on consumer's method calls
        self.proper_oper_addr = undefined
    }
    //
    // Interface Implementation
    public async startDiscoveringAccounts()
    {
        const self = this
        if (!self.didDiscoverAccounts_fn) {
            throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
        }
        // no need to manually observe inbox here for updates since the deposit_mon calls delegate methods
        // but we must also trigger the first discover here
        self.discover()
    }
    //
    // Imperatives 
    async discover()
    {
        const self = this
        // check is_torn_down?
        let deposit_accounts_by_keypath: { [key: string]: XMRDepositAccount_Base } = {}
        //
        let oper_addr = self.proper_oper_addr! // expected to be non-torn-down by here
        //
        let msgs = self.inbox_client.storable_messages__by__oper_addr[oper_addr] 
        if (!msgs) {
            self.didDiscoverAccounts_fn([])
            return
        }
        let _uuids_of_invalid_msgs_without__sender_addr: string[] = []
        for (var i = 0 ; i < msgs.length ; i++) {
            let m = msgs[i]
            if (typeof m.auth_state === 'undefined' || m.auth_state === null) { // we'll expect these to be things like failed to prep, i.e., an original still, not split into a pair
                // console.log("auth state is undefined on this:", m)
            }
            if (m.auth_state) {
                switch (m.auth_state) {
                    case DecrdMsgAuthProgressState.authd_fromOther_trustFromA: // skip this - known to be an incoming message
                    case DecrdMsgAuthProgressState.authd_fromSelf_alsoToSelf_notDual: // skip these - metadata messages - we're only interested in outgoing messages
                        continue
                    default:
                        break
                }
            } else {
                // no auth state we know we can skip
            }

            let isOutgoingOriginal_notYetSplitIntoDualAndTwin = Inbox.StorableMessages.IsOutgoingOriginal_notYetSplitIntoDualAndTwin(m, oper_addr)
            let m_counterpartyAddr: string|undefined = Inbox.StorableMessages.CounterpartyAddrFor(m, isOutgoingOriginal_notYetSplitIntoDualAndTwin)
            if (!m_counterpartyAddr || typeof m_counterpartyAddr === 'undefined') {
                // console.warn("Message had no discernable sender_addr. m is ", m)
                if (m.msg_uuid) { _uuids_of_invalid_msgs_without__sender_addr.push(m.msg_uuid) } // for debug/ops
                continue
            }
            if (m_counterpartyAddr === oper_addr) {
                console.log("Skipping this msg since the msg counterparty was the oper_addr - that should not really have happened - it would only happen if there's a metadata message that hasn't been prepped for send yet and thus doesn't have an .auth_state")
                continue
            }
            let senderAddr = Inbox.StorableMessages.SenderAddrFor(m, oper_addr, m_counterpartyAddr, isOutgoingOriginal_notYetSplitIntoDualAndTwin)
            let is_outgoing = Inbox.StorableMessages.IsOutgoing(oper_addr, senderAddr)
            if (!is_outgoing) {
                // only looking for outgoing msgs
                // console.log("[DEBUG] skipping msg since the sender was not the oper_addr - only looking for outgoing msgs here")
                continue
            }
            let oper_facing_json__orUndefToContinue = Inbox.StorableMessages.OperatorFacingSentJSON(m, is_outgoing)
            if (!oper_facing_json__orUndefToContinue || typeof oper_facing_json__orUndefToContinue === 'undefined') {
                continue // would have been logged already
            }
            let oper_facing_json = oper_facing_json__orUndefToContinue!
            // console.log("~> str_as_json", str_as_json)
            if (oper_facing_json.t !== StructuredMessage_TypeKeyValues.xmrRequest) {
                // console.log("Skipping messages since it didn't have a .t of .xmrRequest")
                continue
            }
            let dual_spcfc_req = oper_facing_json as StructuredMessageJSON_xmrRequest__dualSpecific
            if ((typeof dual_spcfc_req.ji === 'undefined' || dual_spcfc_req.ji === null || (dual_spcfc_req.ji as any) === '') || (typeof dual_spcfc_req.ni === 'undefined' || dual_spcfc_req.ni === null || (dual_spcfc_req.ni as any) === '')) {
                console.warn("[WARN] Apparently malformed dual of request as it was lacking a .ji and/or .ni", oper_facing_json)
                continue
            }
            let idx_maj = new BigInteger(""+dual_spcfc_req.ji)
            let idx_min = new BigInteger(""+dual_spcfc_req.ni)
            let keypath = AccountIdxKeyPathFromIdcs(idx_maj, idx_min)
            let deposit_account: XMRDepositAccount_Base = deposit_accounts_by_keypath[keypath] || {
                maj_i: idx_maj,
                min_i: idx_min,
                is_merely_scnr_rng_originating: false
            }
            if (typeof deposit_account.reqs === 'undefined') {
                deposit_account.reqs = [] // since we know we have a req msg to push
            }
            let uuid = m.msg_uuid ? m.msg_uuid : m.ephem_uuid
            if (!uuid) {
                console.warn("Expected a uuid on a req message. This will fare badly in the UI.")
            }
            let req_partial: XMRDepositRequest = 
            {
                req_uuid_in_UI: uuid!,
                req_msg_twin_has_been_sent: Inbox.StorableMessages.GivenOutgoing_CanAssumeTwinsHaveBeenSent(m, isOutgoingOriginal_notYetSplitIntoDualAndTwin),
                of_addr: m_counterpartyAddr,
            }
            if (dual_spcfc_req.am && typeof dual_spcfc_req.am !== 'undefined') {
                if (dual_spcfc_req.c != StructuredMessageJSON_shared__c__ccyTypes.XMR) {
                    console.error("[ERROR] Request came in with an .am (amount) but an unexpected ccy. Skipping adding the amount.")
                }
                // parsing the amount here as a float str for xmr atomic units and add to the .expctd_xmr in case multiple requests for the subaddr have been sent for some reason - just in case - but it's not recommended behavior
                try {
                    let parsed_am = monero_amount_format_utils__instance.parseMoney(dual_spcfc_req.am)
                    if (typeof parsed_am === 'undefined' || parsed_am === null) {
                        console.error("[ERROR] Apparently sent an unparseable money amount somehow; Ignoring.")
                        continue
                    }
                    req_partial.xmr = parsed_am
                } catch (e) {
                    console.error("[ERROR] Apparently sent an unparseable money amount somehow; Ignoring.")
                    continue
                }                                    
            } else {
                // console.log("[DEBUG] Found an authd dual msg with a .t == .xmrRequest with .ji,.ni but no amount - which may be fine.")
            }
            deposit_account.reqs.push(req_partial)
            deposit_accounts_by_keypath[keypath] = deposit_account // in case it wasn't set before
        }
        if (_uuids_of_invalid_msgs_without__sender_addr.length) {
            console.log("DEBUG: _uuids_of_invalid_msgs_without__sender_addr: " + _uuids_of_invalid_msgs_without__sender_addr.join(","))
        }
        //
        self.didDiscoverAccounts_fn(Object.values(deposit_accounts_by_keypath))
    }
    //
    public async walletSays__inbox_client__newly_decrd_or_authd_msgs()
    {
        const self = this
        // console.log("~~~~~~~~~~~~~~~~~~> [InboxRequests_DepositAccountProvider/walletSays__inbox_client__newly_decrd_or_authd_msgs]")
        /*await */self.discover()
    }
}
//
class Metadata_AtomicDoc_ScnrRngs_MajIdcs_AccountProvider implements DepositAccountProvider
{
    //
    // Interface - Properties
    didDiscoverAccounts_fn!: (accounts: XMRDepositAccount_Base[]) => void
    //
    _lookup__scnr_rngs__fn!: () => { [key: string]: IndexInterval[] } | undefined
    //
    proper_oper_addr: string|undefined
    //
    constructor(
        proper_oper_addr: string,
        lookup__scnr_rngs__fn: () => { [key: string]: IndexInterval[] } | undefined
    ) {
        const self = this
        self.proper_oper_addr = proper_oper_addr
        self._lookup__scnr_rngs__fn = lookup__scnr_rngs__fn
    }
    async teardown()
    {
        const self = this
        // stop observing metadata client here not required since we're not observing it directly but relying on consumer's method calls
        self.proper_oper_addr = undefined
    }
    //
    // Interface Implementation
    public async startDiscoveringAccounts()
    {
        const self = this
        if (!self.didDiscoverAccounts_fn) {
            throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
        }
        self.discover()
    }
    //
    // Imperatives 
    async discover()
    {
        const self = this
        let parsed_rngs = self._lookup__scnr_rngs__fn()
        // console.log("~~> parsed_rngs", parsed_rngs)
        //
        let deposit_accounts_by_keypath: { [key: string]: XMRDepositAccount_Base } = {}
        if (parsed_rngs) {
            let maj_idx_strs = Object.keys(parsed_rngs)
            for (let maj_idx_str of maj_idx_strs) {
                let idx_maj = new BigInteger(maj_idx_str)
                let idx_min = new BigInteger(0)
                // let min_idcs_IIs = parsed_rngs[maj_idx_str]
                // for (let min_idcs_II of min_idcs_IIs) {
                //     let upper = IndexIntervals.upperOf(min_idcs_II)
                //     for (let idx_min = IndexIntervals.lowerOf(min_idcs_II); idx_min.compare(upper) != 0 ; idx_min = idx_min.add(new BigInteger(1))) {
                        let keypath = AccountIdxKeyPathFromIdcs(idx_maj, idx_min)
                        if (deposit_accounts_by_keypath[keypath] && typeof deposit_accounts_by_keypath[keypath] !== 'undefined') {
                            console.warn("WARNING: A duplicate of an account was somehow found within metadata_xmrAddrAccounts by Metadata_DepositAccountProvider")
                        }
                        let deposit_account: XMRDepositAccount_Base = deposit_accounts_by_keypath[keypath] ||
                        {
                            maj_i: idx_maj,
                            min_i: idx_min,
                            is_merely_scnr_rng_originating: true // this is set to false by the account discovered by any other provider
                        }
                        deposit_accounts_by_keypath[keypath] = deposit_account // doing this to dedupe
                //     }
                // }
            }
        }
        // console.log("discovered deposit_accounts_by_keypath" , deposit_accounts_by_keypath)
        //
        self.didDiscoverAccounts_fn(Object.values(deposit_accounts_by_keypath))
    }
    //
    public async walletSays__atomicDoc_updated_serverOrigin()
    {
        const self = this
        await self.discover()
    }
    public async walletSays__atomicDoc_updated_localOrigin()
    {
        const self = this
        await self.discover()
    }
}
//
class Metadata_DepositAccountProvider implements DepositAccountProvider
{
    //
    // Interface - Properties
    didDiscoverAccounts_fn!: (accounts: XMRDepositAccount_Base[]) => void
    _lookup__used_maj_idx_intvls__fn!: () => IndexInterval[]|undefined
    //
    appWallet_metadata_client!: AppWalletMetadataClient
    //
    oper_addr!: string|undefined
    //
    constructor(
        oper_addr: string,
        appWallet_metadata_client: AppWalletMetadataClient,
        lookup__used_maj_idx_intvls__fn: () => IndexInterval[]|undefined
    ) {
        const self = this
        self.oper_addr = oper_addr
        self.appWallet_metadata_client = appWallet_metadata_client
        self._lookup__used_maj_idx_intvls__fn = lookup__used_maj_idx_intvls__fn
    }
    async teardown()
    {
        const self = this
        // stop observing metadata client here not required since we're not observing it directly but relying on consumer's method calls
        self.oper_addr = undefined
    }
    //
    // Interface Implementation
    public async startDiscoveringAccounts()
    {
        const self = this
        if (!self.didDiscoverAccounts_fn) {
            throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
        }
        // no need to manually observe metadata here for updates since the deposit_mon calls delegate methods
        // but we must also trigger the first discover here
        self.discover()
    }
    //
    // Imperatives 
    async discover()
    {
        const self = this
        // check is_torn_down?

        let optl__atomic_doc__used_maj_idx_intvls = self._lookup__used_maj_idx_intvls__fn()
        if (typeof optl__atomic_doc__used_maj_idx_intvls === 'undefined' || optl__atomic_doc__used_maj_idx_intvls === null) {
            // Is there any other way to look this up?
            console.warn("[Metadata_DepositAccountProvider::discover()] called but is still waiting for the atomic doc's maj idx intervals - Bailing from the min idxs accounts discovery for now.. for oper addr", self.oper_addr)
            return // until later?
        }
        // console.warn("[Metadata_DepositAccountProvider::discover()] doing min idx discovery now for oper_addr", self.oper_addr)
        let metadata_xmrAddrAccounts = self.appWallet_metadata_client.discoveredXMRAccountMetaFor(
            self.oper_addr!, // expected not to be torn down instance
            optl__atomic_doc__used_maj_idx_intvls!            
        ) // it would be nice to memoize this construction but it seems complicated
        // console.log("metadata_xmrAddrAccounts" , metadata_xmrAddrAccounts)
        //
        let deposit_accounts_by_keypath: { [key: string]: XMRDepositAccount_Base } = {}
        let maj_idx_strs = Object.keys(metadata_xmrAddrAccounts)
        for (let maj_idx_str of maj_idx_strs) {
            let maj_idx__md_accts = metadata_xmrAddrAccounts[maj_idx_str]
            for (let account_md of maj_idx__md_accts) {
                let idx_maj = account_md.maj_i!
                let idx_min = account_md.min_i!
                let keypath = AccountIdxKeyPathFromIdcs(idx_maj, idx_min)
                if (deposit_accounts_by_keypath[keypath] && typeof deposit_accounts_by_keypath[keypath] !== 'undefined') {
                    console.warn("WARNING: A duplicate of an account was somehow found within metadata_xmrAddrAccounts by Metadata_DepositAccountProvider")
                }
                let deposit_account: XMRDepositAccount_Base = deposit_accounts_by_keypath[keypath] ||
                {
                    maj_i: idx_maj,
                    min_i: idx_min
                }
                if (account_md.addr_label !== null && typeof account_md.addr_label !== 'undefined') { // discoverable via metadata
                    // we'r enot expecting a dupe deposit_account but in any case this would mean, at present, if there is such an erroneous dupe, the last one with an .addr_label will 'win' here
                    deposit_account.addr_label = account_md.addr_label
                }
                deposit_accounts_by_keypath[keypath] = deposit_account // doing this to dedupe
            }
        }
        //
        self.didDiscoverAccounts_fn(Object.values(deposit_accounts_by_keypath))
    }
    //
    public async walletSays__metadataDoc_modifiedLocally()
    { // TODO: is this necessary? probably... since we have multiple pathways INTO updating the subaddr idcs locally,... mainly thinking of e.g. if we get a .GET/.PUT resp from the LWS where it knew about subaddrs *before* metadata knew about them
        const self = this
        // console.log("~~~~~~~~~~~~~~~~~~> [Metadata_DepositAccountProvider/walletSays__metadataDoc_modifiedLocally]")
        await self.discover()
    }
    public async walletSays__metadataDoc_remotelyUpdatedAfterInit()
    {
        const self = this
        // console.log("~~~~~~~~~~~~~~~~~~> [Metadata_DepositAccountProvider/walletSays__metadataDoc_remotelyUpdatedAfterInit]")
        await self.discover()
    }
    public async walletSays__atomicDoc_updated_serverOrigin()
    {
        const self = this
        // console.log("~~~~~~~~~~~~~~~~~~> [Metadata_DepositAccountProvider/walletSays__atomicDoc_updated_serverOrigin]")
        await self.discover()
    }
    public async walletSays__atomicDoc_updated_localOrigin()
    {
        const self = this
        // console.log("~~~~~~~~~~~~~~~~~~> [Metadata_DepositAccountProvider/walletSays__atomicDoc_updated_localOrigin]")
        await self.discover()
    }
}
//
// class ScannerAccountLookupFns_DepositAccountProvider implements DepositAccountProvider
// {
//     //
//     // Interface - Properties
//     didDiscoverAccounts_fn: (accounts: XMRDepositAccount_Base[]) => void
//     //
//     // init
//     private get_scanner_known_idcs__fn: () => Promise<GETScannerSubaddrIdcsRes>
//     //
//     // state
//     public hasOnceObtainedScannerKnownAccounts: boolean = false
//     //
//     constructor(
//         get_scanner_known_idcs__fn: () => Promise<GETScannerSubaddrIdcsRes>
//     ) {
//         const self = this
//         self.get_scanner_known_idcs__fn = get_scanner_known_idcs__fn
//     }
//     async teardown()
//     {
//         // anything to do here?
//     }
//     //
//     // Interface Implementation
//     public async startDiscoveringAccounts()
//     {
//         const self = this
//         if (!self.didDiscoverAccounts_fn) {
//             throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
//         }
//         async function impl()
//         {
//             let r = await self.get_scanner_known_idcs__fn()
//             if (r.status == ScannerRes_StatusCode.success) {
//                 self.hasOnceObtainedScannerKnownAccounts = true
//                 let accounts: XMRDepositAccount_Base[] = []
//                 let maj_idx_strs = Object.keys(r.min_idx_intervals_by_maj_idx)
//                 for (let i = 0 ; i < maj_idx_strs.length ; i++) {
//                     let maj_idx_str = maj_idx_strs[i].toString()
//                     let min_idx_intervals = r.min_idx_intervals_by_maj_idx[i]
//                     for (let j = 0 ; j < min_idx_intervals.length ; j++) {
//                         let min_idx_interval = min_idx_intervals[j]
//                         if (min_idx_interval.length != 2) {
//                             console.warn("WARNING: Scanner replied with min idx interval with non-2 length components")
//                             continue
//                         }
    // TODO: this would need to be fixed up for BigIntegers
//                         let lower = parseInt("" + min_idx_interval[0])
//                         let upper = parseInt("" + min_idx_interval[1])
//                         if (lower > upper) {
//                             console.warn("WARNING: Scanner replied with a subaddr range upper that was less than the lower bound")
//                         }
//                         for (var min_idx = lower ; min_idx <= upper ; min_idx++) {
//                             let a =
//                             {
//                                 min_i: min_idx,
//                                 maj_i: parseInt(maj_idx_str)
//                             }
//                             accounts.push(a)
//                         }
//                     }
//                 }
//                 self.didDiscoverAccounts_fn(accounts)
//             }
//         }
//         await impl()
//     }
// }
class ScannedTxs_DepositAccountProvider implements DepositAccountProvider
{
    //
    // Interface - Properties
    didDiscoverAccounts_fn!: (accounts: XMRDepositAccount_Base[]) => void
    //
    // init
    private lookup_scanned_txs__fn!: () => Monero_Tx[]
    //
    constructor(
        lookup_scanned_txs__fn: () => Monero_Tx[]
    ) {
        const self = this
        self.lookup_scanned_txs__fn = lookup_scanned_txs__fn
    }
    async teardown()
    { // anything to do here?
    }
    //
    // Interface Implementation
    public async startDiscoveringAccounts()
    {
        const self = this
        if (!self.didDiscoverAccounts_fn) {
            throw new Error("Expected didDiscoverAccounts_fn by startDiscoveringAccounts call")
        }
        // must trigger first run
        await self.discover()
    }
    //
    // Imperatives 

    async discover()
    {
        const self = this
        let scanned_txs = self.lookup_scanned_txs__fn()
        // console.log("ScannedTxs_DepositAccountProvider scanned_txs discover() scanned_txs " , scanned_txs)
        let accounts_by_keypath: { [keypath: string]: XMRDepositAccount_Base } = {}
        function __insert_base_rec(idx_maj: BigInteger|undefined, idx_min: BigInteger|undefined)
        { // nil -> main account (0,0)
            if (idx_maj === null || typeof idx_maj === 'undefined') {
                idx_maj = new BigInteger(0) // this may not actually be true for this tx since the tx may be outgoing - but there's no harm in this case in providing the base account with this opportunity
            }
            if (idx_min === null || typeof idx_min === 'undefined') {
                idx_min = new BigInteger(0) // this may not actually be true for this tx since the tx may be outgoing - but there's no harm in this case in providing the base account with this opportunity
            }
            accounts_by_keypath[AccountIdxKeyPathFromIdcs(idx_maj, idx_min)] =
            {
                maj_i: idx_maj,
                min_i: idx_min,
                is_merely_scnr_rng_originating: false
            } // doing this to dedupe - which is valid in this case for the main account
        }
        for (var i = 0 ; i < scanned_txs.length ; i++) {
            let tx = scanned_txs[i]
            //
            if (!tx.recv_outputs.length) { // some activity without a received output... was it a spend? or a mocked_tx without support for fabricated .recv_outputs yet? either way .. -> 0,0
                __insert_base_rec(undefined, undefined) // undef -> main
            } else {
                for (let ro of tx.recv_outputs) {
                    if (ro.is_scanners) {
                        __insert_base_rec(new BigInteger("" + ro.sa_maj_i), new BigInteger("" + ro.sa_min_i))
                    }
                }
            }
        }
        self.didDiscoverAccounts_fn(Object.values(accounts_by_keypath))
    }
    //
    // Defined Interface - Delegation 
    public async deposit_mon_says__source_did_update_scanned_tx()
    {
        const self = this
        await self.discover()
    }
}