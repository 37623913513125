import EventEmitter from 'events'
const { v4: uuidV4 } = require('uuid')
//
import { DocumentId, DocumentJSON } from '../../Persistable/Documents';
import { UserIdleController, UserIdle_EventName } from '../../UserIdle/controllers/UserIdleController'
//
import { Bootable_setBooted, Bootable_onceBooted } from '../../Bootable/templates/bootable'
//
import { New_DecryptedString, New_ArmoredEncryptedString } from '../../symmetric_cryptor/cryptor.openpgp'
import AppPersistenceController_Encrypting from '../../Persistable/AppPersistenceController_Encrypting';
import { Persistence_EventName } from '../../Persistable/AppPersistenceController_Base';
//
// Constants
const PasswordMeta_collectionName = "PasswordMeta"
const plaintextMessageToSaveForUnlockChallenges = "this is just a string that we'll use for checking whether a given password can unlock an encrypted version of this very message"
//
// Constants - Events
enum Password_EventName
{
    // or
    // ChangedPassword = "ChangedPassword",
    //
    UnlockedWithCorrectExistingPassword = "UnlockedWithCorrectExistingPassword",


    //
    // ObtainedNewPassword = "ObtainedNewPassword",
    // ObtainedCorrectExistingPassword = "ObtainedCorrectExistingPassword", // deprecated    
    ErroredWhileSettingNewPassword = "ErroredWhileSettingNewPassword",
    ErroredWhileGettingExistingPassword = "ErroredWhileGettingExistingPassword",
    CanceledWhileEnteringExistingPassword = "CanceledWhileEnteringExistingPassword",
    CanceledWhileEnteringNewPassword = "CanceledWhileEnteringNewPassword",
    // CanceledWhileChangingPassword = "CanceledWhileChangingPassword",
    ErrorWhileChangingPassword = "ErrorWhileChangingPassword",
    ErrorWhileAuthorizingForAppAction = "ErrorWhileAuthorizingForAppAction",
    SuccessfullyAuthenticatedForAppAction = "SuccessfullyAuthenticatedForAppAction",

    // SingleObserver_getUserToEnterExistingPasswordWithCB = "SingleObserver_getUserToEnterExistingPasswordWithCB",
    SingleObserver_getUserToEnterNewPasswordAndTypeWithCB = "SingleObserver_getUserToEnterNewPasswordAndTypeWithCB",
    //
    DidRemovePasswordEntryLockOut_EntryReEnabled = "DidRemovePasswordEntryLockOut_EntryReEnabled"
}
//
interface ChangePasswordRegistrant
{
    passwordController_ChangePassword: (password: string) => Promise<{ err_str?: string | undefined }>
}
enum TrySetPasswordErrCode
{
    code_fault__already_has_password,
    bad_password__is_required,
    bad_password__too_short,
    err_while_saving,
    crackAttempt_existing_pw_not_equals_entered_pw,
    bad_password__different_pw_required
}
//
// Principal Class
class PasswordController extends EventEmitter
{
    //
	// Properties
	// - Interfaces - Bootable
	hasBooted: boolean = false
    _onceBooted_resolveFns: Array<() => void> = []
	//
    persistenceController!: AppPersistenceController_Encrypting
    //
    _id?: DocumentId
    password: string | undefined = undefined // it's not been obtained from the user yet - we only store it in memory
    hasUserSavedAPassword!: boolean
    encryptedMessageForUnlockChallenge: string | undefined
    _initial_waitingForFirstPWEntryDecode_passwordModel_doc: DocumentJSON | undefined 
    hasCalled__postBoot_isBootedAndNeedsUnlockRequest: boolean = false
    isAlreadyGettingExistingOrNewPWFromUser: boolean = false
    //
    _singleObserver_enterExistingPassword_cb?: (didCancel_orNil: boolean|null, obtainedPasswordString: string) => void
    _singleObserver_enterNewPassword_cb?: (didCancel_orNil: boolean|null, obtainedPasswordString: string) => void
    //
    changePasswordRegistrants: { [key: string]: ChangePasswordRegistrant } = {}
	//
	// Lifecycle - Initialization
	constructor(persistenceController: AppPersistenceController_Encrypting)
	{
		super()
        const self = this
        self.persistenceController = persistenceController
        //
        self._startObserving_persistenceController()
	}
    _startObserving_persistenceController()
    {
        const self = this
        let hasPreviouslyLoaded = false
        self.persistenceController.on(Persistence_EventName.DidLoadAppDataFromFile, async () =>
        {
            // console.log("pw controller observed event Persistence_EventName.DidLoadAppDataFromFile")
            if (hasPreviouslyLoaded) {
                if (self.HasUserEnteredValidPasswordYet()) {
                    throw new Error("Unhandled call of data-load-from-file received by hasPreviouslyLoaded=true pw controller while it already has a password entered")
                }
                if (self.hasBooted) {
                    throw new Error("Unhandled call of data-load-from-file received by hasPreviouslyLoaded=true pw controller while it was already booted")
                }
            }
            hasPreviouslyLoaded = true
            //
            await self.bootByLoadingPasswordChallengeFromFileAndAwaitingUnlock() // might as well use this to trigger the load of 
        })
    }
	public async bootByLoadingPasswordChallengeFromFileAndAwaitingUnlock()
	{	// we can afford to do this w/o any callback saying "success" because we defer execution of
		// things which would rely on boot-time info till we've booted
		const self = this
		//
		// first, check if any password model has been stored
        const ret = self.persistenceController.AllValStringsFor(PasswordMeta_collectionName)
        const valStrs_length = ret.vals.length
        var hasUserSavedAPassword;
        var passwordModel_doc;
        if (valStrs_length === 0) {
            hasUserSavedAPassword = false
            passwordModel_doc = undefined
        } else {
            if (valStrs_length > 1) {
                const errStr = `Error while fetching existing ${PasswordMeta_collectionName}... more than one PasswordModel found. Selecting first.`
                console.error(errStr)
                // this is indicative of a code fault
            }
            
            hasUserSavedAPassword = true
            try {
                passwordModel_doc = JSON.parse(ret.vals[0]) // whole doc is not encrypted - only challenge
            } catch (e) {
                throw new Error("Error parsing password JSON - must have been corrupted. - " + (e as Error).message)
            }
            console.log("Found existing saved password model with _id", passwordModel_doc._id)
            self._id = passwordModel_doc._id || undefined
            self.encryptedMessageForUnlockChallenge = passwordModel_doc.encryptedMessageForUnlockChallenge
            if (self._id !== null && typeof self._id !== 'undefined') { // existing doc
                if (typeof self.encryptedMessageForUnlockChallenge === 'undefined' || !self.encryptedMessageForUnlockChallenge) { // but it was saved w/o an encrypted challenge str
                    const errStr = "Found undefined encrypted msg for unlock challenge in saved password model document" // TODO: not sure how to handle this case. delete all local info? would suck
                    console.error(errStr)
                    throw errStr
                }
            }
        }
        self.hasUserSavedAPassword = hasUserSavedAPassword
        self.hasCalled__postBoot_isBootedAndNeedsUnlockRequest = false // reset / zero for external unlock call
        self._initial_waitingForFirstPWEntryDecode_passwordModel_doc = passwordModel_doc // this will be nil'd after it's been parsed once the user has entered their pw
        //
        await Bootable_setBooted(self) // all done! call waiting fns
	}
    public async OnceBooted_needsInitialUnlockRequest()
    {
        const self = this
		await Bootable_onceBooted(self) // "blocks"
        //
        if (self.hasBooted == false) {
            throw new Error("OnceBooted_needsInitialUnlockRequest self.hasBooted=false despite awaiting Bootable_onceBooted")
        }
        if (self.HasUserEnteredValidPasswordYet() === true) {
            throw new Error("Not expecting user to have already entered password post-boot")
        }
        if (self.hasCalled__postBoot_isBootedAndNeedsUnlockRequest) { // preventing more than one call per boot cycle
            console.warn("OnceBooted_needsInitialUnlockRequest called when self.hasCalled__postBoot_isBootedAndNeedsUnlockRequest==true")
            return false
        }
        self.hasCalled__postBoot_isBootedAndNeedsUnlockRequest = true
        if (self._initial_waitingForFirstPWEntryDecode_passwordModel_doc && typeof self._initial_waitingForFirstPWEntryDecode_passwordModel_doc !== 'undefined') {
            if (typeof self.encryptedMessageForUnlockChallenge === 'undefined' && !self.encryptedMessageForUnlockChallenge) {
                throw new Error("Code fault: Existing document but no encryptedMessageForUnlockChallenge")
            }
            if (typeof self._id === 'undefined' || self._id === null) { // if the user is not unlocking an already pw-protected app
                throw new Error("Never expecting a lack of _id if just after booted with no pw entered and an existing initial doc-for-pw-entry-decode.")
            }
            return true
        }
        // console.log("OnceBooted_needsInitialUnlockRequest() no waiting for-first-pw-entry-decode doc" , false)
        return false
    }
    // public postBoot_checkPasswordControllerToRequestUnlockApp(): {
    //     wants_password_entered_by_user: boolean
    // } {
    //     const self = this
    //     if (self._initial_waitingForFirstPWEntryDecode_passwordModel_doc && typeof self._initial_waitingForFirstPWEntryDecode_passwordModel_doc !== 'undefined') {
    //         if (self.HasUserEnteredValidPasswordYet() === true) {
    //             throw new Error("Not expecting user to have already entered password post-boot")
    //             //
    //             return { wants_password_entered_by_user: false }
    //         }
    //         if (!self.hasBooted) {
    //             throw new Error("Expected postBoot_ called after boot")
    //         }
            // if (typeof self._id === 'undefined' || self._id === null) { // if the user is not unlocking an already pw-protected app
            //     throw new Error("Never expecting app to ask for a new password except manually via the landing process.")
            // }
            // if (typeof self.encryptedMessageForUnlockChallenge === 'undefined' && !self.encryptedMessageForUnlockChallenge) {
            //     throw new Error("Code fault: Existing document but no encryptedMessageForUnlockChallenge")
            // }
    //         //
    //         return { wants_password_entered_by_user: true }
    //     } else {
    //         console.log(" postBoot_triggerPasswordControllerToRequestUnlockApp() no waiting for first pw entry decode doc" )
    //         //
    //         return { wants_password_entered_by_user: false }
    //     }
    // }
	//
	// Runtime - Accessors - Public
    public async OnceBooted_HasUserSavedAPassword()
    {
        const self = this
		await Bootable_onceBooted(self) // "blocks"

        return self.GivenBooted_HasUserSavedAPassword()
    }
    GivenBooted_HasUserSavedAPassword()
    {
        const self = this
        //
        if (!self.hasBooted) {
            console.trace()
            throw new Error("[PasswordController] Not expecting .GivenBooted_HasUserSavedAPassword to be called when hasBooted=false")
        }
        //
        return self.hasUserSavedAPassword == true
    }
	HasUserEnteredValidPasswordYet(): boolean
	{
		const self = this
		if (typeof self.password === 'undefined' || self.password === null || self.password === "") {
			return false
		} else {
			return true
		}
	}
	IsUserChangingPassword(): boolean
	{
		const self = this
		const is = self.HasUserEnteredValidPasswordYet() && self.isAlreadyGettingExistingOrNewPWFromUser === true
		//
		return is
	}
	//
	// Runtime - Accessors - Internal
	_new_incorrectPasswordValidationErrorMessageString(): string
	{
		return "Incorrect password"
	}

	// async Initiate_VerifyUserAuthenticationForAction(
	// 	customNavigationBarTitle_orNull, // String? -- null if you don't want one
	// 	canceled_fn, // () -> Void
	// 	entryAttempt_succeeded_fn // () -> Void
	// ) {
	// 	const self = this
	// 	await Bootable_onceBooted(self) // "blocks"
	// 	//
	// 	if (self.HasUserEnteredValidPasswordYet() === false) {
	// 		const errStr = "Initiate_VerifyUserAuthenticationForAction called but HasUserEnteredValidPasswordYet === false. This should be disallowed in the UI"
	// 		throw errStr
	// 	}
	// 	{ // guard
	// 		if (self.isAlreadyGettingExistingOrNewPWFromUser === true) {
	// 			const errStr = "Initiate_VerifyUserAuthenticationForAction called but isAlreadyGettingExistingOrNewPWFromUser === true. This should be precluded in the UI"
	// 			throw errStr
	// 			// only need to wait for it to be obtained
	// 		}
	// 		self.isAlreadyGettingExistingOrNewPWFromUser = true
	// 	}
	// 	// ^-- we're relying on having checked above that user has entered a valid pw already
	// 	// proceed to verify via passphrase check
	// 	const isForChangePassword = false
	// 	const isForAuthorizingAppActionOnly = true
	// 	self._getUserToEnterTheirExistingPassword(
	// 		isForChangePassword,
	// 		isForAuthorizingAppActionOnly,
	// 		customNavigationBarTitle_orNull,
	// 		async function(didCancel_orNil, validationErr_orNil, entered_existingPassword)
	// 		{
	// 			if (validationErr_orNil != null) { // takes precedence over cancel
	// 				self.unguard_getNewOrExistingPassword()
	// 				self.emit(Password_EventName.ErrorWhileAuthorizingForAppAction, validationErr_orNil)
	// 				return
	// 			}
	// 			if (didCancel_orNil === true) {
	// 				self.unguard_getNewOrExistingPassword()
	// 				//
	// 				// currently there's no need of a .canceledWhileAuthorizingForAppAction note post here
	// 				canceled_fn() // but must call cb
	// 				//
	// 				return 
	// 			}
	// 			// v-- is this check a point of weakness? better to try decrypt? 
	// 			if (self.password !== entered_existingPassword) {
	// 				self.unguard_getNewOrExistingPassword()
	// 				const errStr = self._new_incorrectPasswordValidationErrorMessageString()
	// 				const err = new Error(errStr)
	// 				self.emit(Password_EventName.ErrorWhileAuthorizingForAppAction, err)
	// 				return
	// 			}
	// 			self.unguard_getNewOrExistingPassword() // must be called
	// 			self.emit(Password_EventName.SuccessfullyAuthenticatedForAppAction) // this must be posted so the PresentationController can dismiss the entry modal
	// 			entryAttempt_succeeded_fn()
	// 		}
	// 	)
	// }
    //
    // Imperatives - Entering existing password

    isCurrentlyLockedOutFromTryingPWEntry: boolean = false
    tryingPWEntrySpamLockout_unlock_timeout: any | null = null
    pwEntrySpamLockOut_thisTimePeriod_numberOfTries: number = 0
    pwEntrySpamLockOut_thisTimePeriod_dateOf_firstPWTry: Date | null = null

    __cancelAnyAndRebuild_pwEntryTryingSpam_unlock_timeout()
    {
        const self = this
        const wasAlreadyLockedOut = self.tryingPWEntrySpamLockout_unlock_timeout !== null
        if (self.tryingPWEntrySpamLockout_unlock_timeout !== null) {
            // console.log("Clearing existing unlock timer")
            clearTimeout(self.tryingPWEntrySpamLockout_unlock_timeout!)
            self.tryingPWEntrySpamLockout_unlock_timeout = null // not strictly necessary
        }
        const unlockInT_s = 10 // allows them to try again every 20 s, but resets timer if they submit w/o waiting
        console.log(`Too many password entry attempts within ${unlockInT_s}s. ${!wasAlreadyLockedOut ? "Locking out" : "Extending lockout." }.`)
        self.tryingPWEntrySpamLockout_unlock_timeout = setTimeout(() =>
        {
            console.log("Unlocking password entry.")
            self.isCurrentlyLockedOutFromTryingPWEntry = false
            self.pwEntrySpamLockOut_thisTimePeriod_numberOfTries = 0 // must clear so that _checkUnlockLockoutForEnterPW_orUpdateLockOutForNewEntryAttempt can detect zero and set …_thisTimePeriod_dateOf_firstPWTry
            self.tryingPWEntrySpamLockout_unlock_timeout = null // zero for comparison
            //
            self.emit(Password_EventName.DidRemovePasswordEntryLockOut_EntryReEnabled)
            console.log("TODO: emit that pw entry spam protection lockout has been removed")
            //
        }, unlockInT_s * 1000)
    }
    public _checkUnlockLockoutForEnterPW_orUpdateLockOutForNewEntryAttempt(): {
        validationErr_orNil: Error | null
    } {
        const self = this
        if (self.isCurrentlyLockedOutFromTryingPWEntry) {
            console.log("Received password entry attempt but currently locked out.")
            // setup or extend unlock timer - NOTE: this is pretty strict - we don't strictly need to extend the timer each time to prevent spam unlocks
            self.__cancelAnyAndRebuild_pwEntryTryingSpam_unlock_timeout()
            //
            return {
                validationErr_orNil: new Error("As a security precaution, please wait a few moments before trying again.")
            }
        }
        console.assert(self.isCurrentlyLockedOutFromTryingPWEntry == false)
        //
        // var validationErr_orNil: Error | null = null // so far…
        if (self.pwEntrySpamLockOut_thisTimePeriod_numberOfTries == 0) {
            self.pwEntrySpamLockOut_thisTimePeriod_dateOf_firstPWTry = new Date()
        }
        self.pwEntrySpamLockOut_thisTimePeriod_numberOfTries += 1
        const maxLegal_numberOfTriesDuringThisTimePeriod = 5
        if (self.pwEntrySpamLockOut_thisTimePeriod_numberOfTries > maxLegal_numberOfTriesDuringThisTimePeriod) { // rhs must be > 0
            self.pwEntrySpamLockOut_thisTimePeriod_numberOfTries = 0 
            // ^- no matter what, we're going to need to reset the above state for the next 'time period'
            //
            const now = new Date()
            const ms_dateRange = now.getTime() - self.pwEntrySpamLockOut_thisTimePeriod_dateOf_firstPWTry!.getTime()
            const ms_since_firstPWTryDuringThisTimePeriod = Math.abs(ms_dateRange)
            const s_since_firstPWTryDuringThisTimePeriod = ms_since_firstPWTryDuringThisTimePeriod / 1000
            const noMoreThanNTriesWithin_s = 30
            if (s_since_firstPWTryDuringThisTimePeriod > noMoreThanNTriesWithin_s) { // enough time has passed since this group began - only reset the "time period" with tries->0 and let this pass through as valid check
                self.pwEntrySpamLockOut_thisTimePeriod_dateOf_firstPWTry = null // not strictly necessary to do here as we reset the number of tries during this time period to zero just above
                console.log(`There were more than ${maxLegal_numberOfTriesDuringThisTimePeriod} password entry attempts during this time period but the last attempt was more than ${noMoreThanNTriesWithin_s}s ago, so letting this go.`)
            } else { // simply too many tries!…
                // lock it out for the next time (supposing this try does not pass)
                self.isCurrentlyLockedOutFromTryingPWEntry = true 
            }
        }
        return {
            validationErr_orNil: null
        }
    }
    //
    public async TryEnterExistingPW(
        passwordAttemptString: string,
        isForChangePassword: boolean
    ): Promise<{
        validationErr_orNil: Error | null
    }> {
        const self = this
        if (isForChangePassword != true) {
            if (self.password) {
                throw new Error("Not expecting passwordController to know the password on TryEnterExistingPW call")
            }
        } else {
            if (!self.password) {
                throw new Error("Expecting passwordController to know the password on TryEnterExistingPW call")
            }
        }
        let r = await self._checkUnlockLockoutForEnterPW_orUpdateLockOutForNewEntryAttempt() // mitigate unlock brute force - though, file content could be accessed directly
        if (r.validationErr_orNil) {
            return { validationErr_orNil: r.validationErr_orNil }
        }
        //
        // check password entry attempt:
        // console.log("self.encryptedMessageForUnlockChallenge! " , self.encryptedMessageForUnlockChallenge)
        const dec_ret = await New_DecryptedString(self.encryptedMessageForUnlockChallenge!, passwordAttemptString)
        if (dec_ret.err_str) {
            return {
                validationErr_orNil: new Error(self._new_incorrectPasswordValidationErrorMessageString())
            }
        }
        if (dec_ret.plainString !== plaintextMessageToSaveForUnlockChallenges) {
            return {
                validationErr_orNil: new Error(self._new_incorrectPasswordValidationErrorMessageString())
            }
        }
        // then password is correct.
        // hang onto pw and set state, then for interface consistency with SingleObserver entry path, emit notification
        if (isForChangePassword == false) {
            self._didObtainCurrentPasswordWithoutSaveError(passwordAttemptString)
            self.emit(Password_EventName.UnlockedWithCorrectExistingPassword)
        } else {
            // nothing to do
        }
        //
        return { 
            validationErr_orNil: null
        }
    }
	//
	// Runtime - Imperatives - Private - Requesting password from user
	unguard_getNewOrExistingPassword()
	{
		const self = this
		self.isAlreadyGettingExistingOrNewPWFromUser = false
        self._singleObserver_enterExistingPassword_cb = undefined
        self._singleObserver_enterNewPassword_cb = undefined
	}
	_getUserToEnterNewPassword(
		isForChangePassword: boolean,
		fn: (didCancel_orNil: boolean | null, obtainedPasswordString: string) => void 
	) {
		const self = this
        if (self._singleObserver_enterNewPassword_cb) {
            throw new Error("_getUserToEnterNewPassword called but non-nil _singleObserver_enterNewPassword_cb")
        }
        self._singleObserver_enterNewPassword_cb = function(didCancel_orNil, obtainedPasswordString)
        { // we're passing a function that the single observer should call
            if (didCancel_orNil) {
                // don't emit here - consumer will
            }
            fn(didCancel_orNil, obtainedPasswordString)
            self._singleObserver_enterNewPassword_cb = undefined
        }
		self.emit(
			Password_EventName.SingleObserver_getUserToEnterNewPasswordAndTypeWithCB, 
			isForChangePassword,
            self._singleObserver_enterNewPassword_cb			
		)
	}
    public RespondAsSingleObserver_CallStored_EnterExistingPassword(didCancel_orNil: boolean|null, obtainedPasswordString: string)
    {
        const self = this
        if (!self._singleObserver_enterExistingPassword_cb) {
            throw new Error("RespondAsSingleObserver_CallStored_EnterExistingPassword called but missing _singleObserver_enterExistingPassword_cb")
        }
        self._singleObserver_enterExistingPassword_cb(didCancel_orNil, obtainedPasswordString)
        self._singleObserver_enterExistingPassword_cb = undefined // clear it just in case
    }
    public RespondAsSingleObserver_CallStored_EnterNewPassword(didCancel_orNil: boolean|null, obtainedPasswordString: string)
    {
        const self = this
        if (!self._singleObserver_enterNewPassword_cb) {
            throw new Error("RespondAsSingleObserver_CallStored_EnterNewPassword called but missing _singleObserver_enterNewPassword_cb")
        }
        self._singleObserver_enterNewPassword_cb(didCancel_orNil, obtainedPasswordString)
        self._singleObserver_enterNewPassword_cb = undefined // clear it just in case
    }
	//
	// Runtime - Imperatives - Private - Setting/changing Password
    public async TrySetPassword(
        obtainedPasswordString: string,
        existingPassword_ifChanging?: string
    ): Promise<{
        err_code: TrySetPasswordErrCode|undefined,
        err: Error | undefined,
        success: boolean
    }> {
        const self = this
		await Bootable_onceBooted(self) // "blocks"
		//
        let isChangingPassword = existingPassword_ifChanging && typeof existingPassword_ifChanging !== 'undefined' ? true : false
        if (isChangingPassword == false) {
            if (self.HasUserEnteredValidPasswordYet() === true) {
                console.warn(self.constructor.name + " asked to TrySetPassword but already has password.")
                return {
                    err_code: TrySetPasswordErrCode.code_fault__already_has_password,
                    err: undefined,
                    success: true
                }
            }
        } else { // when changing password
            if (self.password !== existingPassword_ifChanging!) {
                console.warn(self.constructor.name + " asked to TrySetPassword but existingPassword_ifChanging !== self.password.")
                return {
                    err_code: TrySetPasswordErrCode.crackAttempt_existing_pw_not_equals_entered_pw,
                    err: undefined,
                    success: false
                }
            }
            if (self.password == obtainedPasswordString) {
                console.warn(self.constructor.name + " asked to TrySetPassword for change pw but existingPassword_ifChanging was the same as the current password.")
                return {
                    err_code: TrySetPasswordErrCode.bad_password__different_pw_required,
                    err: undefined,
                    success: false
                }
            }
        }
        if (!obtainedPasswordString || obtainedPasswordString.length == 0) {            
            const err = new Error("A password is required to protect your wallet funds.")
            self.emit(Password_EventName.ErroredWhileSettingNewPassword, err)
            return {
                err_code: TrySetPasswordErrCode.bad_password__is_required,
                err: err,
                success: true
            }
        }
        if (obtainedPasswordString.length < 6) { // this is too short. get back to them with a validation err by re-entering obtainPasswordFromUser_cb
            const err = new Error("Please enter a longer password.")
            self.emit(Password_EventName.ErroredWhileSettingNewPassword, err)
            return {
                err_code: TrySetPasswordErrCode.bad_password__too_short,
                err: err,
                success: true
            }
        }
        const ret = await self.saveToDisk(obtainedPasswordString, isChangingPassword)
        if (ret.err_str) {
            self.password = undefined // user will have to try again
            self.hasUserSavedAPassword = false 
            self.emit(Password_EventName.ErroredWhileSettingNewPassword, ret.err_str)
            return {
                err_code: TrySetPasswordErrCode.err_while_saving,
                err: undefined,
                success: true
            }
        }
        self._didObtainCurrentPasswordWithoutSaveError(obtainedPasswordString)
        //
        // success
        return {
            err_code: undefined,
            err: undefined,
            success: true
        }
    }
    async _givenPassword_tellRegistrants_changePassword(): Promise<{ err_str: string | undefined }>
    {
		const self = this
        const tokens = Object.keys(self.changePasswordRegistrants)
        const tokens_length = tokens.length
        for (var i = 0 ; i < tokens_length ; i++) {
            const token = tokens[i]
            const registrant = self.changePasswordRegistrants[token]
            const ret = await registrant.passwordController_ChangePassword(self.password!) // this may well end up failing...
            if (ret.err_str) {
                return { err_str: ret.err_str }
            }
        }
        return { err_str: undefined }
	}
	//
	// Runtime - Imperatives - Private - Persistence
	async saveToDisk(
        obtainedPasswordString: string, 
        isChangingPassword: boolean
    ): Promise<{ err_str: string | undefined }>
	{
		const self = this
		// console.log("Saving password model to disk.")
		//
		if (!obtainedPasswordString || typeof obtainedPasswordString === 'undefined') {
			const errStr = "Code fault: saveToDisk musn't be called without an obtainedPasswordString"
			console.error(errStr)
			return { err_str: errStr }
		}
        let encryptWithPassword = obtainedPasswordString
		const enc_ret = await New_ArmoredEncryptedString(
			plaintextMessageToSaveForUnlockChallenges,
            encryptWithPassword
        )
        if (enc_ret.err_str) {
            console.error("Error while encrypting message for unlock challenge:", enc_ret.err_str)
            return { err_str: enc_ret.err_str }
        }
        const persistableDocument =
        {
            _id: self._id, // critical for update
            encryptedMessageForUnlockChallenge: enc_ret.encryptedString!
        }
        if (self._id === null || typeof self._id === 'undefined') {
            const _id = uuidV4() // generate new
            persistableDocument._id = _id // will be set at self._id on successful save
        }
        const jsonString = JSON.stringify(persistableDocument)
        if (isChangingPassword) {
            const ret = await self.persistenceController.ForChangePassword_WriteNewPasswordDoc_andReEncryptOtherEncrypted_andSave(
                jsonString,
                persistableDocument._id!,
                encryptWithPassword // passing this instead of the currently set .password
            )
            if (ret.err_str) {
                console.error("Error while saving password record:", ret.err_str)
                return { err_str: ret.err_str }
            }
        } else {
            const ret = await self.persistenceController.NotDuringChangePW_EncryptingStoreInterface_WriteStringFor(
                PasswordMeta_collectionName,
                persistableDocument._id!,
                jsonString,
                false // don't encrypt
            )
            if (ret.err_str) {
                console.error("Error while saving password record:", ret.err_str)
                return { err_str: ret.err_str }
            }
        }
        self.encryptedMessageForUnlockChallenge =  persistableDocument.encryptedMessageForUnlockChallenge// it's important that we hang onto this in memory so we can access it if we need to change the password later
        self._id = persistableDocument._id // now _id can be saved back (in case it was newly generated above) to self, given no save error
        console.log("Saved password record with _id " + self._id + ".")
        return { err_str: undefined }
	}
	//
	// Runtime - Delegation - Obtained password
	_didObtainCurrentPasswordWithoutSaveError(password)
	{
		const self = this
		const existing_hasUserSavedAPassword = self.hasUserSavedAPassword
		self.password = password
		self.hasUserSavedAPassword = true // we can now flip this to true
		//
		const waiting_passwordModel_doc = self._initial_waitingForFirstPWEntryDecode_passwordModel_doc
		if (typeof waiting_passwordModel_doc !== 'undefined' && waiting_passwordModel_doc !== null) {
			self._initial_waitingForFirstPWEntryDecode_passwordModel_doc = undefined // zero so we don't do this more than once
		}
	}
	//
	//
	// Runtime - Imperatives - De-boot
	public async DeconstructBootedStateAndClearPasswordPendingReboot(andSilentlySkipNotify: boolean = false/* this presently has no effect */)
	{ // this is used as a central initiation/sync point for delete everything like user idle
		// maybe it should be moved, maybe not.
		// And note we're assuming here the PW has been entered already.
        const self = this
		if (self.hasUserSavedAPassword !== true) {
			throw new Error("InitiateDeleteEverything called but hasUserSavedAPassword !== true. This should be disallowed in the UI")
		}
        // reset state cause we're going all the way back to pre-boot 
        self.hasBooted = false // require this pw controller to boot
        self.password = undefined // this is redundant but is here for clarity
        self.hasUserSavedAPassword = false
        self._id = undefined
        self.encryptedMessageForUnlockChallenge = undefined
        self.hasCalled__postBoot_isBootedAndNeedsUnlockRequest = false // reset 
        self._initial_waitingForFirstPWEntryDecode_passwordModel_doc = undefined
        //
        // deprecated / moved to encrypting persistence c
        // // if (andSilentlySkipNotify != true) {
        // //     self.emit(Password_EventName.didDeconstructBootedStateAndClearPassword)
        // // }
	}
	AddRegistrantForChangePassword(registrant: ChangePasswordRegistrant)
	{
		const self = this
		// console.log("Adding registrant for 'ChangePassword': ", registrant.constructor.name)
		const token = uuidV4()
		self.changePasswordRegistrants[token] = registrant
		return token
	}
	RemoveRegistrantForChangePassword(token)
	{
		const self = this
		// console.log("Removing registrant for 'ChangePassword': ", registrant.constructor.name)
		delete self.changePasswordRegistrants[token]
	}
}
export { PasswordController, Password_EventName, PasswordMeta_collectionName, ChangePasswordRegistrant, TrySetPasswordErrCode };
