//
//
import { themes } from "../themes"
import { AbstractThemed } from "./AbstractThemed"
import { DisplayableListPage } from "./DisplayableListPage"
import { InlineWarningTextCell } from "./InlineWarningTextCell"
import { LargeCTAButtonsGroup } from "./LargeCTAButtonsGroup"
//
// TODO: quite possibly remove "ComposableComponent" from the name - possibly e.g. Base_FormAction_ListPage
//
//
export class ComposableComponentFormPage extends DisplayableListPage
{
    //
    //
    isFormSubmitting: boolean = false // subclassers: modify this instead
    /*overridable */lookup_isFormSubmitting(): boolean { return this.isFormSubmitting } // the default - but you can override this method to make lookup occur based on some other values elsewhere
    //
    //
    warningText!: InlineWarningTextCell
    button__primaryCTA?: LargeCTAButtonsGroup.View
    //
    // Accessors - Overridable
    _isDisabled__button_primaryCTA(): boolean
    {
        const self = this
        //
        return self.isFormSubmitting == true // Subclassers: Add additional parameters here with || conditions
    }
    //
    // Imperatives - Interaction
    _focus_with_state()
    { // overridable
        const self = this
    }
    wants_viewDidAppear_autoFocusWithState(): boolean {
        return false
    }
    //
    // Imperatives - Configuration
    configureWith_formSubmittingState(args: {
    }) {
        const self = this
        //
        // Subclassers: Override and add your various component .setEnabled(..) calls here
        //
        if (self.button__primaryCTA) {
            self.button__primaryCTA.setEnabled(self._isDisabled__button_primaryCTA() != true)
        }
        if (self.stackNavigationView) {
            if (self.stackNavigationView.navigationBar.leftSideAccessoryView) {
                self.stackNavigationView.navigationBar.leftSideAccessoryView.setEnabled(self.navigationBar_isEnabled_leftSideAccessory())
            } else if (self.stackNavigationView.navigationBar.backButtonView) { // is there ever a time when the back button and leftSideAccessory view will both be shown? I dont think so
                self.stackNavigationView.navigationBar.backButtonView.setEnabled(self.navigationBar_isEnabled_backButton())
            }
        }
    }
    configureWith_formSubmissionResult(args: {
        validationErrStr?: string
    }) {
        const self = this
        if (args.validationErrStr) {
            if (!self.warningText) { // this is just a failsafe but a subclasser could construct and place this view wherever they want, as various do already
                let view = new InlineWarningTextCell({ 
                    themeC: self.ctx.themeC,
                    uiContext: themes.ElementContextInUI.FormControl_outsideOfCellGroup
                }).init()
                self.warningText = view
                self.scrollableView().addSubview(view)
            }
            self.warningText.show()
            self.warningText.set_props({ title: "Error: ", text: args.validationErrStr })
            self._scrollToSubview(self.warningText, true, "nearest")
        } else {
            self.warningText.hide()
            self.warningText.set_props({ title: "Error: ", text: "" }) // might as well clear this here for minor security/privacy
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed // TODO: this can stand to be optimized slightly
    }
    //
    // Imperatives - Convenience
    __mark_form_as_currently_submitting(
    ) { // mark as currently busy
        const self = this
        self.isFormSubmitting = true // so user cannot go back during log in
        //
        self.configureWith_formSubmittingState({})
        self.configureWith_formSubmissionResult({ validationErrStr: undefined }) // clearing any inline warning
    }
    __bail_from_form_submission_with(args: {
        err_str: string
    }) {
        const self = this
        self.isFormSubmitting = false
        //
        self.configureWith_formSubmittingState({})
        self.configureWith_formSubmissionResult({ validationErrStr: args.err_str })
    }
    //
    // Delegation - View lifecycle
    viewDidAppear(): void
    {
        super.viewDidAppear()
        //
        const self = this
        let weakSelf = new WeakRef(self)
        if (self.wants_viewDidAppear_autoFocusWithState()) {
            setTimeout(() => {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                optl_self._focus_with_state()
            }, 60)
        }
    }
    //
    // Delegation - NavigableView
    override navigationBar_isEnabled_backButton(): boolean
    {
        const self = this
        //
        return self.isFormSubmitting == true ? false : true
    }
    override navigationBar_isEnabled_leftSideAccessory(): boolean
    {
        const self = this
        //
        return true
    }
    //
    // Delegation - ComposableComponentFormPage subclass
    async _did_press_primaryCTA(
    ): Promise<void> {
        const self = this
        throw new Error("Override and implement: _did_press_primaryCTA")
    }
    //
    _didFinishSettingUpFormViews()
    { // subclassers should call this (manually, presently)
        const self = this
        self.configureWith_formSubmittingState({}) // since we have never done anything to trigger an update of interactivity, this must be called here ... it would be nice to standardize this somehow across all Forms but there's not currently a way to do that ... maybe call "done setting up form views"
    }
}