import View_Web from "../../View/View.web"
import { styles_theme, themes } from "../themes"
import { LinkButton, LinkButtonOrTextSpanItem, LinkButtonOrTextSpanItem_PKey, LinkButtonUsage } from "./LinkButtonCell"
//
export namespace TextInput
{
    export enum Appearance
    {
        default, // with decoration
        noDecoration
    }
}

//
export enum TextInput_InputType
{
    text = "text",
    secureText = "secureText",
    number_ufloat = "number_ufloat", // allows decimals but no negative signs 
    number_uint = "number_uint" // for e.g. scan heights
}
//
export interface TextInput_InitParams
{
    themeC: themes.Controller
    contextInUI: themes.ElementContextInUI // typically will only ever be FormControl_withinCellGroup (if not rarely SplashBody_Mini)
    //
    placeholder?: string
    inputType?: TextInput_InputType // defaults to .text
    multiline?: boolean // turn input[type=".."] element into a textarea
    // preventEngineAskSavePassword?: boolean // only set if .inputType = .secureText
    // only know how to implement this for webkit currently and i wouldnt want it to degrade incorrectly somehow
    //
    appareance?: TextInput.Appearance
    //
    textOrSecureInput_disallowWhitespace?: boolean // ignored under .inputType=number_ufloat,number_uint
    textOrSecureInput_disallow_nonAlphaNum?: boolean
    //
    changed_fn?: (_textInputSelf: _TextInput_ForCell, v: string) => void
    debounced_changed_fn?: (_textInputSelf: _TextInput_ForCell, v: string) => void // actually i think debounce is not the right term here .. derp
    revert_value_on_blur_to__fn?: (_textInputSelf: _TextInput_ForCell) => string|undefined // provide a fn to return a string you want to revert the value to on blur .. such as if the input is empty ; undefined will cause self not to modify the value
    key_pressed_fn?: (_textInputSelf: _TextInput_ForCell, e: KeyboardEvent) => boolean
    //
    focused_fn?: (_textInputSelf: _TextInput_ForCell) => void
    //
    rightSide_linkButton_pressed_fn?: (pressed_item_pkey: string) => void
    //
    // for TextInput_InputType.number_ufloat, number_uint
    maxWholeDigits?: number
    maxDecimalDigits?: number
    //
}
//
export default class _TextInput_ForCell extends View_Web
{
    //
    inputView!: View_Web
    input_layer(): HTMLInputElement
    {
        return this.inputView.layer as HTMLInputElement
    }
    lookup_input_value(): string
    {
        const self = this
        //
        return self.input_layer().value // Note lack of visibility and interactivity checks
    }
    focus__input() {
        this.input_layer().focus()
    }
    public setInputValue(str: string)
    {
        const self = this
        self.input_layer().value = str
        // and now manually fabricate a _input_layer_fn__genericInputListener call - so that the debounced 'changed' listener gets called/triggered/bubbled
        if (self._input_layer_fn__genericInputListener) {
            self._input_layer_fn__genericInputListener(undefined)
        }
    }
    public insertPasteAtCursorOrReplaceSelection(str: string)
    {
        const self = this
        let selection_start = self.input_layer().selectionStart!
        let selection_end = self.input_layer().selectionEnd!
        self.input_layer().setRangeText(str, selection_start, selection_end, "end")
        // and now manually fabricate a _input_layer_fn__genericInputListener call - so that the debounced 'changed' listener gets called/triggered/bubbled
        if (self._input_layer_fn__genericInputListener) {
            self._input_layer_fn__genericInputListener(undefined)
        }
        
    }
    //
    ip!: TextInput_InitParams
    do_debounce_listen!: boolean // derived in constructor from ip
    is_focused: boolean = false
    //
    rightSide_linkButtonCell?: LinkButton._Row
    //
    _awaitingUserInputPause__timer: any|null = null
    _input_layer_fn__genericInputListener?: (e: any) => void 
    valueAtLastNotify__genericInputListener: string|undefined = undefined // PS: This is not currently implemented - but it *probably* is safe to uncomment support for
    //
    _input_layer_fn__blur?: () => void
    _input_layer_fn__focus?: () => void
    _input_layer_fn__keypress?: (e: KeyboardEvent) => void
    //
    _fn__keypress?: (e) => void
    _fn__paste?: (e) => void
    //
    constructor(ip: TextInput_InitParams)
    {
        super({ 
            el_name: "div"
        })
        //
        const self = this
        self.ip = ip
        self.do_debounce_listen = self.ip.debounced_changed_fn && typeof self.ip.debounced_changed_fn !== 'undefined' ? true : false
    }
    public teardown(): void 
    {
        const self = this
        //
        // Is this really a good idea? hmmmm... we'd miss an emit.. but from my testing, not having it allowed an emit after this was supposedly torn down, in a trivial case
        if (self._awaitingUserInputPause__timer) {
            clearTimeout(self._awaitingUserInputPause__timer) 
            self._awaitingUserInputPause__timer = null
        }
        if (self._input_layer_fn__genericInputListener) { // hm.. is the following at all necessary?
            self.input_layer().removeEventListener("change", self._input_layer_fn__genericInputListener)
            self.input_layer().removeEventListener("keyup", self._input_layer_fn__genericInputListener)
            self._input_layer_fn__genericInputListener = undefined
        }
        if (self._input_layer_fn__blur) { // hm.. is the following at all necessary?
            self.input_layer().removeEventListener("blur", self._input_layer_fn__blur)
            self._input_layer_fn__blur = undefined
        }
        if (self._input_layer_fn__keypress) {
            self.input_layer().removeEventListener("keypress", self._input_layer_fn__keypress)
            self._input_layer_fn__keypress = undefined
        }
        if (self._fn__keypress) {
            self.input_layer().removeEventListener("keydown", self._fn__keypress)
            self._fn__keypress = undefined
        }
        // self.input_layer().onkeypress = null
        if (self._fn__paste) {
            self.input_layer().removeEventListener("paste", self._fn__paste)
            self._fn__paste = undefined
        }
        //
        // finally, .layer can be discarded etc
        super.teardown()
    }
    setup(): void 
    {
        super.setup()
        const self = this
        let themeC = self.ip.themeC
        let weakSelf = new WeakRef(self)
        {
            let v = new View_Web({ el_name: (self.ip.multiline == true ? "textarea" : "input") }).init()
            self.inputView = v
            self.addSubview(v)
        }
        { // opting to do this before the filtering below - figure that event listener registration affects bubbling order
            if (self.ip.key_pressed_fn) {
                self._input_layer_fn__keypress = (
                    e: KeyboardEvent
                ) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (optl_self.ip.key_pressed_fn) { // though not having this would not be expected
                        return optl_self.ip.key_pressed_fn!(optl_self, e)
                    }
                }
                self.input_layer().addEventListener('keypress', self._input_layer_fn__keypress!)
            }
        }
        {
            let input = self.input_layer()
            if (self.ip.multiline != true) {
                if (self.ip.inputType) {
                    switch (self.ip.inputType) {
                        case TextInput_InputType.text:
                        {
                            input.type = "text"
                            break
                        }
                        case TextInput_InputType.number_ufloat:
                        case TextInput_InputType.number_uint:
                        {
                            // // {
                            // //     input.type = "number" // this doesn't even have a meaningful implementation in browsers, so, no thanks?
                            // //     input.min = "0"
                            // //     input.max = "99999999999"
                            // //     input.step = "0.000001" // this was purported to filter to numerical + delimiter but... nope
                            // // }
                            //

                            input.type = "text"

                            // self._fn__keydown = (e) =>
                            // {
                                                        // if ([ 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight' ].includes(event.code)) {
                                //     return true
                                // }
                                // console.log("event.key", event.key)

                                // if (!isNaN(Number(event.key)) && event.code !== 'Space') {
                                //     console.log("true")
                                //     return true
                                // }
                                // // TODO: prevent ... what... dupe dots?
                                // console.log("false")
                                // return false
                            // }

                            self._fn__keypress = self._new_fn__keypress__numbers()
                            self._fn__paste = self._new_fn__paste__numbers()
                            break
                        }
                        case TextInput_InputType.secureText:
                        {
                            // if (self.ip.preventEngineAskSavePassword == true) {
                            //     let isWebkit = ('webkitRequestAnimationFrame' in window)
                            //     if (isWebkit) {
                            //         input.type = "text" // this prevents the browser from asking the user to save the password
                            //         // TODO: need a way to support for other browsers
                            //         input.style.setProperty('-webkit-text-security', 'disc')
                            //     } else {
                            // } else {
                            input.type = "password" // this allows the browser to ask the user to save the password
                            // }
                            break
                        }
                        default:
                        {
                            throw new Error("Code fault: Unrecognized TextInput_InputType")
                        }
                    }
                } else {
                    input.type = "text"
                }
                //
                if (!self._fn__keypress) {
                    self._fn__keypress = self._new_fn__keypress__text()
                }
                if (!self._fn__paste) {
                    self._fn__paste = self._new_fn__paste__text()
                }
                //
                self.input_layer().addEventListener('keypress', self._fn__keypress!)
                // ^ Sure, this is deprecated, but the other solutions dont even seem to work universally (yet?)  ... beforeinput, keydown, ..
                self.input_layer().addEventListener('paste', self._fn__paste!)
                //
            }
            input.placeholder = self.ip.placeholder || ''
        }
        {
            let view = self
            switch (self.ip.contextInUI) {
                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup: // notepad
                    view.layer.style.width = "100%" // TODO: some padding here to match group cells perhaps
                    if (self.ip.multiline) {
                        view.layer.style.minHeight = "320px"
                    } else {
                        view.layer.style.height = "44px"
                    }
                    break // already styled as above
    
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.maxWidth = "452px"
                    if (self.ip.multiline) {
                        throw new Error("Not yet supported")
                    } else {
                        view.layer.style.height = "48px"
                    }
                    break // already styled as above
    
                case themes.ElementContextInUI.FormControl_withinCellGroup:
                case themes.ElementContextInUI.totallyCustomContext:
                    view.layer.style.width = "100%" // take up whole line; though this could possibly be overridden and rewritten by e.g. TitledTextInputCell when input not placed in 'primary' location
                    if (self.ip.multiline) {
                        view.layer.style.height = "120px"
                    } else {
                        view.layer.style.height = "36px"
                    }
    
                    break // already styled as above
    
                default:
                    throw new Error("Not (yet) expecting a TitledTextInputCell in that .contextInUI")
            }
            view.layer.style.boxSizing = "border-box" // include the padding in the .width and .height
            view.layer.style.flexGrow = "10"
            view.layer.style.display = "flex"
            view.layer.style.flexDirection = "row"
            view.layer.style.alignItems = "center"
            //
            view.layer.classList.add(styles_theme.ClassNames.stylable_ctl__decoration)
            if (self.ip.appareance == TextInput.Appearance.noDecoration) { 
                view.layer.classList.add(styles_theme.ClassNames.stylable_ctl__invisibleDecoration)
            }
            view.layer.classList.add(styles_theme.ClassNames.cellGroup_row_innerComponentWithoutAbsBaseBG)
        }
        {
            let _input_layer = self.input_layer()
            _input_layer.classList.add(styles_theme.ClassNames.stylable_ctl__control)
            _input_layer.style.boxSizing = "border-box" // include the padding in the .width and .height
            _input_layer.style.height = "100%"
            if (self.ip.multiline) {
                //// _input_layer.style.resize = "vertical" // to start with, anyway 
                // _input_layer.style.overflow = "auto" // PS: i'm not sure what this does - is it relevant to parent-resizing behavior?
                //
                // TODO: i disabled resizing for now since we need to have a flag that lets consumers specify whether to grow up or down in resize - chat needs upwards and everything else (default) needs downwards - but controlling that should not be done by changing the above 'view.layer.style.alignItems = "end"' since I set that in order to get the rightSide link buttons to intentionally align to the bottom of the parent container per design
                _input_layer.style.resize = "none"
                // TODO: update structure or parent styling to enable resizing per child layout, possibly via display: inline-block per https://stackoverflow.com/questions/43461185/resizing-div-according-to-the-textarea
            }
            _input_layer.style.width = "100%"
            _input_layer.style.color = themeC.current.colors.input_text
            _input_layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_regular)
            switch (self.ip.contextInUI) {
                case themes.ElementContextInUI.SplashBody_Mini:
                    _input_layer.style.fontSize = "18px"
                    _input_layer.style.textAlign = "center"
                    break // already styled as above
                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                    _input_layer.style.fontSize = "15px"
                    break
                case themes.ElementContextInUI.FormControl_withinCellGroup:
                case themes.ElementContextInUI.totallyCustomContext:
                    _input_layer.style.fontSize = "15px"
                    break // already styled as above
                default:
                    throw new Error("Not (yet) expecting a TitledTextInputCell in that .contextInUI")
            }
            _input_layer.style.background = "none" // container gets the style
            _input_layer.style.border = "none" // container gets the style - so that we can resize the input freely
            //
            ///_input_layer.style.cursor = "text" // PS: I actually opted to remove this from here because it should always work like this, and if it doesn't, something is properly improperly overriding it
            //
             // PS: I'm gonna leave this for now even though it affects the above textarea case and even though we might not want textareas to appear visually highlightable - because I'm going to add explicit textarea cursor fixups to theme

            if (self.ip.changed_fn || self.do_debounce_listen) {
                self._input_layer_fn__genericInputListener = (e: any) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    let v = optl_self.lookup_input_value()
// TODO: quite possibly implement this to redunce a changed event on blur - but why am I subscribing to changed, then? (may need to dig in history for this)
                    // console.log("v now '" + v + "'")
                    // console.log("optl_self.valueAtLastNotify__genericInputListener '" + optl_self.valueAtLastNotify__genericInputListener + "'")
                    // if (typeof optl_self.valueAtLastNotify__genericInputListener == 'undefined' || optl_self.valueAtLastNotify__genericInputListener !== v) {
                        if (optl_self.ip.changed_fn) { // since it can be nil even if the listener is on
                            optl_self.ip.changed_fn!(optl_self, v)
                        }
                    // } else {
                    //     console.warn("Skipping notify because value does not appear to have changed from last notify")
                    // }
                    optl_self.valueAtLastNotify__genericInputListener = v
                    //
                    if (optl_self.do_debounce_listen) {
                        if (optl_self._awaitingUserInputPause__timer) {
                            clearTimeout(optl_self._awaitingUserInputPause__timer)
                            optl_self._awaitingUserInputPause__timer = null // to be clear
                        }
                        optl_self._awaitingUserInputPause__timer = setTimeout((
                        ) => { // see .teardown() for discussion of whether to skip emitting if the component is torn down ... i dont think there's going to be a race between them though
                            let _inner1__optl_self = weakSelf.deref()
                            if (!_inner1__optl_self) {
                                return
                            }
                            _inner1__optl_self._awaitingUserInputPause__timer = null // probably not necessary but...
                            _inner1__optl_self.ip.debounced_changed_fn!(_inner1__optl_self, _inner1__optl_self.lookup_input_value()) // NOTE: critical to look up the *local,current* value of the input instead of using the value from before
                        }, 400) 
                    }
                }
                // since 'change' doesn't actually cover all updates and may only trigger on blur (...)
                self.input_layer().addEventListener("keyup", self._input_layer_fn__genericInputListener)
                self.input_layer().addEventListener("change", self._input_layer_fn__genericInputListener)
            }
            //
            self._input_layer_fn__blur = (
            ) => {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                optl_self.is_focused = false
                if (optl_self.ip.revert_value_on_blur_to__fn) {
                    let retVal = optl_self.ip.revert_value_on_blur_to__fn!(optl_self) // assume exists if listener is on
                    if (typeof retVal === 'undefined') {
                        return // do nothing
                    }
                    optl_self.setInputValue(retVal)
                }
            }
            self.input_layer().addEventListener("blur", self._input_layer_fn__blur)
            //
            self._input_layer_fn__focus = (
            ) => {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                optl_self.is_focused = true
                if (optl_self.ip.focused_fn) { // though not having this would not be expected
                    optl_self.ip.focused_fn!(optl_self)
                }
            }
            self.input_layer().addEventListener("focus", self._input_layer_fn__focus)
        }

        // censor: (ip.secureTextEntry === true),
//////// TODO ^
        //
        let view = self // for portability of below code
        // focus: {
        //     border: { fg: '#ffffff' }
        // },
        // hover: {
        //     border: { fg: '#a0a0a0' }
        // }
//// TODO: add this ... for which we need either on-hover w/JS or we need to use CSS injection per old code
    }
    _new_fn__keypress__numbers(): (e: KeyboardEvent) => boolean
    {
        const self = this
        let weakSelf = new WeakRef(self)
        //
        return (e: KeyboardEvent) => {
            if (e.metaKey == true || e.ctrlKey == true) { // since we want to allow those and since they dont generate input
                // I think.. we might always be able to assume this here regardless of which key is pressed
                // // // e.preventDefault() // but do NOT prevent default
                return false // and we can assume no input is needed
            }
            //
            let decimalDelimiter_code = 46
            // TODO: support charCode == 44 for ',' separator depending on locale / query browser locale api for separator?
            if (e.charCode == decimalDelimiter_code) {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return false // not that this should ever happen
                }
                if (optl_self.ip.inputType == TextInput_InputType.number_uint) { // no decimal in ints
                    e.preventDefault()
                    return false;
                }
                if (optl_self.input_layer().value.indexOf(String.fromCharCode(decimalDelimiter_code)) != -1) {
                    // do not allow more than one delimiter char
                    e.preventDefault() // no need to worry if meta or ctl key is down since that is checked above
                    return false
                }
                return true
            }
            // let charCode_zero = 48
            let isDigit = e.charCode >= 48 && e.charCode <= 57
            if (isDigit) {
                let will_create_unacceptable_number = false
                /// console.log("self.ip.maxDecimalDigits" , self.ip.maxDecimalDigits)
                /// console.log("self.ip.maxWholeDigits" , self.ip.maxWholeDigits)
                let must_validate = typeof self.ip.maxDecimalDigits !== 'undefined' || typeof self.ip.maxWholeDigits !== 'undefined'
                if (must_validate) {
                    let input = e.currentTarget as HTMLInputElement
                    let i__start = input.selectionStart || 0 // PS: no idea why this would ever be null
                    let i__end = input.selectionEnd || 0 // PS: no idea why this would ever be null
                    let input__value = input.value
                    let to_insert = String.fromCharCode(e.charCode)
                    let would_become_str = input__value.substring(0, i__start) + to_insert + input__value.substring(i__end)
                    let whole_num_digits: string|undefined = undefined
                    let decimal_digits: string|undefined = undefined
                    if (input__value.indexOf(".") == -1) {
                        whole_num_digits = would_become_str
                        decimal_digits = ""
                    } else {
                        let components_as_num = would_become_str.split('.')
                        /// console.log("components_as_num" , components_as_num)
                        whole_num_digits = components_as_num[0]
                        decimal_digits = components_as_num[1]
                    }

// TODO: instead of the above algo, modify it to: when any digits are added to the decimal, validate the decimal part, AND when any digits are added to the whole num, validate the whole num part - the reason is that a user ending up with "9999999999999" by deleting the "." in "99999999999.99" would not be able to then add any decimal digits after "." if they were to change "9999999999999" into "9999999999999."

                    /// console.log("whole_num_digits, decimal_digits", whole_num_digits, decimal_digits)
                    if (typeof self.ip.maxDecimalDigits !== 'undefined') {
                        if (decimal_digits.length > self.ip.maxDecimalDigits) {
                            /// console.log("decimal_digits.length > self.ip.maxDecimalDigits", decimal_digits.length, self.ip.maxDecimalDigits, decimal_digits.length > self.ip.maxDecimalDigits)
                            e.preventDefault()
                            return false
                        }
                    }
                    if (typeof self.ip.maxWholeDigits !== 'undefined') {
                        if (whole_num_digits.length > self.ip.maxWholeDigits) {
                            /// console.log("whole_num_digits.length > self.ip.maxDecimalDigits", whole_num_digits.length, self.ip.maxWholeDigits, whole_num_digits.length > self.ip.maxWholeDigits)
                            e.preventDefault()
                            return false
                        }
                    }
                }
                if (will_create_unacceptable_number) {
                    e.preventDefault()
                    return false
                }
                return true
            }
            if (e.charCode == 0) { // backspace?
                if (typeof self.ip.maxWholeDigits !== 'undefined') {

// TODO: also validate here that if deleted item is the decimal, then we still need to validate if this is a valid whole number ... but the problem is, if only the decimal point char is deleted, then in order to make a valid number, we'd have to chop some of the existing digits, OR just prevent the decimal deletion... only the latter is a reasonable solution, but may be confusing from a UX standpoint if all the user wants to do is modify the text into something acceptable - so, it would also be good to put a red highlight around the field while it is in an invalid length condition, and to make sure the emscr build no longer crashes on a too-long int - for now, the user is currently able to keep deleting til they have a valid number

                }
                return true
            }
            //
            e.preventDefault() // we already made sure above that it's not a meta or ctrl chord
            return false
        }
    }
    _new_fn__paste__numbers(): (e: ClipboardEvent) => void
    {
        const self = this
        let weakSelf = new WeakRef(self)
        //
        return (e: ClipboardEvent) => 
        {
            e.preventDefault()
            //
            function removeDuplicateRegexMatchesExceptFirst(string, regex)
            {
                var count = 0
                var replaceWith = ''
                return string.replace(regex, function (match) {
                    count++
                    if (count === 1) {
                        return match
                    } else {
                        return replaceWith
                    }
                })
            }
            //
            let paste = (e.clipboardData || window['clipboardData']).getData('text');
            paste = paste.toUpperCase()
            const selection = window.getSelection()! // I think we can assume unwrap here
            if (!selection.rangeCount) {
                return
            }
            selection.deleteFromDocument()
            let optl_this = weakSelf.deref()
            if (!optl_this) {
                return
            }
            let _search_regex = self.ip.inputType == TextInput_InputType.number_ufloat 
                ? /[^0-9\.]+/g 
                : /[^0-9]+/g // do not allow decimal delimiter if it's a uint; expecting this to be number_uint
            ;
            let sanitized_paste = paste.replace(_search_regex, '')
            sanitized_paste = removeDuplicateRegexMatchesExceptFirst(sanitized_paste, /\./g)  // probably a better way to do this
            // opting for this rather than just canceling invalid pastes:
            //
            optl_this.insertPasteAtCursorOrReplaceSelection(sanitized_paste) 
        }
    }
    //
    _new_fn__keypress__text(): (e: KeyboardEvent) => boolean
    {
        const self = this
        let weakSelf = new WeakRef(self)
        //
        return (e: KeyboardEvent) => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return false // not that this should ever happen
            }
            if (optl_self.ip.textOrSecureInput_disallowWhitespace == true) {
                if (e.charCode == 13/*newline*/ || e.charCode == 32/*space*/) {
                    // and there probably isn't a need to worry if we're currently in a chord with a meta/ctrl/cmd key
                    e.preventDefault()
                    return false
                }
            }
            if (optl_self.ip.textOrSecureInput_disallow_nonAlphaNum == true) {
                let _search_regex = /[^a-zA-Z0-9]/g
                if (e.key.match(_search_regex)) {
                    e.preventDefault()
                    return false
                }
            }
            return true // allow
        }
    }
    _new_fn__paste__text(): (e: ClipboardEvent) => void
    {
        const self = this
        let weakSelf = new WeakRef(self)
        //
        function removeDuplicateRegexMatchesExceptFirst(string, regex)
        {
            var count = 0
            var replaceWith = ''
            return string.replace(regex, function (match) {
                count++
                if (count === 1) {
                    return match
                } else {
                    return replaceWith
                }
            })
        }
        //
        return (e: ClipboardEvent) => 
        {
            e.preventDefault()
            //
            let paste = (e.clipboardData || window['clipboardData']).getData('text');
            // paste = paste.toUpperCase() // PS: not sure why this was in here - probably removable
            const selection = window.getSelection()! // I think we can assume unwrap here
            if (!selection.rangeCount) {
                return
            }
            selection.deleteFromDocument()
            let optl_this = weakSelf.deref()
            if (!optl_this) {
                return
            }
            let sanitized_paste;
            if (optl_this.ip.textOrSecureInput_disallowWhitespace == true) {
                let _search_regex = /\s+/g;
                sanitized_paste = paste.replace(_search_regex, '')
                sanitized_paste = removeDuplicateRegexMatchesExceptFirst(sanitized_paste, /\s/g)  // probably a better way to do this
            } 
            if (optl_this.ip.textOrSecureInput_disallow_nonAlphaNum == true) {
                let _search_regex = /[^a-zA-Z0-9]/g
                sanitized_paste = paste.replace(_search_regex, '')
                sanitized_paste = removeDuplicateRegexMatchesExceptFirst(sanitized_paste, /[^a-zA-Z0-9]/g)
            }
            else {
                sanitized_paste = paste
            }
            // opting for this rather than just canceling invalid pastes:
            //
            optl_this.insertPasteAtCursorOrReplaceSelection(sanitized_paste) 
        }
    }
    //
    set_props(props: {
        placeholder: string|undefined,
        rightSide_linkButton_items: LinkButtonOrTextSpanItem[]|null|undefined,
        input_immutable: boolean|undefined
    }) {
        const self = this
        let weakSelf = new WeakRef(self)
        if (typeof props.placeholder !== 'undefined') {
            self.input_layer().placeholder = props.placeholder
        }
        if (typeof props.input_immutable !== 'undefined') {
            if (props.input_immutable == true) {
                self.input_layer().setAttribute("readonly", "readonly")
            } else {
                self.input_layer().removeAttribute("readonly")
            }
        }
        if (typeof props.rightSide_linkButton_items !== 'undefined') {
            if (props.rightSide_linkButton_items != null && props.rightSide_linkButton_items.length > 0) {
                if (!self.rightSide_linkButtonCell) {
                    let v = new LinkButton._Row({
                        themeC: self.ip.themeC,
                        uiContext: self.ip.contextInUI,
                        pressed_fn: (_pkey: LinkButtonOrTextSpanItem_PKey) => { 
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            if (optl_self.ip.rightSide_linkButton_pressed_fn) {
                                optl_self.ip.rightSide_linkButton_pressed_fn(_pkey)
                            } else {
                                console.warn("Not calling self.ip.rightSide_linkButton_pressed_fn because it was nil")
                            }
                        }
                    }).init()
                    self.rightSide_linkButtonCell = v
                    v.layer.style.margin = "0" // since it may set a margin internally for usage in other UI contexts, but we're using in a specially embedded form here - though this does indicate a place to improve LinkButtonCell
                    //
                    self.addSubview(self.rightSide_linkButtonCell)
                }
                self.rightSide_linkButtonCell.set_props({
                    items: props.rightSide_linkButton_items
                })
                //
            } else { // empty or null list of items, but it was not undefined
                if (self.rightSide_linkButtonCell) { // so this is not needed anymore
                    self.rightSide_linkButtonCell.removeFromSuperview()
                    self.rightSide_linkButtonCell = undefined
                }
            }
        } else {
             // nothing to do
        }
    }
    override setEnabled(
        isEnabled: boolean
    ): void {
        super.setEnabled(isEnabled)
        //
        const self = this
        self.input_layer().disabled = isEnabled != true
        if (self.rightSide_linkButtonCell) {
            self.rightSide_linkButtonCell.setEnabled(isEnabled)
        }
    }
}

