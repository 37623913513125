//
//
import { WalletBeingAdded_Params } from '@xrds/xrds-libapp-ts/src/Wallets/models/WalletsListController'
import { App_View_Context } from '../App_View_Web'
import { TitledDetailsDescriptionCell } from '../Common/TitledDetailsDescriptionCell'
import { Feed_View } from '../Feed/FeedView'
import { CreatePassword_Base_View, CreatePassword_Base_View_InitParams } from '../Passwords/CreatePassword_Base_View'
import { BigInteger } from '@xrds/monero-utils-ts'
import { _LandingComponents } from './_LandingComponents'
import { AbstractThemed } from '../Common/AbstractThemed'
//
export interface CreatePassword_AndLogin_View_InitParams
{
    add_pending_wallet_params: WalletBeingAdded_Params
    walletName: string|null
    customServerURL?: string
    privateServerAuthToken?: string
    optl__start_height?: BigInteger
    initial__inbox_and_metadata__disable_remote_connectivity: boolean
}
//
export class CreatePassword_AndLogin_View extends CreatePassword_Base_View
{
    ip!: CreatePassword_AndLogin_View_InitParams
    //
    constructor(ctx: App_View_Context, ip: CreatePassword_AndLogin_View_InitParams)
    {
        super(ctx, {})
        const self = this
        self.ip = ip
    }
    //
    _overridable_setup_views__after_inputs(): void
    {
        const self = this
        // if (self.ip.customServerURL) {
        //     let view = _LandingComponents.new__cell_details_scannerURL(self.ctx.themeC, self.ip.customServerURL)
        //     view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })            container_view.addSubview(view)
        //     self.addSubview(view)
        // }
        // if (self.ip.privateServerAuthToken) {
        //     let view = _LandingComponents.new__cell_details_scannerAuthCode(self.ctx.themeC, self.ip.privateServerAuthToken)
        //     view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })            container_view.addSubview(view)
        //     self.addSubview(view)
        // }
    }
    //
    //
    override async overrideable_didSetFirstTimePassword_fn(
    ) {
        const self = this
        self.__mark_form_as_currently_submitting() // temporarily disable back button, cta, etc - since we cant cancel requests yet
        //
        // // self.ctx.controllers.userIdleController.TemporarilyDisable_userIdle()
// Temporarily disabled and using a larger minimum idle timer instead; If the app goes idle when the walletListController call comes back, the biz logic ought to be able to handle that instead
        let r = await self.ctx.controllers.walletsListController.WhenBooted_GivenPW_AddWalletWithParams({
            add_pending_wallet_params: self.ip.add_pending_wallet_params, 
            walletLabel: self.ip.walletName, 
            customServerURL: self.ip.customServerURL,
            privateServerAuthToken: self.ip.privateServerAuthToken,
            optl__start_height: self.ip.optl__start_height,
            initial__inbox_and_metadata__disable_remote_connectivity: self.ip.initial__inbox_and_metadata__disable_remote_connectivity
        })
        // // self.ctx.controllers.userIdleController.ReEnable_userIdle()
        //
        if (r.err_str) {
            self.isFormSubmitting = false // do this here or later?
            self.configureWith_formSubmittingState({}) // critical
            let err_str: string|undefined = undefined
            //
            // critical: this was a UX decision - rather than allow the wallet to be added despite a /login fail, we'll actually clear
            // the password just-saved instead - that way, user can hit 'next' or 'log in' as many times as they want and even if they
            // change the password, or restart the app, they'll have a consistent onboarding experience, rather than e.g. being asked
            // for their existing password on fresh app launch despite then being shown the Landing sequence to add first wallet.
            //
            // here a delete everything is done without spawning the Landing page again - we want to stay on the same page, but just delete things behind the scenes and place it into a pending state
            let DE_r_1 = await self.ctx.controllers.persistenceController.DeleteEverything(
                true, // and true, it's ok to try to reboot - that way persistenceController and pw controller will be in same state as on initial app launch 
                true // and do NOT notify
            )
            if (DE_r_1.err_str) {
                let DE_r_2 = await self.ctx.controllers.persistenceController.DeleteEverything(
                    true, // and true, it's ok to try to reboot - that way persistenceController and pw controller will be in same state as on initial app launch 
                    true // and do NOT notify
                )    
                if (DE_r_2.err_str) { // retry failed too
                    err_str = "Failed to delete password after login failure."
                } else {
                    err_str = r.err_str! // display e.g. login error
                }
            } else {
                err_str = r.err_str! // display e.g. login error
            }
            //
            // in order to re-allow the screen to actually be dismissed
            self.__bail_from_form_submission_with({ err_str: err_str }) // will cause component to re-render
            return
        }
        // Note: not setting the state back to enabled here bc the user is finished with this screen
        //
        self.isFormSubmitting = false // in order to re-allow the screen to actually be dismissed
        self.configureWith_formSubmittingState({})
        //
        // we know that this was not presented in a modal since the password did not yet exist
        self.ctx.rootNavigationView.reset([ // clear 'back' stack - now that we've landed 
            new Feed_View(self.ctx).init()
        ], true /* DO remove all modals */)
    }
    //
    //
    override navigationBar_title(): string
    {
        return "Choose Password"
    }
}
//