
import EventEmitter from 'events'

enum UserIdle_EventName
{
    didBecomeIdle = "didBecomeIdle",
    didWakeFromIdle = "didWakeFromIdle"
}
const default__idleAfter_ms = 10000

class UserIdleController extends EventEmitter
{
    isIdle: boolean = false
    idleAfter_ms: number = default__idleAfter_ms
    idleTimeout: any|null = null
    _numberOfRequestsToLockUserIdleAsDisabled: number = 0
    //
    // Public - Init
    constructor()
    {
        super()
        //
        const self = this
    }
    public SetupAndBootToRuntime()
    {
        const self = this
        self.resetTimerDueToActivity() 
    }
    //
    // Public - Runtime 
    public DidDetectUserActivity() // aka report activity detected
    {
        const self = this
        self.resetTimerDueToActivity()
    }
    //
    // public TemporarilyDisable_userIdle()
    // {
    //     const self = this
    //     self._numberOfRequestsToLockUserIdleAsDisabled += 1
    //     if (self._numberOfRequestsToLockUserIdleAsDisabled == 1) { // if we're requesting to disable without it already having been disabled, i.e. was 0, now 1
    //         console.log("Temporarily disabling the user idle timer")
    //         self.__disable_userIdle()
    //     } else {
    //         console.log("Requested to temporarily disable user idle but already disabled. Incremented lock.")
    //     }
    // }
    // public ReEnable_userIdle()
    // {
    //     const self = this
    //     if (self._numberOfRequestsToLockUserIdleAsDisabled == 0) {
    //         console.log("ReEnable_userIdle, self._numberOfRequestsToLockUserIdleAsDisabled 0")
    //         return // don't go below 0
    //     }
    //     self._numberOfRequestsToLockUserIdleAsDisabled -= 1
    //     if (self._numberOfRequestsToLockUserIdleAsDisabled == 0) {
    //         console.log("Re-enabling the user idle timer.")
    //         self.__reEnable_userIdle()
    //     } else {
    //         console.log("Requested to re-enable user idle but other locks still exist.")
    //     }
    // }
    //
    // Accessors
	AppTimeoutNever_numberValue()
	{
        return -1
	}
    //
    // Imperatives
    public Set_idleAfter_ms__breakingUserIdle(idleAfter_ms: number)
    {
        const self = this
        self.idleAfter_ms = idleAfter_ms
        //
        if (self._numberOfRequestsToLockUserIdleAsDisabled == 0) {
            self.resetTimerDueToActivity() // might as well use this function
        } else {
            console.warn("called Set_idleAfter_ms__breakingUserIdle but _numberOfRequestsToLockUserIdleAsDisabled was non-zero, so not resetting the timer. This can happen if you're unlocking an app with an existing Settings document.")
        }
    }
    //
    // Private - Runtime
    private resetTimerDueToActivity()
    {
        const self = this
        if (self.idleTimeout) {
            clearTimeout(self.idleTimeout)
        }
        if (self.isIdle) {
            // console.log("Did wake from idle")
            self.emit(UserIdle_EventName.didWakeFromIdle)
        }
        // console.log("Breaking idle")
        self.isIdle = false
        self._initiate_userIdle_intervalTimer()

    }
    //
    private __disable_userIdle() 
    {
        const self = this
        if (!self.idleTimeout || typeof self.idleTimeout === 'undefined') {
            throw "__disable_userIdle called but already have nil self.idleTimeout"
        }
        clearTimeout(self.idleTimeout)
        self.idleTimeout = null
        //
        self.isIdle = false // can also break idle here
    }
    private __reEnable_userIdle() 
    {
        const self = this
        if (self.idleTimeout && typeof self.idleTimeout !== 'undefined') {
            throw "__reEnable_userIdle called but non-nil self.idleTimeout"
        }
        self._initiate_userIdle_intervalTimer()
    }
    //
    private _initiate_userIdle_intervalTimer()
    {
        const self = this
        if (self.idleAfter_ms == self.AppTimeoutNever_numberValue()) {
            console.warn("Not initiating the idle timeout back up because it the timer ms was set to the 'never' value.")
            return
        }
        let weakSelf = new WeakRef(self)
        self.idleTimeout = setTimeout(() => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self.idleTimeout = null // zero for comparison
            optl_self.isIdle = true
            optl_self.emit(UserIdle_EventName.didBecomeIdle)
        }, self.idleAfter_ms)
    }

}
export { UserIdleController, UserIdle_EventName };