//
import * as monero_config from "@xrds/monero-utils-ts/src/monero_config"
//
import { Currency } from '@xrds/xrds-libapp-ts/src/Currencies/models/constants'
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { LinkButton, LinkButtonOrTextSpanItem, LinkButtonOrTextSpanItem_PKey, LinkButtonUsage } from './LinkButtonCell'
import { TitledTextInputCell, TitledTextInputCell__set_props__args } from './TitledTextInputCell'
import { TextInput_InputType } from './_TextInput'
import { AbstractControl } from "./AbstractTitledControlCell"
//
export interface IntegerInputCell_IP
{
    themeC: themes.Controller

    setToZero_explctConsumerStoredInputValueStr_fn?: () => void

    minAmount_int?: number

// TODO: possibly use this instead
    // customZero_explctConsumerStoredInputValueStr_fn?: () => string|undefined // use this to specify a custom zero/empty value to set the input contents back to if the input is blurred
    //
    amount_input__changed_fn?: (v: string, textInput_is_focused: boolean) => void
}
export enum BUTTON_ITEM_PKEY
{
    LESS = "LESS",
    MORE = "MORE"
}
export interface IntegerInputCell__set_props__args extends TitledTextInputCell__set_props__args
{ 
    integer?: {
        isEnabled?: boolean
        amount_inputStr?: string|null|undefined,
        optl_explctConsumerStoredInputValueStr?: string|null|undefined,
        //
        title: string|null|undefined // defaults to 'Amount'
        tooltipText: string|null|undefined
    }
}


//
export class IntegerInputCell extends TitledTextInputCell
{
    //
    _caic_ip!: IntegerInputCell_IP
    //
    optl_explctConsumerStoredInputValueStr?: string // an amount you can persist as having explicitly been entered by the user - it's only used to check whether to zero itself when the input is blurred and the max btn is not pressed
    //
    constructor(_caic_ip: IntegerInputCell_IP)
    {
        super({
            themeC: _caic_ip.themeC,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            //
            includeAccessory_checkbox: false,
            // input:
            placeholder: "0",
            inputType: TextInput_InputType.number_uint,
            // maxWholeDigits: ,
            // maxDecimalDigits: ,
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio,
            input__changed_fn: (_self: TitledTextInputCell, v: string) => {
                let self = _self as IntegerInputCell
                if (self._caic_ip.amount_input__changed_fn) {
                    self._caic_ip.amount_input__changed_fn(v, self.textInput!.is_focused)
                }
            },
            input__focused_fn: (_self: TitledTextInputCell) => {
                let self = _self as IntegerInputCell
            },
            // input__debounced_changed_fn?: (v: string) => void
            input__revert_value_on_blur_to__fn: (_self: TitledTextInputCell) => 
            {
                let self = _self as IntegerInputCell
                //
                // reset if the user deleted it and we have something to set
                if (self.optl_explctConsumerStoredInputValueStr === '') {
                    if (self._caic_ip.setToZero_explctConsumerStoredInputValueStr_fn) {
                        self._caic_ip.setToZero_explctConsumerStoredInputValueStr_fn()
                    }
                    return "" // clear it
                }
                return undefined // do not modify
            },
            rightSide_linkButton_pressed_fn: (_self: TitledTextInputCell, pkey: LinkButtonOrTextSpanItem_PKey) => {
                let self = _self as IntegerInputCell
                switch (pkey) {
                    case BUTTON_ITEM_PKEY.LESS:
                    case BUTTON_ITEM_PKEY.MORE:
                    {
                        let add: number = 1
                        if (pkey == BUTTON_ITEM_PKEY.LESS) {
                            add *= -1
                        } else if (pkey == BUTTON_ITEM_PKEY.MORE) {
                            // nothing to do
                        }
                        let toInt = (parseInt(self.lookup_input_value()||"0")+add)
                        if (typeof self._caic_ip.minAmount_int !== 'undefined') {
                            if (toInt <= self._caic_ip.minAmount_int) {
                                toInt = self._caic_ip.minAmount_int
                            }
                        }
                        self.set_props({ integer: { 
                            title: undefined, 
                            tooltipText: undefined ,
                            amount_inputStr: ""+toInt
                        } })
                        //
                        break
                    }
                    default:
                    {
                        throw new Error("Unhandled pkey: " + pkey)
                    }
                }
            }
        })
        const self = this
        self._caic_ip = _caic_ip

    }
    setup(): void
    {
        super.setup()
        const self = this
        {
            let view = self.textInput!
            view.layer.style.width = "auto" // so that we dont have super's width=100% (is this needed?)
            view.layer.style.flexBasis = "96"
        }
        self.set_props({ integer: {
            title: undefined,
            tooltipText: undefined
        } })
    }
    setEnabled(isEnabled: boolean): void
    {
        super.setEnabled(isEnabled)
        //
        const self = this
    }
    set_props(
        args: IntegerInputCell__set_props__args
    ) {
        const self = this
        if (args.integer) {
            let props = args.integer
            let rightSide_linkButton_items: LinkButtonOrTextSpanItem[] = []
            rightSide_linkButton_items.push({ 
                _pkey: BUTTON_ITEM_PKEY.LESS, 
                label: "-", 
                buttonUsage: LinkButtonUsage.default,
                appearance: LinkButton.Appearance.med_enclosedDecorated, 
            })
            rightSide_linkButton_items.push({ 
                _pkey: BUTTON_ITEM_PKEY.MORE, 
                label: "+", 
                buttonUsage: LinkButtonUsage.default,
                appearance: LinkButton.Appearance.med_enclosedDecorated, 
            })
            //
            // now fabricating a super.set_props call
            super.set_props({ textInput: {
                title: props.title === null ? "" : (props.title || undefined),
                placeholder: undefined,
                postTitleButtonText: undefined,
                textInput_rightSide_linkButton_items: rightSide_linkButton_items,
                tooltipText: props.tooltipText
            } })
            if (typeof props.isEnabled !== 'undefined') {
                self.setEnabled(props.isEnabled)
            }
            if (typeof props.amount_inputStr !== 'undefined') {
                self.setInputValue(props.amount_inputStr === null ? '' : props.amount_inputStr!)
            }
            if (typeof props.optl_explctConsumerStoredInputValueStr !== 'undefined') {
                self.optl_explctConsumerStoredInputValueStr = props.optl_explctConsumerStoredInputValueStr === null ? undefined : props.optl_explctConsumerStoredInputValueStr // null means delete
            }
        }
        super.set_props(args) // and *also* call .set_props in case there are any base props to set        
    }

}