import { css_rules } from "../css_rules"

export namespace styles_clearfix
{
    export function mount()
    {
        let optl_themeC = undefined
        css_rules.lazy_injectRuleStrs(styles_clearfix.namespaceName, styles_clearfix.generatorFn, optl_themeC)
    }
    //
    //
    export enum ClassNames
    {
        selectable_text = "selectable_text"
    }
    //
    export let namespaceName: css_rules.injectionNamespaceName = "clearfix"
    export let generatorFn: css_rules.cssRules_generatorFn_type = (optl_themeC) =>
    {
        return [
`* {
    margin: 0px;
    padding: 0px;
}`,
`html,
body {
    overflow: hidden;
}`,
`body {
    -webkit-touch-callout: none;
    -moz-touch-callout: none;
    -ms-touch-callout: none;
    touch-callout: none;

    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;

    cursor: default; /* a debatable addition but possibly correct */

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}`, 
`.${ClassNames.selectable_text} {
    -webkit-touch-callout: default;
    -moz-touch-callout: default;
    -ms-touch-callout: default;
    touch-callout: default;

    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;

    cursor: text; 
}`,
`a {
}`,
`input, 
textarea {
    /*-webkit-overflow-scrolling: touch;*/
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
    /*appearance: none;*/
}`
        ]
    }
}