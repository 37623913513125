import View_Web from "../../View/View.web"
import { styles_theme, themes } from "../themes"
import { Icon_Symbolic } from "./Icon_Symbolic"
//
//
export interface RadioMember_InitParams
{
    themeC: themes.Controller
}
//
export class RadioMemberView extends View_Web
{
    //
    labelView!: View_Web
    label_layer(): HTMLLabelElement
    {
        return this.labelView.layer as HTMLLabelElement
    }
    //
    inputView!: View_Web
    input_layer(): HTMLInputElement
    {
        return this.inputView.layer as HTMLInputElement
    }
    //
    _value!: string // will be available after first .set_input_params__radio call
    value(): string
    { // Previously I tried to put this on the input
        return this._value
    }
    //
    //
    ip!: RadioMember_InitParams
    changed_fn?: (self: RadioMemberView, value: string) => void

    // _fn__filteringToNumbers__paste?: (e) => void
    //
    constructor(ip: RadioMember_InitParams)
    {
        super({ el_name: "div" })
        //
        const self = this
        self.ip = ip
    }
    public teardown(): void 
    {
        const self = this
        //
        // if (self._fn__filteringToNumbers__paste) {
        //     self.input_layer().removeEventListener("paste", self._fn__filteringToNumbers__paste)
        //     self._fn__filteringToNumbers__paste = undefined
        // }
        //
        // finally, .layer can be discarded etc
        super.teardown()
    }
    setup(): void 
    {
        super.setup()
        const self = this
        let weakSelf = new WeakRef(self)
        {
            let view = new View_Web({ el_name: "label" }).init()
            view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
            self.labelView = view
            self.addSubview(view)
        }
        {
            let view = new View_Web({ el_name: "input" }).init()
            self.inputView = view
            let input = self.input_layer()
            input.type = "radio"
            self.addSubview(view)
            // self.input_layer().addEventListener('paste', self._fn__filteringToNumbers__paste!)
            //
            input.addEventListener('change', () => {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                if (optl_self.changed_fn) {
                    optl_self.changed_fn(optl_self, optl_self._value)
                }
            })
        }
    }
    //
    public setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled)
        self.input_layer().disabled = isEnabled == false
        if (isEnabled) {
            self.label_layer().classList.remove("disabled")
        } else {
            self.label_layer().classList.add("disabled")
        }
    }
    //
    public set_props(props: {
        label: string|undefined
    }) {
        const self = this
        if (typeof props.label !== 'undefined') {
            self.label_layer().innerText = props.label
        }
    }
    //
    //
    public set_input_params__radio(args: {
        radio_group_name: string|undefined, // simply pass self.uuid of the view you're adding the RadioMemberView to - it should be unique
        value: string|undefined,
        checked: boolean|undefined
    }) {
        const self = this
        let input = self.input_layer()
        if (typeof args.value !== 'undefined') {
            self._value = args.value // rather than storing it on input. ... since it didnt seem to work on the input anyway
            input.value = args.value // important..
        }
        if (typeof args.radio_group_name !== 'undefined') {
            let radio_input_id = args.radio_group_name + "-" + self.uuid // technically shouldn't even need to prefix with name like that
            input.id = radio_input_id
            input.name = args.radio_group_name
            self.label_layer().setAttribute("for", radio_input_id)
        }
        if (typeof args.checked !== 'undefined') {
            input.checked = args.checked == true // just to sanitize it slightly
        }
    }
}

