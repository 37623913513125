import { EventEmitter } from "events";
import { DocumentJSON, new_DocumentId, CollectionName, DocumentId } from './Documents'
import FilePersister_Base from "./FilePersister_Base";
//
export interface AppPersistenceController_Base_InitParams
{
    filePersister: FilePersister_Base // really, a subclass thereof
}
enum AppData_FileKey
{
    primary = "XRDS-AppData-primary",
    changePW_step1 = "XRDS-AppData-changePW_step1"
}
export enum Persistence_EventName
{
    DidLoadAppDataFromFile = "DidLoadAppDataFromFile", // even upon error - so that consumers can update
    //
    WillDeconstructBootedState = "WillDeconstructBootedState",
    DidDeconstructBootedState = "DidDeconstructBootedState",
    DidDeleteEverythingAndDeboot = "DidDeleteEverythingAndDeboot"
}
//
//
export default class AppPersistenceController_Base extends EventEmitter
{
    //
    //
    initParams!: AppPersistenceController_Base_InitParams
    //
    appDataJSON: { [key: string]: any } = {}
    didStoreLoadCallOccur: boolean = false
    hasFailedFileLoadWithErrStr: string | null | undefined = undefined // not yet booted
    //
    constructor(initParams: AppPersistenceController_Base_InitParams)
    {
        super()
        const self = this
        self.initParams = initParams
        //
        // consumers: call postSetup__appDataJSON_initFromFile() when ready before any other imperative/acccessor calls
    }
    public async postSetup__appDataJSON_initFromFile()
    { // throws
        const self = this
        let str = await self.initParams.filePersister.Read_fileWithKey(AppData_FileKey.primary)
        if (str) {
            try {
                self.appDataJSON = JSON.parse(str)
                self.hasFailedFileLoadWithErrStr = null // non-error
            } catch (e) {
                self.hasFailedFileLoadWithErrStr = "" + e
                self.appDataJSON = {}
            }
        } else { // fresh load / no data
            self.appDataJSON = {} // redundant line but for clarity..
            self.hasFailedFileLoadWithErrStr = null // non-error
        }
        self.didStoreLoadCallOccur = true
        await self.emit(Persistence_EventName.DidLoadAppDataFromFile) // even upon error - so that consumers can update
    }
    //
    // Accessors - From memory - these do not trigger loads
    public AllIdsFor(collectionName: CollectionName): { 
        err_str: string | undefined,
        ids: DocumentId[]
    } {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.appDataJSON[collectionName]
        if (!c) {
            return { ids: [], err_str: undefined }
        }
        return { ids: Object.keys(c), err_str: undefined }
    }
    public AllValStringsFor(collectionName: CollectionName): { vals: string[] }
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.appDataJSON[collectionName]
        if (!c) {
            return { vals: [] }
        }
        return { vals: Object.values(c!) }
    }
    public ValStringFor(collectionName: CollectionName, _id: DocumentId): { valStr?: string }
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.appDataJSON[collectionName]
        if (!c) {
            return { valStr: undefined }
        }
        return { valStr: c[_id] }
    }
    //
    // Imperatives
    public async WriteStringFor(
        collectionName: CollectionName, 
        _id: DocumentId, 
        valStr: string
    ): Promise<{ err_str?: string }> {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.appDataJSON[collectionName]
        if (!c) {
            c = {}
            self.appDataJSON[collectionName] = c
        }
        c[_id] = valStr
        //
        // TODO: queue this save request unless atomic? or batch multiple updates within a single write
        // 
        return await self._saveToDisk()
    }
    public async RemoveValsFor(collectionName: CollectionName, _ids: DocumentId[]): Promise<{
        any_not_found: boolean,
        err_str?: string
    }> { // returns false if any of the _ids were not present
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        if (_ids.length == 0) {
            throw new Error("RemoveValsFor: Non-zero _ids required")
        }
        let c = self.appDataJSON[collectionName]
        if (!c) {
            return {
                any_not_found: true
            }
        }
        let any_not_found = false
        for (var i = 0 ; i < _ids.length ; i++) {
            let _id = _ids[i]
            let v = c[_id]
            if (v == null || typeof v === 'undefined') {
                any_not_found = true
                continue
            }
            delete c[_id]
        }
        let save_r = await self._saveToDisk()
        return {
            any_not_found: !any_not_found,
            err_str: save_r.err_str
        }

    }
    public async DeleteEverything(andReboot: boolean = true, andSilentlySkipNotify: boolean = false): Promise<{ err_str?: string }>
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        {
            await self._overridable_will_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify)
            self.appDataJSON = {}
            await self._overridable_did_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify)
        }
        let r = await self._saveToDisk()
        await self._overridable_did_deleteEverythingAndDeboot(andSilentlySkipNotify)
        if (andReboot) {
            setTimeout(() => { // do not block return
                self.postSetup__appDataJSON_initFromFile()
            })
        }
        //
        return r 
    }
    async _overridable_will_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify: boolean = false)
    { // override but call on super
        const self = this
        if (andSilentlySkipNotify != true) {
            self.emit(Persistence_EventName.WillDeconstructBootedState, {
                isForADeleteEverything: true
            })
        }
    }
    async _overridable_did_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify: boolean = false)
    { // override but call on super
        const self = this
        if (andSilentlySkipNotify != true) {
            self.emit(Persistence_EventName.DidDeconstructBootedState)
        }
    }
    async _overridable_did_deleteEverythingAndDeboot(andSilentlySkipNotify: boolean = false)
    {
        const self = this
        if (andSilentlySkipNotify != true) {
            await self.emit(Persistence_EventName.DidDeleteEverythingAndDeboot)
        }
    }
    //
	// Runtime - Imperatives - Private - Persistence
	async _saveToDisk(): Promise<{ err_str?: string }>
    {
        const self = this
        //
        return self._saveToDisk_specific_appDataJSON(self.appDataJSON)
    }
    
    async _saveToDisk_specific_appDataJSON(
        appDataJSON: { [key: string]: any }
    ): Promise<{ err_str?: string }>
	{
        const self = this
        // console.log("Saving", appDataJSON, "to disk.")
        let str = JSON.stringify(appDataJSON)
        try {
            await self.initParams.filePersister.Write_dataToFileKey(AppData_FileKey.primary, str)
        } catch (e) {
            console.error("Error while saving app data:", e)
            //
            return { err_str: (e as Error).message }
        }
        return {}
	}


}