//
//
import View_Web from '../../View/View.web'
import { styles_theme, themes } from '../themes'
import { AbstractControl } from './AbstractTitledControlCell'
import { WordBreakBehaviorStyle_TitledDetails } from './TitledDetailsDescriptionCell'
import { PropSettable } from './DisplayableListRow'
//
export interface TitledEducationalTextCell_InitParams
{
    themeC: themes.Controller
    contextInUI: themes.ElementContextInUI
    wordBreakBehaviorStyle_description?: WordBreakBehaviorStyle_TitledDetails
}
export interface TitledEducationalTextCell__set_props__args extends PropSettable.base__set_props__args
{ 
    title: string|null, 
    subtitle?: string|null,
    //
    description: string|null,
    //
    isText_userSelectable?: boolean,
    postTitleButtonText?: string|undefined|null,
    tooltipText?: string|null|undefined
}
//
//
export class TitledEducationalTextCell extends AbstractControl.Base_Cell
{
    //
    //
    ip!: TitledEducationalTextCell_InitParams
    //
    label_description?: View_Web
    label_subtitle?: View_Web
    //
    constructor(ip: TitledEducationalTextCell_InitParams)
    {
        super({ 
            themeC: ip.themeC,
            contextInUI: typeof ip.contextInUI == 'undefined' 
                ? themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup
                : ip.contextInUI
        })
        const self = this
        self.ip = ip
    }
    setup(): void 
    {
        super.setup()
        const self = this
        let themeC = self.ip.themeC
        {
            let view = self
            view.layer.style.display = "block" // take up whole line
            //
            switch (self.ip.contextInUI) {
                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                    view.layer.style.paddingTop = "12px" // the Form page is now styled with a top margin
                    view.layer.style.marginTop = `${themeC.current.layouts.cellGroup_parent_margin_top}px`
                    view.layer.classList.add(styles_theme.ClassNames.form_row)
                    break
                case themes.ElementContextInUI.FormControl_outsideOfCellGroup:
                    view.layer.style.marginTop = `${themeC.current.layouts.cellGroup_parent_margin_top}px`
                    view.layer.style.paddingTop = `${themeC.current.layouts.cellGroup_row_padding_top}px`
                    view.layer.classList.add(styles_theme.ClassNames.form_row)
                    break
                case themes.ElementContextInUI.CellGroupHeader_large_outsideOfCellGroup:
                    view.layer.style.marginTop = `${themeC.current.layouts.cellGroup_parent_margin_top}px`
                    view.layer.style.paddingTop = `${themeC.current.layouts.cellGroup_row_padding_top}px`
                    view.layer.classList.add(styles_theme.ClassNames.form_row)
                    break
                case themes.ElementContextInUI.SplashHeader_Mini:
                    view.layer.style.paddingTop = "71px"
                    break
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.paddingTop = "48px"
                    break
                case themes.ElementContextInUI.SplashHeader:
                    break
                case themes.ElementContextInUI.FormControl_withinCellGroup: // this will get all the styles it needs elsewhere - but prevent the extraneous paddingTop from being added
                    break
                default:
                    view.layer.style.paddingTop = `${themeC.current.layouts.cellGroup_row_padding_top}px`
                    view.layer.classList.add(styles_theme.ClassNames.form_row)
                    break
            }
            //
            self._primaryInputContainer_subcontainer_0.layer.style.flexDirection = "column" // mainly relevant if there is a subtitle
        }
    }
    //
    set_props(args: TitledEducationalTextCell__set_props__args): void {
        const self = this
        let themeC = self.ip.themeC
        if (args.title !== null) {
            self.set_props_labelRow({
                title: args.title,
                postTitleButtonText: args.postTitleButtonText,
                tooltipText: args.tooltipText
            })
            //
// TODO: possibly port 'context' into _LabelRowContainer 
            //
            let view = self._labelRow
            view.layer.style.width = "100%"
            switch (self.ip.contextInUI) {
                case themes.ElementContextInUI.SplashHeader:
                    view.layer.style.textAlign = "center"  
                    view.layer.style.marginTop = "29px"
                    break
                case themes.ElementContextInUI.SplashHeader_Mini:
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.textAlign = "center"  
                    break
                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                default:
                    view.layer.style.textAlign = "left"
                    break
            }
            if (args.isText_userSelectable === true) {
                view.layer.style.userSelect = "text"
                view.layer.style.webkitUserSelect = "text"
            } else {
                view.layer.style.userSelect = "none"
                view.layer.style.webkitUserSelect = "none"
            }
        }
        if (args.subtitle !== null) {
            if (typeof args.subtitle !== 'undefined') {
                if (!self.label_subtitle) {
                    let view = new View_Web({ el_name: "div" }).init()
                    view.layer.classList.add("label_subtitle")
                    if (args.isText_userSelectable === true) {
                        view.layer.style.userSelect = "text"
                        view.layer.style.webkitUserSelect = "text"
                    } else {
                        view.layer.style.userSelect = "none"
                        view.layer.style.webkitUserSelect = "none"
                    }
                    if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                        view.setEnabled(false)
                    }
                    view.layer.style.color = themeC.current.colors.font_absoluteLightest
                    view.layer.style.width = "100%"
                    //
                    switch (self.ip.contextInUI) {
                        case themes.ElementContextInUI.SplashHeader:
                            view.layer.style.marginTop = "12px"
                            view.layer.style.textAlign = "center"
                            break
                        case themes.ElementContextInUI.SplashHeader_Mini:
                        case themes.ElementContextInUI.SplashBody_Mini:
                            view.layer.style.textAlign = "center"
                            break
                        case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                        case themes.ElementContextInUI.CellGroupHeader_large_outsideOfCellGroup:
                        case themes.ElementContextInUI.FormControl_outsideOfCellGroup:
                        case themes.ElementContextInUI.FormControl_withinCellGroup:
                        default:
                            view.layer.style.textAlign = "left"
                            break
                    }
                    //
                    switch (self.ip.contextInUI) {
                        case themes.ElementContextInUI.SplashHeader:
                            view.layer.style.fontFamily = themes.styles_fonts.FontFamilies.ss_regular
                            view.layer.style.fontSize = `24px`
                            view.layer.style.fontWeight = "300"
                            view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                            break
                        default:
                            throw new Error("Styling for that UI context is not yet supported with .subtitle")
                    }
                    //
                    self.label_subtitle = view
                    self._primaryInputContainer_subcontainer_0.insertSubview(view, 0) // before any possible description
                }
                self.label_subtitle.layer.innerText = args.subtitle
            }
        } else {
            if (self.label_subtitle) {
                self.label_subtitle.removeFromSuperview()
                self.label_subtitle = undefined
            }
        }
        if (args.description !== null) {
            if (typeof args.description !== 'undefined') {
                if (!self.label_description) {
                    let view = new View_Web({ el_name: "div" }).init()
                    if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                        view.setEnabled(false)
                    }
                    view.layer.classList.add("label_description")

                    view.layer.style.color = themeC.current.colors.font_light
                    view.layer.style.width = "100%"
                    view.layer.style.fontFamily = themes.styles_fonts.FontFamilies.ss_regular
                    view.layer.style.fontWeight = "400"
                    if (self.ip.wordBreakBehaviorStyle_description == null || typeof self.ip.wordBreakBehaviorStyle_description == 'undefined' || self.ip.wordBreakBehaviorStyle_description == WordBreakBehaviorStyle_TitledDetails.default) {
                        view.layer.style.wordBreak = "word" // default styling
                    } else { // then we know it's not nil and not .default
                        if (self.ip.wordBreakBehaviorStyle_description == WordBreakBehaviorStyle_TitledDetails.anywhere) {
                            view.layer.style.overflowWrap = "anywhere"
                        } else {
                            throw new Error("Code fault: Unhandled (new?) self.ip.wordBreakBehaviorStyle_description")
                        }
                    }            
                    switch (self.ip.contextInUI) {
                        case themes.ElementContextInUI.SplashHeader:
                            view.layer.style.textAlign = "center"
                            view.layer.style.fontSize = "16px"
                            view.layer.style.marginTop = "50px"
                            break
                        //
                        case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                            view.layer.style.textAlign = "left"
                            view.layer.style.fontSize = "16px"
                            view.layer.style.marginTop = "22px"
                            break
                        case themes.ElementContextInUI.SplashHeader_Mini:
                            view.layer.style.textAlign = "center"
                            view.layer.style.fontSize = "16px"
                            view.layer.style.marginTop = "31px"
                            view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                            break
                        case themes.ElementContextInUI.SplashBody_Mini:
                            view.layer.style.textAlign = "center"
                            view.layer.style.fontSize = "16px"
                            view.layer.style.marginTop = "0px"
                            view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                            break
                        default:
                            view.layer.style.textAlign = "left"
                            view.layer.style.fontSize = "16px"
                            view.layer.style.marginTop = "0px"
                            view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                            break
                    }
                    if (args.isText_userSelectable === true) {
                        view.layer.style.userSelect = "text"
                        view.layer.style.webkitUserSelect = "text"
                    } else {
                        view.layer.style.userSelect = "none"
                        view.layer.style.webkitUserSelect = "none"
                    }
                    self.label_description = view
                    self._primaryInputContainer_subcontainer_0.insertSubview(view, self._primaryInputContainer_subcontainer_0.subviews.length) // after any possible subtitle
                }
                self.label_description.layer.innerText = args.description
            }
        } else {
            if (self.label_description) {
                self.label_description.removeFromSuperview()
                self.label_description = undefined
            }
        }
    }
}