//
//
import View_Web from "../../View/View.web"
import { styles_theme } from "../themes"
import { PropSettable } from './DisplayableListRow'
//
export namespace CellGroupSetMarker
{
    // the nice thing about CellGroupSetMarker is they both serve as a hierarchy marker for relative insertSubviews and an explicit css styling separator for cases where the rules wouldn't be able to tell, otherwise (not that the latter is an issue anymore with our programmatic end-capping etc)
    export interface set_props__args extends PropSettable.base__set_props__args {}
    export class View extends View_Web
    {
        constructor(ip: any)
        {
            super({})
        }
        override setup()
        {
            super.setup()
            let self = this
            self.layer.style.width = "0px"
            self.layer.style.height = "0px"
        }
        public set_props(args: set_props__args) {} // I place this here for simple compat in DisplayableList even tho it's not required
    }
}
//
export namespace AbstractThemed
{
    export enum GroupPrecedence
    {
        _0 = "_0", // base
        _1 = "_1", // bolder/higher than base
    }
    function cellGroup_Precedence_className_with(to_type: GroupPrecedence): string
    {
        switch (to_type) {
            case GroupPrecedence._0:
                return styles_theme.ClassNames.cellGroup_bg_0
            case GroupPrecedence._1:
                return styles_theme.ClassNames.cellGroup_bg_1
        }
    }
    function cellGroup_Precedence_removePossibleOther_classNames_with(to_type: GroupPrecedence): string[]
    {
        switch (to_type) {
            case GroupPrecedence._0:
                return [
                    styles_theme.ClassNames.cellGroup_bg_1
                ]
            case GroupPrecedence._1:
                return [
                    styles_theme.ClassNames.cellGroup_bg_0,
                    styles_theme.ClassNames.cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1
                ]
        }
    }
    //
    export namespace CellGroup_Special
    {
        export enum Variant
        { // generally these are going to be mutually exclusive but in principle they might be able to be set simultaneously and successfully apply to do some degree
            addtlRightGap_small,
            addtlLeftGap_small,
            flexGrow_1,
            noMargin
        }
        export let allVariants: Variant[] = 
        [
            Variant.addtlRightGap_small,
            Variant.addtlLeftGap_small,
            Variant.flexGrow_1,
            Variant.noMargin
        ]
        export function classFrom(variant: Variant): string
        {
            switch (variant) {
                case Variant.addtlLeftGap_small:
                    return styles_theme.ClassNames.cellGroup_row_variant_addtlGap_left_small
                case Variant.addtlRightGap_small:
                    return styles_theme.ClassNames.cellGroup_row_variant_addtlGap_right_small
                case Variant.flexGrow_1:
                    return styles_theme.ClassNames.cellGroup_row_variant_flexGrow_1
                case Variant.noMargin:
                    return styles_theme.ClassNames.cellGroup_row_variant_noMargin
            }
        }
    }
    //
    export interface Set_CellTheme_Props // exported for DisplayableListRow
    {
        p: GroupPrecedence
        isInEmbeddedGroup: boolean
        explicitNoBottomSeparator?: boolean // defaults to false
        explicitZeroEmbGroupBorderRadius_top?: boolean // defaults to false
        cellVariants?: CellGroup_Special.Variant[]
    }
    //
    export class View extends View_Web
    {
        setup(): void 
        {
            super.setup()
            //
            // PS: one nice design feature currently is that at present, unless the subclasser/consumer wants to, they can write their views to inherit from AbstractThemed.View but don't have to necessarily require it to be used, enabling the view to be embedded in non-groupcell contexts, e.g. LinkButtonCell
        }
        //
        // Imperatives - Theming
        current_theme_props: Set_CellTheme_Props|null = null
        public set_cellTheme(
            props: Set_CellTheme_Props|null 
        ) {
            const self = this
            self.current_theme_props = props
            //
            if (props === null) { // not very common at all but... may as well support it
                self.layer.classList.remove(styles_theme.ClassNames.form_row)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_bg_0)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_bg_1)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_explicitNoBottomSeparator)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_explicitZeroEmbGroupBorderRadius_top)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_variant_addtlGap_right_small)
                self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_variant_addtlGap_left_small)
            } else {
                self.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h
                self.layer.classList.add(styles_theme.ClassNames.cellGroup_row)
                {
                    let to_class = cellGroup_Precedence_className_with(props.p)
                    let remove_classes = cellGroup_Precedence_removePossibleOther_classNames_with(props.p) // in case they were added, zero the other group-cell theming
                    for (let c of remove_classes) {
                        self.layer.classList.remove(c)
                    }
                    self.layer.classList.add(to_class!)
                }
                if (props.isInEmbeddedGroup == true) {
                    self.layer.classList.add(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)
                } else {
                    self.layer.classList.remove(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)
                }
                if (props.explicitNoBottomSeparator == true) {
                    self.layer.classList.add(styles_theme.ClassNames.cellGroup_row_explicitNoBottomSeparator)
                } else {
                    self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_explicitNoBottomSeparator)
                }
                if (props.explicitZeroEmbGroupBorderRadius_top == true) {
                    self.layer.classList.add(styles_theme.ClassNames.cellGroup_row_explicitZeroEmbGroupBorderRadius_top)
                } else {
                    self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_explicitZeroEmbGroupBorderRadius_top)
                }
                if (props.cellVariants) {
                    for (let variant of CellGroup_Special.allVariants) {
                        let variantClass = CellGroup_Special.classFrom(variant)
                        if (props.cellVariants.indexOf(variant) != -1) {
                            self.layer.classList.add(variantClass)
                        } else {
                            self.layer.classList.remove(variantClass)
                        }
                    }
                } else {
                    self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_variant_addtlGap_right_small)
                    self.layer.classList.remove(styles_theme.ClassNames.cellGroup_row_variant_addtlGap_left_small)
                } 
            }
        }
    }
}
//
//
export namespace AbstractThemed
{
    function styleAs_topCap(sv: View_Web)
    {
        sv.layer.classList.add(styles_theme.ClassNames.__programmatic_cellGroup_bg_roundCap_top)
    }
    function styleAs_notA_topCap(sv: View_Web)
    {
        sv.layer.classList.remove(styles_theme.ClassNames.__programmatic_cellGroup_bg_roundCap_top)
    }
    function styleAs_bottomCap(sv: View_Web)
    {
        sv.layer.classList.add(styles_theme.ClassNames.__programmatic_cellGroup_bg_roundCap_bottom)
    }
    function styleAs_notA_bottomCap(sv: View_Web)
    {
        sv.layer.classList.remove(styles_theme.ClassNames.__programmatic_cellGroup_bg_roundCap_bottom)
    }
    //
    function styleAs_cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1(sv: View_Web)
    {
        sv.layer.classList.add(styles_theme.ClassNames.cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1) // there are currently no other types of embedded groups

    }
    function styleAs_notA_cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1(sv: View_Web)
    {
        sv.layer.classList.remove(styles_theme.ClassNames.cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1) // there are currently no other types of embedded groups
    }
    //
    export function updateDynamicClassesOfGroupCellsOf(ofView: View_Web)
    { // this function is used in JS land instead of trying to rely on CSS in order to capture *all* of the cases ... we need something akin to an arbitrary forward sibling lookahead .. but it gets kinda annoying
        const self = ofView
        let n_sv = self.subviews.length
        for (let i = 0 ; i < n_sv ; i++) {
            let sv = self.subviews[i] as View_Web
            if (! sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_row)) {
                continue
            }
            if (sv.isHidden) { 
                styleAs_notA_topCap(sv)
                styleAs_notA_bottomCap(sv)
                styleAs_notA_cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1(sv)
                continue
            }
            //
            let is_top_cap = false
            // top cap detection
            // 1. cg-row which has no prior visible sibling 
            // 2a/b. any next sibling of a visible non-same-bg cg-row
            let prior_visible_sv: View_Web|null = null;
            {
                let j = i - 1;
                while (j >= 0) {
                    let _sv = self.subviews[j] as View_Web
                    if (! _sv.isHidden) {
                        prior_visible_sv = _sv
                        break
                    }
                    j--
                }
            }
            if (! prior_visible_sv) {
                is_top_cap = true
            } else if (! prior_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_row)) {
                is_top_cap = true
            } else if (! prior_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                if (sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                    is_top_cap = true
                }
            } else if (! prior_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_1)) {
                if (sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_1)) {
                    is_top_cap = true
                }
            }
            //
            let is_bottom_cap = false
            // bottom cap detection
            // 1. followed by a non-cellGroup_row (in which case i think we can assume its not harmful to match even if it's hidden); or
            // 2. have no next siblings at all to check classes of
            // 3a/b. followed by another 'first' which isn't starting an embedded group (which we detect by a change of bg color) (and is therefore not the first element in the parent either, so no need to check that case); or
            let next_visible_sv: View_Web|null = null;
            {
                let j = i + 1;
                while (j < n_sv) {
                    let _sv = self.subviews[j] as View_Web
                    if (! _sv.isHidden) {
                        next_visible_sv = _sv
                        break
                    }
                    j++
                }
            }
            if (! next_visible_sv) {
                is_bottom_cap = true
            } else if (! next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_row)) {
                is_bottom_cap = true
            } else if (sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_1)) {
                if (next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                    if (! next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)) {
                        is_bottom_cap = true
                    }
                }
            } else if (sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                if (next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_1)) {
                    if (! next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)) {
                        is_bottom_cap = true
                    }
                }
            }
            //
            if (is_top_cap) {
                styleAs_topCap(sv)
            } else { // in case it was a top cap, then..
                styleAs_notA_topCap(sv)
            }
            if (is_bottom_cap) {
                styleAs_bottomCap(sv)
            } else { // in case it was a bottom cap, then..
                styleAs_notA_bottomCap(sv)
            }
            //
            //
            let nextCellWillBeNewEmbeddedGroupWith_p: GroupPrecedence | null = null
            if (next_visible_sv) {
                if (! sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)) {
                    if (next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_isEmbeddedInOtherGroup)) {
                        if (next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                            throw new Error("Not expecting embedded group of bg_0")
                        }
                        if (! next_visible_sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_1)) {
                            throw new Error("Unrecognized or no bg precedence on a .cellGroup_isEmbeddedInOtherGroup")
                        }
                        nextCellWillBeNewEmbeddedGroupWith_p = GroupPrecedence._1
                    }
                }
            }
            if (nextCellWillBeNewEmbeddedGroupWith_p) {
                if (!sv.layer.classList.contains(styles_theme.ClassNames.cellGroup_bg_0)) {
                    throw new Error("Expected sv to have .cellGroup_bg_0")
                }
                styleAs_cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1(sv)
            } else {
                styleAs_notA_cellGroup_bg_0_nextCellIsEmbeddedGroupOf_1(sv)
            }
        }
    }
}