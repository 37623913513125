//
import * as monero_config from "@xrds/monero-utils-ts/src/monero_config"
//
import { Currency } from '@xrds/xrds-libapp-ts/src/Currencies/models/constants'
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { LinkButton, LinkButtonOrTextSpanItem, LinkButtonOrTextSpanItem_PKey, LinkButtonUsage } from './LinkButtonCell'
import { TitledTextInputCell, TitledTextInputCell__set_props__args } from './TitledTextInputCell'
import { TextInput_InputType } from './_TextInput'
import { AbstractControl } from "./AbstractTitledControlCell"
//
export interface CcyAmountInputCell_IP
{
    themeC: themes.Controller
    //
    show_maxButton?: boolean, // defaults to true
    changed__isMaxButtonToggledOn_fn?: (isMaxButtonToggledOn: boolean) => void 

    setToZero_explctConsumerStoredInputValueStr_fn?: () => void

// TODO: possibly use this instead
    // customZero_explctConsumerStoredInputValueStr_fn?: () => string|undefined // use this to specify a custom zero/empty value to set the input contents back to if the input is blurred
    //
    amount_input__changed_fn?: (v: string, textInput_is_focused: boolean) => void
}
export interface CcyAmountInputCell__set_props__args extends TitledTextInputCell__set_props__args
{ 
    ccyAmount?: {
        isMaxButtonToggledOn: boolean|undefined, 
        ccy: Currency|undefined,
        isEnabled?: boolean
        amount_inputStr?: string|null|undefined,
        optl_explctConsumerStoredInputValueStr?: string|null|undefined,
        //
        title: string|null|undefined // defaults to 'Amount'
        tooltipText: string|null|undefined
    }
}
//
let MAX_BUTTON_ITEM_PKEY: string = "max_btn"

//
export class CcyAmountInputCell extends TitledTextInputCell
{
    private _isMaxButtonToggledOn: boolean = false
    isMaxButtonToggledOn(): boolean { return this._isMaxButtonToggledOn }
    //
    _ccy: Currency = Currency.XMR
    //
    _caic_ip!: CcyAmountInputCell_IP
    //
    button_max?: LinkButton.Cell
    label_ccy!: View_Web
    //
    optl_explctConsumerStoredInputValueStr?: string // an amount you can persist as having explicitly been entered by the user - it's only used to check whether to zero itself when the input is blurred and the max btn is not pressed
    //
    constructor(_caic_ip: CcyAmountInputCell_IP)
    {
        super({
            themeC: _caic_ip.themeC,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            //
            includeAccessory_checkbox: false,
            // input:
            placeholder: "0.000000000000",
            inputType: TextInput_InputType.number_ufloat,
            maxWholeDigits: 12,
            maxDecimalDigits: monero_config.coinUnitPlaces,
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio,
            input__changed_fn: (_self: TitledTextInputCell, v: string) => {
                let self = _self as CcyAmountInputCell
                if (self._caic_ip.amount_input__changed_fn) {
                    self._caic_ip.amount_input__changed_fn(v, self.textInput!.is_focused)
                }
            },
            input__focused_fn: (_self: TitledTextInputCell) => {
                let self = _self as CcyAmountInputCell
                if (self._isMaxButtonToggledOn == true) {
                    self.set_props({ ccyAmount: { 
                        isMaxButtonToggledOn: false, 
                        ccy: undefined, 
                        title: undefined,
                        tooltipText: undefined 
                    } })
                }                
            },
            // input__debounced_changed_fn?: (v: string) => void
            input__revert_value_on_blur_to__fn: (_self: TitledTextInputCell) => 
            {
                let self = _self as CcyAmountInputCell
                //
                // reset if the user deleted it and we have something to set
                if (self.optl_explctConsumerStoredInputValueStr === '' && !self._isMaxButtonToggledOn) {
                    if (self._caic_ip.setToZero_explctConsumerStoredInputValueStr_fn) {
                        self._caic_ip.setToZero_explctConsumerStoredInputValueStr_fn()
                    }
                    return "" // clear it
                }
                return undefined // do not modify
            },
            rightSide_linkButton_pressed_fn: (_self: TitledTextInputCell, pkey: LinkButtonOrTextSpanItem_PKey) => {
                let self = _self as CcyAmountInputCell
                if (pkey == MAX_BUTTON_ITEM_PKEY) {
                    self.set_props({ ccyAmount: { 
                        isMaxButtonToggledOn: true, 
                        ccy: undefined, 
                        title: undefined, 
                        tooltipText: undefined 
                    } })
                } else {
                    throw new Error("Unhandled pkey: " + pkey)
                }
            }
        })
        const self = this
        self._caic_ip = _caic_ip

    }
    setup(): void
    {
        super.setup()
        const self = this
        {
            let view = self.textInput!
            view.layer.style.width = "auto" // so that we dont have super's width=100% (is this needed?)
            view.layer.style.flexBasis = "96"
        }
        self.set_props({ ccyAmount: {
            isMaxButtonToggledOn: undefined,
            ccy: self._ccy, // initial configuration
            title: undefined,
            tooltipText: undefined
        } })
    }
    setEnabled(isEnabled: boolean): void
    {
        super.setEnabled(isEnabled)
        //
        const self = this
        self.button_max?.setEnabled(isEnabled)
    }
    set_props(
        args: CcyAmountInputCell__set_props__args
    ) {
        const self = this
        if (args.ccyAmount) {
            let props = args.ccyAmount
            let rightSide_linkButton_items: LinkButtonOrTextSpanItem[] = []
            if (typeof props.ccy !== 'undefined') {
                self._ccy = props.ccy
            }
            rightSide_linkButton_items.push({
                _pkey: "ccy_label",
                label: self._ccy
            })
            if (self._caic_ip.show_maxButton != false) { // undefined -> true
                if (props.isMaxButtonToggledOn != true) {
                    rightSide_linkButton_items.push({ 
                        _pkey: MAX_BUTTON_ITEM_PKEY, 
                        label: "MAX", 
                        buttonUsage: LinkButtonUsage.default,
                        appearance: LinkButton.Appearance.small_enclosedDecorated, // special for MAX btn
                    })
                } else {
                    // otherwise don't include the MAX btn
                }
            }
            //
            super.set_props({ textInput: {
                title: props.title || "Amount",
                placeholder: undefined,
                postTitleButtonText: undefined,
                textInput_rightSide_linkButton_items: rightSide_linkButton_items,
                tooltipText: props.tooltipText
            } })
            if (typeof props.isMaxButtonToggledOn !== 'undefined') {
                let was = self._isMaxButtonToggledOn
                self._isMaxButtonToggledOn = props.isMaxButtonToggledOn
                if (self._caic_ip.changed__isMaxButtonToggledOn_fn) { // emit
                    if (was != self._isMaxButtonToggledOn) {
                        self._caic_ip.changed__isMaxButtonToggledOn_fn(self._isMaxButtonToggledOn)
                    }
                }
            }
            self._configureWith_maxButtonVisibility()
            if (typeof props.isEnabled !== 'undefined') {
                self.setEnabled(props.isEnabled)
            }
            if (typeof props.amount_inputStr !== 'undefined') {
                self.setInputValue(props.amount_inputStr === null ? '' : props.amount_inputStr!)
            }
            if (typeof props.optl_explctConsumerStoredInputValueStr !== 'undefined') {
                self.optl_explctConsumerStoredInputValueStr = props.optl_explctConsumerStoredInputValueStr === null ? undefined : props.optl_explctConsumerStoredInputValueStr // null means delete
            }
        }
        super.set_props(args) // and *also* call .set_props in case there are any base props to set        
    }
    _configureWith_maxButtonVisibility()
    {
        const self = this
        if (self.button_max) { // since it's possible for it not to exist
            let should_display_max_btn = !self._isMaxButtonToggledOn && self._ccy == Currency.XMR/* for now */
            let view = self.button_max
            view.layer.style.visibility = should_display_max_btn ? "visible" : "hidden"
        }
    }

    // set_explctConsumerStoredInputValueStr(optl_explctConsumerStoredInputValueStr?: string // an amount you can persist as having explicitly been entered by the user - it's only used to check whether to zero itself when the input is blurred and the max btn is not pressed
    // }) {
    //     const self = this

    // }
}