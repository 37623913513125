import fs from 'fs'
import path from 'path'
//
import FilePersister_Base from '@xrds/xrds-libapp-ts/src/Persistable/FilePersister_Base'
//
export interface FilePersister_LocalStorage_InitParams
{
}
export default class FilePersister_LocalStorage extends FilePersister_Base
{
    init_params!: FilePersister_LocalStorage_InitParams
    //
    constructor(init_params: FilePersister_LocalStorage_InitParams)
    {
        super()
        //
        const self = this
        self.init_params = init_params
    }
    //
    // For Subclassers - Required Overrides
    async _overridable_removeItemWithFileKey(fileKey: string)
    {
        window.localStorage.removeItem(fileKey)
    }
    async _overridable_readAllFileKeys(): Promise<string[]>
    {
        const self = this
        let strs: string[] = []
        for (var key in window.localStorage) {
            strs.push(key)
        }
        //
        return strs
    }
    async _overridable_read_fileWithKey(fileKey: string): Promise<string|null>
    {
        const self = this
        //
        return window.localStorage.getItem(fileKey)
    }
    async _overridable_write_dataToFileKey(fileKey: string, contentString: string): Promise<{ err_str: string | undefined }>
    { // NOTE: throws
        const self = this
        //
        window.localStorage.setItem(fileKey, contentString)
        if (navigator.storage && typeof navigator.storage.persist !== 'undefined' && navigator.storage.persist != null) {
            let isPersisted = await navigator.storage.persisted()
            if (!isPersisted) {
                const isPersisted = await navigator.storage.persist();
                console.log(`Persisted storage granted: ${isPersisted}`);
            }
        }
        //
        return { err_str: undefined }
    }
}