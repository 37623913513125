//
//
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { DisplayableListPage } from '../Common/DisplayableListPage'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { TitledEducationalTextCell } from '../Common/TitledEducationalTextCell'
import { themes } from '../themes'
//
//
//
export namespace AppAlreadyOpen
{
    export interface IP
    {
        themeC: themes.Controller
    }
    export class View extends View_Web
    {
        //
        //
        ip!: IP
        //
        constructor(ip: IP)
        {
            super({})
            const self = this
            self.ip = ip
        }
        setup(): void 
        {
            super.setup()
            const self = this
            self.setup_views()
        }
        setup_views()
        {
            const self = this
            let weakSelf = new WeakRef(self) // key so as not to cause a strong ref cycle
            //
            self.layer.style.display = "flex"
            self.layer.style.flexDirection = "row"
            self.layer.style.justifyContent = "center"
            self.layer.style.width = "100%"
            self.layer.style.minHeight = "100vh"
            //
            let container = new View_Web({ }).init()
            container.layer.style.display = "flex"
            container.layer.style.flexDirection = "column"
            container.layer.style.justifyContent = "center"
            container.layer.style.minHeight = "100vh"
            self.addSubview(container)
            //
            let icon_c_v = new View_Web({}).init()
            icon_c_v.layer.style.display = "flex"
            icon_c_v.layer.style.flexDirection = "row"
            icon_c_v.layer.style.justifyContent = "center"
            container.addSubview(icon_c_v)
            //
            {
                let v = new Icon_Symbolic.View().init()
                v.set_props({
                    svg: Icon_Symbolic.SVG_Icons.crossroadsLogo_white_48
                })
                icon_c_v.addSubview(v)
            }
            {
                let view = new TitledEducationalTextCell({
                    themeC: self.ip.themeC,
                    contextInUI: themes.ElementContextInUI.SplashHeader
                }).init()
                view.set_props({
                    title: null,
                    subtitle: "Crossroads is already open",
                    description: "Please navigate to or close the other tab"
                })
                container.addSubview(view)
            }
        }
        //
        // Interface - NavigableView
        navigationBar_hidden(): boolean
        {
            // return true
            return true
        }
        //
        //
        viewDidAppear(): void 
        {
            super.viewDidAppear()
            const self = this
        }
    }
}