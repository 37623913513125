import { TrySetPasswordErrCode } from '@xrds/xrds-libapp-ts/src/Passwords/controllers/PasswordController'
import { App_View_Context } from '../App_View_Web'
import { ComposableComponentFormPage } from '../Common/ComposableComponentFormPage'
import { InlineWarningTextCell } from '../Common/InlineWarningTextCell'
import { TitledEducationalTextCell } from '../Common/TitledEducationalTextCell'
import { TitledTextInputCell } from '../Common/TitledTextInputCell'
import { TextInput_InputType } from '../Common/_TextInput'
import { themes } from '../themes'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { LargeCTAButtonsGroup } from '../Common/LargeCTAButtonsGroup'
import { AbstractThemed } from '../Common/AbstractThemed'
//
//
export interface CreatePassword_Base_View_InitParams 
{
    educationalTitle?: string
    educationalDescription?: string
    existingPassword_ifChanging?: string
}
//
//
export class CreatePassword_Base_View extends ComposableComponentFormPage
{
    base_ip!: CreatePassword_Base_View_InitParams
    //
    educationalTextCell!: TitledEducationalTextCell
    input__password!: TitledTextInputCell
    input__password_confirm!: TitledTextInputCell
    //
    constructor(ctx: App_View_Context, base_ip: CreatePassword_Base_View_InitParams)
    {
        super(ctx)
        const self = this
        self.base_ip = base_ip
    }
    setup(): void
    {
        super.setup()
        const self = this
        self.setup_views()
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        {
            let view = new TitledEducationalTextCell({ 
                themeC: self.ctx.themeC, 
                contextInUI: themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup
            }).init()
            view.set_props({
                title: self.base_ip.educationalTitle
                    ? self.base_ip.educationalTitle!
                    : "Unlock the app and encrypt locally stored data with this password.",
                //
                description: self.base_ip.educationalDescription
                    ? self.base_ip.educationalDescription!
                    : "This password is never sent to the server and cannot be recovered."
            })
            self.educationalTextCell = view
            self.addSubview(view)
        }
        {
            let view = new TitledTextInputCell({ 
                themeC: self.ctx.themeC, 
                contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
                placeholder: "Enter password", 
                inputType: TextInput_InputType.secureText,
                input__key_pressed_fn: (cell: TitledTextInputCell, e: KeyboardEvent) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (e.keyCode == 13) {
                        if (e.shiftKey == true) { // since we want to allow those -
                            // // e.preventDefault() // but do NOT prevent default
                            return true // allow insertion - this includes enter key - which we might actually need to detect..
                        }
                        e.preventDefault()
                        /*await <~ not bothering to wait before returning control to the DOM*/self._really_submitForm()
                        //
                        return false
                    }
                    return true
                } 
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            view.set_props_labelRow({
                title: "Password", 
                postTitleButtonText: undefined,
                tooltipText: null
            })
            self.input__password = view
            self.addSubview(view)
        }
        {
            let view = new TitledTextInputCell({ 
                themeC: self.ctx.themeC, 
                contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
                placeholder: "Enter password again", 
                inputType: TextInput_InputType.secureText,
                input__key_pressed_fn: (cell: TitledTextInputCell, e: KeyboardEvent) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (e.keyCode == 13) {
                        if (e.shiftKey == true) { // since we want to allow those -
                            // // e.preventDefault() // but do NOT prevent default
                            return true // allow insertion - this includes enter key - which we might actually need to detect..
                        }
                        e.preventDefault()
                        /*await <~ not bothering to wait before returning control to the DOM*/self._really_submitForm()
                        //
                        return false
                    }
                    return true
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            view.set_props_labelRow({
                title: "Confirm", 
                postTitleButtonText: undefined,
                tooltipText: null
            })
            self.input__password_confirm = view
            self.addSubview(view)
        }
        self._overridable_setup_views__after_inputs()
        {
            let view = new LargeCTAButtonsGroup.View({ 
                themeC: self.ctx.themeC,
                layoutDirection: LargeCTAButtonsGroup.FlowDirection.col,
                contextInUI: themes.ElementContextInUI.FormControl_outsideOfCellGroup
            }).init()
            view.set_props({
                descs: [
                    {
                        title: "Set Password",
                        symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.padlock__locked },
                        //
                        pressed_fn: async () => 
                        {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            await optl_self._did_press_primaryCTA()
                        }
                    }
                ]
            })
            self.button__primaryCTA = view
            self.addSubview(view)
        }
        {
            let view = new InlineWarningTextCell({ 
                themeC: self.ctx.themeC,
                uiContext: themes.ElementContextInUI.FormControl_outsideOfCellGroup
            }).init()
            self.warningText = view
            self.addSubview(view)
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self._didFinishSettingUpFormViews() // must call this manually in each Form implementor
    }
    _overridable_setup_views__after_inputs()
    {
        const self = this
    }
    //
    configureWith_formSubmittingState(args: {
    }) {
        const self = this
        //
        self.input__password.setEnabled(self.isFormSubmitting != true)
        self.input__password_confirm.setEnabled(self.isFormSubmitting != true)
        //
        super.configureWith_formSubmittingState(args) // modulates primaryCTA etc        
    }
    //
    override navigationBar_title(): string|undefined
    {
        throw new Error("Code fault: " + this.constructor.name + " must override and implement this navigationBar_title()")
    }
    override navigationBar_isEnabled_backButton(): boolean
    { // TODO: This can probably be deleted given superclass impl
        const self = this
        //
        return self.isFormSubmitting == true ? false : true
    }
    //
    //
    viewDidAppear(): void
    {
        super.viewDidAppear()
        //
        const self = this
        let weakSelf = new WeakRef(self)
        setTimeout(() => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self._focus_with_state()
        }, 0)
    }
    //
    override _focus_with_state(): void
    {
        super._focus_with_state()
        const self = this
        self.input__password.focus__input()
    }
    //
    async _really_submitForm()
    {
        const self = this
        let password1 = self.input__password.lookup_input_value()
        let password2 = self.input__password_confirm.lookup_input_value()
        if (password1 !== password2) {
            self.__bail_from_form_submission_with({ err_str: "Passwords do not match." })
            return
        }
        self.__mark_form_as_currently_submitting()  // disallow double submission and clear validation errs
        //
        let pw__r = await self.ctx.controllers.passwordController.TrySetPassword(password1, self.base_ip.existingPassword_ifChanging)
        if (typeof pw__r.err_code !== 'undefined') {
            let err_str;
            switch (pw__r.err_code) {
                case TrySetPasswordErrCode.bad_password__different_pw_required:
                    err_str = "Please enter a fresh password."
                    break
                case TrySetPasswordErrCode.code_fault__already_has_password:
                    err_str = "Not expecting the app to already have the password in this case"
                    break
                case TrySetPasswordErrCode.bad_password__is_required:
                    err_str = "A password is required to protect your wallet funds."
                    break
                case TrySetPasswordErrCode.bad_password__too_short:
                    err_str = "Please use a longer password."
                    break
                case TrySetPasswordErrCode.code_fault__already_has_password:
                    throw "App already has a password. This screen should not be visible."
                case TrySetPasswordErrCode.err_while_saving:
                    err_str = "Error while saving. Please check storage availability and try again."
                    break
            }
            self.__bail_from_form_submission_with({ err_str: err_str })
            return
        }
        if (pw__r.success != true) {
            throw new Error("Expected success flag to be true after setting password without error.")
        }
        //
        await self.overrideable_didSetFirstTimePassword_fn()
    }
    //
    async _did_press_primaryCTA(
    ) {
        const self = this
        await self._really_submitForm()
    }    
    async overrideable_didSetFirstTimePassword_fn()
    {
    }
}
//



