//
//
import { App_View_Context } from "../App_View_Web";
import { AbstractThemed } from "../Common/AbstractThemed";
import { CTAButton } from "../Common/CTAButton";
import { ComposableComponentFormPage } from "../Common/ComposableComponentFormPage";
import { Icon_Symbolic } from "../Common/Icon_Symbolic";
import { InlineWarningTextCell } from "../Common/InlineWarningTextCell";
import { LargeCTAButtonsGroup } from "../Common/LargeCTAButtonsGroup";
import { TitledEducationalTextCell } from '../Common/TitledEducationalTextCell';
import { TitledTextInputCell } from "../Common/TitledTextInputCell";
import _TextInput_ForCell, { TextInput_InputType } from "../Common/_TextInput";
import { themes } from "../themes";
//
//
export interface EnterExistingPassword_Base_View_InitParams
{
    isForChangePW: boolean,
    //
    // tryEnterPasswordAsSingleObserverResponse?: boolean // this isnt used for anything anyway (can it be removed?)
    //
    largeCTAButtonTitle?: string
    promptTitle: string
    promptDescription: string|null
}
//
export class EnterExistingPassword_Base_View extends ComposableComponentFormPage
{
    base_ip!: EnterExistingPassword_Base_View_InitParams
    //
    educationalTextCell!: TitledEducationalTextCell
    input__password!: TitledTextInputCell
    //
    constructor(ctx: App_View_Context, base_ip: EnterExistingPassword_Base_View_InitParams)
    {
        super(ctx)
        const self = this
        self.base_ip = base_ip
    }
    setup(): void
    {
        super.setup()
        const self = this
        self.setup_views()
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        {
            let view = new TitledEducationalTextCell({ 
                themeC: self.ctx.themeC, 
                contextInUI: themes.ElementContextInUI.SplashHeader_Mini
            }).init()
            view.set_props({
                title: self.base_ip.promptTitle,
                description: self.base_ip.promptDescription
            })
            self.educationalTextCell = view
            self.addSubview(view)
        }
        {
            let view = new TitledTextInputCell({
                themeC: self.ctx.themeC, 
                placeholder: "Your existing password",
                inputType: TextInput_InputType.secureText,
                contextInUI: themes.ElementContextInUI.SplashBody_Mini,
                input__key_pressed_fn: (cell: TitledTextInputCell, e: KeyboardEvent) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (e.keyCode == 13) {
                        if (e.shiftKey == true) { // since we want to allow those -
                            // // e.preventDefault() // but do NOT prevent default
                            return true // allow insertion - this includes enter key - which we might actually need to detect..
                        }
                        e.preventDefault()
                        /*await <~ not bothering to wait before returning control to the DOM*/self._really_submitForm()
                        //
                        return false
                    }
                    return true
                }
            }).init()
            self.input__password = view
            self.addSubview(view)
        }
        self._overridable_setup_views__after_inputs()
        let largeCTAButtonTitle = self.base_ip.largeCTAButtonTitle ? self.base_ip.largeCTAButtonTitle : "Unlock App"
        {
            let view = new LargeCTAButtonsGroup.View({ 
                themeC: self.ctx.themeC,
                contextInUI: themes.ElementContextInUI.SplashBody_Mini,
                layoutDirection: LargeCTAButtonsGroup.FlowDirection.row
            }).init()
            view.set_props({
                descs: [
                    {
                        title: largeCTAButtonTitle,
                        arrangement: CTAButton.ArrangementAndGeometry.inline_max_452w,
                        //
                        symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.padlock__unlocked },
                        //
                        pressed_fn: async () => {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            await optl_self._did_press_primaryCTA()
                        }
                    }
                ]
            })
            self.button__primaryCTA = view
            self.addSubview(view)
        }
        {
            let view = new InlineWarningTextCell({
                themeC: self.ctx.themeC,
                uiContext: themes.ElementContextInUI.SplashBody_Mini
            }).init()
            self.warningText = view
            self.addSubview(view)
        }
        self._overridable_setup_views__after_primaryCTA()
        //
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self._didFinishSettingUpFormViews() // must call this manually in each Form implementor
    }
    _overridable_setup_views__after_inputs()
    {
        const self = this
    }
    _overridable_setup_views__after_primaryCTA()
    {
        const self = this
    }
    //
    // Accessors
    _isDisabled__button_primaryCTA(): boolean
    {
        const self = this
        //
        return super._isDisabled__button_primaryCTA() 
        // || self.input__password.lookup_input_value().length == 0
        // This could be re-enabled in the future
    }
    //
    // Imperatives
    async _really_submitForm()
    {
        const self = this
        self.__mark_form_as_currently_submitting() // disallow double submission and clear validation errs
        //
        let enteredPasswordString = self.input__password.lookup_input_value()
        let r = await self.ctx.controllers.passwordController.TryEnterExistingPW(enteredPasswordString, self.base_ip.isForChangePW)
        if (r.validationErr_orNil) {
            self.isFormSubmitting = false
            self.configureWith_formSubmittingState({})
            self.configureWith_formSubmissionResult({ validationErrStr: r.validationErr_orNil?.message || "" })
            //
            return
        }
        await self.overridable_didEnterCorrectPassword_fn(enteredPasswordString)
    }
    //
    // Delegation
    async _did_press_primaryCTA()
    {
        const self = this
        await self._really_submitForm()
    }
    //
    async overridable_didEnterCorrectPassword_fn(enteredPasswordString: string)
    {
        throw new Error("Override and implement overridable_didEnterCorrectPassword_fn")
    }
    //
    wants_viewDidAppear_autoFocusWithState(): boolean {
        return true
    }
    _focus_with_state(): void
    {
        super._focus_with_state()
        const self = this
        self.input__password.focus__input()
    }
    //
    viewDidAppear(): void {
        super.viewDidAppear()
        const self = this
        // console.log("view did appear ", self)
    }
}