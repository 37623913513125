//
//
import { v4 as uuidV4 } from 'uuid'
//
let queue_key_domain_prefix = "xrds_isTabOpen_Q" // is "xrds" even necessary since localStorage is scoped to the domain? ; the tokens in the prefix should be "_" rather than "-" to allow for convenient splitting of the thisTabID out
let thisTabID = uuidV4().split("-").join("_") // a UUID by replacing all "-" with "_" so we can split the entire UUID out by "-" later
console.log("[IsTabOpen] thisTabID", thisTabID)
//
function async_sleep(ms) { return new Promise(res => setTimeout(res, ms)) }
//
export interface IsTabOpen_Setup_RetVal
{
    can_allow_boot: boolean
}
enum IsTabOpen_StorageKeyFragment
{
    APrimaryIsRegistered = "regd_prim",
    RequestHeartbeatFromPrimaryRegistered = "req_hb", // such keys will contain a uuid from the requester.. the (living) primary registered can go and delete the request for heartbeat .. the requester can then go and delete the heartbeat 
    HeartbeatFromPrimary = "res_hb"
}
// ^ any primary registered should listen for changes to localStorage which are prefixed with this key and respond to them with the following, which should get observed by any tabs which are waiting in their .setup to verify that the primaryRegistered is still alive
function IsTabOpen_Queue__commandPreamble(
    fragment: IsTabOpen_StorageKeyFragment
): string {
    return `${queue_key_domain_prefix}_${fragment}` // use underscore to join command key so that we can conveniently just split by '-' to get the tabID
}
function IsTabOpen_Queue__fullStorageKey(
    fragment: IsTabOpen_StorageKeyFragment,
    inReplyTo_thisTabID?: string // this will be used instead of the local thisTabID if fragment is a response to a request
): string {
    let commandPreamble = IsTabOpen_Queue__commandPreamble(fragment)
    if (shouldStorageKeyCommandFragmentHaveA_thisTabID(fragment)) {
        let isAResponseToARequest = fragment == IsTabOpen_StorageKeyFragment.HeartbeatFromPrimary
        if (isAResponseToARequest) {
            if (!inReplyTo_thisTabID || typeof inReplyTo_thisTabID == 'undefined') {
                throw new Error("Expected a inReplyTo_thisTabID for a fragment which is a response to a request")
            }
            return `${commandPreamble}-${inReplyTo_thisTabID}`
        } else {
            if (inReplyTo_thisTabID != null && typeof inReplyTo_thisTabID !== 'undefined') {
                throw new Error("Did not expect a inReplyTo_thisTabID for a fragment which is a response to a request")
            }
            return `${commandPreamble}-${thisTabID}` // tab ids are to always be joined with '-' for easy split
        }
    }
    return commandPreamble // in other cases, the preamble is the full key
}
function isStorageKeyForQueueDomain(storageKey: string): boolean
{
    return storageKey.startsWith(queue_key_domain_prefix)
}
type QueueDomainStorageKey = string
interface ParsedQueueCommand
{
    fragment: IsTabOpen_StorageKeyFragment,
    theThisTabID?: string
}
function parsedQueueCommandFromStorageKey(storageKey: QueueDomainStorageKey): ParsedQueueCommand
{
    // we expect a "_" to join a preamble and a thisTabID - thisTabIDs are never to have "_" in them -- we read up until the "-"
    // and we also expect the storageKey to have been validated as a QueueDomainStorageKey
    // so we assume is starts with queue_key_domain_prefix
    let cmdSansQueueDomain = storageKey.substring(queue_key_domain_prefix.length + "_".length, storageKey.length)
    // cmdSansQueueDomain has the preamble plus, if appropriate, the thisTabId
    let remainingComponents = cmdSansQueueDomain.split("-")
    if (remainingComponents.length < 1) {
        throw new Error("Expected non-zero components of queue domain key")
    }
    let fragment = remainingComponents[0] as IsTabOpen_StorageKeyFragment
    if (Object.values(IsTabOpen_StorageKeyFragment).indexOf(fragment) == -1) {
        throw new Error("That was not a valid isTabOpen_queue command preamble fragment")
    }
    if (shouldStorageKeyCommandFragmentHaveA_thisTabID(fragment)) {
        if (remainingComponents.length != 2) {
            throw new Error("Expected two components in a fragment which has a thisTabID")
        }
        return {
            fragment: fragment,
            theThisTabID: remainingComponents[1]
        }
    }
    return {
        fragment: fragment
    }
}
function shouldStorageKeyCommandFragmentHaveA_thisTabID(fragment: string)
{
    switch (fragment) {
        case IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered:
        case IsTabOpen_StorageKeyFragment.HeartbeatFromPrimary: // in this case the thisTabID is the tabID that the heartbeat is in reply TO, not the tabID of the primary tab that's emiting the heartbeat
            return true
        default:
            return false
    }
}
//
export default class IsTabOpen_Web
{
    //
    //
    constructor() 
    {
        const self = this
    }
    async setup(): Promise<IsTabOpen_Setup_RetVal>
    {
        const self = this
        if (!window || typeof window === 'undefined') {
            return {
                can_allow_boot: true // as window is required for localStorage
            }
        }
        await async_sleep(Math.random() * 40) // wait for some random amount of time up to N ms to make it less likely that another tab reloaded at precisely the same time will race with this one
        let registered_tab_flag_key_value = window.localStorage.getItem(IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.APrimaryIsRegistered))
        let hasRequestedHeartbeat: boolean = false // if this is true and hasReceivedReplyToHeartbeatRequest is false then we should wait to receiver the heartbeat reply from a primary tab for some ms and if we dont receive it, elect self as primary
        let awaitingHeartbeatReplyTimeout: any|undefined = undefined
        let hasReceivedReplyToHeartbeatRequest: boolean = false
        let hasTimedOutWaitingForHeartbeatAndHasElectedSelfAsPrimary: boolean = false
        let electedSelfAsPrimaryDueToNoOtherPrimary: boolean = false
        function _lookup_isPrimary(): boolean
        {
            return hasTimedOutWaitingForHeartbeatAndHasElectedSelfAsPrimary == true || electedSelfAsPrimaryDueToNoOtherPrimary == true
        }
        function _storage_deleteAllQueueDomainKeyValues()
        {
            for (var key in window.localStorage) { // check all .localStorage keys and clean up any left over keys from crashed tabs
                if (isStorageKeyForQueueDomain(key) != true) {
                    continue // ignore
                }
                let queueDomainKey: QueueDomainStorageKey = key
                window.localStorage.removeItem(queueDomainKey)
            }
        }
        function _storage_setPrimaryRegd()
        {
            window.localStorage.setItem(
                IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.APrimaryIsRegistered), 
                JSON.stringify({flag:true})
            )
        }
        return new Promise((resolve, reject) => {
            window.addEventListener("beforeunload", (e) =>
            {
                // console.log("IsTabOpen window event beforeunload")
                let isPrimary = _lookup_isPrimary()
                if (isPrimary) {
                    let primary_regd_storageKey = IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.APrimaryIsRegistered)
                    let primary_regd_existingValue = window.localStorage.getItem(primary_regd_storageKey)
                    if (primary_regd_existingValue == null && typeof primary_regd_existingValue === 'undefined') {
                        throw new Error("isPrimary but no primary_regd_existingValue")
                    }
                    // console.log(".removeItem(" + primary_regd_storageKey + ")")
                    window.localStorage.removeItem(primary_regd_storageKey)
                }
            })
            let window_storage__fn = (event) =>
            {
                if (event.storageArea != localStorage) {
                    return
                }
                if (isStorageKeyForQueueDomain(event.key) != true) {
                    return // ignore
                }
                let queueDomainKey: QueueDomainStorageKey = event.key
                // console.log("queueDomainKey" , queueDomainKey)
                let parsedCommand = parsedQueueCommandFromStorageKey(queueDomainKey)
                // console.log("parsedCommand" , parsedCommand)
                let newValue = event.newValue // this would be an easy way to obtain the thisTabID without parsing the event.key perhaps in future if we want to expand the protocol
                if (newValue == null || typeof newValue === 'undefined' || !newValue) {
                    // console.log("[DEBUG] ignoring deletion of value at key ", event.key)
                    return // ignore deletion
                }
                let parsed_newValue = JSON.parse(newValue)
                if (parsed_newValue.from_thisTabID == thisTabID) {
                    // console.log("That write originated from this tab - ignoring.")
                    return
                }
                let isPrimary = _lookup_isPrimary()
                // console.log("isPrimary", isPrimary)
                if (isPrimary) {
                    // look for heartbeat reply requests, get their thisTabIDs, and reply with responses that they will be waiting for 
                    switch (parsedCommand.fragment) {
                        case IsTabOpen_StorageKeyFragment.HeartbeatFromPrimary:
                        {
                            throw new Error("isPrimary but somehow received a .HeartbeatFromPrimary")
                        }
                        case IsTabOpen_StorageKeyFragment.APrimaryIsRegistered:
                        {
                            throw new Error("isPrimary but somehow received a .APrimaryIsRegistered")
                        }
                        case IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered:
                        {
                            let theThisTabID = parsedCommand.theThisTabID
                            if (!theThisTabID) {
                                throw new Error("Expected a .theThisTabID to be found on a .RequestHeartbeatFromPrimaryRegistered")
                            }
                            window.localStorage.removeItem(queueDomainKey) // this is the primary acknowledging by deletion that it received the request for heartbeat - this way it wont be left around in storage
                            // console.log(".removeItem(" + queueDomainKey + ")")
                            //
                            let for_thisTabID = theThisTabID
                            let replyWithKey = IsTabOpen_Queue__fullStorageKey(
                                IsTabOpen_StorageKeyFragment.HeartbeatFromPrimary,
                                for_thisTabID // inReplyTo_thisTabID
                            )
                            let existingValue = window.localStorage.getItem(replyWithKey)
                            if (existingValue != null && typeof existingValue !== 'undefined') {
                                throw new Error("Didn't expect a heartbeat reply to already be in the localStorage for that thisTabID")
                            }
                            // console.log("[DEBUG] writing heartbeat reply with ", replyWithKey)
                            window.localStorage.setItem(replyWithKey, JSON.stringify({
                                from_thisTabID: thisTabID,
                                inReplyTo_thisTabID: for_thisTabID
                            }))
                            return
                        }
                        default:
                        {
                            throw new Error("isPrimary but unhandled parsedCommand.fragment")
                        }
                    }
                } else {
                    // otherwise, if not is primary, check to see if it's a heartbeat response
                    switch (parsedCommand.fragment) {
                        case IsTabOpen_StorageKeyFragment.HeartbeatFromPrimary:
                        {
                            let for_thisTabID = parsedCommand.theThisTabID!
                            if (!for_thisTabID) {
                                throw new Error("Expected a heartbeat from primary to have a .theThisTabID")
                            }
                            if (for_thisTabID != thisTabID) {
                                console.warn("A IsTabOpen storageKey that is a response to a request for a heatbeat was observed written for *another* requesting tab ID. Ignoring.")
                                return
                            }
                            // Then this means that a primary has told us it is still alive - kill self
                            if (electedSelfAsPrimaryDueToNoOtherPrimary) {
                                throw new Error("Even though electedSelfAsPrimaryDueToNoOtherPrimary, some other primary has replied with a heartbeat.")
                            }
                            //
                            console.log("[DEBUG] Received heartbeat reply from living primary tab with ", queueDomainKey)
                            if (hasRequestedHeartbeat != true) {
                                throw new Error("Expecting hasRequestedHeartbeat to have been true")
                            }
                            hasRequestedHeartbeat = false
                            //
                            if (hasReceivedReplyToHeartbeatRequest) {
                                throw new Error("Not expecting hasReceivedReplyToHeartbeatRequest to be true here")
                            }
                            if (typeof awaitingHeartbeatReplyTimeout == 'undefined') {
                                throw new Error("Expected awaitingHeartbeatReplyTimeout not to be undefined here")
                            }
                            clearTimeout(awaitingHeartbeatReplyTimeout)
                            awaitingHeartbeatReplyTimeout = undefined
                            //
                            hasReceivedReplyToHeartbeatRequest = true
                            //
                            // console.log(".removeItem(" + queueDomainKey + ")")
                            window.localStorage.removeItem(queueDomainKey) // delete it for the primary - as a secondary, by deleting this, we're acknowledging we received it
                            //
                            window.removeEventListener("storage", window_storage__fn) // so that we no longer listen for any, such as when other tabs pop up
                            //
                            resolve({
                                can_allow_boot: false // do not allow boot - another primary has been detected!
                            })
                            return
                        }
                        case IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered: // perhaps another non-primary tab has opened and is requesting the heartbeat - ignore
                        case IsTabOpen_StorageKeyFragment.APrimaryIsRegistered: // this means another tab has registered itself as primary - what should be done in that case?
                            return // secondary can ignore these
                        default: 
                            throw new Error("Unhandled case of written IsTabOpen key observed while not primary")
                            break
                    }
                }
                throw new Error("Unhandled case")
            }
            window.addEventListener("storage", window_storage__fn)
            let exists_registered_tab_flag = typeof registered_tab_flag_key_value !== 'undefined' && registered_tab_flag_key_value != null
            // console.log("exists_registered_tab_flag" , exists_registered_tab_flag)
            if (exists_registered_tab_flag) {
                // then here, attempt communication with the other tab in order to verify that the tab is not stale
                // we assume the other tab has already started observing writes and will be looking for any attempts at writing communications from tabs such as self 

                // but first, check if any other tabs are also waiting on the heartbeat (such as if both were reloaded by the file change monitor daemon within ~300ms of each other)
                // and if so, just bail ... say that cannot boot .. since that other heartbeat tab will become primary (defer to it)
                //
                for (var key in window.localStorage) {
                    if (isStorageKeyForQueueDomain(key) != true) {
                        continue // ignore
                    }
                    let queueDomainKey: QueueDomainStorageKey = key
                    // console.log("queueDomainKey" , queueDomainKey)
                    let parsedCommand = parsedQueueCommandFromStorageKey(queueDomainKey)
                    if (parsedCommand.fragment == IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered) {
                        console.log("Found another tab waiting on a heartbeat response from the primary - bailing from this tab and deferring to that one")
                        resolve({
                            can_allow_boot: false
                        })
                        return
                    }
                }
                //
                hasRequestedHeartbeat = true // set this before the .setItem() call so that the above observation can make sure to watch for it
                //
                // first wait to observe the heartbeat reply
                awaitingHeartbeatReplyTimeout = setTimeout(() =>
                {
                    console.log("[IsTabOpen] timed out waiting for heartbeat request reply.")
                    awaitingHeartbeatReplyTimeout = undefined // for good measure
                    if (!hasRequestedHeartbeat) {
                        throw new Error("Expected true hasRequestedHeartbeat")
                    }
                    window.localStorage.removeItem( // delete this so that we don't mess up state tracking, since the primary that would have received and deleted this is no longer around
                        IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered)
                    )
                    if (!hasReceivedReplyToHeartbeatRequest) {
                        let primary_regd_storageKey = IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.APrimaryIsRegistered)
                        let primary_regd_existingValue = window.localStorage.getItem(primary_regd_storageKey)
                        if (primary_regd_existingValue == null || typeof primary_regd_existingValue === 'undefined') {
                            console.warn("!isPrimary and timed out awaiting heartbeat reply but the primary_regd_existingValue disappeared during timeout interval")
                            // This must mean something like another tab cleared it, somehow... 
                            // to just gracefully 'fail', simply write the value again
                            _storage_deleteAllQueueDomainKeyValues() // but first, clear old values, in case there's some other heartbeat requests hanging out
                            _storage_setPrimaryRegd()
                        } else {
                            // since no communication occurs with other tab, leave the flag in storage and assume primary role
                        }
                        electedSelfAsPrimaryDueToNoOtherPrimary = false
                        hasTimedOutWaitingForHeartbeatAndHasElectedSelfAsPrimary = true
                        resolve({
                            can_allow_boot: true
                        })
                        return
                    }
                    throw new Error("Expected awaitingHeartbeatReplyTimeout to have been cleared if a heartbeat response was actually obtained")
                }, 300) // these ms should be enough time for the other primary tab to reply .. if not, it's probably pretty badly locked up on something
                //
                // write the request for heartbeat
                // console.log("Writing heartbeat request...")
                window.localStorage.setItem(
                    IsTabOpen_Queue__fullStorageKey(IsTabOpen_StorageKeyFragment.RequestHeartbeatFromPrimaryRegistered), 
                    JSON.stringify({
                        from_thisTabID: thisTabID
                    })
                )
                //
            } else {
                // then self can be considered primary - register the flag and return
                electedSelfAsPrimaryDueToNoOtherPrimary = true
                //
                _storage_deleteAllQueueDomainKeyValues()
                _storage_setPrimaryRegd()
                //
                resolve({
                    can_allow_boot: true
                })
                return
            }
        })
    }
}