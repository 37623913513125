import AppPersistenceController_Encrypting from "@xrds/xrds-libapp-ts/src/Persistable/AppPersistenceController_Encrypting"
import alert from "../Common/AlertPolyfill"

export function doAlertFor_confirmAndDo_deleteEverything(
    persistenceController: AppPersistenceController_Encrypting
) {
    alert(
        'Delete Everything?',
        'Are you sure you want to clear all locally saved data including your wallet seed phrases?',
        [
            { text: "Nevermind", style: 'cancel', onPress: () => {} },
            {
                text: 'Clear App Data',
                style: 'destructive',
                // If the user confirmed, then we dispatch the action we blocked earlier
                // This will continue the action that had triggered the removal of the screen
                onPress: () =>
                {
                    alert(
                        'Warning: Really Delete Everything?',
                        'Are you absolutely sure you want to clear all saved data? (If you wrote down your wallet mnemonic and can re-enter it after this, this app will be able to re-download an encrypted backup of your other data.)',
                        [
                            { text: "Nevermind", style: 'cancel', onPress: () => {} },
                            {
                                text: 'Yes, Delete Everything',
                                style: 'destructive',
                                // If the user confirmed, then we dispatch the action we blocked earlier
                                // This will continue the action that had triggered the removal of the screen
                                onPress: () => {
                                    setTimeout(() => {
                                        persistenceController.DeleteEverything(true, false) // reboot and notify so nav stack resets etc
                                    })
                                }
                            }
                        ]
                    )
                }
            }
        ]
    )
}
export function doAlertFor_forgotPassword(
    persistenceController: AppPersistenceController_Encrypting
) {
    alert(
        'Forgot Password?',
        'Unfortunately there is no way for us to determine your app data password. If you wrote down your wallet secret mnemonic, then you can clear your existing app data and re-enter your mnemonic to recover your data.',
        [
            { text: "Okay", style: 'cancel', onPress: () => {} }
        ]
    )
}