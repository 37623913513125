//
//
import View_Web from '../../View/View.web'
import { styles_theme, themes } from '../themes'
import { Icon_Symbolic } from './Icon_Symbolic'
import { CTAButton } from './CTAButton'
import { NavigableView } from './NavigationView'
//
export interface NavigationBarView_InitParams
{
    themeC: themes.Controller
    back__tapped_fn: () => void
}
//
//
export namespace NavigationBar
{
    export function new_base_barButtonView(
        args: CTAButton.IP
    ): View_Web {
        let view = new CTAButton.View(args).init()
        view.layer.style.position = "absolute"
        view.layer.style.zIndex = "2"
        view.layer.style.top = "0"
        view.layer.style.display = "flex"
        view.layer.style.flexDirection = "column"
        view.layer.style.justifyContent = "center"
        view.layer.style.padding = "0 19px"
        view.layer.style.boxSizing = "border-box"
        view.layer.style.height = `${args.themeC.current.layouts.nav_bar_h}px`
        //
        return view
    }
}
//
//
export class NavigationBarView extends View_Web
{
    //
    ip!: NavigationBarView_InitParams
    //
    backButtonView!: View_Web
    leftSideAccessoryView?: View_Web
    titleLabelView!: View_Web
    rightSideAccessoryView?: View_Web
    //
    constructor(ip: NavigationBarView_InitParams)
    {
        // let topPxs = 10
/////////// TODO: for now
        super({
            ...ip,
            //
            el_name: "div", // for now - since we 're just setting the content - but maybe we should do this in a more efficient manner by optimizing only to draw what's on the screen at the time
        })
        const self = this
        self.ip = ip
    }
    setup()
    {
        super.setup()
        const self = this
        {
            const view = self
            view.layer.classList.add(styles_theme.ClassNames.nav_bar)
            view.layer.style.display = "flex" // previously, "flex"
        }
        self.setup_views()
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        let themeC = self.ip.themeC
        let buttonWidth = 70
        {
            let view = new View_Web({ el_name: "div" }).init()
            view.layer.style.position = "absolute"
            view.layer.style.zIndex = "2"
            view.layer.style.left = buttonWidth + "px"
            view.layer.style.right = buttonWidth + "px"
            view.layer.style.width = `calc(100% - ${2 * buttonWidth})`
            view.layer.style.height = `${themeC.current.layouts.nav_bar_h}px`
            view.layer.style.lineHeight = `${(themeC.current.layouts.nav_bar_h - Math.floor(themeC.current.layouts.nav_bar_h/10))}px`;
            view.layer.style.textAlign = "center"
            view.layer.style.verticalAlign = "middle"
            view.layer.style.zIndex = "1"
            view.layer.style.color = themeC.current.colors.nav_bar_text
            view.layer.style.fontFamily = themes.styles_fonts.FontFamilies.ss_regular
            view.layer.style.fontSize = "18px"  // TODO: figure out how to make 'max(18px, ...vw)' work
            view.layer.style.textOverflow = "ellipsis"
            // "Both of the following are required for text-overflow"
            view.layer.style.whiteSpace = "nowrap"
            view.layer.style.overflow = "hidden"

            //
            self.titleLabelView = view
            self.addSubview(view)
        }
        { // add after the title so as to be on top of it
            let view = NavigationBar.new_base_barButtonView({ 
                themeC, 
                symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.backArrow },
                clicked_fn: (e) => {
                    e.preventDefault()
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ip.back__tapped_fn()
                    //
                    return false
                }
            })
            self.backButtonView = view
            self.addSubview(view)
        }
    }
    //
    public h_pxs() // only use this after self.ip set
    {
        const self = this
        let themeC = self.ip.themeC
        //
        return themeC.current.layouts.nav_bar_h
    }
    //
    public setTopView(navigableView: NavigableView, displayBackButton: boolean)
    {
        const self = this
        { // guess this has to be done regardless:
            if (self.leftSideAccessoryView && typeof self.leftSideAccessoryView !== 'undefined') {
                self.leftSideAccessoryView.removeFromSuperview()
                self.leftSideAccessoryView = undefined
            }
            if (self.rightSideAccessoryView && typeof self.rightSideAccessoryView !== 'undefined') {
                self.rightSideAccessoryView.removeFromSuperview()
                self.rightSideAccessoryView = undefined
            }
        }
        {
            let view = navigableView.navigableView_leftSideAccessoryView()
            if (view && typeof view !== 'undefined') {
                self.leftSideAccessoryView = view // we don't need to retain it outside of the lifecycle of its presence in the view hierarchy
                view.layer.style.position = "absolute"
                view.layer.style.left = "0px"
                view.layer.style.zIndex = "2"
                self.insertSubview(view, 0) // insert it before the title etc
                //
                self.backButtonView.hide()
            } else {
                if (displayBackButton != true) {
                    self.backButtonView.hide()
                } else {
                    self.backButtonView.show()
                }
            }
        }
        //
        let title = navigableView.navigationBar_title() || ''
        self.titleLabelView.layer.innerHTML = title
        // console.log("self.title.layer.content" , self.title.layer.content)
        //
        let navigationBar_hidden = navigableView.navigationBar_hidden()
        if (navigationBar_hidden) {
            self.hide()
        } else {
            self.show()
        }
        {
            let view = navigableView.navigableView_rightSideAccessoryView()
            if (view && typeof view !== 'undefined') {
                self.rightSideAccessoryView = view // we don't need to retain it outside of the lifecycle of its presence in the view hierarchy
                view.layer.style.position = "absolute"
                view.layer.style.right = "0px"
                view.layer.style.zIndex = "2"
                self.addSubview(view) // insert it after the title etc; equiv to .insertSubview(view, self.subviews.length - 1)
            } else {
            }
        }
    }
    //
    //
    viewDidAppear(): void
    {
        super.viewDidAppear()
    }
}