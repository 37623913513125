
import { BigInteger, monero_amount_format_utils__instance } from '@xrds/monero-utils-ts'
import { Monero_Persistable_RecvOrSpentOutput_Common, Monero_Tx } from '@xrds/payments-client-ts/src/monero-wallet-clients/WalletScannerClient_Base'
import { DisplayableTransaction, Displayable_Monero_RecvdOutput, Displayable_Monero_SpentOutput, TxsDisplayReadinessStatus } from './WalletDisplayable_ScanAndTxs'
//
export interface DisplayableTxsExportableCSV
{ // TODO: factor this
    suggested_filename: string
    ext: string // ".csv"
    csv: string
    file_type_and_enc_preamble: string
}
interface _CSVCustomAddtlCol
{
    header_str: string
    // col_val_or_nils: (string|null)[]
    col_val_by_row_pk: { [pkey: string]: string }
}
//
export function new__if_ready__displayable_txs__csv_export(displayableTxs: DisplayableTransaction[], walletLabelForFilename?: string): DisplayableTxsExportableCSV
{
    if (displayableTxs.length == 0) {
        throw new Error("Expected non-zero displayable transactions")
    }
    let custom_addtl_cols: _CSVCustomAddtlCol[] = []
    displayableTxs.forEach((tx, i) => {
        tx.metadata.dests?.forEach(dest => {
            let dest_addr_col_header_str = `${dest.label} address`
            let dest_addr_col_val = dest.addr
            let dest_amt_col_header_str = `${dest.label} amount`
            let dest_amt_col_val = "-" + monero_amount_format_utils__instance.formatMoney(new BigInteger(dest.amount)) + " XMR"
            // TODO: annotations support here
            //
            let col_row_pkey = tx.hash
            let existing__dest_addr_col = custom_addtl_cols.find(c => c.header_str == dest_addr_col_header_str)
            if (!existing__dest_addr_col) {
                existing__dest_addr_col = {
                    header_str: dest_addr_col_header_str,
                    col_val_by_row_pk: {}
                }
                custom_addtl_cols.push(existing__dest_addr_col)
            }
            existing__dest_addr_col.col_val_by_row_pk[col_row_pkey] = dest_addr_col_val
            //
            let existing__dest_amt_col = custom_addtl_cols.find(c => c.header_str == dest_amt_col_header_str)
            if (!existing__dest_amt_col) {
                existing__dest_amt_col = {
                    header_str: dest_amt_col_header_str,
                    col_val_by_row_pk: {}
                }
                custom_addtl_cols.push(existing__dest_amt_col)
            }
            existing__dest_amt_col.col_val_by_row_pk[col_row_pkey] = dest_amt_col_val
        })
    })
    //
    let csvContent = "";
    const header_strs = ["date", "amount", "status", "tx id", "payment_id", "maj_i", "min_i"].concat(custom_addtl_cols.map(c => c.header_str))
    csvContent += `${header_strs.join(",")}\r\n`
    function concat_o(
        tx: DisplayableTransaction, 
        o: Displayable_Monero_RecvdOutput|Displayable_Monero_SpentOutput, 
        is_recv_not_spent: boolean
    ) {
        let cols = [
            tx.date.toISOString(),
            monero_amount_format_utils__instance.formatMoney(new BigInteger(o.amount).multiply(new BigInteger(is_recv_not_spent ? 1 : -1))),
            o.status, 
            tx.hash, 
            tx.paymentID || "",
            o.sa_maj_i,
            o.sa_min_i
        ]
        custom_addtl_cols.forEach(col => {
            let col_row_pkey = tx.hash
            let raw__col_custom_val = col.col_val_by_row_pk[col_row_pkey]
            delete col.col_val_by_row_pk[col_row_pkey] // so that subsequent items do not pick it up and display it redundantly - for the purpose of clarity, i suppose, to the importer, that there is only *one* to addr per tx - BUT it should be noted that this 'dest' info is not associated with specific outputs being spent or received, but actually the transactions themselves - so technically the outputs of the transactions should be all shown here instead of just the received and spent scanned outs - so perhaps the dest data can be used for that instead.
            let col_custom_val = typeof raw__col_custom_val == 'undefined' || !raw__col_custom_val ? "" : raw__col_custom_val!
            cols.push(col_custom_val)
        })
        csvContent += `${cols.join(",")}\r\n`;
    }
    displayableTxs.forEach((tx, i) => {
        for (let recv_o of tx.recv_outputs) {
            concat_o(tx, recv_o, true)
        }
        for (let sp_o of tx.spent_outputs) {
            concat_o(tx, sp_o, false)
        }
    })
    return {
        suggested_filename: walletLabelForFilename || "transactions",
        ext: ".csv",
        csv: csvContent,
        file_type_and_enc_preamble: "data:text/csv;charset=utf-8,"
    }
}