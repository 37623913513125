import View_Web from "src/View/View.web"
import { styles_theme } from "../themes"

export namespace ClickableLayer
{
    export interface View_Interface extends View_Web
    {
        //
        _fn__click?: (e: any) => boolean

    }
    //
    //
    export function styleAs_clickable(self: ClickableLayer.View_Interface)
    {
        let view = self
        view.layer.classList.add(styles_theme.ClassNames.clickable) // handles the cursor too
    }
    //
    // Imperatives
    export function setEnabled(
        self: ClickableLayer.View_Interface,
        isEnabled?: boolean // not actually accessed directly - i.e. make sure you call your own super.setEnabled()
    ) {
        ClickableLayer._configureWith_interactivity(self)
    }

    //
    export function _configureWith_interactivity(
        self: ClickableLayer.View_Interface,
        trampoline_to__tapped_fn?: () => void
    ) {
        let weakSelf = new WeakRef(self)
        if (self._isEnabled) {
            if (!self._fn__click) {
// TODO: possibly even do not observe clicks if self.ip.tapped_fn is nil
                self._fn__click = (e) =>  {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (trampoline_to__tapped_fn) {
                        e.preventDefault()
                        e.stopPropagation()
                        trampoline_to__tapped_fn()
                        return false
                    }
                    return true
                }
                self.layer.addEventListener("click", self._fn__click)
            }
        } else {
            if (self._fn__click) {
                _stopObserving_click(self)
            }
        }
    }
    export function _stopObserving_click(
        self: ClickableLayer.View_Interface
    ) {
        if (self._fn__click) {
            self.layer.removeEventListener("click", self._fn__click)
            self._fn__click = undefined
        }
    }
}