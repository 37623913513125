import View_Web from "../../View/View.web";
import { css_rules } from "../css_rules";
import { themes } from "../themes";
//
export namespace styles_statusicons
{
    export enum ClassNames
    {
        loading_spinner = "loading-spinner",
        //
        success_check = "success-check",
        error_X = "error_X",
        //
        _circle = "circle-base", // do not use this directly
        circle_green = "circle-green",
        circle_red = "circle-red",
        circle_orange = "circle-orange"
    }
    //
    export let namespaceName: css_rules.injectionNamespaceName = "statusicons"
    export let generatorFn: css_rules.cssRules_generatorFn_type = (optl_themeC) =>
    {
        let themeC = optl_themeC!
        return [
`.${ClassNames.loading_spinner} {
    display: inline-block;
    width: 13px;
    height: 13px;
}`,
`.${ClassNames.loading_spinner}:after {
    content: " ";
    display: block;
    width: 9px;
    height: 9px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${themeC.current.colors.status_light__warning};
    border-color: ${themeC.current.colors.status_light__warning} transparent ${themeC.current.colors.status_light__warning} transparent;
    animation: ${ClassNames.loading_spinner} 1.2s linear infinite;
}`,
`@keyframes ${ClassNames.loading_spinner} {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`,
//
//
`.${ClassNames.success_check} {
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}`,
`.${ClassNames.success_check}:before {
    content:"";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: ${themeC.current.colors.status_light__success};
    left: 11px;
    top: 6px;
}`,
`.${ClassNames.success_check}:after {
    content:"";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: ${themeC.current.colors.status_light__success};
    left: 8px;
    top: 12px;
}`,
`.${ClassNames.error_X}:before {
    content: "";
    display: block;
    margin-top: -3px;
    width: 16px;
    height: 3px;
    background: red;
    border-radius: 1px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}`,
`.${ClassNames.error_X}:after {
    content: "";
    display: block;
    margin-top: -3px;
    width: 16px;
    height: 3px;
    background: red;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}`,
//
`.${ClassNames._circle} {
    height: 9px;
    width: 9px;
    border-radius: 50%;
}`,
`.${ClassNames.circle_green} {
    background-color: ${themeC.current.colors.status_light__success};
    box-shadow: 0px 0px 0px 4px ${themeC.current.colors.status_light__success}33
}`, // note: 0x33 -> 0.2 * 255 = 51
`.${ClassNames.circle_orange} {
    background-color: ${themeC.current.colors.status_light__warning};
    box-shadow: 0px 0px 0px 4px ${themeC.current.colors.status_light__warning}33
}`,
`.${ClassNames.circle_red} {
    background-color: ${themeC.current.colors.status_light__error};
    box-shadow: 0px 0px 0px 4px ${themeC.current.colors.status_light__error}33
}`
        ]
    }
}
export namespace InformativeIcon // maybe not the best name since I may add disclosureChevron here
{
    export interface IP
    {
        themeC: themes.Controller
        type: IconType
    }
    export enum IconType
    {
        loadingSpinner      = 1,
        success             = 2,
        error               = 3,
        circle_green        = 4,
        circle_orange       = 5,
        circle_red          = 6
        // TODO: quite possibly add the disclosureChevron here
    }
    //    
    export class View extends View_Web
    {
        ip!: IP
        //
        constructor(ip: IP)
        {
            super({ el_name: "div" })
            //
            const self = this
            self.ip = ip
        }
        setup(): void {
            super.setup()
            const self = this
            // let themeC = self.ip.themeC
            css_rules.lazy_injectRuleStrs(styles_statusicons.namespaceName, styles_statusicons.generatorFn, self.ip.themeC)
            {
                const view = self
                switch (self.ip.type) {
                    case IconType.loadingSpinner:
                    {
                        view.layer.classList.add(styles_statusicons.ClassNames.loading_spinner)
                        break
                    }
                    case IconType.success:
                    {
                        view.layer.classList.add(styles_statusicons.ClassNames.success_check)
                        break
                    }
                    case IconType.error:
                        {
                            view.layer.classList.add(styles_statusicons.ClassNames.error_X)
                            break
                        }
                    case IconType.circle_green:
                    {
                        view.layer.classList.add(styles_statusicons.ClassNames._circle)
                        view.layer.classList.add(styles_statusicons.ClassNames.circle_green)
                        break
                    }
                    case IconType.circle_orange:
                    {
                        view.layer.classList.add(styles_statusicons.ClassNames._circle)
                        view.layer.classList.add(styles_statusicons.ClassNames.circle_orange)
                        break
                    }
                    case IconType.circle_red:
                    {
                        view.layer.classList.add(styles_statusicons.ClassNames._circle)
                        view.layer.classList.add(styles_statusicons.ClassNames.circle_red)
                        break
                    }

                    default:
                    {
                        throw new Error("Code fault: implement icon type" + self.ip.type)
                    }
                }
            }
        }
    }
}