
//
"use strict"
//
import EventEmitter from 'events'
//
import AppPersistenceController_Encrypting, { Persistence_Encrypting_EventName } from '../../Persistable/AppPersistenceController_Encrypting'
//
const This_CollectionName = "Settings"
import { Currency } from '../../Currencies/models/constants'
import { PasswordController } from '../../Passwords/controllers/PasswordController'
import { new_DocumentId, DocumentId } from '../../Persistable/Documents'
import { Persistence_EventName } from '../../Persistable/AppPersistenceController_Base'
import { UserIdleController } from '../../UserIdle/controllers/UserIdleController'
//
export let default__appTimeoutAfterS = 5 * 60
//
//
export namespace TipPicker
{
    export let initial_default__supportTheApp_isToggled_pctsNotAmts = false
    export let initial_default__supportMRL_isToggled_pctsNotAmts = false
    //
    export enum FixedAmounts
    {
        m_001    = "0.001",
        m_01     = "0.01",
        m_1      = "0.1",
        m1       = "1",
        none     = "-1"
    }
    export enum Pcts
    {
        p_1      = "1",
        p_5      = "5",
        p_10     = "10",
        p_15     = "15",
        none     = "-1"
    }
}
//
let k_defaults_record = 
{
    supportTheApp_selectedValue: TipPicker.initial_default__supportTheApp_isToggled_pctsNotAmts 
        ? TipPicker.Pcts.none 
        : TipPicker.FixedAmounts.none
    ,
    supportMRL_selectedValue: TipPicker.initial_default__supportMRL_isToggled_pctsNotAmts 
        ? TipPicker.Pcts.none 
        : TipPicker.FixedAmounts.none
    ,
    //
    supportTheApp_isToggled_pctsNotAmts: TipPicker.initial_default__supportTheApp_isToggled_pctsNotAmts,
    supportMRL_isToggled_pctsNotAmts: TipPicker.initial_default__supportMRL_isToggled_pctsNotAmts,
    //
	appTimeoutAfterS: default__appTimeoutAfterS,
	invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount: false,
	displayCcySymbol: Currency.XMR, // default
	authentication_requireWhenSending: true,
	authentication_requireWhenDisclosingWalletSecrets: true,
    authentication_tryBiometric: true, // this will be used on iOS only
	autoDownloadUpdatesEnabled: true  // will be used for desktop and possibly mobile, only
}
//
export namespace SettingsKey
{
    //
    export enum KeyString
    {
        supportTheApp_selectedValue = "supportTheApp_selectedValue",
        supportMRL_selectedValue = "supportMRL_selectedValue",
        supportTheApp_isToggled_pctsNotAmts = "supportTheApp_isToggled_pctsNotAmts",
        supportMRL_isToggled_pctsNotAmts = "supportMRL_isToggled_pctsNotAmts",
        //
        appTimeoutAfterS = "appTimeoutAfterS",
        invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount = "invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount",
        displayCcySymbol = "displayCcySymbol",
        authentication_requireWhenSending = "authentication_requireWhenSending",
        authentication_requireWhenDisclosingWalletSecrets = "authentication_requireWhenDisclosingWalletSecrets",
        authentication_tryBiometric = "authentication_tryBiometric",
        autoDownloadUpdatesEnabled = "autoDownloadUpdatesEnabled"
    }
    //
    //
    export type DidChange_EventName = string
    export function DidChange_EventName_for(keyString: KeyString): DidChange_EventName
    {
        return "Settings_changed_" + keyString
    }
}
//
//
//
class SettingsController extends EventEmitter
{
    _id?: string
    persistenceController!: AppPersistenceController_Encrypting // TODO: how to avoid the '!'?
    passwordController!: PasswordController
    userIdleController!: UserIdleController
    //
    //
    // TODO: probably move these properties to an object in which they are all nested - we can still define its interface type
    supportTheApp_selectedValue!: string
    supportMRL_selectedValue!: string
    //
    supportTheApp_isToggled_pctsNotAmts!: boolean
    supportMRL_isToggled_pctsNotAmts!: boolean
    //
    appTimeoutAfterS!: number
    invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount!: boolean
    displayCcySymbol!: Currency
    authentication_requireWhenSending!: boolean
    authentication_requireWhenDisclosingWalletSecrets!: boolean
    authentication_tryBiometric!: boolean
    autoDownloadUpdatesEnabled!: boolean
    //
    deleteEverything_handler_fn?: () => void
    //
	constructor(persistenceController: AppPersistenceController_Encrypting, passwordController: PasswordController, userIdleController: UserIdleController)
	{
		super()
		// ^--- have to call super before can access `this`
		//
        const self = this
        let weakSelf = new WeakRef(self) // so as not to cause a strong ref cycle
        self.persistenceController = persistenceController
        self.passwordController = passwordController
        self.userIdleController = userIdleController
        //
        // Start observing
        self.deleteEverything_handler_fn = () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self.persistenceController_didDeleteEverything()
        }
        self.persistenceController.on(Persistence_EventName.DidDeleteEverythingAndDeboot, self.deleteEverything_handler_fn!)
        //
        //
        let _fn_on__Persistence_EventName_DidLoadAppDataAndDecryptWithPassword = async () => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            /*await */optl_self._persistenceController_didLoadAppDataAndDecryptWithPassword()
        }
		let _fn_on__Persistence_EventName_WillDeconstructBootedStateAndClearPassword = async (params) => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
			await optl_self._persistenceController_willDeconstructBootedStateAndClearPassword()	
		}
		let _fn_on__Persistence_EventName_DidDeconstructBootedStateAndClearPassword = async (params) => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
			await optl_self._persistenceController_didDeconstructBootedStateAndClearPassword()	
		}
        self.persistenceController.on(
            Persistence_Encrypting_EventName.DidLoadAppDataAndDecryptWithPassword,
            _fn_on__Persistence_EventName_DidLoadAppDataAndDecryptWithPassword
        )
		self.persistenceController.on(
			Persistence_Encrypting_EventName.WillDeconstructBootedStateAndClearPassword, 
			_fn_on__Persistence_EventName_WillDeconstructBootedStateAndClearPassword
		)
		self.persistenceController.on(
			Persistence_Encrypting_EventName.DidDeconstructBootedStateAndClearPassword, 
			_fn_on__Persistence_EventName_DidDeconstructBootedStateAndClearPassword
		)
    }
    public setup()
    { // consumers: call this - it's separated to give instantiators a chance to set observations hooks
        const self = this
        if (self.passwordController.hasBooted == false) {
            const mocked_doc = JSON.parse(JSON.stringify(k_defaults_record)) // easy way to do a deep-copy
            self._init_loadStateFromRecord(mocked_doc)
            //
            // and await DidLoadAppDataAndDecryptWithPassword
        } else {
            self._givenDataDecrypted_tryToLoadFromSavedData()
        }
    }
	//
    _init_loadStateFromRecord(record_doc: { [key: string]: any })
    {
        const self = this
        //
        self._id = record_doc._id || undefined // since we dont want to expose thie capability to _set_settings_valuesByKey_andNotify
        //
        // migration on potential legacy saved record_doc - setting defaults
        // this is commented since these will be present in the initial version
        // record_doc.authentication_requireWhenSending = 
        //     record_doc.authentication_requireWhenSending == null || typeof record_doc.authentication_requireWhenSending === 'undefined' 
        //     ? k_defaults_record.authentication_requireWhenSending 
        //     : record_doc.authentication_requireWhenSending
        // record_doc.authentication_requireWhenDisclosingWalletSecrets = 
        //     record_doc.authentication_requireWhenDisclosingWalletSecrets == null || typeof record_doc.authentication_requireWhenDisclosingWalletSecrets === 'undefined' 
        //     ? k_defaults_record.authentication_requireWhenDisclosingWalletSecrets 
        //     : record_doc.authentication_requireWhenDisclosingWalletSecrets
        // record_doc.autoDownloadUpdatesEnabled = 
        //     record_doc.autoDownloadUpdatesEnabled == null || typeof record_doc.autoDownloadUpdatesEnabled === 'undefined' 
        //     ? k_defaults_record.autoDownloadUpdatesEnabled 
        //     : record_doc.autoDownloadUpdatesEnabled
        //
        //
        self._set_settings_valuesByKey_andNotify(record_doc, false/*do not save*/)
    }
    async _givenDataDecrypted_tryToLoadFromSavedData()
    {
        const self = this
		// first, check if any password model has been stored
        const ret = self.persistenceController.Decrypted_AllValStringsFor(This_CollectionName)
        const vals_length = ret.vals!.length
        if (vals_length === 0) { //
            const mocked_doc = JSON.parse(JSON.stringify(k_defaults_record)) // easy way to do a deep-copy
            self._init_loadStateFromRecord(mocked_doc)
            return
        }
        if (vals_length > 1) {
            const errStr = "Error while fetching existing " + This_CollectionName + "... more than one record found. Selecting first."
            console.error(errStr)
            // this is indicative of a code fault
            throw errStr // might as well throw then
        }
        let str = ret.vals![0] // assumed returned in plaintext
        const doc = JSON.parse(str)
        // console.log("Found existing saved " + This_CollectionName + " with _id", doc._id)
        self._init_loadStateFromRecord(doc)
	}
    //
	// Accessors
	AppTimeoutNever_numberValue()
	{
        const self = this
        //
		return self.userIdleController.AppTimeoutNever_numberValue()
	}
    // TODO: possibly introduce a minimum app timeout value so that the rest of the app doesn't have to worry about network requests getting killed too soon .. for sensitive operations we wouldnt want to end up with corrupt data somehow... but the biz logic, if not already, needs to be at the state where it's hardened against that anyway
    //
	defaultValue__autoDownloadUpdatesEnabled()
	{
		return k_defaults_record.autoDownloadUpdatesEnabled
	}
    //
    //
    valueForKey(key: string): any {
        const self = this
        switch (key) {
            case SettingsKey.KeyString.supportTheApp_selectedValue:
                return self.supportTheApp_selectedValue
            case SettingsKey.KeyString.supportMRL_selectedValue:
                return self.supportMRL_selectedValue
            case SettingsKey.KeyString.supportTheApp_isToggled_pctsNotAmts:
                return self.supportTheApp_isToggled_pctsNotAmts
            case SettingsKey.KeyString.supportMRL_isToggled_pctsNotAmts:
                return self.supportMRL_isToggled_pctsNotAmts
            //
            case SettingsKey.KeyString.appTimeoutAfterS:
                return self.appTimeoutAfterS
            case SettingsKey.KeyString.invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount:
                return self.invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount
            case SettingsKey.KeyString.displayCcySymbol:
                return self.displayCcySymbol
            case SettingsKey.KeyString.authentication_requireWhenSending:
                return self.authentication_requireWhenSending
            case SettingsKey.KeyString.authentication_requireWhenDisclosingWalletSecrets:
                return self.authentication_requireWhenDisclosingWalletSecrets
            case SettingsKey.KeyString.authentication_tryBiometric:
                return self.authentication_tryBiometric
            case SettingsKey.KeyString.autoDownloadUpdatesEnabled:
                return self.autoDownloadUpdatesEnabled
        }
    }
	//
	// Runtime - Imperatives - Settings Values
    async Set_settings_valuesByKey_andSave_andNotify(
        valuesByKey: { [key: string]: any }
    ): Promise<{ err_str?: string }> {
        const self = this
        //
        return self._set_settings_valuesByKey_andNotify(valuesByKey, true)
    }
    async _set_settings_valuesByKey_andNotify(
        valuesByKey: { [key: string]: any }, 
        andSave: boolean = true
    ): Promise<{ err_str?: string }> {
		const self = this
        //
        const valueKeys = Object.keys(valuesByKey)

        let didUpdate_by_key: { [key: string] : boolean } = {}
        for (let valueKey of valueKeys) {
            const value = valuesByKey[valueKey]
            didUpdate_by_key[valueKey] = true
            { // set
                self[valueKey] = value
            }
        }
        if (andSave == true) {
            const ret = await self.saveToDisk()
            if (ret.err_str) {
                console.error("Failed to save new valuesByKey", ret.err_str!)
                return { err_str: ret.err_str } // done
            }
            // console.log("Successfully saved " + self.constructor.name + " update ", JSON.stringify(valuesByKey))
        }
        let didUpdate_keys = Object.keys(didUpdate_by_key)
        for (let key of didUpdate_keys) {
            switch (key) {
                case SettingsKey.KeyString.appTimeoutAfterS:
                    self.userIdleController.Set_idleAfter_ms__breakingUserIdle( // critical
                        self.appTimeoutAfterS == self.userIdleController.AppTimeoutNever_numberValue() 
                        ? self.appTimeoutAfterS 
                        : self.appTimeoutAfterS * 1000
                    ) 
                    break
                default:
                    break
            }
            self.emit(
                SettingsKey.DidChange_EventName_for(key as SettingsKey.KeyString), 
                self.valueForKey(key)
            )
        }
        //
        return {}
	}
	//
	// Runtime - Imperatives - Private - Persistence
	async saveToDisk(): Promise<{ err_str?: string }>
	{
        const self = this
        //
        // console.log("Saving " + This_CollectionName + " to disk.")
        // console.trace()
        const persistableDocument =
        {
            _id: self._id, // important to set for updates
            //
            supportTheApp_selectedValue: self.supportTheApp_selectedValue,
            supportMRL_selectedValue: self.supportMRL_selectedValue,
            supportTheApp_isToggled_pctsNotAmts: self.supportTheApp_isToggled_pctsNotAmts,
            supportMRL_isToggled_pctsNotAmts: self.supportMRL_isToggled_pctsNotAmts,
            //
            appTimeoutAfterS: self.appTimeoutAfterS,
            invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount: self.invisible_hasAgreedToTermsOfCalculatedEffectiveMoneroAmount,
            displayCcySymbol: self.displayCcySymbol,
            authentication_requireWhenSending: self.authentication_requireWhenSending,
            authentication_requireWhenDisclosingWalletSecrets: self.authentication_requireWhenDisclosingWalletSecrets,
            authentication_tryBiometric: self.authentication_tryBiometric,
            autoDownloadUpdatesEnabled: self.autoDownloadUpdatesEnabled
        }
        // console.log("persistableDocument" , JSON.stringify(persistableDocument))
        var willInsert = (self._id === null || typeof self._id === 'undefined')
        var _fresh_id: DocumentId | undefined;
        if (willInsert) {
            _fresh_id = new_DocumentId() // must generate it
            self._id = _fresh_id
            persistableDocument._id = _fresh_id
        }
        const ret = await self.persistenceController.NotDuringChangePW_EncryptingStoreInterface_WriteStringFor(
            This_CollectionName, 
            self._id!, 
            JSON.stringify(persistableDocument),
            true // do encrypt
        )
        if (ret.err_str) {
            console.error("Error while saving Settings record:", ret.err_str!)
            return { err_str: ret.err_str! }
        }

        return {}
	}
	//
	// Delegation - Notifications - Persistence Controller
    async _persistenceController_didLoadAppDataAndDecryptWithPassword()
    {
		const self = this
        await self._givenDataDecrypted_tryToLoadFromSavedData()
    }
	async persistenceController_didDeleteEverything()
	{
		const self = this
		console.log("[" + self.constructor.name + "] passwordController_DeleteEverything")
		// we're not gonna delete the record and reboot - this controller is straightforward enough
		const defaultsValues = JSON.parse(JSON.stringify(k_defaults_record)) // a copy - tho prolly not necessary to do this
		const ret = await self._set_settings_valuesByKey_andNotify(defaultsValues, false) // do not save
        if (ret.err_str) {
            return { err_str: ret.err_str! }
        }
        return {}
    }
    //
    //
	async _persistenceController_willDeconstructBootedStateAndClearPassword()
	{
		const self = this
        //
        const defaultsValues = JSON.parse(JSON.stringify(k_defaults_record)) // a copy - tho prolly not necessary to do this
		const ret = await self._set_settings_valuesByKey_andNotify(defaultsValues, false) // do not save
        //
	}
	_persistenceController_didDeconstructBootedStateAndClearPassword()
	{
		const self = this
        
	}
}
export { SettingsController }