//
export default class FilePersister_Base
{
    constructor() {}
    async setup() {} // overridable
    //
    // For Subclassers - Required Overrides
    async _overridable_removeItemWithFileKey(fileKey: string)
    {
        throw new Error("Code fault: Override and implement _overridable_removeItemWithFileKey")
    }
    async _overridable_readAllFileKeys(): Promise<string[]>
    {
        throw new Error("Code fault: Override and implement _overridable_readAllFileKeys")
    }
    async _overridable_read_fileWithKey(fileKey: string): Promise<string|null>
    {
        throw new Error("Code fault: Override and implement _overridable_read_fileWithKey")
    }
    async _overridable_write_dataToFileKey(fileKey: string, contentString: string): Promise<{ err_str: string | undefined }>
    { // NOTE: throws
        throw new Error("You must override and implement _overridable_write_dataToFileKey")
    }
    //
    // Interface
    public async RemoveItemWithFileKey(fileKey: string)
    {
        return await this._overridable_removeItemWithFileKey(fileKey)
    }
    public  async ReadAllFileKeys(): Promise<string[]>
    { // NOTE: possibly throws
        return await this._overridable_readAllFileKeys()
    }
    public async Read_fileWithKey(fileKey: string): Promise<string|null>
    {
        return await this._overridable_read_fileWithKey(fileKey)
    }
    public async Write_dataToFileKey(fileKey: string, contentString: string)
    { // NOTE: throws
        return await this._overridable_write_dataToFileKey(fileKey, contentString)
    }
}

