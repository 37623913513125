import * as openpgp from 'openpgp'///lightweight' // lightweight -> lazy load curves
//
async function streamToString(
    stream: any
): Promise<string> {
    if (typeof stream === 'string') {
        // then we're probably on web and openpgp is returning a string instead
        return stream as string
    }
    const chunks: any[] = []
    return new Promise((resolve, reject) => {
      stream.on('data', (chunk) => chunks.push(Buffer.from(chunk)))
      stream.on('error', (err) => reject(err))
      stream.on('end', () => resolve(Buffer.concat(chunks).toString('utf8')))
    })
}
  
//
async function New_ArmoredEncryptedString(
    plainString: string, 
    password: string
): Promise<{ 
    err_str?: string|undefined, 
    encryptedString: string|undefined 
}> {
    try {
        let stream = await openpgp.encrypt({
            message: await openpgp.createMessage({ text: plainString }),
            passwords: [ password ],
            format: 'armored'
        })
        const string = await streamToString(stream)
        //
        return { encryptedString: string }
    } catch (e) {
        console.error("Caught encrypt err: ", e)
        return { err_str: ""+e, encryptedString: undefined }
    }
}
async function New_DecryptedString(
    armored_encryptedString: string, 
    password: string
): Promise<{ 
    err_str?: string|undefined, 
    plainString: string|undefined 
}> {
    let message: openpgp.Message<string>|undefined = undefined
    try {
        message = await openpgp.readMessage({ 
            armoredMessage: armored_encryptedString
        })
    } catch (e) {
        console.error("Caught msg armor parse err: ", e)
        return { err_str: ""+e, plainString: undefined }
    }
    let decrypted: openpgp.DecryptMessageResult & { data: openpgp.Stream<string> }|null = null
    try {
        decrypted = await openpgp.decrypt({
            message: message,
            passwords: [ password ]
        })
    } catch (e) {
        console.error("Caught decrypt err: ", e)
        return { err_str: ""+e, plainString: undefined }
    }
    let stream = decrypted.data!
    const string = await streamToString(stream)
    //
    return { plainString: string }
}
//
export { New_ArmoredEncryptedString, New_DecryptedString }