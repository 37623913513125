import { css_rules } from "../css_rules"
import { themes } from "../themes"

export namespace styles_tooltips
{ // TODO: possibly move this to themeC
    export enum ClassNames
    {
    }
    export enum ThemeNames
    {
        crossroadsDark = "crossroadsDark"
    }
    //
    export let namespaceName: css_rules.injectionNamespaceName = "tooltips"
    export let generatorFn: css_rules.cssRules_generatorFn_type = (optl_themeC) =>
    {
        let themeC = optl_themeC!
        return [

`.tippy-box[data-theme~='${ThemeNames.crossroadsDark}'] {
    background-color: ${themeC.current.colors.tooltip_bg};
    border-color: ${themeC.current.colors.tooltip_bg};
    color: ${themeC.current.colors.tooltip_text};

    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    font-family: ${themes.styles_fonts.FontFamilies.ss_regular};
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
}`,
`.tippy-box[data-theme~='${ThemeNames.crossroadsDark}'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: ${themeC.current.colors.tooltip_bg};
}`,
`.tippy-box[data-theme~='${ThemeNames.crossroadsDark}'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: ${themeC.current.colors.tooltip_bg};
}`,
`.tippy-box[data-theme~='${ThemeNames.crossroadsDark}'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: ${themeC.current.colors.tooltip_bg};
}`,
`.tippy-box[data-theme~='${ThemeNames.crossroadsDark}'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: ${themeC.current.colors.tooltip_bg};
}`
        ]
    }
}