//
//
import { themes } from '../themes'
import { AbstractControl } from './AbstractTitledControlCell'
import { PropSettable } from './DisplayableListRow'
import SeparatorLineView from './SeparatorLineView'
import { StyledRadio_Control } from './StyledRadio_Control'
import { _LabelRowContainer, _LabelRowContainer_EventName } from './_LabelRowContainer'
import { _DescriptionLabel } from './_DescriptionLabel'
import { LinkButton } from './LinkButtonCell'
//
export interface TDDC_IP
{
    
}
//
export interface TitledDetailsDescriptionCell_InitParams extends TDDC_IP
{
    themeC: themes.Controller
    labelRow_titleLabel_appearance?: LinkButton.Appearance // NOTE: this is used in order to also change the appearance of the description text
    contextInUI?: themes.ElementContextInUI // CellGroupHeader_small_withinCellGroup
    accessoryRadio_roleWithinUICell?: StyledRadio_Control.RadioControlRoleWithinUICell
    //
    allowTextSelect_description?: boolean // defaults to true
    // _labelRow
    includeAccessory_checkbox?: boolean // defaults to false
    checkboxControlsInputHiding?: boolean // defaults to true when includeAccessory_checkbox is true
    checkbox__checked?: boolean // defaults to false ,
    accessory_checkbox_leftSideTitle?: string|null|undefined // defaults to 'Show'
    accessory_checkbox_valLabel_false?: string|undefined // defaults to 'Off'
    accessory_checkbox_valLabel_true?: string|undefined // defaults to 'On'
    _accessoryRadio_changed?: (isChecked: boolean) => void
    //
    wordBreakBehaviorStyle_description?: WordBreakBehaviorStyle_TitledDetails
}
//
export interface TitledDetailsDescriptionCell__set_props__args extends PropSettable.base__set_props__args
{ 
    title: string|undefined, 
    description?: string
    tooltipText: string|null|undefined
}
//
//
enum _accessoryRadio_value
{
    true = "true",
    false = "false"
}
//
export enum WordBreakBehaviorStyle_TitledDetails
{
    default, // TODO: comment what this one will actually do - 'breaks at word'?
    anywhere
}
//
export class TitledDetailsDescriptionCell extends AbstractControl.Base_Cell
{
    //
    //
    _tddc_ip!: TitledDetailsDescriptionCell_InitParams
    //
    lookup_checkbox_isChecked(): boolean|undefined // proxying this function 
    {
        return this._labelRow.accessoryRadio?.selectedValue() == _accessoryRadio_value.true // Note lack of visibility and interactivity checks; and no optl unwrap or check
    }
    //
    descriptionLabel?: _DescriptionLabel._View
    //
    //
    constructor(ip: TitledDetailsDescriptionCell_InitParams)
    {
        super({ 
            themeC: ip.themeC,
            contextInUI: ip.contextInUI,
            labelRow_titleLabel_appearance: ip.labelRow_titleLabel_appearance || LinkButton.Appearance.diminutive_mono/* actually defaulting to this here */,
            accessoryRadio_roleWithinUICell: ip.accessoryRadio_roleWithinUICell || StyledRadio_Control.RadioControlRoleWithinUICell.tertiary

         })
        const self = this
        self._tddc_ip = ip
    }
    setup(): void 
    {
        super.setup()
        const self = this
        // let weakSelf = new WeakRef(self)
        // let themeC = self._tddc_ip.themeC
        {
            let view = self
            view.layer.style.display = "block" // take up whole line
        }
        if (self._tddc_ip.includeAccessory_checkbox == true) { // default to false
            self._labelRow.set_props({
                accessoryRadio: { // using this method is important since the accessoryRadio might need to be set up by calling this method first
                    members: [
                        {
                            value: _accessoryRadio_value.false,
                            explct_title: typeof self._tddc_ip.accessory_checkbox_valLabel_false !== 'undefined'
                                ? self._tddc_ip.accessory_checkbox_valLabel_false
                                : 'Off'
                        },
                        {
                            value: _accessoryRadio_value.true,
                            explct_title: typeof self._tddc_ip.accessory_checkbox_valLabel_true !== 'undefined'
                                ? self._tddc_ip.accessory_checkbox_valLabel_true
                                : 'On'
                        }
                    ],
                    selectedValue: self._tddc_ip.checkbox__checked == true ? "true" : "false", // defaults to false
                    leftAdjacent_title: self._tddc_ip.accessory_checkbox_leftSideTitle === null 
                        ? null
                        : typeof self._tddc_ip.accessory_checkbox_leftSideTitle !== 'undefined' 
                            ? self._tddc_ip.accessory_checkbox_leftSideTitle
                            : "Show"
                    ,
                    rightAdjacent_title: null
                }
            })
        }
    }
    //
    //
    //
    set_props(args: TitledDetailsDescriptionCell__set_props__args): void
    {
        const self = this
        let themeC = self._tddc_ip.themeC
        //
        self.set_props_labelRow({ 
            title: args.title,
            postTitleButtonText: undefined, 
            // TODO: support a 'copy' button here
            //
            tooltipText: args.tooltipText
        })
        //
        if (typeof args.description !== 'undefined') {
            if (!self.descriptionLabel) {
                let view = _DescriptionLabel.new_view({
                    themeC: themeC,
                    sibling_labelRow_titleLabel_appearance: self._ac_base_ip.labelRow_titleLabel_appearance!, // assume exists on superclass (with default) thanks to super() call in constructor
                    wordBreakBehaviorStyle_description: self._tddc_ip.wordBreakBehaviorStyle_description,
                    allowTextSelect_description: self._tddc_ip.allowTextSelect_description,
                    locationInCell: AbstractControl.LocationWithinTitledCell.underTitle // which is the default anyway
                }, {
                    _isEnabled: self._isEnabled // ensure (lack of) interactivity configuration survives reconstruction of subviews
                })
                self.descriptionLabel = view
                //
                self.rootContentsContainer.addSubview(view)
            }
            _DescriptionLabel.set_props(self.descriptionLabel!, {
                description: args.description,
                _isEnabled: undefined,
                _isHidden: self._tddc_ip.includeAccessory_checkbox === true && self._tddc_ip.checkboxControlsInputHiding !== false // NOTE: handling defaults 
                    ? self.lookup_checkbox_isChecked() == false
                    : false
            })
        } else if (args.description == null) {
            if (self.descriptionLabel) {
                self.descriptionLabel.removeFromSuperview() // it will have been retained as long as it exists in the view hierarchy
                self.descriptionLabel = undefined
            }
        }
    }
    //
    //
    setEnabled(isEnabled: boolean): void {
        super.setEnabled(isEnabled)
        //
        const self = this
        self.descriptionLabel?.setEnabled(isEnabled)
    }
    //
    // Overridable - Delegation
    _accessoryRadio_changed(selectedValue: StyledRadio_Control.ButtonValue)
    {
        const self = this
        super._accessoryRadio_changed(selectedValue)
        //
        let isChecked = selectedValue == _accessoryRadio_value.true 
        _DescriptionLabel.set_props(self.descriptionLabel!, {
            _isHidden: self._tddc_ip.includeAccessory_checkbox === true && self._tddc_ip.checkboxControlsInputHiding !== false // NOTE these condition forms handle undefined and defaults
                ? isChecked == false
                : false
        })
        //
        if (self._tddc_ip._accessoryRadio_changed) {
            self._tddc_ip._accessoryRadio_changed(isChecked)
        }
    }
    _postTitleButtonText_tapped()
    {
        const self = this
        super._postTitleButtonText_tapped()
    }
}