//
//
import { styles_theme, themes } from '../themes';
import { CTAButton } from './CTAButton';
import { Icon_Symbolic } from './Icon_Symbolic';
import { InformativeIcon } from './InformativeIcon';
//
//
export namespace LargeCTA
{
    export interface IP
    {
        themeC: themes.Controller

        title: string
        variant?: CTAButton.Variant
        arrangement?: CTAButton.ArrangementAndGeometry
        //
        iconType?: InformativeIcon.IconType|undefined|null 
        symbolicIcon_props?: Icon_Symbolic.Props|undefined|null
        //
        pressed_fn: () => void
        //
        pre_setup?: (_this: CTAButton.View) => void
    }
}
export namespace LargeCTA
{
    export class _Button extends CTAButton.View
    {
        //
        declare ip: LargeCTA.IP
        //
        constructor(ip: LargeCTA.IP)
        {
            super({ 
                pre_setup: (_this: CTAButton.View) => {
                    (_this as LargeCTA._Button).ip = ip
                },
                //
                themeC: ip.themeC,
                //
                innerHTML: ip.title, // if any
                //
                iconType: ip.iconType,
                symbolicIcon_props: ip.symbolicIcon_props,
                //
                variant: ip.variant || CTAButton.Variant.main, // default to .main
                //
                clicked_fn: ip.pressed_fn
            }) // for now - since we 're just setting the content - but maybe we should do this in a more efficient manner by optimizing only to draw what's on the screen at the time
        }
        setup()
        {
            super.setup()
            //
            const self = this
            const view = self
            switch (self.ip.arrangement) {
                case CTAButton.ArrangementAndGeometry.inline_fixed_240w:
                    view.layer.style.width = "240px"
                    view.layer.style.margin = "0px 0px"
                    break
                case CTAButton.ArrangementAndGeometry.inline_max_452w:
                    view.layer.style.maxWidth = "452px"
                    view.layer.style.width = "100%" // to counteract the align-self: 
                    view.layer.style.margin = "0px 0px"
                    break
                case CTAButton.ArrangementAndGeometry.default_fullRow:
                default:
                    let h = 0
                    view.layer.style.width = `calc(100% - ${2 * h}px)`
                    view.layer.style.margin = `0px ${h}px`
                    break
            }
            self.button.layer.classList.add(styles_theme.ClassNames.largeCTAButton) // specifically
            //
            view.layer.setAttribute("role", "link")
        }
        //
        a_layer(): HTMLAnchorElement
        {
            const self = this
            //
            return self.layer as HTMLAnchorElement
        }
        //
        override setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            //
            let a = self.a_layer()
            a.href = isEnabled ? "#" : ""
            a.style.pointerEvents = isEnabled ? "" : "none"
            a.ariaDisabled = isEnabled ? "false" : "true"
            //
            // TODO: attribute / class for disabled 
        }
    }
}