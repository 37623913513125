//
//
import { AppState_Event } from '@xrds/xrds-libapp-ts/src/Application/app_state___shared'
import PasswordEntry_Presentation, { PasswordEntry_PWNowRequired_TransitionMode } from '@xrds/xrds-libapp-ts/src/Passwords/controllers/app_state__password'
import { App_View_Context } from '../App_View_Web'
import StackNavigationView, { ModalView, StackNavigationView_InitParams } from '../Common/NavigationView'
import { EnterExistingPassword_ForUnlock_View } from './EnterExistingPassword_ForUnlock_View'
//
export interface PasswordEntryModalNavigationView_InitParams
{
    pe_p: PasswordEntry_Presentation
}
//
export class PasswordEntryModalNavigationView extends StackNavigationView implements ModalView
{
    ip!: PasswordEntryModalNavigationView_InitParams
    ctx!: App_View_Context
    //
    constructor(ctx: App_View_Context, ip: PasswordEntryModalNavigationView_InitParams)
    {
        super({ themeC: ctx.themeC })
        const self = this
        self.ip = ip
        self.ctx = ctx
        {
            const view = self
        }
        self.start_observing()
        self.configureVisibilityAndContent()
    }
    teardown()
    {
        const self = this
        super.teardown()
        console.log(`${self.constructor.name}::teardown`)
        console.trace()
        throw new Error("teardown of PasswordEntryModalNavigationView is expected - this is likely a bug")
    }
    start_observing()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        self.ip.pe_p.on(AppState_Event.did_update, () => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self.configureVisibilityAndContent()
        })
    }
    //
    configureVisibilityAndContent()
    { // this function should remain fairly idempotent
        const self = this
        // console.log(`${self.constructor.name}::configureVisibilityAndContent`)
        // console.trace()
        // PS: This gets called twice on boot->unlock, currently, but self.ip.pe_p.password_entry_required is undefined the first time
        if (self.ip.pe_p.password_entry_required == true) {
            {
                switch (self.ip.pe_p.password_entry_required_pw_trnstn_mode) {
                    case PasswordEntry_PWNowRequired_TransitionMode.resetStackAndPresent:
                    case PasswordEntry_PWNowRequired_TransitionMode.justPresent:
                    default:
                    {
                        // doesn't really matter, here ... the .reset is handled (albeit somewhat out of place) in ScreenView 
                    }
                }
            }
            let params = self.ip.pe_p.password_entry_required_params!
            // now configure the actual content based on the type of entry needed
            if (params.isForChangingPassword) {
                throw new Error("Never expecting .isForChangingPassword=true")
            }
            //
            // now the contents must be set up
            self.reset([
                new EnterExistingPassword_ForUnlock_View(self.ctx, {
                    isFromADeconstructBootedState: params.isFromADeconstructBootedState
                }).init()
            ], true /* not that any modals would be presented on self as it's also a modal and a nav view */)
            //
            if (self.superlayer === null || typeof self.superlayer === 'undefined') {
/////// TODO: will this ever race in any future conditions? .present -> ::.addSubview must remain synchronous.
                self.ctx.rootNavigationView.present(self, { 
                    animated: params.presentation_animated
                })
            }
        } else {
            // PS: This is commented as the following .dismiss() or self.removeFromSuperview() is handled implicitly in the .reset to Landing which is called in ScreenView
           // // if (typeof self.superlayer !== 'undefined' && self.superlayer) {
           // //     self.ctx.rootNavigationView.dismiss() // This might race with the .reset() in ScreenView
          //  // }
        }
    }



// possibly port later..
//  navigation.addListener('beforeRemove', (e) => {

//     let can_be_dismissed = controllers.passwordController.HasUserEnteredValidPasswordYet()
//     // console.log("can_be_dismissed" , can_be_dismissed)
//     if (can_be_dismissed) { // then we don't need to prevent dismissal
//         console.log("PasswordEntryModalStackScreenComponent - allowing PW entry modal dismissal.")
//         return // not preventing default
//     }
//     if (controllers.passwordController.GivenBooted_HasUserSavedAPassword() == false) {
//         console.warn("PasswordEntryModalStackScreenComponent - Allowing pw entry modal to be dismissed because it must have been shown before a DeleteEverything and was asked to be dismissed after a successful one")
//         return // not preventing default
//     }
//     console.warn("PasswordEntryModalStackScreenComponent - Disallowing PW entry modal dismissal.")
//     // then prevent default behavior of leaving the screen
//     e.preventDefault()
//     //
//     // then later, dismiss with 'navigation.dispatch(e.data.action)'
// }






}