//
//
import AppControllers from "./AppControllers";
import { EventEmitter } from "events";
//
export interface AppState_Base__Init_Params
{
    controllers: AppControllers
}
//
//
export enum AppState_Event
{
    did_update = "did_update"
}
//
export class AppState_Base extends EventEmitter
{
    init_params!: AppState_Base__Init_Params
    //
    //
    // Lifecycle
    constructor(init_params: AppState_Base__Init_Params)
    {
        super()
        //
        const self = this
        self.init_params = init_params
    }
    //
    // Delegation
    _did_update()
    {
        const self = this
        //
        self.emit(AppState_Event.did_update)
    }
}
