//
//
import { themes } from '../themes'
import { AbstractControl } from './AbstractTitledControlCell'
import { LinkButton, LinkButtonOrTextSpanItem, LinkButtonOrTextSpanItem_PKey } from './LinkButtonCell'
import { StyledRadio_Control } from './StyledRadio_Control'
import { WordBreakBehaviorStyle_TitledDetails } from './TitledDetailsDescriptionCell'
import { _DescriptionLabel } from './_DescriptionLabel'
import _TextInput_ForCell, { TextInput_InputType } from './_TextInput'
import { PropSettable } from './DisplayableListRow'
//
function _new_staticValueDisplay_view(themeC: themes.Controller, locationInCell: AbstractControl.LocationWithinTitledCell)
{
    return _DescriptionLabel.new_view({
        themeC: themeC,
        sibling_labelRow_titleLabel_appearance: LinkButton.Appearance.diminutive_mono, // it is always this, currently, in TitledTextInputCell - but that may change once code converges
        wordBreakBehaviorStyle_description: WordBreakBehaviorStyle_TitledDetails.default, // for now
        allowTextSelect_description: true, // for now? 
        locationInCell: locationInCell
    }, {})
}
//
export interface TitledTextInputCell_InitParams
{
    themeC: themes.Controller
    contextInUI: themes.ElementContextInUI // typically will only ever be FormControl_withinCellGroup (if not rarely SplashBody_Mini)
    accessoryRadio_roleWithinUICell?: StyledRadio_Control.RadioControlRoleWithinUICell
    //
    // _labelRow
    postTitleButtonText_tapped_fn?: (_self: TitledTextInputCell) => void
    accessory_checkbox_leftSideTitle?: string|null|undefined // defaults to 'Show'
    accessory_checkbox_valLabel_false?: string|undefined // defaults to 'Off'
    accessory_checkbox_valLabel_true?: string|undefined // defaults to 'On'
    includeAccessory_checkbox?: boolean // defaults to false
    checkboxControlsInputHiding?: boolean // defaults to true when includeAccessory_checkbox is true
    _accessoryRadio_changed?: (isChecked: boolean) => void
    //
    include_primaryInput?: boolean // if true, no input will be constructed
    primaryHidden_displayStaticValue?: string|null // TODO: eventually generalize this into just displaying whatever views we want based on the general state
    primary_mutable?: boolean // defaults to true i.e. input mode
    primary_value?: string|null|undefined 
    //
    //
    checkbox__checked?: boolean // defaults to false 
/// TODO: add titles for true and false options of the checkbox

    // input:
    multiline?: boolean
    placeholder?: string
    inputType?: TextInput_InputType // defaults to .text
    primaryInput_location?: AbstractControl.LocationWithinTitledCell, // defaults to .underTitle
    textOrSecureInput_disallowWhitespace?: boolean
    textOrSecureInput_disallow_nonAlphaNum?: boolean
    input__changed_fn?: (self: TitledTextInputCell, v: string) => void
    input__debounced_changed_fn?: (self: TitledTextInputCell, v: string) => void
    input__revert_value_on_blur_to__fn?: (self: TitledTextInputCell) => string|undefined // provide a fn to return a string you want to revert the value to on blur .. such as if the input is empty ; undefined will cause self not to modify the value
    input__focused_fn?: (self: TitledTextInputCell) => void
    input__key_pressed_fn?: (self: TitledTextInputCell, e: KeyboardEvent) => boolean
    //
    // for TextInput_InputType.number_ufloat, number_uint
    maxWholeDigits?: number
    maxDecimalDigits?: number
    //
    rightSide_linkButton_pressed_fn?: (self: TitledTextInputCell, pkey: LinkButtonOrTextSpanItem_PKey) => void
}
//
//
export interface TitledTextInputCell__set_props__args extends PropSettable.base__set_props__args {
    textInput?: {
        placeholder: string|undefined,
        title: string|undefined,
        postTitleButtonText: string|undefined,
        textInput_rightSide_linkButton_items: LinkButtonOrTextSpanItem[]|null|undefined,
        tooltipText?: string|undefined|null,
        inputText?: string|undefined|null,
        input_immutable?: boolean|undefined
    }
    accessoryRadio?: {
        isChecked: boolean|undefined
    }
}
//
//
export namespace TitledTextInput
{
    export enum _accessoryRadio_value
    {
        true = "true",
        false = "false"
    }
}
//
export class TitledTextInputCell extends AbstractControl.Base_Cell
{
    //
    //
    textInput?: _TextInput_ForCell
    staticValueDisplay?: _DescriptionLabel._View
    //
    lookup_input_value(): string
    {
        if (!this.textInput) {
            throw new Error("Expecting this only to be called with non-nil this.textInput")
        }
        return this.textInput.lookup_input_value()
    }
    focus__input()
    {
        if (!this.textInput) {
            throw new Error("Expecting this only to be called with non-nil this.textInput")
        }
        this.textInput.focus__input()
    }
    //
    lookup_checkbox_isChecked(): boolean|undefined
    {
        return this._labelRow.accessoryRadio?.selectedValue() == TitledTextInput._accessoryRadio_value.true 
        //
        // Note lack of visibility and interactivity checks; and no optl unwrap or check
        //
    }
    //
    _ttic_ip!: TitledTextInputCell_InitParams
    _ephemeral_primaryHidden_displayStaticValue?: string
    effective_primaryHidden_displayStaticValue()
    {
        return typeof this._ephemeral_primaryHidden_displayStaticValue != 'undefined'
            ? this._ephemeral_primaryHidden_displayStaticValue 
            : this._ttic_ip.primaryHidden_displayStaticValue
    }
    public set_primaryHidden_displayStaticValue(v: string|undefined) {
        const self = this
        if (!this._labelRow.accessoryRadio) {
            throw new Error("Expected this._labelRow.accessoryRadio")
        }
        if (self._ttic_ip.includeAccessory_checkbox != true) {
            throw new Error("Expected self._ttic_ip.includeAccessory_checkbox")
        }
        self._ephemeral_primaryHidden_displayStaticValue = v
        if (typeof v !== 'undefined') {
            if (!self.staticValueDisplay) { // lazy init; configured later on 
                self.add_staticValueDisplay_view()
        }
            if (self._ttic_ip.includeAccessory_checkbox == true) {
                self._configure_visibility_with_accessory_isChecked(self.lookup_checkbox_isChecked()!)
            }
        } else {
             // TODO: probably worth checking if there are initial params left and if there aren't nor any primary static val, removing the static val display - honestly this should be moved to a prop, fairly clearly
        }
    }
    //
    _ephemeral_primary_value?: string|null|undefined
    effective_primary_value(): string|null|undefined
    {
        return typeof this._ephemeral_primary_value !== 'undefined'
            ? this._ephemeral_primary_value 
            : this._ttic_ip.primary_value
    }
    public set_primary_value(v: string|undefined) {
        const self = this
        self._ephemeral_primary_value = v
        if (typeof v !== 'undefined') {
            if (!self.staticValueDisplay) { // lazy init; configured later on 
                self.add_staticValueDisplay_view()
        }
        } else {
             // TODO: probably worth checking if there are initial params left and if there aren't nor any primary static val, removing the static val display - honestly this should be moved to a prop, fairly clearly
        }
        self._configure_visibility_with_accessory_isChecked(self.lookup_checkbox_isChecked()!)
    }
    //
    constructor(ip: TitledTextInputCell_InitParams)
    {
        super({ 
            themeC: ip.themeC, 
            contextInUI: ip.contextInUI,
            accessoryRadio_roleWithinUICell: ip.accessoryRadio_roleWithinUICell || StyledRadio_Control.RadioControlRoleWithinUICell.tertiary
        })
        const self = this
        self._ttic_ip = ip
    }
    setup(): void 
    {
        super.setup()
        //
        const self = this
        //
        if (self._ac_base_ip.contextInUI) {
            switch (self._ac_base_ip.contextInUI) {
                case themes.ElementContextInUI.SplashBody_Mini:
                    self.layer.style.marginTop = "20px"
                    break
            }
        }
        let weakSelf = new WeakRef(self)
        let themeC = self._ttic_ip.themeC
        if (self._ttic_ip.includeAccessory_checkbox == true) { // default to false
            self._labelRow.set_props({
                accessoryRadio: { // using this method is important since the accessoryRadio might need to be set up by calling this method first
                    members: [
                        {
                            value: TitledTextInput._accessoryRadio_value.false,
                            explct_title: self._ttic_ip.accessory_checkbox_valLabel_false || "Off" 
                        },
                        {
                            value: TitledTextInput._accessoryRadio_value.true,
                            explct_title: self._ttic_ip.accessory_checkbox_valLabel_true || "On" 
                        }
                    ],
                    selectedValue: self._ttic_ip.checkbox__checked == true ? "true" : "false", // defaults to false
                    leftAdjacent_title: self._ttic_ip.accessory_checkbox_leftSideTitle === null 
                        ? null
                        : typeof self._ttic_ip.accessory_checkbox_leftSideTitle !== 'undefined' 
                            ? self._ttic_ip.accessory_checkbox_leftSideTitle
                            : "Show", 
                    //
                    rightAdjacent_title: null
                }
            })
        }
        if (self._ttic_ip.primary_mutable === false) {
            if (!self.staticValueDisplay) { // lazy init; configured later on 
                self.add_staticValueDisplay_view()
            }
        } else if (self._ttic_ip.include_primaryInput != false) {
            {
                let view = new _TextInput_ForCell({
                    themeC: themeC,
                    contextInUI: self._ac_base_ip.contextInUI!,
                    placeholder: self._ttic_ip.placeholder,
                    inputType: self._ttic_ip.inputType,
                    multiline: self._ttic_ip.multiline,
                    maxWholeDigits: self._ttic_ip.maxWholeDigits,
                    maxDecimalDigits: self._ttic_ip.maxDecimalDigits,
                    textOrSecureInput_disallowWhitespace: self._ttic_ip.textOrSecureInput_disallowWhitespace,
                    textOrSecureInput_disallow_nonAlphaNum: self._ttic_ip.textOrSecureInput_disallow_nonAlphaNum,
                    changed_fn: self._ttic_ip.input__changed_fn ? (_textInputSelf: _TextInput_ForCell, v: string) => { 
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return
                        }
                        optl_self._ttic_ip.input__changed_fn!(optl_self, v)
                    } : undefined, // may be nil
                    debounced_changed_fn: self._ttic_ip.input__debounced_changed_fn ? (_textInputSelf: _TextInput_ForCell, v: string) => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return
                        }
                        optl_self._ttic_ip.input__debounced_changed_fn!(optl_self, v)
                    } : undefined, // might be nil
                    revert_value_on_blur_to__fn: self._ttic_ip.input__revert_value_on_blur_to__fn ? (_textInputSelf: _TextInput_ForCell) => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return undefined
                        }
                        return optl_self._ttic_ip.input__revert_value_on_blur_to__fn!(optl_self)
                    } : undefined,
                    focused_fn: self._ttic_ip.input__focused_fn ? (_textInputSelf: _TextInput_ForCell) => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return
                        }
                        return optl_self._ttic_ip.input__focused_fn!(optl_self)
                    } : undefined,
                    key_pressed_fn: self._ttic_ip.input__key_pressed_fn ? (_textInputSelf: _TextInput_ForCell, e: KeyboardEvent) => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return false
                        }
                        return optl_self._ttic_ip.input__key_pressed_fn!(optl_self, e)
                    } : undefined,
                    rightSide_linkButton_pressed_fn: self._ttic_ip.rightSide_linkButton_pressed_fn ? (pkey: LinkButtonOrTextSpanItem_PKey) => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return false
                        }
                        return optl_self._ttic_ip.rightSide_linkButton_pressed_fn!(optl_self, pkey)
                    } : undefined
                }).init()
                if (typeof self._ttic_ip.primary_value !== 'undefined' && self._ttic_ip.primary_value !== null) { // might as well support this here too
                    view.setInputValue(self._ttic_ip.primary_value)
                }
                self.textInput = view
                if (self._ttic_ip.primaryInput_location == AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio) {
                    view.layer.style.minWidth = `${self._ttic_ip.themeC.current.layouts.leftOfAccessoryRadio_primaryValueElement_minWidth}px`
                    view.layer.style.width = "auto" // to reset from the parent's 100% so that the minWidth functions properly
                    // 
                    self._labelRow.insertSubviewToLeftOfAccessoryRadio(view)
                } else {
                    self._primaryInputContainer_subcontainer_0.addSubview(view) // the default
                }
            }
            if (typeof self._ttic_ip.primaryHidden_displayStaticValue !== 'undefined' && self._ttic_ip.primaryHidden_displayStaticValue != null) {
                if (!self.staticValueDisplay) { // lazy init; configured later on 
                    self.add_staticValueDisplay_view()
                } else {
                }
            }
            //
        }
        //
        // Other initial configuration
        if (self.staticValueDisplay) {
            _DescriptionLabel.set_props(self.staticValueDisplay, {
                _isEnabled: self._isEnabled, // ensure (lack of) interactivity configuration survives reconstruction of subviews
            })
        }
        if (self._ttic_ip.includeAccessory_checkbox == true) {
            self._configure_visibility_with_accessory_isChecked(self._ttic_ip.checkbox__checked === true/*handling default -> false*/)
        }
    }
    //
    // Imperatives - Configuration
    _configure_visibility_with_accessory_isChecked(isChecked: boolean)
    {
        const self = this
        let primary_isHidden = !isChecked && self._ttic_ip.includeAccessory_checkbox == true && self._ttic_ip.checkboxControlsInputHiding !== false
        if (self.textInput) {
            if (self._ttic_ip.checkboxControlsInputHiding !== false) { // NOTE this condition form handles undefined and defaults
                if (primary_isHidden) {
                    self.textInput.input_layer().value = "" // resetting - and not using the .clear_input() here since that will fire an event we dont want consumers to see - since we are not controlling what the "static" value should really be - consumers should check whether the checkbox is on/off instead
                    //
                    self.textInput.hide()
                } else {
                    self.textInput.show()
                }
            }
        }
        if (self.staticValueDisplay) {
            let effective_primaryHidden_displayStaticValue = self.effective_primaryHidden_displayStaticValue()
            let has_secondaryValue = (typeof effective_primaryHidden_displayStaticValue !== 'undefined' && effective_primaryHidden_displayStaticValue != null)
            if (primary_isHidden) {
                if (has_secondaryValue) {
                    _DescriptionLabel.set_props(self.staticValueDisplay!, {
                        description: has_secondaryValue 
                            ? effective_primaryHidden_displayStaticValue!
                            : undefined 
                        ,
                        _isHidden: false 
                    })
                } else {
                    throw new Error("Not expected")
                }
            } else if (self._ttic_ip.primary_mutable === false) { // primary is visible ... but do we have anything to display?
                _DescriptionLabel.set_props(self.staticValueDisplay!, {
                    description: self.effective_primary_value()!,
                    _isHidden: false 
                })
            } else { // primary still visible... but we might not have anything but the .input - so make sure what would be 'secondary' gets hidden here
                _DescriptionLabel.set_props(self.staticValueDisplay!, {
                    _isHidden: true 
                })
            }
        }
    }
    //
    // Imperatives - Visibility - Used in e.g. UseExistingWallet form
    hide(andClear: boolean = false)
    {
        const self = this
        super.hide(andClear) // important
        //
        // used by Use Existing Wallet
        if (andClear) {
            self.clear_input()
        }
    }
    clear_input()
    {
        const self = this
        self.textInput?.setInputValue("")
    }
    show()
    {
        const self = this
        super.show()
    }
    //
    public setValue(str: string)
    {
        const self = this
        if (self._ttic_ip.primary_mutable === false) { // properly handling defaults
            _DescriptionLabel.set_props(self.staticValueDisplay!, {
                description: str
            })
        } else if (self.textInput) {
            if (self.textInput.lookup_input_value() != str) { // prevent unnecessary focus losing
                // console.log(self.constructor.name, self.uuid, "is setting the input value ('" + str + "')")
                self.textInput.setInputValue(str) // this handles 'changed' bubbling
            } else {
                // console.warn(self.constructor.name, self.uuid, "is skipping .setInputValue() due to value being the same ('" + str + "')")
            }
        } else if (self._ttic_ip.include_primaryInput != false) {
            throw new Error("Neither immutable primary nor input yet include_primaryInput != false")
        }
    }
    public setInputValue(str: string)
    {
        const self = this
        self.setValue(str)
    }
    //
    public setEnabled(isEnabled: boolean)
    {
        const self = this
        //
        self._labelRow.setEnabled(isEnabled)
        self.textInput?.setEnabled(isEnabled)
        self.staticValueDisplay?.setEnabled(isEnabled)
        //
        super.setEnabled(isEnabled) // key for e.g. labelRow
    }
    //
    set_props(args: TitledTextInputCell__set_props__args) {
        const self = this
        if (args.accessoryRadio) {
            let props = args.accessoryRadio
            self._labelRow.set_props({
                accessoryRadio: {
                    members: undefined,
                    leftAdjacent_title: undefined,
                    selectedValue: props.isChecked ? "true" : "false", // defaults to false
                    rightAdjacent_title: undefined,
                }
            })
        }
        if (args.textInput) {
            let props = args.textInput
            self.textInput?.set_props({
                placeholder: props.placeholder,
                rightSide_linkButton_items: props.textInput_rightSide_linkButton_items,
                input_immutable: props.input_immutable,
            })
            self.set_props_labelRow({ 
                title: props.title,
                postTitleButtonText: props.postTitleButtonText,
                // TODO: support a 'copy' button here
                tooltipText: props.tooltipText
            })
            if (typeof props.inputText !== 'undefined') {
                self.setInputValue(props.inputText!)
            }
        }
    }
    //
    add_staticValueDisplay_view()
    {
        const self = this
        let themeC = self._ttic_ip.themeC
        let staticValueDisplay_locationInCell = self._ttic_ip.primaryInput_location || AbstractControl.LocationWithinTitledCell.underTitle // default
        let view = _new_staticValueDisplay_view(themeC, staticValueDisplay_locationInCell)
        if (self._ttic_ip.primaryInput_location == AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio) {
            view.layer.style.minWidth = `${self._ac_base_ip.themeC.current.layouts.leftOfAccessoryRadio_primaryValueElement_minWidth}px`
        }
        self.staticValueDisplay = view
        if (self._ttic_ip.primaryInput_location == AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio) {
            self._labelRow.insertSubviewToLeftOfAccessoryRadio(view)
        } else {
            self._primaryInputContainer_subcontainer_0.addSubview(view) // the default
        }
    }
    //
    //
    override _accessoryRadio_changed(selectedValue: StyledRadio_Control.ButtonValue)
    {
        const self = this
        super._accessoryRadio_changed(selectedValue)
        //
        let isChecked = selectedValue == TitledTextInput._accessoryRadio_value.true 
        self._configure_visibility_with_accessory_isChecked(isChecked)
        //
        if (self._ttic_ip._accessoryRadio_changed) {
            self._ttic_ip._accessoryRadio_changed(isChecked)
        }
    }
    override _postTitleButtonText_tapped()
    {
        const self = this
        super._postTitleButtonText_tapped()
        //
        // bubble
        if (self._ttic_ip.postTitleButtonText_tapped_fn) {
            self._ttic_ip.postTitleButtonText_tapped_fn(self)
        }
    }
}