import { App_View_Context } from '../App_View_Web'
import { ComposableComponentFormPage } from '../Common/ComposableComponentFormPage'
import { LinkButton, LinkButtonUsage } from '../Common/LinkButtonCell'
import StringSelect_Cell_View from '../Common/StringSelect_Cell'
import { default__appTimeoutAfterS, SettingsKey } from '@xrds/xrds-libapp-ts/src/Preferences/controllers/SettingsController'
import { SpecificCommon_LinkButtonCells } from '../Common/SpecificCommon_LinkButtonCells'
import StackNavigationView from '../Common/NavigationView'
import { EnterExistingPassword_ForChangePassword_View } from '../Passwords/EnterExistingPassword_ForChange_View'
import { AbstractThemed } from '../Common/AbstractThemed'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { themes } from '../themes'
//
//
// export interface Settings_View_InitParams
// {
// }
//
//
export class Settings_View extends ComposableComponentFormPage
{
    //
    select__appTimeoutAfterS!: StringSelect_Cell_View
    //
    _sc_fn__changed_appTimeoutAfterS?: () => void
    //
    //
    constructor(ctx: App_View_Context)
    {
        super(ctx)
        const self = this
    }
    setup(): void 
    {
        super.setup()
        const self = this
        // self._lifecycleObject_wants_debug_log__teardown = true
        let weakSelf = new WeakRef(self) // so as not to cause a strong ref cycle
        {


            // ,
            // {
            //     title: "Require unlock for: Sending funds",
            //     settingsFieldName: SettingsKeyString.authentication_requireWhenSending,
            //     inputType: SettingsInputType.boolean
            // }
            // {
            //     title: "Require unlock for: Showing wallet seed",
            //     settingsFieldName: SettingsKeyString.authentication_requireWhen....,
            //     inputType: SettingsInputType.boolean
            // }


            let view = new StringSelect_Cell_View({
                themeC: self.ctx.themeC,
                title: "Lock the app after",
                did_change_value__fn: (Vs: string[]) => 
                {
                    let optl_self = weakSelf.deref() // critical so as not to cause a strong ref cycle
                    if (!optl_self) {
                        return
                    }
                    let final_value: number = parseInt(Vs[0]) // z: -1, 10 ... n
                    /*await */optl_self.setSettingsValueForItem(SettingsKey.KeyString.appTimeoutAfterS, final_value)
                },
                tooltipText: "As a security precaution to prevent access to your secret data, the app is designed to lock itself after an idle period."
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.select__appTimeoutAfterS = view
            self.configure_select__appTimeoutAfterS() // after setting ivar
            self.addSubview(view)
        }
        {
            let view = new LinkButton.Cell({
                themeC: self.ctx.themeC,
                uiContext: themes.ElementContextInUI.FormControl_outsideOfCellGroup,
                pressed_fn: () => 
                { 
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    let modal = new StackNavigationView({ themeC: optl_self.ctx.themeC }).init()
                    modal.push(new EnterExistingPassword_ForChangePassword_View(optl_self.ctx, {}).init())
                    optl_self.stackNavigationView!.present(modal, { animated: true }) // more rigorous than optl_self.ctx.rootNavigationView ?
                }
            }).init()
            view.set_props({
                items: [ 
                    { _pkey: "icon", symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.padlock__locked }, buttonUsage: LinkButtonUsage.default },
                    { _pkey: "btn", label: "CHANGE PASSWORD", buttonUsage: LinkButtonUsage.default }
                ]
            })
            self.addSubview(view)
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_deleteEverything(
                self.ctx.themeC, 
                themes.ElementContextInUI.FormControl_outsideOfCellGroup,
                self.ctx.controllers.persistenceController
            )
            self.addSubview(view)
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self.startObserving()
    }
    startObserving()
    {
        const self = this
        let weakSelf = new WeakRef(self) // so as not to cause a strong ref cycle
        //
        self._sc_fn__changed_appTimeoutAfterS = () =>
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self.configure_select__appTimeoutAfterS() // this will set is_selected on the matching option
        }
        let c = self.ctx.controllers.settingsController
        c.on( // now this code is portable and less copy-paste heisenbug prone
            SettingsKey.DidChange_EventName_for(SettingsKey.KeyString.appTimeoutAfterS),
            self._sc_fn__changed_appTimeoutAfterS
        )

        // Settings_EventName.changed_authentication_requireWhenSending // TODO
    }
    public teardown(): void 
    {
        super.teardown()
        const self = this
        //
        let c = self.ctx.controllers.settingsController
        c.removeListener( // now this code is portable and less copy-paste heisenbug prone
            SettingsKey.DidChange_EventName_for(SettingsKey.KeyString.appTimeoutAfterS), 
            self._sc_fn__changed_appTimeoutAfterS!
        )
        self._sc_fn__changed_appTimeoutAfterS = undefined
        //
        // TODO: Settings_EventName.changed_authentication_requireWhenSending
    }
    //
    override navigationBar_title(): string|undefined
    {
        return "Settings"
    }
    //
    // Imperatives - Common - SettingsController interface - possibly can remove this
    async setSettingsValueForItem(settingsFieldName: SettingsKey.KeyString, final_value: any)
    {
        const self = this
        if (self.ctx.controllers!.passwordController.HasUserEnteredValidPasswordYet() == false) { // not sure this is necessary.. and if anything the SettingsController should handle it and perhaps throw
            console.warn("Ignoring a stray setSettingsValueForItem while no password was actually set")
            return
        }
        let valuesByKey = {}
        valuesByKey[settingsFieldName] = final_value
        let r = await self.ctx.controllers.settingsController.Set_settings_valuesByKey_andSave_andNotify(valuesByKey)
        if (r.err_str) {
            throw new Error(r.err_str!)
        }
    }
    //
    // Imperatives - UI - config
    configure_select__appTimeoutAfterS()
    { // This is factored and used even though it's so inefficient for the following .. because it allows us to set is_selected
        const self = this
        let settableValues__by__option_valueStrs =
        {
            "15 seconds": 15,
            // ^ This used to be 10 but I increased it (after having removed it) that network requests on e.g. LogIn while unlocked have a reasonable chance of succeeding so that we dont have to include the sensitive concept of disabling the idle timer and re-enabling it .. ideally it'd take enhancement to temporarily adjust the idle timer to an upper limit - but the biz logic that may be idled (torn down) around ought to be able to handle teardowns anyway - and if not, they're probably leaking as zombies in memory anyway which they definitely shouldnt be
            "30 seconds": 30,
            "2 minute": 2 * 60,
            "5 minutes": default__appTimeoutAfterS, // TODO: this happens to be 5 minutes right now... it'll be nice one day to just generate the labels from the numerical values but
            "10 minutes" : 10 * 60,
            "Half an hour": 30 * 60,
            "Never": self.ctx.controllers.settingsController.AppTimeoutNever_numberValue() // actually -1
        }
        let option_valueStrs = Object.keys(settableValues__by__option_valueStrs)
        let settingValue = self.ctx.controllers.settingsController.appTimeoutAfterS
        let valueOf_selected = settingValue// the rest of this is no longer needed == self.ctx.controllers.settingsController.AppTimeoutNever_numberValue() ? idleTimeout_maxValue : settingValue
        self.select__appTimeoutAfterS.set_props__stringSelect({
            items: option_valueStrs.map(str => ({
                value: settableValues__by__option_valueStrs[str],
                label: str,
                is_selected: settableValues__by__option_valueStrs[str] === valueOf_selected
            }))
        })
    }
}