//
//
import View_Base, { View_debug_utils } from './View_Base'
//
export interface View_Web__Options // note this does not inherit from View_Base__Options since we can supply the remaining properties here manually as the concrete implementation
{
    el_name?: string, // this will default to 'div' as below
    layer_init_options?: { [key: string]: any }
}
type _LayerType = HTMLElement 
//
// export const View_Web_appendChild = (parent: View_Web, child: View_Web|string) =>
// { // '...children' can be used by .jsx construction
//     if (Array.isArray(child)) {
//         child.forEach((nestedChild) => View_Web_appendChild(parent, nestedChild))
//     } else {
//         let c!: View_Web
//         if (child['is_a_View_Web'] === true) {
//             c = child as View_Web
//         } else {
//             c = new View_Web({
//                 el_name: 'div'
//             }).init()
//             c.layer.innerText = ''+child // this ought to be a string 
//         }
//         parent.addSubview(c)
//     }
// }
//
export default class View_Web extends View_Base<_LayerType>
{
    is_a_View_Web: boolean = true // usable for introspection - there's got to be a better way to do this
    //
    isHidden: boolean = false
    //
    constructor(opts: View_Web__Options)
    {
        super({
            ...opts,
            _concrete__new__layer: (final_el_name: string, layer_init_options: { [key: string]: any }, uuid: string) => 
            {
                // layer_init_options are not really used in the DOM - there is no way to set attributes, properties, and/or style in the document constructor yet
                //
                let el = document.createElement(final_el_name, layer_init_options)
                ////// el.setAttribute("xrds-u", uuid) // this can be enabled for debug
                //
                return el
            },
            _concrete_appendLayerToSuperlayer: (superlayer: _LayerType, sublayer: _LayerType) => 
            {
				superlayer.appendChild(sublayer)
            },
            _concrete_upsertLayerAtEnd: (
                superlayer: _LayerType, view_layer: _LayerType
            ) => {
                superlayer.insertBefore(view_layer, null) // "If this is null, then newNode is inserted at the end of node's child nodes."
            },
            _concrete_insertLayerBefore: (superlayer: _LayerType, view_layer: _LayerType, layerOf_subviewAbove: _LayerType) => 
            {
                if (layerOf_subviewAbove.parentNode !== superlayer) {
                    throw new Error('View hierarchy error - layerOf_subviewAbove.parent !== superlayer')
                }
                superlayer.insertBefore(view_layer, layerOf_subviewAbove)
            },
            _concrete_removeSublayer: (superlayer: _LayerType, sublayer: _LayerType) => 
            {
                if (sublayer.parentNode !== superlayer) {
                    throw new Error("Asked to remove sublayer but it was not in the specified superlayer")
                }
                superlayer.removeChild(sublayer)
            },
            _concrete_childrenOfLayer: (layer: _LayerType): _LayerType[] => 
            {
                return Array.prototype.slice.call(layer.childNodes)
            },
            _concrete_render: (layer: _LayerType) => 
            {
                // nothing to do here - DOM handles it
            },
            //
            _concrete_DEBUG_borderLayer: (layer: _LayerType) =>
            {
                layer.style.border = `1px solid ${View_debug_utils.RandomColorHexString()}`
            }
        })
    }
    _overridable__default__el_name(): string
    {
        return "div"
    }
    //
    setEnabled(isEnabled: boolean)
    {
        const self = this
        if (isEnabled) {
            self.layer.classList.remove("disabled")
        } else {
            self.layer.classList.add("disabled")
        }
        super.setEnabled(isEnabled)
    }
    //
    // Overridable - Imperatives - Show/Hide
    layer_style_display_fromLastHide?: string
    overridable_defaultInitial_style_display_forShow()
    {
        return "block"
    }
    hide(andClear: boolean = false)
    {
        const self = this
        //
        if (self.isHidden != true) { // otherwise we dont want to record a "none"
            if (self.layer.style.display == "none") {
                throw new Error("Code fault: Expected self.layer.style.display != 'none'")
            }
            self.layer_style_display_fromLastHide = self.layer.style.display // store the style at hide-time and use it again for the show
        }
        self.layer.style.display = "none"
        // override but call on super
        self.isHidden = true // for reading
    }
    show()
    {
        const self = this
        if (self.isHidden == false) {
            /// console.warn("Silently bailing from .show() in ", self)
            // ^ This is left commented so as to give the ability for consumers to call .show as if it's idempotent and near-zero perf cost
            return // already showing - and we don't want to override anything! it hasn't been hidden so it won't have a proper to_v to reset to
        }
        //
        self.layer.style.display = self.layer_style_display_fromLastHide  // this way, a display:inline prior to the 'hide' won't turn into e.g. a display:block
            ? self.layer_style_display_fromLastHide 
            : self.overridable_defaultInitial_style_display_forShow()
        ;
        // override but call on super
        self.isHidden = false // for reading
    }
}
