//
//
import View_Web from '../../View/View.web'
import { styles_theme, themes } from '../themes';
import { css_rules } from "../css_rules";
import { PropSettable } from './DisplayableListRow';
import { InformativeIcon } from './InformativeIcon';
import { AbstractThemed } from './AbstractThemed';
import { Icon_Symbolic } from './Icon_Symbolic';
import { CTAButton } from './CTAButton';
import { styles_tooltips } from './Tooltips';
import tippy from './tippy.umd.min.js'
import './tippy.min.css'
//
export namespace LinkButton
{
    export enum Appearance
    {
        moreSignificant_ss = 1, // default
        diminutive_mono = 2,
        small_enclosedDecorated = 3, // i.e. the MAX btn
        med_enclosedDecorated = 4 // i.e. the '?' spawn btn
    }
}
//
//
enum LinkOrTextSpanButtonCell_ItemType
{
    linkButton = 1,
    textSpan = 2,
    informativeIcon = 3,
    symbolicIcon = 4
}
//
export type LinkButtonOrTextSpanItem_PKey = string
//
export interface LinkButtonOrTextSpanItem
{
    _pkey: LinkButtonOrTextSpanItem_PKey // this is a unique identifier that the internal 'machinery' can use to diff values and support 'undefined'
    // //
    // type: LinkOrTextSpanButtonCell_ItemType
    // maybe we can use this in the future, especially if a third type is necessary
    iconType?: InformativeIcon.IconType|undefined|null 
    symbolicIcon_props?: Icon_Symbolic.Props|undefined|null
    //
    label?: string|undefined|null // the actual text of the linked button (a tag) or the content of non-linked text; when this is undefined, when combined with a _pkey, the 'machinery' can tell not to remove the subview corresponding to the _pkey
    //
    buttonUsage?: LinkButtonUsage // required when this is to be used as a LinkButton rather than a TextSpan
    // when .buttonUsage is undefined, we'll treat it as a text span - this could be better formalized by creating an enum
    //
    tooltipText?: string|undefined|null // when this is non-undefined, it will be a Link type subitem and will have OpenTip installed upon it; when it's null, it won't have a tooltip
    //
    appearance?: LinkButton.Appearance // will default to .moreSignificant_ss
    //
    style?: { [key: string]: any }
}
//
export interface LinkButton_Cell__set_props__args extends PropSettable.base__set_props__args
{ // undefined means do not change; null means delete
    items: LinkButtonOrTextSpanItem[] 
    // not supporting undefined right now because there are no other properties (any longer) that you'd want to set without having to specify .items
}
export enum LinkButtonUsage
{
    default = "default",
    cancelLike = "cancelLike",
    destructive = "destructive",
    floating_util = "floating_util", // such as scroll-to-btm
    customColorIcon = "customColorIcon"
}
//
interface _LinkButtonCell_SubItem_Subview_Item_IP
{
    itemType: LinkOrTextSpanButtonCell_ItemType
    i: LinkButtonOrTextSpanItem
    pressed_fn?: (_clicked_pkey: LinkButtonOrTextSpanItem_PKey) => void
    themeC: themes.Controller
    uiContext: themes.ElementContextInUI
    pressFocusBehavior?: CTAButton.PressFocusBehavior
    appearance?: LinkButton.Appearance
}
//
//
//
let pkey_attr_key = "link-item-pkey"
//
class _LinkButtonCell_SubItem_Subview extends View_Web
{
    ip!: _LinkButtonCell_SubItem_Subview_Item_IP
    //
    iconView?: InformativeIcon.View | Icon_Symbolic.View
    //
    _inner_label?: View_Web
    //
    tooltipInstance?: any
    //
    constructor(_item_ip: _LinkButtonCell_SubItem_Subview_Item_IP)
    {
        super({ 
            el_name: _item_ip.itemType == LinkOrTextSpanButtonCell_ItemType.informativeIcon || _item_ip.itemType == LinkOrTextSpanButtonCell_ItemType.symbolicIcon
                ? 'div' 
                : _item_ip.itemType == LinkOrTextSpanButtonCell_ItemType.linkButton
                    ? "a" 
                    : "span"
        })
        //
        const self = this
        self.ip = _item_ip
    }
    setup()
    {
        super.setup()
        const self = this
        let weakSelf = new WeakRef(self)
        let view = self
        let i = self.ip.i
        //
        let _is_linkButton = self.ip.itemType == LinkOrTextSpanButtonCell_ItemType.linkButton
        let _is_type_informativeIcon = self.ip.itemType == LinkOrTextSpanButtonCell_ItemType.informativeIcon
        let _is_type_symbolicIcon = self.ip.itemType == LinkOrTextSpanButtonCell_ItemType.symbolicIcon
        let _is_textSpan = self.ip.itemType == LinkOrTextSpanButtonCell_ItemType.textSpan
        //
        view.layer.classList.add(styles_theme.ClassNames.linkedText_subcomponent)
        switch (i.buttonUsage!) { // almost all types of components can benefit from these classes so I now add it generally to self
            case LinkButtonUsage.destructive:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__destructive)
                break
            case LinkButtonUsage.cancelLike:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__cancelLike)
                break
            case LinkButtonUsage.floating_util:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__floating_util)
                break
            case LinkButtonUsage.customColorIcon:
                // no class - may add soon 
                break
            case LinkButtonUsage.default:
            default:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__default)
                break
        }
        if (i.buttonUsage! == LinkButtonUsage.floating_util) {
            view.layer.classList.add(styles_theme.ClassNames.button__floating_util)
        }
        if (_is_textSpan != true) {
            if (self.ip.pressed_fn) {
                if (self.ip.pressFocusBehavior == CTAButton.PressFocusBehavior.dontStealFocus) {
                    view.layer.addEventListener("mousedown", (e) => 
                    {
                        e.preventDefault() // PS: I am using 'mousedown' and this preventDefault because I want to prevent focus being lost by another hypothetical input element - such as the input in the message bar when 'Send' clicked
                    })
                }
                view.layer.setAttribute(pkey_attr_key, i._pkey)
                view.layer.addEventListener("click", (e) => 
                {
                    e.preventDefault()
                    e.stopPropagation()
                    //
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (optl_self._isEnabled != true) {
                        throw new Error("Not expecting to observe 'click' on a disabled 'a'")
                    }
                    optl_self.ip.pressed_fn!(i._pkey) // assume fn still there..
                    //
                    return false
                })
            }
        }
        if (typeof i.tooltipText != 'undefined') {
            css_rules.lazy_injectRuleStrs(styles_tooltips.namespaceName, styles_tooltips.generatorFn, self.ip.themeC)
        }
        let appearance = self.ip.appearance ? self.ip.appearance : LinkButton.Appearance.moreSignificant_ss
        switch (self.ip.uiContext) {
            case themes.ElementContextInUI.SplashHeader_Mini:
                view.layer.style.fontWeight = "400"
                view.layer.style.fontSize = "22px"
                view.layer.style.lineHeight = "33px"
                view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                break

            case themes.ElementContextInUI.AppFooter:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "14px" 
                break
    
            case themes.ElementContextInUI.SplashBody_Mini:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "14px" // unlike the titles which are at 16px
                view.layer.style.textTransform = "uppercase"
                break

            case themes.ElementContextInUI.SplashHeader:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "32px"
                view.layer.style.fontFamily = themes.styles_fonts.FontFamilies.ss_medium
                view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                break

            case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "24px"
                view.layer.classList.add(themes.styles_fonts.ClassNames.fontSmoothing_antiAlias)
                break

            case themes.ElementContextInUI.CellGroupHeader_large_outsideOfCellGroup:
                // view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "18px"
                break
                
            case themes.ElementContextInUI.CellGroupHeader_large_withinCellGroup:
                // view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "20px"
                break

            case themes.ElementContextInUI.CellGroupHeader_normal_withinCellGroup:
                // view.layer.style.fontWeight = ""
                view.layer.style.fontSize = "15px"
                break

            case themes.ElementContextInUI.CellGroupHeader_small_withinCellGroup:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "15px"
                break

            case themes.ElementContextInUI.totallyCustomContext:
            case themes.ElementContextInUI.FeedCellHeader:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "18px"
                break

            case themes.ElementContextInUI.FormControl_withinCellGroup:
                view.layer.style.fontWeight = "500"
                switch (appearance) {
                    case LinkButton.Appearance.small_enclosedDecorated:
                        view.layer.style.fontSize = "10px" // TODO: this can be removed
                        break
                    case LinkButton.Appearance.med_enclosedDecorated:
                        view.layer.style.fontSize = "14px" // unlike the titles which are at 16px
                        break
                    default:
                        view.layer.style.fontSize = "14px"
                        break
                }
                break

            case themes.ElementContextInUI.FormControl_outsideOfCellGroup:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "18px" // unlike the titles which are at 16px
                break

            default:
                view.layer.style.fontWeight = "500"
                view.layer.style.fontSize = "18px"
                break
        }
        switch (appearance) {
            case LinkButton.Appearance.moreSignificant_ss:
                view.layer.style.fontFamily = themes.styles_fonts.FontFamilies.ss_regular
                view.layer.style.textTransform = "none"
                break
            case LinkButton.Appearance.diminutive_mono:
                view.layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_bold)
                view.layer.style.fontSize = "15px"
                view.layer.style.textTransform = "uppercase"
                break
            case LinkButton.Appearance.small_enclosedDecorated:
            case LinkButton.Appearance.med_enclosedDecorated:
                view.layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_regular)
                view.layer.style.textTransform = "uppercase"
                break
        }
        switch (appearance) {
            case LinkButton.Appearance.small_enclosedDecorated:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__appearance__small_enclosedDecorated)
                break
            case LinkButton.Appearance.med_enclosedDecorated:
            // default:
                view.layer.classList.add(styles_theme.ClassNames.linkedText__appearance__med_enclosedDecorated)
                break
        }
        if (_is_type_informativeIcon) {
             // add new Icon subview here and should be good to go - aside from it not being clickable, tho - but that could be added to the Icon view itself
            let v = new InformativeIcon.View({
                themeC: self.ip.themeC,
                type: self.ip.i.iconType!
            }).init()
            self.addSubview(v)
        } else if (_is_type_symbolicIcon) {
            let v = new Icon_Symbolic.View().init()
            v.set_props(self.ip.i.symbolicIcon_props!)
            self.addSubview(v)
        } else if (_is_linkButton) {
            let a__layer = view.layer as HTMLAnchorElement
            a__layer.href = "#"
            //
            switch (appearance) {
                case LinkButton.Appearance.small_enclosedDecorated:
                case LinkButton.Appearance.med_enclosedDecorated:
                {
                    let inner_a = new View_Web({ el_name: "a" }).init()
                    inner_a.layer.classList.add(styles_theme.ClassNames.button)
                    inner_a.layer.classList.add(styles_theme.ClassNames.ctaButton)
                    if (self.ip.pressed_fn) { // otherwise, do not show the pointer, (although, for now, in fact, also causes not to show half opacity on disable)
                        inner_a.layer.classList.add(styles_theme.ClassNames.interactive_ctl__control)
                        inner_a.layer.classList.add(styles_theme.ClassNames.interactive_ctl__decoration)
                        // TODO: possibly just deprecate/remove those ^^
                    }
                    self.addSubview(inner_a)
                    //
                    let v = new View_Web({ el_name: "span" }).init()
                    v.layer.style.fontSize = appearance == LinkButton.Appearance.small_enclosedDecorated ? "10px" : "14px"
                    self._inner_label = v
                    inner_a.addSubview(v)
                    //
                    break
                }
            }
            //
        } else if (_is_textSpan) {
            switch (appearance) {
                case LinkButton.Appearance.moreSignificant_ss:
                    // switch (self.ip.uiContext) {
                    //     case themes.ElementContextInUI.FormControl_withinCellGroup:
                    //         view.layer.style.color = self.ip.themeC.current.colors.font_lightest
                    //         break
                    //     default:
                    view.layer.style.color = self.ip.themeC.current.colors.font_absoluteLightest
                    break
                    // }

                    break
                case LinkButton.Appearance.diminutive_mono:
                    view.layer.style.color = self.ip.themeC.current.colors.font_light
                    break
            }
        } else {
            throw new Error("Unsupported ip.itemType")
        }
    }
    override teardown(
    ): void {
        super.teardown()
        const self = this
        //
        // console.log("tearing down, and tooltip is", self.tooltipInstance)
        self.tooltipInstance?.destroy() 
        self.tooltipInstance = undefined
        //
}
    //
    // Imperatives - Overrides
    setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled)
        if (self.ip.itemType == LinkOrTextSpanButtonCell_ItemType.linkButton) {
            let a = self.layer as HTMLAnchorElement
            if (a) {
                a.href = isEnabled ? "#" : "" // TODO: does this really work? it doesn't prevent clicks
                a.style.pointerEvents = isEnabled ? "" : "none"
                a.ariaDisabled = isEnabled ? "false" : "true"
                if (isEnabled) {
                    a.classList.remove("disabled")
                } else {
                    a.classList.add("disabled")
                }
            }
        }
    }
    //
    set_props(props: {
        label: string
    }) {
        const self = this
        if (self._inner_label) {
            self._inner_label.layer.innerText = props.label
        } else {
            self.layer.innerText = props.label
        }
}
}
//
//
export namespace LinkButton
{
    export interface Cell_IP extends Omit<_Row_IP, 
        'uiContext'
    > {
        uiContext?: themes.ElementContextInUI // defaults to ElementContextInUI.PageHeaderOrText_outsideOfCellGroup - no code aside from init should access this directly
    }
    
    export class Cell extends AbstractThemed.View /* though often, it wont be used for the fact that it's a themable cell! */
    {
        //
        //
        declare ip: Cell_IP
        uiContext!: themes.ElementContextInUI
        //
        _row!: _Row
        //
        constructor(ip: Cell_IP)
        {
            super({ })
            const self = this
            self.ip = ip
            self.uiContext = self.ip.uiContext || themes.ElementContextInUI.FormControl_withinCellGroup
        }
        setup()
        {
            super.setup()
            //
            const self = this
            const view = self
            //
            view.layer.style.boxSizing = "border-box"
            view.layer.style.width = "auto"
            //
            switch (self.uiContext) {
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.padding = "28px 0 0 0"
                    break

                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                case themes.ElementContextInUI.FormControl_outsideOfCellGroup: 
                    view.layer.style.marginTop = `${self.ip.themeC.current.layouts.cellGroup_parent_margin_top}px`
                    // no .form_row here, on purpose - since sometimes it's not desired given embed within another cell - therefore, .form_row should be in the .Cell class, not ._Row
                    view.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h
                    break


                case themes.ElementContextInUI.FormControl_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_large_outsideOfCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_small_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_normal_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_large_withinCellGroup:
                    view.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h
                    break

                default:
                    break
            }
            //
            let _row_ip = {...self.ip} as _Row_IP
            _row_ip.uiContext = self.uiContext // optl ->
            let _row = new _Row(_row_ip).init()
            self._row = _row
            self.addSubview(_row)
        }
        //
        //
        set_props(props: LinkButton_Cell__set_props__args) {
            const self = this
            self._row.set_props(props)
        }
        setEnabled(isEnabled: boolean) {
            const self = this
            super.setEnabled(isEnabled)
            self._row.setEnabled(isEnabled)
        }
    }
    //
    //
    export interface _Row_IP
    {
        themeC: themes.Controller
        pressed_fn?: (pressed_item_pkey: LinkButtonOrTextSpanItem_PKey) => void
        //
        uiContext: themes.ElementContextInUI // whereas this is overridden as optl and defaulted, in Cell_IP
        //
        pressFocusBehavior?: CTAButton.PressFocusBehavior // chances are it would be fine to always default this to .dontStealFocus
    }
    //
    export class _Row extends View_Web /* though often, it wont be used for the fact that it's a themable cell! */
    {
        //
        //
        declare ip: _Row_IP
        //
        constructor(ip: _Row_IP)
        {
            super({ })
            const self = this
            self.ip = ip
        }
        setup()
        {
            super.setup()
            //
            const self = this
            {
                const view = self
                //
                switch (self.ip.uiContext) {
                    case themes.ElementContextInUI.SplashHeader:
                    case themes.ElementContextInUI.SplashHeader_Mini:
                    case themes.ElementContextInUI.SplashBody_Mini:
                    case themes.ElementContextInUI.FeedCellHeader:
                        view.layer.style.justifyContent = "center"
                        break

                    case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                    case themes.ElementContextInUI.FormControl_withinCellGroup: 
                    case themes.ElementContextInUI.FormControl_outsideOfCellGroup: 
                    case themes.ElementContextInUI.totallyCustomContext:
                    default:
                        view.layer.style.justifyContent = "left" // the default
                        break
                }

    // // view.layer.style.flexGrow = 1
    // // // TODO??
    // view.layer.style.flexWrap = 'wrap'
                //
                view.layer.style.display = "flex"
                view.layer.style.flexDirection = 'row'
                view.layer.style.alignItems = 'center'
                //
                view.layer.style.padding = "0px"
                view.layer.style.width = `auto`
                view.layer.style.boxSizing = "border-box"
            }
        }
        //
        //
        // Imperatives -
        //
        existing_item_subviews_by_pkeys: { [key: LinkButtonOrTextSpanItem_PKey]: _LinkButtonCell_SubItem_Subview } = {}
        existing_items: LinkButtonOrTextSpanItem[] = []
        //
        set_props(props: LinkButton_Cell__set_props__args) {
            const self = this
            let was_items = self.existing_items
            let to_items = props.items
            let to_items__trues_by_pkeys: { [key: LinkButtonOrTextSpanItem_PKey]: boolean } = {}
            props.items.forEach(i => to_items__trues_by_pkeys[i._pkey] = true) 
            //
            let pre__existing_item_subviews_by_pkeys__pkeys = Object.keys(self.existing_item_subviews_by_pkeys)
            for (let pkey of pre__existing_item_subviews_by_pkeys__pkeys) { // the ones formerly existing 
                if (to_items__trues_by_pkeys[pkey] != true) { // then it will no longer exist in the "to" set
                    delete self.existing_item_subviews_by_pkeys[pkey]
                }
            }
            //
            let to_items_subviews: View_Web[] = []
            for (let i of to_items) {
                let itemType = typeof i.iconType != 'undefined' 
                    ? LinkOrTextSpanButtonCell_ItemType.informativeIcon
                    : typeof i.symbolicIcon_props != 'undefined' 
                        ? LinkOrTextSpanButtonCell_ItemType.symbolicIcon
                        : typeof i.buttonUsage == 'undefined' 
                            ? LinkOrTextSpanButtonCell_ItemType.textSpan 
                            : LinkOrTextSpanButtonCell_ItemType.linkButton
                ;
                let to_subview: _LinkButtonCell_SubItem_Subview|undefined = self.existing_item_subviews_by_pkeys[i._pkey]
                if (to_subview) {
                } else {
                    let view = new _LinkButtonCell_SubItem_Subview({
                        itemType: itemType,
                        i: i,
                        pressed_fn: self.ip.pressed_fn,
                        //
                        themeC: self.ip.themeC,
                        uiContext: self.ip.uiContext,
                        pressFocusBehavior: self.ip.pressFocusBehavior,
                        appearance: i.appearance // if any
                    }).init()
                    if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                        view.setEnabled(false)
                    }
                    //
                    self.existing_item_subviews_by_pkeys[i._pkey] = view
                    to_subview = view
                }
                if (i.style) {
                    let Ks = Object.keys(i.style)
                    for (let K of Ks) {
                        to_subview.layer.style[K] = i.style[K]
                    }
                }
                if (itemType != LinkOrTextSpanButtonCell_ItemType.informativeIcon) {
                    if (typeof i.label !== 'undefined') {
                        if (typeof to_subview.layer.innerText == 'undefined' || !to_subview.layer.innerText || to_subview.layer.innerText != i.label) {
                            if (i.label == null) {
                                to_subview.set_props({ label: "" }) // null -> "" ... not that we'd ever really see a null where a .label is required but...
                            } else {
                                // doesn't matter what the type is, the label is placed on the layer the same
                                to_subview.set_props({ label: i.label })
                            }
                        }
                    }
                }
                if (typeof i.tooltipText != 'undefined') {
                    if (to_subview.tooltipInstance) {
                        // console.log("destroying tooltip first..", i._pkey)
                        to_subview.tooltipInstance.destroy() 
                        to_subview.tooltipInstance = undefined
                    }
                    const tooltip_options = // TODO: possibly move this out
                    {
                        // target: true, // target trigger (`layer`)
                        // tipJoint: 'bottom',

                        // autoOffset: true,

                        // containInViewport: true, // true is the default
                        // //
                        // stemLength: 5,
                        // stemBase: 8,
                        // //
                        // background: self.ip.themeC.current.colors.popover_bg,
                        // //
                        // borderWidth: 0,
                        // borderRadius: 8,
                        // //
                        // shadow: true,
                        // shadowBlur: 48,
                        // shadowOffset: [0, 12],
                        // shadowColor: 'rgba(0,0,0,0.5)',
                        // //
                        // removeElementsOnHide: true // mainly for user idle


                        allowHTML: true,
                        maxWidth: 560,
                        theme: styles_tooltips.ThemeNames.crossroadsDark
                    }
                    to_subview.tooltipInstance = tippy(
                        to_subview.layer, 
                        tooltip_options
                    )
                    // console.log("to_subview.tooltipInstance for ", i._pkey, " is now ", to_subview.tooltipInstance)
                    to_subview.tooltipInstance!.setContent(i.tooltipText)
                } else {
                    if (i.tooltipText === null) {
                        // console.log("destroyed the tooltipInstance for", i._pkey)
                        to_subview.tooltipInstance?.destroy() 
                        to_subview.tooltipInstance = undefined
                    } else {
                        // otherwise preserve the .tooltipInstance
                    }
                }
                //
                to_items_subviews.push(to_subview)
            }
            //
            self.existing_items = to_items
            self.setOrderedSubviews(to_items_subviews)
            //
            //
            // in case you need a non-zero top padding (no longer done in LinkButton.*)
            /// if (to_items_subviews.length) {
            ///     self.layer.style.paddingTop = `${self.pad_top}px`
            /// } else { // visual fixup for empty/null title of label row container
            ///     self.layer.style.paddingTop = "0px"
            /// }
        }
        //
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            let pkeys = Object.keys(self.existing_item_subviews_by_pkeys)
            for (let pkey of pkeys) {
                let v = self.existing_item_subviews_by_pkeys[pkey]
                v.setEnabled(isEnabled)
            }
        }
    }
}