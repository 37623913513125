import AppPersistenceController_Encrypting from "@xrds/xrds-libapp-ts/src/Persistable/AppPersistenceController_Encrypting"
import { doAlertFor_confirmAndDo_deleteEverything, doAlertFor_forgotPassword } from "../Passwords/DeleteEverything_Alerts"
import { themes } from "../themes"
import { LinkButton, LinkButtonUsage } from "./LinkButtonCell"
import { InformativeIcon } from "./InformativeIcon"
import { Icon_Symbolic } from "./Icon_Symbolic"

export namespace SpecificCommon_LinkButtonCells
{
    export function new_linkButtonCell_forgotPassword(
        themeC: themes.Controller, 
        uiContext: themes.ElementContextInUI,
        persistenceController: AppPersistenceController_Encrypting
    ): LinkButton.Cell {
        let weak_pc = new WeakRef(persistenceController)
        let view = new LinkButton.Cell({
            themeC: themeC,
            uiContext: uiContext,
            pressed_fn: () => {
                let optl_pc = weak_pc.deref() // I *think* this is important (not that we expect pc to disappear within the app lifecycle)
                if (!optl_pc) {
                    return
                }
                doAlertFor_forgotPassword(optl_pc)
            }
        }).init()
        view.set_props({ items: [ 
            // no icon
            // intentionally lowercase
            { _pkey: "btn", label: "Forgot Password?", buttonUsage: LinkButtonUsage.default }
        ] })
        //
        return view
    }
    //
    export function new_linkButtonCell_deleteEverything(
        themeC: themes.Controller, 
        uiContext: themes.ElementContextInUI,
        persistenceController: AppPersistenceController_Encrypting,
        appearance_cancelLike: boolean = false
    ): LinkButton.Cell {
        let weak_pc = new WeakRef(persistenceController)
        let view = new LinkButton.Cell({
            themeC: themeC,
            uiContext: uiContext,
            pressed_fn: () => {
                let optl_pc = weak_pc.deref() // I *think* this is important (not that we expect pc to disappear within the app lifecycle)
                if (!optl_pc) {
                    return
                }
                doAlertFor_confirmAndDo_deleteEverything(optl_pc)
            }
        }).init()
        let usage = appearance_cancelLike ? LinkButtonUsage.cancelLike : LinkButtonUsage.destructive
        view.set_props({ items: [
            { _pkey: "icon", symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.trashBin }, buttonUsage: usage }, 
            { 
                _pkey: "btn", 
                label: "DELETE EVERYTHING", 
                buttonUsage: usage
            }
        ] })
        //
        return view
    }

}