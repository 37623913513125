import { themes } from "./themes" // css_rules is only here because that's where themes is ... but it can be moved somewhere along with the future themes_base factor 
//
//
export namespace css_rules
{
    //
    let injectedSS_by_nsName: { [key: injectionNamespaceName]: CSSStyleSheet } = {}
    //
    export type injectionNamespaceName = string
    export type cssRule_type = string
    export type cssRules_generatorFn_type = (optl_themeC: themes.Controller|undefined) => cssRule_type[]
    //
    // TODO: support to remove and purge injected ss by ns name
    //
    export function lazy_injectRuleStrs(
        injectionNamespaceName: injectionNamespaceName,  // TODO: could just pass userNamespaceName instead
        cssRules__orGeneratorFn: cssRule_type[] | cssRules_generatorFn_type,
        themeC__orNilIfNotGeneratorFn: themes.Controller | undefined
    ): boolean {
        let optl_injectedSS = injectedSS_by_nsName[injectionNamespaceName]
        if (!optl_injectedSS && typeof optl_injectedSS == 'undefined') {
            var cssRules: cssRule_type[];
            if (typeof cssRules__orGeneratorFn === 'function') { // TODO: can we introspect/reflect with typescript instead?
                cssRules = cssRules__orGeneratorFn(themeC__orNilIfNotGeneratorFn)
            } else {
                cssRules = cssRules__orGeneratorFn
            }
            //
            let el = document.createElement('style')
            el.appendChild(document.createTextNode("")) // WebKit hack?
            document.head.appendChild(el)
            //
            const reversed_cssRules = cssRules.reverse()
            for (let cssRuleString of reversed_cssRules) {
                try {
                    el.sheet!.insertRule(cssRuleString, 0)
                } catch(e) {
                    console.warn("Unable to insert rule: ", cssRuleString, e)
                    // possibly throw here instead
                }
            }
            injectedSS_by_nsName[injectionNamespaceName] = el.sheet!
            //
            return true
        }
        return false
    }
    //
    export function lazy_injectCSSStringAsStyleEl(rulesPKeyForLazySet: string, rulesStr: string): boolean {
        const key = "hasCSSFileBeenInjected_" + rulesPKeyForLazySet // different prefix from rules for perfect safety
        if (document[key] !== true) {
            document[key] = true
            //
            var head = document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');
            style.type = 'text/css'; // TODO: this is apparently deprecated
            if ((style as any).styleSheet) { // IE8 and below.
                (style as any).styleSheet.cssText = rulesStr
            } else {
                style.appendChild(document.createTextNode(rulesStr));
            }
            head.appendChild(style);
            //
            return true
        }
        return false
    }
    //
    export function lazy_injectRulesFile(stylesheetHref: string): boolean {
        const key = "hasCSSFileBeenInjected_" + stylesheetHref // different prefix from rules for perfect safety
        if (document[key] !== true) {
            var head = document.getElementsByTagName('head')[0]
            var link = document.createElement('link')
            link.id = key
            link.rel = 'stylesheet'
            link.type = 'text/css'
            link.href = stylesheetHref
            link.media = 'all'
            head.appendChild(link)
            //
            document[key] = true
            //
            return true
        }
        return false
    }
    //
    //
    export type stylesheetHref_type = string
    export type stylesheetHrefs_generatorFn_type = (themeC: themes.Controller) => stylesheetHref_type[]
    //
    export function lazy_injectRulesFiles(
        stylesheetHrefs__orGeneratorFn: stylesheetHref_type[] | stylesheetHrefs_generatorFn_type,
        themeC__orNilIfNotGeneratorFn: themes.Controller | undefined
    ) {
        let stylesheetHrefs
        if (typeof stylesheetHrefs__orGeneratorFn === 'function') {
            if (!themeC__orNilIfNotGeneratorFn || typeof themeC__orNilIfNotGeneratorFn == 'undefined') {
                throw new Error("Expected non-nil themeC__orNilIfNotGeneratorFn when typeof stylesheetHrefs__orGeneratorFn == 'function'")
            }
            stylesheetHrefs = stylesheetHrefs__orGeneratorFn(themeC__orNilIfNotGeneratorFn!)
        } else {
            stylesheetHrefs = stylesheetHrefs__orGeneratorFn
        }
        for (let stylesheetHref of stylesheetHrefs) {
            let _ = lazy_injectRulesFile(stylesheetHref)
            // TODO: accumulate _ and flip to one of the polarities (any false or any true) .. didn't need it yet
        }
    }
}
//