import View_Web from "../../View/View.web";
import { styles_theme, themes } from "../themes";
import { Icon_Symbolic } from "./Icon_Symbolic";
import { InformativeIcon } from "./InformativeIcon";
//
//

// possibly useful later
// export namespace CTAButtons
// {
//     export function new_appUtilButton(
//         args: CTAButton.IP
//     ): View_Web {
//         let view = new CTAButton.View(args).init()
//         view.layer.style.display = "flex"
//         view.layer.style.flexDirection = "column"
//         view.layer.style.justifyContent = "center"
//         view.layer.style.padding = "0 19px"
//         view.layer.style.boxSizing = "border-box"
//         view.layer.style.height = "32px"
//         //
//         return view
//     }
// }
//
//
export namespace CTAButton
{
    export enum Variant
    {
        main = "1",
        secondary = "2",
        cancelLike = "3",
        destructive = "4",
        //
        textOnly = "5"
    }
    export enum ArrangementAndGeometry
    {
        default_fullRow = 1,
        inline_fixed_240w = 2,
        inline_max_452w = 3,
    }
    //
    export enum PressFocusBehavior
    {
        stealFocus = 1, // keeping this as the default since it's what browsers do normally and can be nice as a default
        dontStealFocus = 2
    }
}
export namespace CTAButton
{

    export interface IP
    {
        themeC: themes.Controller
        //
        innerHTML?: string

        iconType?: InformativeIcon.IconType|undefined|null 
        symbolicIcon_props?: Icon_Symbolic.Props|undefined|null

        variant?: CTAButton.Variant
        //
        clicked_fn?: (e: any) => void
        pressFocusBehavior?: CTAButton.PressFocusBehavior
        //
        pre_setup?: (_this: CTAButton.View) => void
    }
    export class View extends View_Web
    {
        //
        //
        _cta_ip!: CTAButton.IP
        //
        button!: View_Web
        //
        constructor(ip: IP)
        {
            super({ el_name: "a" })
            const self = this
            self._cta_ip = ip
            if (ip.pre_setup) {
                ip.pre_setup(self)
            }
        }
        setup()
        {
            super.setup()
            //
            const self = this
            let weakSelf = new WeakRef(self)
            let ip = self._cta_ip
            //
            self.layer.setAttribute("role", "link")
            {
                let a__layer = self.layer as HTMLAnchorElement
                a__layer.href = "#"
            }
            self.layer.style.display = "flex"
            self.layer.style.flexDirection = "column"
            self.layer.style.justifyContent = "center"
            //
            let button = new View_Web({ el_name: "a" }).init()
            {
                let a__layer = button.layer as HTMLAnchorElement
                a__layer.href = "#"
            }
            button.layer.style.display = "flex"
            button.layer.style.flexDirection = "row"
            button.layer.style.alignItems = "center"
            button.layer.style.justifyContent = "center"
            //
            self.button = button
            self.addSubview(button)
            //
            // kind of reproducing the 'largectabutton' here:
            if (ip.iconType) {
               // add new Icon subview here and should be good to go - aside from it not being clickable, tho - but that could be added to the Icon view itself
               let v = new InformativeIcon.View({
                   themeC: ip.themeC,
                   type: ip.iconType!
               }).init()
               button.addSubview(v)
           } else if (ip.symbolicIcon_props) {
               let v = new Icon_Symbolic.View().init()
               v.set_props(ip.symbolicIcon_props!)
               button.addSubview(v)
            }
            //
            if (ip.innerHTML) {
                let span = new View_Web({el_name: "span" }).init()
                span.layer.classList.add(styles_theme.ClassNames.ctaButton_textSpan)
                span.layer.innerText = ip.innerHTML
                button.addSubview(span)
                //
                button.layer.style.width = "100%" // this is hacky since it does not co-exist with non-innerHMTL types but it is required for properly rendering the cases where there is a span from ip.innerHTML
            }
            //
            button.layer.classList.add(styles_theme.ClassNames.button)
            button.layer.classList.add(styles_theme.ClassNames.ctaButton)
            {
                button.layer.classList.add(styles_theme.ClassNames.interactive_ctl__control)
                button.layer.classList.add(styles_theme.ClassNames.interactive_ctl__decoration)
                // TODO: possibly just deprecate/remove those ^^
            }
            if (ip.variant && ip.variant != CTAButton.Variant.textOnly) {
                switch (ip.variant) {
                    case CTAButton.Variant.main:
                        button.layer.classList.add(styles_theme.ClassNames.ctaButton_main)
                        break
                    case CTAButton.Variant.cancelLike:
                        button.layer.classList.add(styles_theme.ClassNames.ctaButton_cancelLike)
                        break
                    case CTAButton.Variant.destructive:
                        button.layer.classList.add(styles_theme.ClassNames.ctaButton_destructive)
                        break
                    case CTAButton.Variant.secondary:
                        button.layer.classList.add(styles_theme.ClassNames.ctaButton_secondary)
                        break
                }
            }
            //
            if (self._cta_ip.pressFocusBehavior == PressFocusBehavior.dontStealFocus) {
                self.layer.addEventListener("mousedown", (e) => 
                {
                    e.preventDefault() // PS: I am using 'mousedown' and this preventDefault because I want to prevent focus being lost by another hypothetical input element - such as the input in the message bar when 'Send' clicked
                })
                self.button.layer.addEventListener("mousedown", (e) => 
                    {
                        e.preventDefault() // PS: I am using 'mousedown' and this preventDefault because I want to prevent focus being lost by another hypothetical input element - such as the input in the message bar when 'Send' clicked
                    })
                }
            if (ip.clicked_fn) {
                self.layer.addEventListener('click', (e) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return false
                    }
                    if (optl_self._isEnabled != true) {
                        throw new Error("Not expecting to observe click on a disabled 'a'")
                    }
                    if (optl_self._cta_ip.clicked_fn) { // i guess in case a nefarious consumer un-sets it :P
                        e.preventDefault()
                        e.stopPropagation()
                        optl_self._cta_ip.clicked_fn(e)
                        return false
                    }
                    //
                    return true
                })
            }
        }
    }
}