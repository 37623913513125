
import { BigInteger, monero_amount_format_utils__instance } from '@xrds/monero-utils-ts'
import { Currency } from '@xrds/xrds-libapp-ts/src/Currencies/models/constants'
import { HorizontalStyledRadio } from './StyledRadio_Cell'
import { StyledRadio_Control } from './StyledRadio_Control'
import { themes } from '../themes'
import { TipPicker } from '@xrds/xrds-libapp-ts/src/Preferences/controllers/SettingsController'
//
//
let inlineTipPicker_members__fixedAmts: StyledRadio_Control.MemberDesc[] = 
[
    {
        value: TipPicker.FixedAmounts.m_001,
        explct_title: '0.001'
    },
    {
        value: TipPicker.FixedAmounts.m_01,
        explct_title: '0.01'
    },
    {
        value: TipPicker.FixedAmounts.m_1,
        explct_title: '0.1'
    },
    {
        value: TipPicker.FixedAmounts.m1,
        explct_title: '1'
    },
    {
        value: TipPicker.FixedAmounts.none,
        explct_title: 'None'
    }
]
let inlineTipPicker_members__pcts: StyledRadio_Control.MemberDesc[] = 
[
    {
        value: TipPicker.Pcts.p_1,
        explct_title: '1'
    },
    {
        value: TipPicker.Pcts.p_5,
        explct_title: '5'
    },
    {
        value: TipPicker.Pcts.p_10,
        explct_title: '10'
    },
    {
        value: TipPicker.Pcts.p_15,
        explct_title: '15'
    },
    {
        value: TipPicker.Pcts.none,
        explct_title: 'None'
    }
]
//
export let cached_BigInteger_oneHundredPctInAtomicUnits = monero_amount_format_utils__instance.parseMoney('100') // TODO: cache this
//
// TODO: make a cache of the values to actual BigIntegers parsed amts
//

export namespace Tipping {
    export function convenience_NONSWEEPING_calcd_tipAmt(
        initial_subtotalToSpend: BigInteger,
        parsed__selectedValue: BigInteger, 
        tipPicker_isToggled_pctsNotAmts: boolean
    ): BigInteger {
        let amt_BigInt: BigInteger
        if (tipPicker_isToggled_pctsNotAmts == true) {
            amt_BigInt = initial_subtotalToSpend.multiply(parsed__selectedValue).divide(cached_BigInteger_oneHundredPctInAtomicUnits)
        } else { // fixed amt
            amt_BigInt = parsed__selectedValue
        }

        return amt_BigInt
    }
}
//
export namespace InlineTipPicker
{
    export function new_selectedValue_asBigInteger_orUndefForNone(
        tipPicker__selectedValue: string
    ): BigInteger|undefined {
        if (tipPicker__selectedValue == '-1') { // this is the 'none' value; I translate -1 to undef so that the control component can still use undef internally
            return undefined
        }
        let final_parseable_selectedValue = tipPicker__selectedValue
        if (tipPicker__selectedValue == '' 
            || tipPicker__selectedValue == '0' 
            || !tipPicker__selectedValue/*null and empty str technically and possibly '0'*/
        ) {
            final_parseable_selectedValue = '0' // just normalizing them to '0'
        }
        let parsed__selectedValue = monero_amount_format_utils__instance.parseMoney(final_parseable_selectedValue)

        return parsed__selectedValue
    }
    //
    export function _ccyValuePicker_members__for(isToggled_pctsNotAmts: boolean): StyledRadio_Control.MemberDesc[]
    {
        return isToggled_pctsNotAmts ? inlineTipPicker_members__pcts : inlineTipPicker_members__fixedAmts
    }

    export interface Cell_IP 
    {
        themeC: themes.Controller
        control_title: string // TODO: perhaps move this to a set_props_..
        control_tooltipText: string|null
        //
        yieldNewPropsForState_fn: (props: {
        }) => void
    }
    let value__pct = "%"

    export class Cell extends HorizontalStyledRadio.Cell
    {
        itpc_ip!: Cell_IP
        //
        _ccyIfNotPct: Currency = Currency.XMR
        //
        isToggled_pctsNotAmts(): boolean { return this._labelRow.accessoryRadio!.selectedValue()!/*NOTE: this unwrap was placed here naively - it may cause a crash*/ == value__pct }
        //
        constructor(ip: Cell_IP)
        {
            super({
                themeC: ip.themeC,
            })
            const self = this
            self.itpc_ip = ip
        }
        setup(): void
        {
            super.setup()
            const self = this
            let weakSelf = new WeakRef(self)
            //
            self.set_props_labelRow({
                title: self.itpc_ip.control_title, // TODO: add tooltip to the title 
                postTitleButtonText: undefined,
                tooltipText: self.itpc_ip.control_tooltipText
            })
            //
            self.control.on(StyledRadio_Control.Control_EventName.memberDifftThanSelectedValueTapped, (v) =>
            { // rumor is that we dont have to remove this listener if no other references to it exist
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                optl_self.itpc_ip.yieldNewPropsForState_fn({})
            })
        }
        //
        // Imperatives
        set_props__tip(props: {
            ccyIfNotPct: Currency|undefined, // Currency.XMR; undefined means no change
            //
            selectedValue: StyledRadio_Control.ButtonValue|undefined,
            //
            isToggled_pctsNotAmts: boolean|undefined // this is intentionally always required ... for simplicity, right now, anyway
        }) {
            const self = this
            if (typeof props.ccyIfNotPct !== 'undefined') {
                self._ccyIfNotPct = props.ccyIfNotPct
            }
            let use__isToggled_pctsNotAmts = typeof props.isToggled_pctsNotAmts !== 'undefined' 
                ? props.isToggled_pctsNotAmts 
                : self.isToggled_pctsNotAmts() 
            ;
            let was__selectedValue = self.selectedValue_primary()
            //
            super.set_props__primaryRadio({
                members: _ccyValuePicker_members__for(use__isToggled_pctsNotAmts),
                selectedValue: props.selectedValue, // NOTE: passing undefined if this is undefined rather than the non-nil value so that the control knows it doesn't have to be changed
                rightAdjacent_title: null, // this is now displayed directly adjecently by the rightSide accessory radio picker
            })
            //
            let accessoryRadio_selectedValue = use__isToggled_pctsNotAmts == true 
                ? value__pct 
                : props.ccyIfNotPct
            ;
            self._labelRow.set_props({
                accessoryRadio: { // this will construct the accessoryRadio if it is needed. just make sure to pass props.ccyIfNotPct at least once and initially
                    leftAdjacent_title: null,
                    //
                    members: typeof props.ccyIfNotPct !== 'undefined' 
                        ? [
                            {
                                value: props.ccyIfNotPct,
                                explct_title: props.ccyIfNotPct // TODO: this is not actually the human readable value ... wrap in transform accessor
                            },
                            {
                                value: value__pct,
                                explct_title: "%"
                            }
                        ]
                        : undefined,
                    selectedValue: accessoryRadio_selectedValue,
                    //
                    rightAdjacent_title: undefined
                }
            })
            //
            let current_selectedValue_primary = self.selectedValue_primary()
            if (!was__selectedValue || !current_selectedValue_primary || was__selectedValue != current_selectedValue_primary) { 
                self.itpc_ip.yieldNewPropsForState_fn({
                })
            }
        }
        //
        // Delegation
        override _accessoryRadio_changed(selectedValue: any): void 
        { 
            super._accessoryRadio_changed(selectedValue)
            const self = this
            //
            let to__isToggled_pctsNotAmts = selectedValue == value__pct
            //
            let members = _ccyValuePicker_members__for(!to__isToggled_pctsNotAmts) // would be more rigorous to access 'prior' state but since there are only two states here we can assume it is the inverse of the new state: props.isToggled_pctsNotAmts
            
            let selectedValue_primary = self.selectedValue_primary()
            let selectedValue_idx = members.findIndex(m => m.value == selectedValue_primary)
            // ^ This, at least, preserves the selected index, rather than going to (illegal) zero selection; 
            // Visually, same index is consistent, but in future, if list member count btwn pcts and amts ever 
            // differs, possibly at least map .e.g 'none' to the new 'none'
            //
            if (selectedValue_idx == -1) {
                throw new Error("Expected selectedValue_idx != -1")
            }
            let to_members = _ccyValuePicker_members__for(to__isToggled_pctsNotAmts/*after update!*/)
            let to__selectedValue = to_members[selectedValue_idx].value
            self.set_props__tip({
                selectedValue: to__selectedValue,
                isToggled_pctsNotAmts: to__isToggled_pctsNotAmts,
                ccyIfNotPct: undefined // no change
            })
        }
    }
}