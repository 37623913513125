//
//
import { themes } from "../themes"
import { AbstractControl } from "./AbstractTitledControlCell"
import { TitledTextInputCell, TitledTextInputCell_InitParams } from "./TitledTextInputCell"
import { TextInput_InputType } from "./_TextInput"
//
export interface CustomStartScanHeightInputCell_InitParams
{
    themeC: themes.Controller
}
//
export default class CustomStartScanHeightInputCell extends TitledTextInputCell
{
    constructor(ip: CustomStartScanHeightInputCell_InitParams)
    {
        super({ 
            ...ip,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            placeholder: "e.g. 3000000",
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio,
            inputType: TextInput_InputType.number_uint, // allow no decimal
            // maxWholeDigits: // not gonna attempt this.. yet
            includeAccessory_checkbox: true,
            checkboxControlsInputHiding: true,
            checkbox__checked: true,
            accessory_checkbox_leftSideTitle: null,
            accessory_checkbox_valLabel_false: 'Off',
            accessory_checkbox_valLabel_true: 'On'
        })
        const self = this
    }
    setup()
    {
        const self = this
        super.setup()
        self.set_props({
            textInput: {
                placeholder: undefined,
                title: undefined,
                tooltipText: undefined,
                postTitleButtonText: undefined,
                textInput_rightSide_linkButton_items: undefined,
                //
                inputText: "3000000"
            }
        })
        self.set_props_labelRow({
            title: "Scan Start Point (XMR Block No.)", 
            postTitleButtonText: undefined,
            tooltipText: "To greatly speed up the initial scan, set your wallet's birthday here. If you have already logged into this scanner server, it will already have stored this value and so it is only required the first time you log in."
        })
    }
    //
    // TODO: input validation etc
}
