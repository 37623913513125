//
//
import AppPersistenceController_Base, { AppPersistenceController_Base_InitParams, Persistence_EventName } from "./AppPersistenceController_Base";
import { ChangePasswordRegistrant, PasswordController, Password_EventName, PasswordMeta_collectionName } from '../Passwords/controllers/PasswordController'
//
import { New_DecryptedString, New_ArmoredEncryptedString } from '../symmetric_cryptor/cryptor.openpgp'
import { CollectionName, DocumentId } from "./Documents";
import { UserIdleController, UserIdle_EventName } from "../UserIdle/controllers/UserIdleController";
//
export interface AppPersistenceController_Encrypting_InitParams extends AppPersistenceController_Base_InitParams
{
}
//
let BYPASS_CONSOLE_CLEAR_ON_IDLE_LOCKOUT = false // TODO: set this to true if we're in dev mode - how is that looked up in the app?
//
export enum Persistence_Encrypting_EventName
{
    DidLoadAppDataAndDecryptWithPassword = "DidLoadAppDataAndDecryptWithPassword",
    //
    //
    WillDeconstructBootedStateAndClearPassword = "WillDeconstructBootedStateAndClearPassword",
    DidDeconstructBootedStateAndClearPassword = "DidDeconstructBootedStateAndClearPassword",
}
export interface WillDeconstructBootedStateAndClearPassword_Params
{
    isForADeleteEverything: boolean
}
export interface DidDeconstructBootedStateAndClearPassword_Params
{
    isForADeleteEverything: boolean
}
//
const checkFor_enc_str_armor_preamble = "-----BEGIN"
//
//
export default class AppPersistenceController_Encrypting extends AppPersistenceController_Base
{
    declare initParams: AppPersistenceController_Encrypting_InitParams
    passwordController!: PasswordController
    userIdleController!: UserIdleController
    //
    _unlockedWithCorrectExistingPassword_listenerFn?: () => void
    registrantForChangePassword_token?: string
    //
    decrypted_appDataJSON?: { [key: string]: { [_id: string]: string } }
    //
    constructor(initParams: AppPersistenceController_Base_InitParams)
    {
        super(initParams) // will retain initParams
        const self = this
        //
        // consumers: call setPasswordController(), postSetup__appDataJSON_initFromFile() when ready before any other imperative/acccessor calls
    }
    public setControllers(
        userIdleController: UserIdleController, passwordController: PasswordController
    ) {
        const self = this
        self.userIdleController = userIdleController
        self.passwordController = passwordController
        //
        self._startObserving_userIdleController()
    }
    _startObserving_userIdleController()
    {
        const self = this
        const controller = self.userIdleController
        let weakSelf = new WeakRef(self)
        controller.on(
            UserIdle_EventName.didBecomeIdle,
            async () => {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
            /*await */optl_self._application_didBecomeIdle()
            }
        )
    }
    public async postSetup__appDataJSON_initFromFile()
    {
        await super.postSetup__appDataJSON_initFromFile()
        //
        const self = this
        if (self.hasFailedFileLoadWithErrStr != null && typeof self.hasFailedFileLoadWithErrStr !== 'undefined') {
            self.decrypted_appDataJSON = undefined // flash in case this is not the first load attempt
            return // nothing to attempt to decrypt
        }
        // not directly triggering the password controller here to get pw, to allow this function to return,
        // thus to allow the PasswordEntry_Presentation component in the UI to actually load and start observing the PasswordController
        // but so as not to introduce a janky/fragile setTimeout, a method for that is exposed on PasswordController
        if (self.passwordController.password) {
            await self._givenPassword_decrypt_loadedData()
        }
        { // start observing subsequent unlocks and changes to password - if necessary (to support re-entry)
            if (!self._unlockedWithCorrectExistingPassword_listenerFn) {
                let weakSelf = new WeakRef(self)
                self._unlockedWithCorrectExistingPassword_listenerFn = async () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    await optl_self._givenPassword_decrypt_loadedData()
                }
                self.passwordController.on(Password_EventName.UnlockedWithCorrectExistingPassword, self._unlockedWithCorrectExistingPassword_listenerFn)
            }
            if (self.registrantForChangePassword_token == null) {
                self.registrantForChangePassword_token = self.passwordController.AddRegistrantForChangePassword(self as ChangePasswordRegistrant)
            }
        }
    }
    async _givenPassword_decrypt_loadedData()
    {
        const self = this
        if (!self.passwordController.HasUserEnteredValidPasswordYet()) {
            throw new Error("Expected password in _givenPassword_decrypt_loadedData")
        }
        if (!self.decrypted_appDataJSON) {
            self.decrypted_appDataJSON = {}
        }
        // traverse appJSONData and check - if it's not JSON and if it starts with the armor characters, decrypt it and save it back to decrypted
        let c_names = Object.keys(self.appDataJSON)
        for (var i = 0 ; i < c_names.length ; i++) {
            let c_name = c_names[i]
            if (!self.decrypted_appDataJSON![c_name]) {
                self.decrypted_appDataJSON![c_name] = {}
            }
            // rather than skipping PasswordMeta_collectionName, we'll just keep it in as if it's decrypted - for completeness
            // if (c_name == PasswordMeta_collectionName) { 
            //     continue // we know we dont need to look at these
            // }
            let valStrs_by__id = self.appDataJSON[c_name]
            let ids = Object.keys(valStrs_by__id)
            for (var j = 0 ; j < ids.length ; j++) {
                let _id = ids[j]
                let valStr = valStrs_by__id[_id]
                let plainString: string|null = null
                if (valStr.indexOf("{") == 0) {
                    // this is not an encrypted message
                    // console.log("valStr", valStr);
                    // throw new Error("Not expecting any non-PasswordMeta collection valStrs to not be encrypted strings")
                    // ^- this is commented per note about PasswordMeta collection above
                    plainString = valStr // doing this instead for change pw impl
                } else {
                    if (valStr.indexOf(checkFor_enc_str_armor_preamble) != 0) { 
                        throw new Error("Non-JSON, non-armor-prefixed string. Unhandled.")
                    }
                    let r = await New_DecryptedString(valStr, self.passwordController.password!)
                    if (r.err_str) {
                        throw new Error("Unexpected decrypt error: " + r.err_str!)
                    }
                    plainString = r.plainString!
                }
                self.decrypted_appDataJSON![c_name][_id] = plainString!
            }
        }
        //
        // on success
        self.emit(Persistence_Encrypting_EventName.DidLoadAppDataAndDecryptWithPassword)
    }
    //
    // Accessors
    public givenStoreLoaded_hasRecordsToDecryptInCollection(collectionName: string): boolean
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        //
        return Object.keys(self.appDataJSON[collectionName]||{}).length > 0
    }
    public Decrypted_AllValStringsFor(collectionName: CollectionName): { vals: string[] }
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        if (!self.decrypted_appDataJSON) {
            throw new Error("PersistenceController has not generated decrypted_appDataJSON - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.decrypted_appDataJSON[collectionName]
        if (!c) {
            return { vals: [] }
        }
        return { vals: Object.values(c!) }
    }
    public Decrypted_ValStringFor(collectionName: CollectionName, _id: DocumentId): { valStr?: string }
    {
        const self = this
        if (!self.didStoreLoadCallOccur) {
            throw new Error("PersistenceController not booted - call postSetup__appDataJSON_initFromFile")
        }
        if (!self.decrypted_appDataJSON) {
            throw new Error("PersistenceController has not generated decrypted_appDataJSON - call postSetup__appDataJSON_initFromFile")
        }
        let c = self.decrypted_appDataJSON[collectionName]
        if (!c) {
            return { valStr: undefined }
        }
        return { valStr: c[_id] }
    }
    //
    // encrypting each doc independently and hanging onto encrypted docs in memory to optimize write/save 
    public async NotDuringChangePW_EncryptingStoreInterface_WriteStringFor(  // called something different from super.WriteStringFor() 
        c_name: CollectionName, 
        _id: DocumentId, 
        valStr: string,
        shouldEncrypt: boolean = true
    ): Promise<{ err_str?: string }> {
        const self = this
        let writable_valStr: string;
        if (!shouldEncrypt) { // ordinarily the interface would be designed to have the consumer just call WriteStringFor() but in this case we need to do other operations
            writable_valStr = valStr
        } else {
            if (!self.passwordController.HasUserEnteredValidPasswordYet()) {
                return { err_str: "Password unavailable to write an update" }
            }
            let r = await New_ArmoredEncryptedString(valStr, self.passwordController.password!)
            if (r.err_str) {
                return { err_str: r.err_str! }
            }
            writable_valStr = r.encryptedString!
        }
        let write_r = await super.WriteStringFor(c_name, _id, writable_valStr!)
        if (write_r.err_str) {
            return write_r // return without setting writeable value to self.decrypted_appDataJSON
        }
        { // ensure that the (usually plaintext, serialized JSON) valStr is saved back in the decrypted_appDataJSON so that change password is implementable
            if (!self.decrypted_appDataJSON) {
                self.decrypted_appDataJSON = {}
            }
            if (!self.decrypted_appDataJSON![c_name]) {
                self.decrypted_appDataJSON![c_name] = {}
            }
            self.decrypted_appDataJSON![c_name][_id] = valStr 
        }
        //
        return {}
    }
    public async ForChangePassword_WriteNewPasswordDoc_andReEncryptOtherEncrypted_andSave(
        updatedPasswordDoc_valStr_notForEncrypting: string,
        updatedPasswordDoc_id: DocumentId,
        updatedPassword: string
    ): Promise<{ 
        err_str?: string
    }> {
        const self = this
        if (!self.passwordController.HasUserEnteredValidPasswordYet()) {
            throw new Error("Expected password in WriteNewPasswordDoc_andReEncryptOtherWithPassword_andSave")
        }
        if (!self.decrypted_appDataJSON) {
            throw new Error("Expected non-nil self.decrypted_appDataJSON for a change pw")
        }
        { // initial validations around the PasswordMeta collection
            let pw_collection_docs_by_id = self.decrypted_appDataJSON[PasswordMeta_collectionName]
            if (!pw_collection_docs_by_id || Object.keys(pw_collection_docs_by_id).length == 0) {
                throw new Error("Expected non-zero docs to exist in the PasswordMeta collection on a changePassword")
            }
            let pw_c_doc_ids = Object.keys(pw_collection_docs_by_id)
            if (pw_c_doc_ids.length != 1) {
                throw new Error("Expected only one PasswordMeta collection doc")
            }
            let only_pw_doc_id = pw_c_doc_ids[0]
            if (only_pw_doc_id !== updatedPasswordDoc_id) {
                throw new Error("Expected updatedPasswordDoc_id to be equal to only_pw_doc_id")
            }
        }
        //
        // waiting to save until the very end
        //
        // and reconstructing the decrypted_appDataJSON and appDataJSON before setting them to the real values so that an easy atomic revert on a fail can be done
        let next__appDataJSON: { [key: string]: { [_id: string]: string } } = {}
        // let next__decrypted_appDataJSON: { [key: string]: { [_id: string]: string } } = {}
        {
            next__appDataJSON![PasswordMeta_collectionName] = {}
            next__appDataJSON![PasswordMeta_collectionName][updatedPasswordDoc_id] = updatedPasswordDoc_valStr_notForEncrypting
            //
            // this is done directly on self.decrypted_appDataJSON[PasswordMeta_collectionName] after a successful save
            // next__decrypted_appDataJSON![PasswordMeta_collectionName] = {}
            // next__decrypted_appDataJSON![PasswordMeta_collectionName][updatedPasswordDoc_id] = updatedPasswordDoc_valStr_notForEncrypting
        }
        //
        // now re-encrypt remainder of the docs
        // - traverse appDataJSON and check if doc str starts with the armor characters,
        // - re-encrypt to corresponding record in decrypted_appDataJSON and write it back to encrypted,
        // then save
        //
        let c_names = Object.keys(self.appDataJSON)
        for (var i = 0 ; i < c_names.length ; i++) {
            let c_name = c_names[i]
            if (c_name == PasswordMeta_collectionName) {
                continue // we know we dont need to look at these - we wrote the updatedPasswordDoc earlier
            }
            let encrypted__valStrs_by__id = self.appDataJSON[c_name]
            let decrypted__valStrs_by__id = self.decrypted_appDataJSON[c_name]
            if (!decrypted__valStrs_by__id) {
                throw new Error("Expected decrypted__valStrs_by__id where there were encrypted__valStrs_by__id during a ChangePassword")
            }
            { // prep for reconstruct
                next__appDataJSON[c_name] = {}
                // next__decrypted_appDataJSON[c_name] = {}
            }
            let ids = Object.keys(encrypted__valStrs_by__id)
            let decrypted__ids = Object.keys(decrypted__valStrs_by__id)
            if (ids.length != decrypted__ids.length) {
                throw new Error("Expected encrypted and decrypted ids count to be the same")
            }
            for (var j = 0 ; j < ids.length ; j++) {
                let _id = ids[j]
                let encrypted__valStr = encrypted__valStrs_by__id[_id]
                if (encrypted__valStr.indexOf("{") == 0) {
                    // this is not an encrypted message
                    throw new Error("Not expecting any non-PasswordMeta collection valStrs to not be encrypted strings") // we skip PasswordMeta_collectionName above as it's handled manually, and is also currently the only collection holding non-encrypted docs
                }
                if (encrypted__valStr.indexOf(checkFor_enc_str_armor_preamble) != 0) { 
                    throw new Error("Non-JSON, non-armor-prefixed string. Unhandled.")
                }
                let decrypted_valStr = decrypted__valStrs_by__id[_id]
                if (typeof decrypted_valStr == 'undefined' || !decrypted_valStr) {
                    throw new Error("Expected non-nil decrypted_valStr")
                }
                //
                let r = await New_ArmoredEncryptedString(decrypted_valStr, updatedPassword)
                if (r.err_str) {
                    console.log("Failure to re-encrypt a doc during change pw - returning early here is effectively an atomic revert.")
                    return { err_str: r.err_str! }
                }
                //
                next__appDataJSON[c_name][_id] = r.encryptedString!
                // next__decrypted_appDataJSON[c_name][_id] = decrypted_valStr // we do not really need to reconstruct the next__decrypted_appDataJSON in its entirety -- the only thing that really neds to be set in there is the PW doc, at present
            }
        }
        let save__r = await self._saveToDisk_specific_appDataJSON( // sending the next__appDataJSON for save specifically so that the 'next' value is known saved
            next__appDataJSON
        )
        if (save__r.err_str) {
            console.log("This was a failure to save the changed pw - returning early here is effectively an atomic revert.")
            return save__r
        }
        console.log("Successfully saved changed password")
        //
        // Now since save was a success, write the updated values back to the actual in-memory properties 
        self.decrypted_appDataJSON![PasswordMeta_collectionName][updatedPasswordDoc_id] = updatedPasswordDoc_valStr_notForEncrypting
        self.appDataJSON = next__appDataJSON
        //
        return {}
    }
    //
    // Delegation - Overrides
    async _overridable_will_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify: boolean = false) 
    {
        await super._overridable_will_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify)
        const self = this
        // WillDeconstructBootedStateAndClearPassword gets emitted by _internal_clearMemoryPropertiesAndPassword
        //
        await self._internal_clearMemoryPropertiesAndPassword(true, andSilentlySkipNotify)
    }
    private async _internal_clearMemoryPropertiesAndPassword(isForADeleteEverything: boolean = false, andSilentlySkipNotify: boolean = false)
    {
        const self = this
        //
        if (andSilentlySkipNotify != true) {
            await self.emit(Persistence_Encrypting_EventName.WillDeconstructBootedStateAndClearPassword, {
                isForADeleteEverything: isForADeleteEverything
            })
        }
        //
        self.decrypted_appDataJSON = undefined
        //
        await self.passwordController.DeconstructBootedStateAndClearPasswordPendingReboot(andSilentlySkipNotify)
        //
        if (andSilentlySkipNotify != true) {
            await self.emit(Persistence_Encrypting_EventName.DidDeconstructBootedStateAndClearPassword, {
                isForADeleteEverything: isForADeleteEverything
            })
        }
    }
    async _overridable_did_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify: boolean = false)
    {
        const self = this
        //
        await super._overridable_did_clearMemoryPropertiesForDeleteEverything(andSilentlySkipNotify)
        // 
        // Persistence_Encrypting_EventName.DidDeconstructBootedStateAndClearPassword gets emitted by _internal_clearMemoryPropertiesAndPassword so that it's always covered
    }
	//
	// Delegation - PasswordController registrant protocols
    async passwordController_ChangePassword(password: string): Promise<{ err_str?: string | undefined }>
    {
		const self = this
        const ret = await self._saveToDisk()
        if (ret.err_str) {
            return { err_str: ret.err_str! }
        }
        return { }
	}
    //
    // Delegation - User idle
    async _application_didBecomeIdle()
    {
        const self = this
        if (self.passwordController.GivenBooted_HasUserSavedAPassword() !== true) {
            // nothing to do here because the app is not unlocked and/or has no data which would be locked
            // console.log("User became idle but no password has ever been entered/no saved data should exist.")
            return
        } else if (self.passwordController.HasUserEnteredValidPasswordYet() !== true) {
            // user has saved data but hasn't unlocked the app yet
            // console.log("User became idle and saved data/pw exists, but user hasn't unlocked app yet.")
            return
        }
        await self._internal_clearMemoryPropertiesAndPassword(false, false/* DO notify */)
        //
        // PS: Not the best place to put this but I'm placing the following in this function in order to wait til the above is finished so all logs are done logging
        
        
        if (!BYPASS_CONSOLE_CLEAR_ON_IDLE_LOCKOUT) {
            console.clear() // for security purposes
            //
            // TODO: how can we clear the console's history and network activity? it hangs onto a lot of stuff!
            if (window.history && (window.history as any).deleteAll) { // some chrome plugin? ... https://stackoverflow.com/a/20044638
                (window.history as any).deleteAll()
            }
        }
    }
}