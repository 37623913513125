//
//
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { NavigationBarView } from './NavigationBarView'
//
export interface NavigableView extends View_Web
{
    navigationBar_title(): string|undefined
    navigationBar_hidden(): boolean
    navigationBar_isEnabled_backButton(): boolean
    navigationBar_isEnabled_leftSideAccessory(): boolean
    navigableView_willGoBackFrom()
    //
    navigableView_leftSideAccessoryView(): View_Web|null // return null for no button (default) or your own custom left side e.g. button; overwise the back button will be shown if necessary
    navigableView_rightSideAccessoryView(): View_Web|null // return null for no button (default) or your own custom right side e.g. button
    // ^ TODO: possibly make these functions optl themselves i.e. navigableView_…?(): View_Web|null and treat nil fn as null retval
    //
    stackNavigationView?: StackNavigationView // if the view has been pushed onto the stack of a NavigationView then it will be set here; NOTE: 
    //
    record_reconstitutable_scrollTop_forNavigatorPopOrRemoval()
    actually_reconstitute_reconstitutable_scrollTop()
    reconstitutable_scrollTop?: number
}
export interface ModalView extends View_Web
{
    modalParentView?: StackNavigationView // if the view has been presented as a modal, this will be set to the parent in which the view is presented
}
//
export interface StackNavigationView_InitParams
{
    themeC: themes.Controller
}
//
export default class StackNavigationView extends View_Web implements ModalView
{
    _stackViews: NavigableView[] = []
    _modalViews: ModalView[] = []
    //
    stackContainerView!: View_Web
    navigationBar!: NavigationBarView
    //
    stacknav_ip!: StackNavigationView_InitParams
    //
    // ModalView
    modalParentView?: StackNavigationView // if self has been presented as a modal, this will be set to the parent in which the view is presented
    //
    //
    constructor(stacknav_ip: StackNavigationView_InitParams)
    {
        // let topPxs = 10
/////////// TODO: for now
        super({ el_name: "div" }) // for now - since we 're just setting the content - but maybe we should do this in a more efficient manner by optimizing only to draw what's on the screen at the time
        //
        const self = this
        self.stacknav_ip = stacknav_ip
    }
    setup(): void
    {
        super.setup()
        const self = this
        {
            const view = self
            view.layer.style.left = "0"
            view.layer.style.top = "0"
            view.layer.style.width = "100%"
            view.layer.style.height = "100%"
        }
        self.setup_views()
    }
    public teardown(): void
    {
        super.teardown()
        const self = this
        self.stackContainerView.release() // allow stackContainerView to be torn down at last
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        {
            let view = new View_Web({}).init()
            view.layer.style.left = "0"
            view.layer.style.top = "0"
            view.layer.style.width = "100%"
            view.layer.style.height = "100%"
            view.layer.id = "stack-container-view"
            self.stackContainerView = view
            view.retain() // retaining this because we do not want it to be torn down when we first remove it from the superview - however the consequence of this is that in .teardown() of self, we have to call release on the self.stackContainerView
            //
            self.addSubview(view)
        }
        {
            let view = new NavigationBarView({
                themeC: self.stacknav_ip.themeC,
                //
                back__tapped_fn: () => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    let existingTop = optl_self.existingTop_stack()
                    if (!existingTop) {
                        throw new Error("Back button unexpectedly pressable while no top view exists")
                    }
                    optl_self.pop()
                }
            }).init()
            self.navigationBar = view
            self.stackContainerView.addSubview(view)
        }
    }
    //
    // Accessors - Stack Views
    existingTop_stack(): NavigableView | null
    {
        const self = this
        //
        return self._stackViews.length > 0 ? self._stackViews[self._stackViews.length - 1] : null
    }
    existingTop_modal(): ModalView | null
    {
        const self = this
        //
        return self._modalViews.length > 0 ? self._modalViews[self._modalViews.length - 1] : null
    }
    //
    // Imperatives - Stack 
    public push(view: NavigableView)
    {
        const self = this
        for (let sv of self._stackViews) { // critical
            if (sv.isEqualToView(view)) {
                throw new Error("Asked to .push but that view already exists in the stack.")
            }
            /////// TODO: in this membership check... for extra safety... we should also check that view !in _modalViews
        }
        let optl_preexistingTop_stack = self.existingTop_stack()
        view.stackNavigationView = self // hook up references
        self._stackViews.push(view)
        view.retain() // since the .retain within adding it to the view hiearchy might not remain if another view is pushed on top and the stack view is removed from the hierarchy
        self._configure_with_nextTop_stack__noRender(view)
        if (optl_preexistingTop_stack) {
            optl_preexistingTop_stack.record_reconstitutable_scrollTop_forNavigatorPopOrRemoval()
            optl_preexistingTop_stack.removeFromSuperview() // reasoning behind this is to save on memory
            // without the above call, we'd want to manually call .viewWillDisappear etc
            // and note... View != UIViewController .. UIViewControllers are not in the view hierarchy, and UIViews do not really have .viewWillDisappear etc .. so there is a conflation with .addSubview/.removeFromSuperview and view visibility - which I did for convenience and ease ...
            // and we do not want to call .teardown() on that view
        }
    }
    public pop() // pops the top stack view
    {
        const self = this
        if (self._stackViews.length == 0) {
            throw new Error("Expected non-zero views on stack in order to pop")
        }
        let existingTop = self.existingTop_stack()!
        let navigable_stackView = existingTop as NavigableView
        let navigationBar_isEnabled_backButton = navigable_stackView.navigationBar_isEnabled_backButton()
        if (navigationBar_isEnabled_backButton != true) {
            console.log("Bailing on back button press since view elected for it to be disabled")
// TODO: actually disable the button element (reliably) and also support e.g. the top view being popped and the back btn returning to the correct enabled state
            return
        }
        //
        navigable_stackView.navigableView_willGoBackFrom() // TODO: is this a sufficient location to do this from? probably not..
        //
        let optl_nextTop_stack = self._stackViews.length - 1 > 0 ? self._stackViews[self._stackViews.length - 2] : null
        let popped_stackView = self._stackViews.pop()
        if (optl_nextTop_stack) {
            self._configure_with_nextTop_stack__noRender(optl_nextTop_stack)
            optl_nextTop_stack.actually_reconstitute_reconstitutable_scrollTop()
        }
        popped_stackView!.removeFromSuperview()
        popped_stackView!.stackNavigationView = undefined // clear ref - if only to reduce risk of strong ref cycle - or if nothing else clear stale refs
        popped_stackView!.release()
    }
    private _configure_with_nextTop_stack__noRender(nextTop: NavigableView)
    {
        const self = this
        //
        self.stackContainerView.addSubview(nextTop)
        /// PS: This could cause bugs if the nextTop is already added but luckily View_Base checks if a superlayer already exists at least and we also have pre-existing membership checks on .push, .reset etc
        //
        self._configure_navigationBarWith(nextTop)
        //
        self.layoutSubviews(true) // so that layout happens - or we could just do layout here
    }
    //
    public reset(to_stackViews: NavigableView[], andRemoveAllModals: boolean = true)
    { // this only resets stack nav views - TODO: include modals (and when done ensure addtn of critical membership check)
        const self = this
        for (let prior_v of self._stackViews) { // cirtical
            for (let to_v of to_stackViews) {
                if (to_v.isEqualToView(prior_v)) {
                    throw new Error(".reset may only be called with views that do not already exist in the stack (mostly due to JS reference tracking limitations on managing .teardown calls)")
                }
            }
            /////// TODO: in all these membership checks... for extra safety... we should also check that to_stackViews !in _modalViews
        }
        let optl_existingTop_stack = self.existingTop_stack()
        let prior_stackViews = self._stackViews
        for (let v of to_stackViews) {
            v.retain() // since we're about to use them
            v.stackNavigationView = self // hook up references so the view knows where it's been pushed
        }
        self._stackViews = to_stackViews // flash existing
        let nextTop = self._stackViews.length > 0 ? self._stackViews[self._stackViews.length - 1] : null
        if (nextTop) {
            self._configure_with_nextTop_stack__noRender(nextTop)
        }
        if (optl_existingTop_stack) {
            if (nextTop && optl_existingTop_stack.isEqualToView(nextTop)) { //// NOTE This actually should never be the case)
                throw new Error("Detected no nextTop! == optl_existingTop_stack")
            }
            optl_existingTop_stack!.removeFromSuperview()
            // it will already have teardown called in the just-below .forEach; perhaps we could assert prior_stackViews has optl_existingTop_stack
        }
        for (let v of prior_stackViews) {
            v.stackNavigationView = undefined // eliminate chance of strong ref cycle - or if nothing else clear stale refs
            v.release() // since we removed all existing references
        }
        if (andRemoveAllModals != false) { // undefined (not that it could be) -> true
            if (self._modalViews.length > 0) {
                let existingTop_modal = self.existingTop_modal()!
                if (!existingTop_modal.superlayer || typeof existingTop_modal.superlayer === 'undefined') {
                    throw new Error("Expected non nil existingTop_modal.superlayer in .reset() with true andRemoveAllModals and non-zero self._modalViews")
                }
                existingTop_modal.removeFromSuperview()
                for (let v of self._modalViews) {
                    v.modalParentView = undefined // eliminate the chance of a strong ref cycle - or if nothing else clear stale refs
                    v.release() // since we're about to zero the list
                }
                self._modalViews = [] // zero
            }
        }
        if (self._modalViews.length == 0) { // if the modals were removed OR if there are no modals left
            if (!self.stackContainerView.superlayer || typeof self.stackContainerView.superlayer == 'undefined') { // and if by some prior path like a modal having been presented, the self.stackContainerView was removed from the hierarchy, then we must add it back
                self.addSubview(self.stackContainerView)
                self.layoutSubviews(true) // just in case?
            }
        }
    }
    //
    // Imperatives - Modals (above the Stack)
    present(
        view: ModalView, 
        args: { 
            animated?: boolean // undef -> true
////// TODO: animation
        }
    ) {
        const self = this
        for (let mv of self._modalViews) { // critical
            if (mv.isEqualToView(view)) {
                throw new Error("Asked to .present but that view already exists in the modal stack.")
/////// TODO: in all these membership checks... for extra safety... we should also check that view !in _stackViews
            }
        }
        let optl_preexistingTop_modal = self.existingTop_modal()
        //
        self._modalViews.push(view)
        view.modalParentView = self // so it knows where it's presented
        view.retain()
        self.addSubview(view)
        self.layoutSubviews(true)
        //
        if (optl_preexistingTop_modal) {
            optl_preexistingTop_modal.removeFromSuperview() // reasoning behind this is to save on memory - for now
            // we do not want to call .release() on that view since it's going to still be in the list
        }
        if (self.stackContainerView.superlayer && typeof self.stackContainerView.superlayer !== 'undefined') { // if it hasn't already been removed
            self.stackContainerView.removeFromSuperview() // reasoning behind this is to save on memory - for now
        }
    }
    dismiss_topModal() // dismisses the top modal ... so perhaps rename to .dismiss__top_modal()
    { 
// PS: this .dimiss is different from the iOS API, which puts .dismiss on the ModalView itself ... which is really not a bad design but requires the modal view obtains a reference to its presenting view .. which is a bit much for now. in the future that's what this needs to be enhanced into so we can manage nested modal views more safely
        const self = this
        let optl_existingTop_modal = self.existingTop_modal()
        if (!optl_existingTop_modal || typeof optl_existingTop_modal === 'undefined') {
            throw new Error("Expected non-zero modal views in order to .dismiss()")
        }
        let popped_modalView = self._modalViews.pop() // should be the same as optl_existingTop_modal
        //
        let optl_nextTop_modal = self.existingTop_modal() // accesses the last member so ok to use this fn
        if (optl_nextTop_modal) {
            self.addSubview(optl_nextTop_modal)
            self.layoutSubviews(true) // so that layout happens - or we could just do layout here
        } else { // no more modals left to display ... re-show the top stack view
            if (self.stackContainerView.superlayer && typeof self.stackContainerView.superlayer !== 'undefined') {
                throw new Error("Expected self.stackContainerView not to be in the view hierarchy if there was a modal to dismiss")
            }
            self.addSubview(self.stackContainerView)
            self.layoutSubviews(true) // just in case
        }
        //
        optl_existingTop_modal.removeFromSuperview()
        popped_modalView!.modalParentView = undefined // eliminate chance of strong ref cycle - or if nothing else clear stale refs
        popped_modalView!.release() // popped_modalView == optl_existingTop_modal
    }
    //
    //
    layoutSubviews(callRecursively?: boolean): void
    {
        super.layoutSubviews(callRecursively)
        //
        //////// TODO: this stuff could be moved to ._configure_with_nextTop_stack__noRender if .layoutSubviews() is ever removed
        //
        const self = this
        if (self._stackViews.length > 0) {
            let view = self.existingTop_stack()!
            let top = view.navigationBar_hidden() ? 0 : self.navigationBar.h_pxs()
            //
            view.layer.style.position = "absolute"
            view.layer.style.top = `${top}px` 
            view.layer.style.left = "0"
            view.layer.style.width = "100%"
            view.layer.style.height = `calc(100% - ${top}px)` // this is assumed
        }
        if (self._modalViews.length > 0) {
            let view = self.existingTop_modal()!
            //
            view.layer.style.top = "0" // unnecessary but incld for clarity vs being bounded by a nav view
            view.layer.style.left = "0"
            view.layer.style.width = "100%"
            view.layer.style.height = `100%`
        }
    }
    //
    //
    setNavigationBarConfigurationNeedsUpdate()
    {
        const self = this
        let top = self.existingTop_stack()
        if (!top) {
            throw new Error("setNavigationTitleNeedsUpdate called but but there was no existingTop_stack")
        }
        self._configure_navigationBarWith(top)
    }
    private _configure_navigationBarWith(top: NavigableView)
    {
        const self = this
        self.navigationBar.setTopView(
            top, 
            self._stackViews.length > 1
        )
    }
    //
    //
    public user_did_type__escape()
    {
        // This is a fairly janky and lazy way to implement this since it's on the consumer to implement, but at least it's not "bad" coupling
        const self = this
        if (self._stackViews.length > 1) { // so we neither trigger exceptions nor go back past the root view
            self.pop()
        } else {
            // TODO: system beep?
        }
    }
}