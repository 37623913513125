//
//
import { themes } from "../themes"
import { AbstractControl } from "./AbstractTitledControlCell"
import { StyledRadio_Control } from "./StyledRadio_Control"
import { _LabelRowContainer, _LabelRowContainer_EventName } from "./_LabelRowContainer"
//
export namespace HorizontalStyledRadio
{
    //
    //
    export interface Cell_IP
    {
        themeC: themes.Controller,
        //
        postTitleButtonText_tapped_fn?: (_self: Cell) => void
    }
    export class Cell extends AbstractControl.Base_Cell
    {
        _radiocellbase_ip!: Cell_IP
        //
        control!: StyledRadio_Control.Control
        selectedValue_primary(): StyledRadio_Control.ButtonValue|undefined { return this.control.selectedValue() }
        //
        accessoryRadio(): StyledRadio_Control.Control|undefined { return this._labelRow.accessoryRadio }
        selectedValue_accessory(): StyledRadio_Control.ButtonValue|undefined { return this.accessoryRadio()?.selectedValue() }
        //
        constructor(ip: Cell_IP)
        {
            super({
                themeC: ip.themeC
            })
            const self = this
            self._radiocellbase_ip = ip
        }
        setup(): void 
        {
            super.setup()
            const self = this
            {
                let view = new StyledRadio_Control.Control({
                    themeC: self._radiocellbase_ip.themeC,
                    grouping: StyledRadio_Control.VisualGrouping.group,
                    roleWithinUICell: StyledRadio_Control.RadioControlRoleWithinUICell.primary // this is the default anyway
                }).init()
                self.control = view
                self._labelRow.insertSubviewToLeftOfAccessoryRadio(view)
            }
        }
        //
        override setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled) // handles label row interactivity
            self.control.setEnabled(isEnabled)
        }
        set_props__primaryRadio(props: {
            members: StyledRadio_Control.MemberDesc[]|undefined, // undefined means don't change 
            selectedValue: StyledRadio_Control.ButtonValue|undefined // undefined means don't change 
            //
            rightAdjacent_title: string|null|undefined, // undefined means dont change; null means delete
        }) {
            const self = this
            self.control.set_props({
                leftAdjacent_title: null,
                //
                members: props.members,
                selectedValue: props.selectedValue,
                //
                rightAdjacent_title: props.rightAdjacent_title
            })
        }
        // You may also call the self._labelRow.set_props({ accessoryRadio: {..} })
        //
        // Delegation - Overrides
        override _accessoryRadio_changed(selectedValue: StyledRadio_Control.ButtonValue)
        {
            const self = this
            super._accessoryRadio_changed(selectedValue)
        }
        _postTitleButtonText_tapped()
        {
            const self = this
            super._postTitleButtonText_tapped()
            if (self._radiocellbase_ip.postTitleButtonText_tapped_fn) {
                self._radiocellbase_ip.postTitleButtonText_tapped_fn(self)
            }
        }
    }
}
