//
//
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { SpecificCommon_LinkButtonCells } from '../Common/SpecificCommon_LinkButtonCells'
import { Feed_View } from '../Feed/FeedView'
import { EnterExistingPassword_Base_View } from '../Passwords/EnterExistingPassword_Base_View'
import { themes } from '../themes'
//
export interface EnterExistingPassword_ForUnlock_View_Addtl_IP
{
    isFromADeconstructBootedState: boolean
}
//
export class EnterExistingPassword_ForUnlock_View extends EnterExistingPassword_Base_View
{
    ip!: EnterExistingPassword_ForUnlock_View_Addtl_IP
    //
    constructor(ctx: App_View_Context, ip: EnterExistingPassword_ForUnlock_View_Addtl_IP)
    {
        super(ctx, {
            isForChangePW: false,
            // tryEnterPasswordAsSingleObserverResponse: true, // this isnt used for anything anyway (can it be removed?)
            //
            promptTitle: ip.isFromADeconstructBootedState 
                ? "Crossroads has been locked\nafter going idle"
                : "Your app data is stored\nunder encryption", // design excludes periods from ends of these sentences
            promptDescription: ip.isFromADeconstructBootedState
                ? "Please enter your password to unlock your encrypted data."
                : "Please enter your password to continue."
        })
        const self = this
        self.ip = ip
    }
    _overridable_setup_views__after_primaryCTA(): void
    {
        const self = this
        //
        let container_view = new View_Web({}).init() // paddingLeft: 15, marginTop: 14
        {
            let view = container_view
            // view.layer.style.marginTop = TODO
            view.layer.style.paddingLeft = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
            view.layer.style.paddingRight = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_forgotPassword(
                self.ctx.themeC,
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController
            )
            container_view.addSubview(view)
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_deleteEverything(
                self.ctx.themeC, 
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController,
                true
            )
            container_view.addSubview(view)
        }
        self.addSubview(container_view)
    }
    //
    //
    viewWillDisappear(): void 
    { // since anytime password entry is canceled, they'll be causing the wallet instance to be re-created and added to the wallet list controller again anyway - the entire purpose of the retaining of the wallet was to transport it across react navigation screens
        super.viewWillDisappear()
        // walletsListController.ReleaseWalletForPWEntry()
///////// TODO: figure out if this should still be commented ... quite possibly?
    }
    //
    // Delegation - Overrides
    override async overridable_didEnterCorrectPassword_fn(enteredPassword: string)
    {
        const self = this
        //
        self.isFormSubmitting = false // allow actual dismissal of the form again
        self.configureWith_formSubmittingState({})
        //
        // // // self.ctx.rootNavigationView.dismiss() // dismiss the currently presented modal - in which this view resides
        // ^ commenting this and turning it into a .reset in case the FeedView wasn't previously pushed in the root stack nav view
        self.ctx.rootNavigationView.reset([ 
            new Feed_View(self.ctx).init()
        ], true /* DO dismiss modals if any, which there are */)
//////// TODO: factor this to the RootNavigationView
    }
    //
    //
    navigationBar_title(): string
    {
        return "Enter Password"
    }
}