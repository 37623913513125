//
//
import EventEmitter from 'events'
import { PersistableObject } from '../Persistable/PersistableObject';
//
import AppPersistenceController_Encrypting, { Persistence_Encrypting_EventName } from './AppPersistenceController_Encrypting';
import { DocumentId, CollectionName, DocumentJSON } from '../Persistable/Documents'
//
// constants
enum PersistableObjectList_EventName
{
    erroredOnReconstitute = "erroredOnReconstitute", // check self.lastRecordLoadExceptions
	list_updated = "list_updated"
}
class PersistableObjectListController extends EventEmitter // DeleteEverythingRegistrant, ChangePasswordRegistrant
{
	//
	// Properties - Initializing inputs and constants
	collectionName!: CollectionName
	persistenceController!: AppPersistenceController_Encrypting
	listedObject_factoryFn!: (existingJSONDoc: DocumentJSON) => PersistableObject
	//
    _fn_on__Persistence_EventName_DidLoadAppDataAndDecryptWithPassword?: () => void
    // _fn_on__Persistence_EventName_DidDeleteEverything?: (params) => void
	_fn_on__Persistence_EventName_WillDeconstructBootedStateAndClearPassword?: (params) => void
	_fn_on__Persistence_EventName_DidDeconstructBootedStateAndClearPassword?: (params) => void
	//
	records: PersistableObject[] = []
    lastRecordLoadExceptions: string[] = []
	//
	// Lifecycle - Init
	constructor(
		persistenceController: AppPersistenceController_Encrypting, 
		collectionName: CollectionName, 
		listedObject_factoryFn: (existingJSONDoc: DocumentJSON) => PersistableObject
	) {
		super()
		//
		const self = this
		self.collectionName = collectionName
		self.persistenceController = persistenceController
		self.listedObject_factoryFn = listedObject_factoryFn
		self.setMaxListeners(10000) // seems fairly reasonable lower bound
	}
	async setup()
	{
		const self = this
		self.setup_startObserving()
	}
	setup_startObserving()
	{
		const self = this
        let weakSelf = new WeakRef(self)
		//
        self._fn_on__Persistence_EventName_DidLoadAppDataAndDecryptWithPassword = async () => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            /*await */optl_self._persistenceController_didLoadAppDataAndDecryptWithPassword()
        }
        // self._fn_on__Persistence_EventName_DidDeleteEverything = (params) => 
        // {
        //     let optl_self = weakSelf.deref()
        //     if (!optl_self) {
        //         return
        //     }
        //     optl_self._persistenceController_didDeleteEverything()
        // }
		self._fn_on__Persistence_EventName_WillDeconstructBootedStateAndClearPassword = (params) => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
			optl_self._persistenceController_willDeconstructBootedStateAndClearPassword()	
		}
		self._fn_on__Persistence_EventName_DidDeconstructBootedStateAndClearPassword = (params) => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
			optl_self._persistenceController_didDeconstructBootedStateAndClearPassword()	
		}
        self.persistenceController.on(
            Persistence_Encrypting_EventName.DidLoadAppDataAndDecryptWithPassword,
            self._fn_on__Persistence_EventName_DidLoadAppDataAndDecryptWithPassword!
        )
        // self.persistenceController.on(
        //     Persistence_EventName.DidDeleteEverythingAndDeboot,
        //     self._fn_on__Persistence_EventName_DidDeleteEverything!
        // )
		self.persistenceController.on(
			Persistence_Encrypting_EventName.WillDeconstructBootedStateAndClearPassword, 
			self._fn_on__Persistence_EventName_WillDeconstructBootedStateAndClearPassword!
		)
		self.persistenceController.on(
			Persistence_Encrypting_EventName.DidDeconstructBootedStateAndClearPassword, 
			self._fn_on__Persistence_EventName_DidDeconstructBootedStateAndClearPassword!
		)
	}
    //
    // TODO: teardown code
	// func tearDown()
	// { // overridable but call on super obvs
	// 	self.stopObserving()
	// }
	// func stopObserving()
	// { // overridable but call on super obvs
	// 	self._stopObserving_passwordController()
	// }
	// _stopObserving_passwordController()
	// {
	// 	const self = this
	// 	self.passwordController.removeRegistrantForDeleteEverything(self)
	// 	self.passwordController.removeRegistrantForChangePassword(self)
	// 	//
	// 	self.passwordController.removeListener(
	// 		Password_EventName.WillDeconstructBootedStateAndClearPassword, 
	// 		self._fn_on__Password_EventName_WillDeconstructBootedStateAndClearPassword
	// 	)
	// 	self.passwordController.removeListener(
	// 		Password_EventName.DidDeconstructBootedStateAndClearPassword
	// 		self._fn_on__Password_EventName_DidDeconstructBootedStateAndClearPassword
	// 	)
	// }
    //
    //
	async reconstituteExistingPersistedRecords()
	{
		const self = this
		const ret = await self.overridable_deferReconstituteUntil()
		if (ret.err_str && typeof ret.err_str !== 'undefined') {
			throw ret.err_str! // necessary?
		}
		self.records = [] // zeroing
        self.lastRecordLoadExceptions = [] // flash
        //
        let load_ret = self.persistenceController.Decrypted_AllValStringsFor(self.collectionName)
		if (load_ret.vals.length == 0) { // just in case
			await self._didFinish_reconstituteExistingPersistedRecords()
			return
		}
		const num_contentStrings = load_ret.vals!.length
		for (var i = 0 ; i < num_contentStrings ; i++) {
			const decrypted_base64Encoded_string = load_ret.vals![i]
            let did_fail__parse = false
			var plaintext_documentJSON: {[index: string]: any}
			try {
				plaintext_documentJSON = JSON.parse(decrypted_base64Encoded_string)
			} catch (e) {
                self.lastRecordLoadExceptions.push((e as Error).message) // TODO: associate these with walletId somehow?; in any case store them on the instance
                did_fail__parse = true
			}
            let did_fail__init = false
            var listedObjectInstance: PersistableObject | null = null
            if (did_fail__parse != true) {
                try {
                    listedObjectInstance = self.listedObject_factoryFn(plaintext_documentJSON!)
                } catch (e) {
                    self.lastRecordLoadExceptions.push((e as Error).message)
                    did_fail__init = true
                }
            }
            if (listedObjectInstance) {
    			self.records.push(listedObjectInstance!) // is this is a good idea?; in any case, TODO: store the error on the instance itself
            }
            if (did_fail__parse != true && did_fail__init != true) {
    			await self.overridable_loading_didReconstitute(listedObjectInstance!)
            }
		}
		await self._didFinish_reconstituteExistingPersistedRecords()
        if (self.lastRecordLoadExceptions.length > 0) {
            await self._didErrorOn_reconstituteExistingPersistedRecords()
        }
	}
	async overridable_deferReconstituteUntil(): Promise<{ err_str?: string | undefined }>
	{
		return { } // make sure to call this
	}
	async _didFinish_reconstituteExistingPersistedRecords()
	{
		const self = this
		self.overridable_finalizeAndSortRecords() // finalize on every boot - so that overriders can opt to do CRUD even with 0 extant records
        self.__dispatchAsync_listUpdated_records() // on "next tick" to avoid instantiator missing this
	}
	_didErrorOn_reconstituteExistingPersistedRecords()
	{
		const self = this
		console.error(`Lists:`, self, ` failed to boot with err: ${self.lastRecordLoadExceptions}`)
		//
        let weakSelf = new WeakRef(self)
		setTimeout(() => { // on next tick to avoid instantiator missing this
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
			optl_self.emit(PersistableObjectList_EventName.erroredOnReconstitute) 
		}, 1)
	}
	//
	// Accessors - Overridable
	overridable_shouldSortOnEveryRecordAdditionAtRuntime(): boolean
	{
		return false // default
	}
	overridable_wantsRecordsAppendedNotPrepended(): boolean
	{
		return false // default
	}
	//
	// Runtime - Accessors - Private - Lookups - Documents & instances
	public LookedUp_IdsOfAllPersistedRecords(): {
		err_str: string | undefined,
		ids: DocumentId[] | undefined
	} {
		const self = this
		//
		return self.persistenceController.AllIdsFor(self.collectionName)
	}
	//
	// Imperatives - Overridable
	overridable_finalizeAndSortRecords() {}
	//
	// Imperatives - Delete
    public async DeleteRecordWithId(_id: DocumentId): Promise< { err_str?: string | undefined }>
    {
        const self = this
        let object = self.records.find(po => po._id == _id)
        if (!object) {
            throw "Object with that _id not found for delete"
        }
        return await this.Delete(object)
    }
	async Delete(object: PersistableObject): Promise<{ err_str?: string | undefined }>
	{
		const self = this
		let ret = await object.delete()
		if (ret.err_str != null && typeof ret.err_str !== 'undefined') { 
			return { err_str: ret.err_str! }
		}
		// remove / release
		self._removeFromList(object)
		return {}
	}
	async Delete_noListUpdatedNotify(object: PersistableObject): Promise<{ err_str?: string | undefined }>
	{
		const self = this
		let ret = await object.delete()
		if (ret.err_str != null && typeof ret.err_str !== 'undefined') { 
			return { err_str: ret.err_str! }
		}
		// remove / release
		self._removeFromList_noListUpdatedEmit(object)
		return {}
	}
	_removeFromList(object: PersistableObject)
	{
		const self = this
		self._removeFromList_noListUpdatedEmit(object)
		self._listUpdated_records()
	}
	_removeFromList_noListUpdatedEmit(object: PersistableObject)
	{
		const self = this
		// self.stopObserving(record: record) // if observation added later…
		var index = -1;
		for (var i = 0 ; i < self.records.length ; i++) {
			const record = self.records[i]
			if (record._id != null) {
				if (record._id === object._id) {
					index = i;
					break;
				}
			}
		}
		if (index === -1) {
			throw "Expected to find object within _removeFromList_noListUpdatedEmit"
		}
		self.records.splice(index, 1) // remove 1 element at index
	}
	//
	// Delegation
	async overridable_loading_didReconstitute(listedObjectInstance: PersistableObject) {} // somewhat intentionally ignores errors and values which would be returned asynchronously, e.g. by way of a callback/block
	_atRuntime__record_wasSuccessfullySetUp(listedObject: PersistableObject)
	{
		const self = this
		self._atRuntime__record_wasSuccessfullySetUp_noSortNoListUpdated(listedObject)
		if (self.overridable_shouldSortOnEveryRecordAdditionAtRuntime()) { // this is no longer actually used by anything
			self.overridable_finalizeAndSortRecords()
		}
		self.__dispatchAsync_listUpdated_records()
		// ^-- so control can be passed back before all observers of notification handle their work - which is done synchronously
	}
	_atRuntime__record_wasSuccessfullySetUp_noSortNoListUpdated(listedObject: PersistableObject)
	{
		const self = this
		if (self.overridable_wantsRecordsAppendedNotPrepended()) {
			self.records.push(listedObject)
		} else {
			self.records.splice(0, 0, listedObject) // so we add it to the top
		}
//		self.overridable_startObserving(record: recordInstance) // TODO if necessary - but shouldn't be at the moment - if implemented, be sure to add corresponding stopObserving in _removeFromList_noListUpdatedNotify
	}
	//
	_listUpdated_records()
	{
		const self = this
		self.emit(PersistableObjectList_EventName.list_updated)
	}
	__dispatchAsync_listUpdated_records()
	{
		const self = this
        let weakSelf = new WeakRef(self)
        setTimeout(() =>
		{ // this isn't strictly async … so *might* not be beneficial
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self._listUpdated_records()
		}, 2)
	}
	//
	// Delegation - Notifications - Persistence Controller
    async _persistenceController_didLoadAppDataAndDecryptWithPassword()
    {
		const self = this
        console.log("[",self.constructor.name,"::_persistenceController_didLoadAppDataAndDecryptWithPassword]")
        await self.reconstituteExistingPersistedRecords()
    }
	// _persistenceController_didDeleteEverything()
	// {
	// 	const self = this
	// 	console.log(`${self.constructor.name}/_persistenceController_didDeleteEverything: ${self.collectionName}`)
	// }
	_persistenceController_willDeconstructBootedStateAndClearPassword()
	{
		const self = this
		// console.log(`${self.constructor.name}/_persistenceController_willDeconstructBootedStateAndClearPassword: ${self.collectionName}`)
        for (let r of self.records) {
            r.willDeconstructBootedStateAndClearPassword()
        }
		self.records = [] // flash
		// now we'll wait for the "did" event ---v before emiting anything like list updated, etc
	}
	_persistenceController_didDeconstructBootedStateAndClearPassword()
	{
		const self = this
		// console.log(`${self.constructor.name}/_persistenceController_didDeconstructBootedStateAndClearPassword: ${self.collectionName}`)
		self.__dispatchAsync_listUpdated_records() // manually emit so that the UI updates to empty list after the pw entry screen is shown
		// // self.reconstituteExistingPersistedRecords() // now performed on observation of persistence controller boot and decrypt
	}
}
export { PersistableObjectListController, PersistableObjectList_EventName }