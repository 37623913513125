//
//
import EventEmitter from "events"
import { v4 as uuidV4 } from 'uuid'
import { LifecycleObject, _LifecycleObject } from "./LifecycleObject"
import View_Web from "./View.web"
//
export interface View_Base__Options<GenericLayerType>
{
    el_name?: string // this will default to 
    layer_init_options?: { [key: string]: any }
    //
    _concrete__new__layer: (final_el_name: string, layer_init_options: { [key: string]: any }, uuid: string) => GenericLayerType,
    _concrete_appendLayerToSuperlayer: (superlayer: GenericLayerType, sublayer: GenericLayerType) => void
    _concrete_insertLayerBefore: (superlayer: GenericLayerType, view_layer: GenericLayerType, layerOf_subviewAbove: GenericLayerType) => void
    _concrete_upsertLayerAtEnd: (superlayer: GenericLayerType, view_layer: GenericLayerType) => void
    _concrete_removeSublayer: (superlayer: GenericLayerType, sublayer: GenericLayerType) => void
    _concrete_childrenOfLayer: (layer: GenericLayerType) => GenericLayerType[]
    _concrete_render: (layer: GenericLayerType) => void,
    //
    _concrete_DEBUG_borderLayer: (layer: GenericLayerType) => void
}
//
// I'm just leaving this in to show that I tried observing GC handler calls for the Views but it's basically useless because (a) the target is already GC'd by the time the handler is called (what is the point?!) and (b) GC is unreliably performed anyway
// namespace Views_MemMgmt
// {
//     //
//     let rgstry_targetWeakRefs_by_heldValues: { [key: string]: WeakRef<any> } = {} // okay...
//     //
//     export function register_object_for_dealloc_obs_with_plainValueKey(target: any, consumerFacingKey: string)
//     {
//         // console.log("target" , target)

//         let existing__weak_target = rgstry_targetWeakRefs_by_heldValues[consumerFacingKey]
//         if (existing__weak_target && typeof existing__weak_target !== 'undefined') {
//             throw new Error("register_object_for_dealloc_obs_with_plainValueKey was already called for consumerFacingKey: " + consumerFacingKey)
//         }
//         //
//         let weak_target = new WeakRef(target)
//         rgstry_targetWeakRefs_by_heldValues[consumerFacingKey] = weak_target // so we can access the darn target later based on the heldValue (wut) - but so that we don't extra-retain the target
//         // console.log("rgstry_targetWeakRefs_by_heldValues now ", rgstry_targetWeakRefs_by_heldValues)


//         // let read__weak_target = rgstry_targetWeakRefs_by_heldValues[consumerFacingKey]
//         // console.log("read__weak_target", read__weak_target, "read__weak_target.deref()", read__weak_target.deref())
//         // trying to verify i'm not crazy

//         finalization_rgstry.register(
//             target, // target; weak ref'd
//             consumerFacingKey, // aka heldValue; strongly ref'd
//             target // unregister token; weak ref'd
//         ) // all of this just so that .teardown gets called on ~GC  
//     }
//     //
//     // I'm leaving this in and using a special _teardown_via_finalizationRegistry to silently ignore dupe calls but .... this doesn't even work reliably :(
//     let finalization_rgstry/*in case namespacing is an issue..?*/ = new FinalizationRegistry((heldValue) => 
//     {
//         if (typeof heldValue !== 'string') {
//             throw new Error("Inappropriate (non-string) heldValue placed into Views_MemMgmt.finalization_rgstry")
//         }
//         let consumerFacingKey = heldValue // to be clear
//         let weak_deallocd = rgstry_targetWeakRefs_by_heldValues[consumerFacingKey] as WeakRef<any>
//         let deallocd = weak_deallocd.deref()! // this is fine
//         delete rgstry_targetWeakRefs_by_heldValues[consumerFacingKey]

// let another__weak_target = rgstry_targetWeakRefs_by_heldValues[Object.keys(rgstry_targetWeakRefs_by_heldValues)[0]]
//         console.log("another__weak_target" , another__weak_target, "another__weak_target.deref", another__weak_target.deref())
// // this looks strange but i was using this to verify that weak_targets were actually recoverable from rgstry_targetWeakRefs_by_heldValues and that it wasn't just that all the weak targets were not stored properly

//         finalization_rgstry.unregister(deallocd) // in theory we should never have to call this here - since we're calling it on the GC itself, not at any other time
//         // ^ um I think I may have to move that somewhere else since it's also a retain cycle on the finalization_rgstry itself within a block probably retained by finalization_rgstry (facepalm)
//         //
//         if (typeof deallocd !== 'object') {
//             throw new Error("Inappropriate (non-object) value placed into Views_MemMgmt.finalization_rgstry")
//         }
//         if ((deallocd as object)['is_a_View_Base'] != true) {
//             throw new Error("Inappropriate (non-View_Base) object value placed into Views_MemMgmt.finalization_rgstry")
//         }
//         let v = deallocd as View_Base<any>
//         v._teardown_via_finalizationRegistry()
//     })
// }
//
//
export default class View_Base<GenericLayerType> extends EventEmitter implements LifecycleObject
{
    // LifecycleObject
    had_init_called: boolean = false
    _lifecycleObject_post_init__autoreleaseTimer?: any // I knowww it's super jank but there doesn't seem to be a way to make sure that views which are never (a) explicitly retained, or (b) added to subview or stacknav view hierarchy, get .teardown() called .. such as those which go out of scope without being .retained or called via .addSubview
    _lifecycleObject_is_torn_down: boolean = false
    _lifecycleObject_n_refs: number = 0
    retain() { return _LifecycleObject._impl__retain(this) }
    release() { _LifecycleObject._impl__release(this) }
    _lifecycleObject_wants_debug_log__retain?: boolean
    _lifecycleObject_wants_debug_log__release?: boolean
    _lifecycleObject_wants_debug_log__teardown?: boolean
    //
    //
    is_a_View_Base: boolean = true // usable for introspection - though there's got to be a better way to do this
    //
    opts!: View_Base__Options<GenericLayerType>
    // I call this .ops here so that subclassers can use .opts
    //
    uuid!: string
    list_row_pkey?: string // PS: instead of calling this custom_pkey, which might get stomped on by someone, I'm reserving this for DisplayableListRow 
    is_torn_down: boolean = false
    //
    initial_or_default__el_name!: string
    layer!: GenericLayerType
    subviews: View_Base<GenericLayerType>[] = []
    superview: View_Base<GenericLayerType>|null = null
    superlayer: GenericLayerType|null = null
    //
    _isEnabled: boolean = true
    //
    constructor(opts: View_Base__Options<GenericLayerType>)
    {
        super()
        const self = this
        self.opts = opts
        //
        // // // self.wants_debug_log__teardown = true // enable to watch screens tearing down on .removeFromSuperview() etc
        //
        self.initial_or_default__el_name = (typeof opts.el_name === 'string' && opts.el_name) 
            ? opts.el_name
            : self._overridable__default__el_name()
        //
        self.uuid = uuidV4() // set a UUID so we can do equality checks
        // Views_MemMgmt.register_object_for_dealloc_obs_with_plainValueKey(self, self.uuid)
        // ^ left commented in case someone figures out a way to program in Javascript in a reasonable fashion :'(
    }
    init(): this
    { // NOTE: using '.init()' (instantiate with 'new View_Concrete(...).init()') allows subclassers to call overridden methods in this 'init' chain and not have them set to undefined in the process as would happen if we tried to consider the constructor the init chain
        const self = this
        _LifecycleObject._impl__pre_init(self) // throws if init already called
        self.setup() // so that people dont have to override init .. since it's less safe and more cumbersome since have to remember to return self
        _LifecycleObject._impl__post_init(self)
        //
        return self
    }
    setup()
    { // overridable but ensure you call this on super
        const self = this
        self.setup_loadView()
    }
    _overridable__default__el_name(): string // TODO: move this to .opts ?
    {
        throw new Error("Code fault: Subclassers must override and implement this")
    }
    overridable__finalized__el_name(): string
    {
        return this.initial_or_default__el_name // a default is the default by the concrete implementation; you can either override this method to return what you'd like or pass "el_name" in the initialization parameters
    }
    setup_loadView()
    {
        const self = this
        self.layer = self.opts._concrete__new__layer(
            self.overridable__finalized__el_name(), 
            self.opts.layer_init_options ? self.opts.layer_init_options : {},
            self.uuid!
        )
    }
    //
    //
    // _teardown_via_finalizationRegistry()
    // {
    //     const self = this
    //     console.log(`${self.constructor.name}::_teardown_via_finalizationRegistry`)
    //     self.teardown(
    //         false // do not throw if already called
    //     )
    // }
    public teardown()
    { 
        const self = this
        if (self._lifecycleObject_wants_debug_log__teardown) {
            console.log(`${self.constructor.name}::teardown`, self)
            console.trace()
        }
        if (self.is_torn_down) {
            throw new Error(`[${self.constructor.name}/teardown] after having already been torn down!`)
        }
        self.is_torn_down = true
        //
        for (let sv of self.subviews) {
            sv.release() // so that they know they can .teardown()
        }
    }
    //
    //
    public hasASuperview(): boolean
    {
        const self = this
        const hasASuperview = typeof self.superview !== 'undefined' && self.superview !== null
        //
        return !!hasASuperview
    }
    is_toSuperlayer_already_attached(): boolean
    {
        const self = this
        if (self.hasASuperview()) {
            return self.superview!.is_toSuperlayer_already_attached()
        } else if (self.superlayer) { // then this must be the root view!
            // console.log("[DEBUG] expecting this to be screenview... ", self.constructor.name)
            return true
        } else {
            // console.log("returning false from is_toSuperlayer_already_attached for ", self)
            return false // no superlayer, and no superview - just assume not attached - the rootView should at least have a .superlayer if not 
        }
    }
    //
    //
    public isEqualTo(view: View_Base<GenericLayerType>): boolean
    {
        const self = this
        //
        return self.isEqualToView(view)
    }
    public isEqualToView(view: View_Base<GenericLayerType>): boolean
    {
        return view.uuid === this.uuid
    }
    //
    //
    public addSubview(subview: View_Base<GenericLayerType>): void
    {
        const self = this
        if (!subview || typeof subview === 'undefined') {
            throw self.constructor.name + ' asked to `addSubview` but passed nil `view`.'
        }
        if (subview.had_init_called != true) { // this is at least one wide coverage place we can catch this
            throw new Error("You may have forgotten to call .init() on this subview")
        }
        const toLayer = self.layer
        self._addSubview_appendingToLayer(subview, toLayer)
    }
    //
    _addSubview_appendingToLayer(subview: View_Base<GenericLayerType>, superlayer: GenericLayerType)
    { // NOTE: ^-- this is exposed so you can inject subviews into manually created children elements of your choice
        const self = this
        if (!subview || typeof subview === 'undefined') {
            throw self.constructor.name + ' asked to `addSubview` but passed nil `subview`.'
        }
        if (subview.superlayer && typeof subview.superlayer !== 'undefined') {
            throw new Error("A view may only have one .superlayer at a time")
        }
        if (subview.superview && typeof subview.superview !== 'undefined') {
            throw new Error("A view may only have one .superview at a time")
        }
        let _is_toSuperlayer_already_attached = self.is_toSuperlayer_already_attached()
        if (_is_toSuperlayer_already_attached) {
            subview.viewWillAppear()
        }
        //
        self.subviews.push(subview)
        subview.retain()
        self._configureViewStateForInsertionIntoHierarchy(subview, superlayer)
        //
        self.opts._concrete_appendLayerToSuperlayer(superlayer, subview.layer)
        //
        if (_is_toSuperlayer_already_attached) {
            subview.viewDidAppear()
        }
    }
    //
    _configureViewStateForInsertionIntoHierarchy(subview: View_Base<GenericLayerType>, superlayer: GenericLayerType)
    {
        const self = this
        // subview's dependency setup:
        subview.superview = self
        subview.superlayer = superlayer // generally this is going to be self.layer
    }
    indexOf_existingSubview(existingSubview: View_Base<GenericLayerType>): number
    {
        const self = this
        let existingSubview_atI = 0;
        for (let v of self.subviews) {
            if (existingSubview.uuid == v.uuid) {
                break
            }
            existingSubview_atI++
        }
        //
        return existingSubview_atI
    }
    insertSubview_after(subview: View_Base<GenericLayerType>, existingSubview: View_Base<GenericLayerType>) {
        const self = this
        let i = self.indexOf_existingSubview(existingSubview)
        self.insertSubview(subview, i + 1)        
    }
    insertSubview_before(subview: View_Base<GenericLayerType>, existingSubview: View_Base<GenericLayerType>) {
        const self = this
        let i = self.indexOf_existingSubview(existingSubview)
        self.insertSubview(subview, i)
    }
    insertSubview(subview: View_Base<GenericLayerType>, atIndex: number)
    {
        const self = this
        const toLayer = self.layer
        const superlayer = toLayer
        //
        let _is_toSuperlayer_already_attached = self.is_toSuperlayer_already_attached()
        if (_is_toSuperlayer_already_attached) {
            subview.viewWillAppear()
        }
        { // state:
            // local:
            self.subviews.splice(atIndex, 0, subview)
            subview.retain()
            // subview:
            self._configureViewStateForInsertionIntoHierarchy(subview, superlayer)
        }
        { // "DOM"
            const numberOf_subviews = self.subviews.length
            // console.log(`numberOf_subviews (${numberOf_subviews}) > atIndex + 1 (${atIndex + 1})`)
            if (numberOf_subviews > atIndex + 1) { // if we're inserting under a subview
                const subviewAbove_view_atIndex = self.subviews[atIndex + 1]
                // console.log(`insert under subview ${subviewAbove_view_atIndex.Description()}`)
                const layerOf_subviewAbove = subviewAbove_view_atIndex.layer
                // console.log("layerOf_subviewAbove" , layerOf_subviewAbove)
                self.opts._concrete_insertLayerBefore(superlayer, subview.layer, layerOf_subviewAbove)
            } else { // then we're able to just append the view
                self.opts._concrete_appendLayerToSuperlayer(superlayer, subview.layer)
            }
        }
        //
        if (_is_toSuperlayer_already_attached) {
            subview.viewDidAppear()
        }
    }
    //
    setOrderedSubviews(to_ordered_subviews: View_Base<GenericLayerType>[]) 
    // NOTE: .subviews is assumed not to contain any subviews that don't also exist in ordered_subviews
    // NOTE: This function makes use of a DOM quirk whereby .insertBefore(..) allows callers to reorder *and* insert elements without redundantly inserting existing children - https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore
    {
        const self = this
        const toLayer = self.layer
        const superlayer = toLayer
        //
        let past__document_activeElement = document.activeElement
        // console.log("[.setOrderedSubviews] document.activeElement" , document.activeElement)
        //
        let to_ordered_subviews_uuids: { [key: string]: boolean } = {}
        for (let sv of to_ordered_subviews) {
            to_ordered_subviews_uuids[sv.uuid] = true
        }
        // console.log("[View_Base/setOrderedSubviews] to_ordered_subviews_uuids", to_ordered_subviews_uuids)
        //
        let preexisting_inserted_uuids: { [key: string]: boolean } = {}
        for (let sv of self.subviews) {
            preexisting_inserted_uuids[sv.uuid] = true
        }
        //
        // First handling deletions:
        for (let i = 0 ; i < self.subviews.length ; i++) { 
            let v = self.subviews[i]
            if (to_ordered_subviews_uuids[v.uuid] != true) { // then it must be removed/pruned
                // console.log("[View_Base/setOrderedSubviews] Pruning cell view", v.uuid, v)
                v.removeFromSuperview() // the v is no longer live - remove it (this will also release it)
                i -= 1 // since the i++ will no longer be necessary
            }
        }
        let _is_toSuperlayer_already_attached = self.is_toSuperlayer_already_attached()
        //
        // Now additions:
        for (let o_sv of to_ordered_subviews) {
            if (preexisting_inserted_uuids[o_sv.uuid] == true) {
                continue // already inserted
            }
            // console.log("inserting sv for first time: ", o_sv)
            if (_is_toSuperlayer_already_attached) {
                o_sv.viewWillAppear()
            }
            //
            o_sv.retain() // it is already 'inserted' into .subviews by being in ordered_subviews - but we do need to retain it
            //
            // mirroring insert/addSubview
            self._configureViewStateForInsertionIntoHierarchy(o_sv, superlayer)
        }
        for (let o_sv of to_ordered_subviews) { // "DOM"
            self.opts._concrete_upsertLayerAtEnd(superlayer, o_sv.layer)
        }
        for (let o_sv of to_ordered_subviews) {
            if (preexisting_inserted_uuids[o_sv.uuid] == true) {
                continue // already previously inserted - so .viewDidAppear must have already been called
            }
            if (_is_toSuperlayer_already_attached) {
                o_sv.viewDidAppear()
            }
        }
        if (typeof past__document_activeElement !== 'undefined' && past__document_activeElement) {
            (past__document_activeElement as HTMLElement).focus()
        }
        // console.log("[.setOrderedSubviews] after update document.activeElement" , document.activeElement)
        //
        self.subviews = to_ordered_subviews // keeping it simple
    }
    //
    removeFromSuperview() { // throws
        const self = this
        if (typeof self.superview === 'undefined' || self.superview === null) {
            throw new Error('no superview')
        }
        if (typeof self.superlayer === 'undefined' || self.superlayer === null) {
            throw new Error('no superlayer')
        }
        self.viewWillDisappear()
        {
            self.opts._concrete_removeSublayer(self.superlayer, self.layer)
            // now we can release the superlayer
            self.superlayer = null
            // now before we can release the superview,
            // we must manage the superview's subview list
            const superview_indexOf_self = self.superview.subviews.indexOf(self)
            if (superview_indexOf_self === -1) {
                throw new Error("superview didn't have self as subview")
            }
            // console.log("self.superview.subviews was", self.superview.subviews)
            self.superview.subviews.splice(superview_indexOf_self, 1)
            //
            // console.log("self.superview.subviews is now", self.superview.subviews)
            // and now we can free the superview
            self.superview = null
        }
        self.viewDidDisappear()
        //
        self.release()
    }
    removeAllSubviews()
    {
        const self = this
        // console.log(self.constructor.name,"removeAllSubviews")
        while (self.subviews.length > 0) { // it has to be done this way since we remove members with each iteration
            let v = self.subviews[0]
            v.removeFromSuperview() // will call release on v
        }
        self.subviews = []
    }
    removeAllSubviews_except(except_subviews: View_Web[])
    {
        const self = this
        for (let v of self.subviews) {
            let skip_subview = false
            for (let e_v of except_subviews) {
                if (v.uuid == e_v.uuid) {
                    skip_subview = true
                    break
                }
            }
            if (skip_subview) {
                break
            }
            v.removeFromSuperview()
        }
    }
    //
    // Runtime - Imperatives - Rendering/Drawing
    public render()
    {
        const self = this
        self.layoutSubviews(true/* -do- call recursively */)
        self.opts._concrete_render(self.layer)
    }
    layoutSubviews(callRecursively?: boolean) // overridable but call on super
    {
        const self = this
        //
        if (callRecursively !== false) {
            self.subviews.forEach((v) => {
                v.layoutSubviews(true)
            })
        }
    }
    //
    // Runtime - Imperatives - Convenience methods
    convenience_removeAllSublayers()
    { // you should/would probably only use this when you're not using any subviews
        const self = this
        let children = self.opts._concrete_childrenOfLayer(self.layer)
        for (let c of children) {
            self.opts._concrete_removeSublayer(self.layer, c)
        }
    }
    //
    // Imperatives - Interactivity
    setEnabled(isEnabled: boolean)
    {
        const self = this
        self._isEnabled = isEnabled
        // Subclassers: override but call upon super!
    }

    //
    // Runtime - Imperatives - Debug
    DEBUG_BorderAllLayers() 
    {
        const self = this
        self.opts._concrete_DEBUG_borderLayer(self.layer)
        View_debug_utils.DEBUG_BorderChildLayers(self.opts._concrete_childrenOfLayer(self.layer), self.opts._concrete_DEBUG_borderLayer)
    }
    DEBUG_GiveBorder() 
    {
        const self = this
        self.opts._concrete_DEBUG_borderLayer(self.layer)
    }
    DEBUG_BorderSubviews() 
    {
        const self = this
        View_debug_utils.DEBUG_BorderSubviews(self, self.opts._concrete_DEBUG_borderLayer)
    }
    DEBUG_BorderChildLayers()
    {
        const self = this
        View_debug_utils.DEBUG_BorderChildLayers(self.opts._concrete_childrenOfLayer(self.layer), self.opts._concrete_DEBUG_borderLayer)
    }
    //
    // Runtime - Delegation - View visibility - Overridable - Be sure to call on super
    viewWillAppear()
    {
        const self = this
        // console.log(self.constructor.name + " viewWillAppear")

        for (const i in self.subviews) {
            const subview = self.subviews[i]
            subview.viewWillAppear()
        }
    }
    viewDidAppear()
    {
        const self = this
        self._overridable_restoreAnyCachedUIStateFromHavingBeenRemovedFromHierarchy()
        //
        self.layoutSubviews(false/* do not recurse .. because we call .viewDidAppear on all subviews just below - there may be a better way to do all this .layoutSubview calling - but the way Apple does it may not translate because they may call it in relation to -drawRect: */)
        //
        // console.log(self.constructor.name + " viewDidAppear")
        for (const i in self.subviews) {
            const subview = self.subviews[i]
            subview.viewDidAppear()
        }
    }
    viewWillDisappear() 
    {
        const self = this
        // console.log(self.constructor.name + " viewWillDisappear")
        self._overridable_recordUIStateUponBeingRemovedFromDOM()
        for (const i in self.subviews) {
            const subview = self.subviews[i]
            subview.viewWillDisappear()
        }
    }
    viewDidDisappear()
    {
        const self = this
        // console.log(self.constructor.name + " viewDidDisappear")
        for (const i in self.subviews) {
            const subview = self.subviews[i]
            subview.viewDidDisappear()
        }
    }
    //
    // Runtime - Imperatives -
    // To preserve scroll offset on add/remove from DOM as that doesn't persist
    // To solve bugs seen in Nav & TabBar View Controllers
    _overridable_recordUIStateUponBeingRemovedFromDOM()
    {
        const self = this
        const layer = self.layer
        // self.scrollOffsetsUponTransitionedFrom =
        // {
        //     Left: layer.scrollLeft,
        //     Top: layer.scrollTop
        // }
    }
    _overridable_restoreAnyCachedUIStateFromHavingBeenRemovedFromHierarchy() 
    {
        const self = this
        // const scrollOffsets = self.scrollOffsetsUponTransitionedFrom
        // if (typeof scrollOffsets === 'undefined' || scrollOffsets === null) {
        //     return
        // }
        // const layer = self.layer
        // layer.scrollLeft = scrollOffsets.Left
        // layer.scrollTop = scrollOffsets.Top
    }
}
//
//
export namespace View_debug_utils
{
    export function DEBUG_BorderSubviews<GenericLayerType>(ofView, DEBUG_BorderLayer__fn: (layer: GenericLayerType) => void)
    {
        const self = ofView
        self.subviews.forEach(
            (subview, i) => {
                DEBUG_BorderLayer__fn(subview.layer)
                DEBUG_BorderSubviews(subview, DEBUG_BorderLayer__fn) // recursive traversal
            }
        )
    }
    export function DEBUG_BorderChildLayers<GenericLayerType>(
        children: GenericLayerType[], 
        DEBUG_BorderLayer__fn: (layer: GenericLayerType) => void
    ) {
        const keysOf_children = Object.keys(children)
        keysOf_children.forEach(
            (key, _) => {
                const childLayer = children[key]
                //
                DEBUG_BorderLayer__fn(childLayer)
                DEBUG_BorderChildLayers(childLayer, DEBUG_BorderLayer__fn)
            }
        )
    }
    export function RandomColorHexString(): string
    {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }
}