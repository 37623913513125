//
//
import { Currency } from '@xrds/xrds-libapp-ts/src/Currencies/models/constants'
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { LinkButton } from './LinkButtonCell'
import { TitledTextInputCell, TitledTextInputCell__set_props__args } from './TitledTextInputCell'
import { TextInput_InputType } from './_TextInput'
import { AbstractControl } from './AbstractTitledControlCell'
//
export interface ToAddrInputCell_IP
{
    themeC: themes.Controller
    //
    title?: string,
    tooltipText: string|null,
    to_ccy?: Currency // setting this initially is important since without, no placeholder will be set
    //
    primaryInput_location?: AbstractControl.LocationWithinTitledCell
    //
    input__changed_fn?: (v: string) => void
}
export namespace ToAddr
{
    export namespace Input
    {
        export interface set_props__args extends TitledTextInputCell__set_props__args
        {
            toAddr?: {
                to_ccy: Currency
            }
        }
    }
}
//
export class ToAddrInputCell extends TitledTextInputCell
{
    private _isMaxButtonToggledOn: boolean = false
    isMaxButtonToggledOn(): boolean { return this._isMaxButtonToggledOn }
    //
    _ccy: Currency = Currency.XMR
    //
    _inner_ip!: ToAddrInputCell_IP
    //
    button_max?: LinkButton.Cell
    label_ccy!: View_Web
    //
    //
    constructor(_inner_ip: ToAddrInputCell_IP)
    {
        super({
            themeC: _inner_ip.themeC,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            //
            includeAccessory_checkbox: false,
            // input
            placeholder: undefined, // will be set via set_props
            inputType: TextInput_InputType.text,
            textOrSecureInput_disallowWhitespace: true,
            textOrSecureInput_disallow_nonAlphaNum: true,
            primaryInput_location: _inner_ip.primaryInput_location, // may be undefined
            //
            input__changed_fn: (_self: TitledTextInputCell, v: string) => { // perhaps just pass 
                let self = _self as ToAddrInputCell
                if (self._inner_ip.input__changed_fn) {
                    self._inner_ip.input__changed_fn(v)
                }
            },
            // input__focused_fn: (_self: TitledTextInputCell) => {
            //     let self = _self as ToAddrInputCell
            // },
            // input__debounced_changed_fn?: (v: string) => void
            input__revert_value_on_blur_to__fn: (_self: TitledTextInputCell) => 
            {
                let self = _self as ToAddrInputCell
                //
                return undefined // do not modify
            }
        })
        const self = this
        self._inner_ip = _inner_ip

    }
    setup(): void
    {
        super.setup()
        const self = this
        self.set_props_labelRow({
            title: self._inner_ip.title || "To",
            postTitleButtonText: undefined,
            tooltipText: self._inner_ip.tooltipText
        })
        let themeC = self._inner_ip.themeC
        let weakSelf = new WeakRef(self)
        {
            let view = self.textInput
        }
        if (self._inner_ip.to_ccy) {
            self.set_props({
                toAddr: {
                    to_ccy: self._inner_ip.to_ccy
                }
            })
        }


//// TODO: add right side qr button
//         <View style={styles.qrContainer}>
//           <Text style={styles.qr} selectable={false} >QR</Text>
//         </View>

    }
    set_props(args: ToAddr.Input.set_props__args) {
        const self = this
        if (args.toAddr) {
            let props = args.toAddr
            self._ccy = props.to_ccy
            //
            super.set_props({ 
                textInput: {
                    title: undefined, // no change there?
                    postTitleButtonText: undefined,
                    placeholder: `${props.to_ccy.toUpperCase()} Address`,
                    textInput_rightSide_linkButton_items: null, // TODO: add the camera icon for presenting the image select (qr scan or file open) interface
                    tooltipText: undefined // no change (yet)
                }
            })
        }
        super.set_props(args) // and *also* call .set_props in case there are any base props to set
    }
}