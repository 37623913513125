export interface OverridableClass
{
    had_init_called: boolean
    init(): this
    //
    // PS: I have declared this class since it was actually first and came out of the need to declare .init() distinct from constructor so that subclasses can use custom properties within the init/setup chain in overridden methods without having those properties zeroed after super's constructor ... e.g. Feed_Wallet_Cell's ... .descrView
}
//
//
export interface LifecycleObject // this is a protocol 
    extends OverridableClass 
{
    had_init_called: boolean
    init(): this
    _lifecycleObject_post_init__autoreleaseTimer?: any // I knowww it's super jank but there doesn't seem to be a way to make sure that views which are never (a) explicitly retained, or (b) added to subview or stacknav view hierarchy, get .teardown() called .. such as those which go out of scope without being .retained or called via .addSubview
    //
    _lifecycleObject_is_torn_down: boolean // default to false
    _lifecycleObject_n_refs: number // default to 0
    retain(): any // return self
    release()
    _lifecycleObject_wants_debug_log__retain?: boolean
    _lifecycleObject_wants_debug_log__release?: boolean
    _lifecycleObject_wants_debug_log__teardown?: boolean
    //
    teardown()
}
export namespace _LifecycleObject
{
    export function _impl__pre_init(self: LifecycleObject)
    {
        if (self.had_init_called == true) {
            throw new Error("This " + self.constructor.name + " has already had either .init() or _LifecycleObject._impl__pre_init() called.")
        }
        self.had_init_called = true // can be used for safety 
    }
    export function _impl__post_init(self: LifecycleObject)
    {
        let t = self._lifecycleObject_post_init__autoreleaseTimer
        if (t && typeof t !== 'undefined') {
            throw new Error("This " + self.constructor.name + " has already had _LifecycleObject._impl__post_init() called.")
        }
        self._lifecycleObject_post_init__autoreleaseTimer = setTimeout(() => { // PS: *dies a little bit inside*
            self._lifecycleObject_post_init__autoreleaseTimer = undefined // to be clear i guess .. TODO?
            if (self._lifecycleObject_n_refs == 0) {
                console.warn(`WARNING: Automatically calling .teardown() on a ${self.constructor.name} because it was never retained. This is likely a code fault in something creating a View.`)
                if (self._lifecycleObject_is_torn_down) {
                    throw new Error(`A ${self.constructor.name} was already torn down when _lifecycleObject_post_init__autoreleaseTimer fired`)
                }
                self._lifecycleObject_is_torn_down = true
                self.teardown()
            } else {
                throw new Error("Never expected the _lifecycleObject_post_init__autoreleaseTimer to finish firing while the self._lifecycleObject_n_refs was != 0 as it should have been cleared.")
            }
        }, 2) // something tiny
    }
    export function _impl__retain(self: LifecycleObject)
    {
        // console.trace()
        if (self._lifecycleObject_is_torn_down) {
            throw new Error("Attempting to retain an object which was already torn down")
        }
        let t = self._lifecycleObject_post_init__autoreleaseTimer
        if (t && typeof t !== 'undefined') { // we can cancel this
            // console.log(`Clearing an autorelease timer in ${self.constructor.name}`)
            self._lifecycleObject_post_init__autoreleaseTimer = undefined // zero
            clearTimeout(t)
        }
        self._lifecycleObject_n_refs += 1
        if (self._lifecycleObject_wants_debug_log__retain) {
            console.log(self.constructor.name+"::_LifecycleObject._impl__retain (now " + self._lifecycleObject_n_refs + ")", self)
        }
        //
        return self // for convenience so it can be chained with .init() - though .addSubview also causes the superview to retain, so sometimes retaining is not necessary 
    }
    export function _impl__release(self: LifecycleObject)
    {
        // console.trace()
        // if (self.constructor.name == "... IntegerInputCell") {
            // console.log("releasing " , self.constructor.name)
            // console.trace()
        // }
        if (self._lifecycleObject_is_torn_down) {
            throw new Error("Attempting to release an object which was already torn down")
        }
        self._lifecycleObject_n_refs -= 1
        if (self._lifecycleObject_wants_debug_log__release) {
            console.log(self.constructor.name+"::_LifecycleObject._impl__release (now " + self._lifecycleObject_n_refs + ")", self)
        }
        // console.trace()
        if (self._lifecycleObject_n_refs < 0) {
            throw new Error("Somehow an object had its n refs counter decremented below 0 without setting _lifecycleObject_is_torn_down to true; Chances are it had .release called before .retain was ever called")
            // NOTE: if this if branch ever gets removed then make sure to handle redundant teardowns and the _lifecycleObject_post_init__autoreleaseTimer
        }
        if (self._lifecycleObject_n_refs == 0) {
            self._lifecycleObject_is_torn_down = true
            self.teardown()
        }
    }
}