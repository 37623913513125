import View_Web from "../../View/View.web";
import { themes } from "../themes";
import { PropSettable } from './DisplayableListRow'
//
export interface SeparatorLineView_IP
{
    themeC: themes.Controller
}

export interface SeparatorLineView__set_props__args extends PropSettable.base__set_props__args
{
    incl_addtl_spacing__above: boolean|undefined
    incl_addtl_spacing__below: boolean|undefined
    
}
//
// NOTE: This class isn't really used anymore given that the cell group theming handles separators automatically
//
export default class SeparatorLineView extends View_Web
{
    ip!: SeparatorLineView_IP
    //
    constructor(ip: SeparatorLineView_IP)
    {
        super({ el_name: "div" })
        //
        const self = this
        self.ip = ip
    }
    setup(): void {
        super.setup()
        const self = this
        let themeC = self.ip.themeC
        {
            const view = self
            view.layer.style.background = themeC.current.colors.separator_line_view__bg
            view.layer.style.width = "100%"
            view.layer.style.height = "1px" // TODO: -> themeC?
        }
    }
    //
    public set_props(
        args: SeparatorLineView__set_props__args
    ) {
        const self = this
        if (typeof args.incl_addtl_spacing__below !== 'undefined') {
            if (args.incl_addtl_spacing__below == true) {
                let to_px = "10px"
                if (self.layer.style.marginBottom != to_px) {
                    self.layer.style.marginBottom = to_px
                }
            } else {
                if (self.layer.style.marginBottom != "0") {
                    self.layer.style.marginBottom = "0"
                }
            }
        } else {
            if (self.layer.style.marginBottom != "0") {
                self.layer.style.marginBottom = "0"
            }
        }
        if (typeof args.incl_addtl_spacing__above !== 'undefined') {
            if (args.incl_addtl_spacing__above == true) {
                let to_px = "10px"
                if (self.layer.style.marginTop != to_px) {
                    self.layer.style.marginTop = to_px
                }
            } else {
                if (self.layer.style.marginTop != "0") {
                    self.layer.style.marginTop = "0"
                }
            }
        } else {
            if (self.layer.style.marginTop != "0") {
                self.layer.style.marginTop = "0"
            }
        }
    }
}