import { PersistableObjectList_EventName } from "../../Persistable/PersistableObjectListController"
import { Wallet, Wallet_EventName } from "./Wallet"
import { WalletsListController } from "./WalletsListController"

export interface WalletObserver_IP
{
    wlc: WalletsListController
    //
    mode?: WalletObserver_Mode // defaults to .singleSelectable
    walletId?: string // optl initially selected wallet depending on .mode
    //
    obs_fns_by_eventName: { [key in Wallet_EventName]?: (payload?: any) => void }
    mode_all__listUpdated_cb?: (toWallets: Wallet.Instance[]) => void
}
//
export enum WalletObserver_Mode
{
    singleSelectable = "singleSelectable",
    all = "all"
}
//
export class WalletObserver
{
    ip!: WalletObserver_IP
    selectedWalletId?: string
    mode!: WalletObserver_Mode
    //
    observingWalletRefs: WeakRef<Wallet.Instance>[] = [] // weakrefs since we dont intend to prevent GC of the Wallet
    //
    //
    _fn__list_updated?: () => void
    //
    //
    selectedWallet(): Wallet.Instance|undefined { // TODO: cache on self instead?
        const self = this
        if (self.mode != WalletObserver_Mode.singleSelectable) {
            throw new Error("Code fault: .selectedWalletId is only to be used in .singleSelectable mode")
        }
        return self.selectedWalletId ? self.lookup_walletWithId(self.selectedWalletId) : undefined
    }
    wallets(): Wallet.Instance[]
    {
        return this.ip.wlc.records as Wallet.Instance[]
    }
    lookup_walletWithId(_id: string): Wallet.Instance|undefined { 
        return this.wallets().find(o => o._id == _id) as Wallet.Instance 
    }
    //
    constructor(ip: WalletObserver_IP)
    {
        const self = this
        self.ip = ip
        self.mode = self.ip.mode ? self.ip.mode : WalletObserver_Mode.singleSelectable
        self.selectedWalletId = ip.walletId // can be nil! but should not be if in .singleSelectable
        //
        self._setup() // doing this automatically since it's less error prone on the consumer side
    }
    private _setup()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        switch (self.mode) {
            case WalletObserver_Mode.singleSelectable:
            {
                if (!self.selectedWalletId) {
                    throw new Error(".start() called but no .selectedWalletId (which is needed for the .wallet() lookup); call setSelectedWalletId_andStartObserving instead")
                }
                self._startObserving__changeEventsPer([ self.selectedWallet()! ])
                //
                break
            }
            case WalletObserver_Mode.all:
            {
                self._fn__list_updated = () =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self._stopObserving_observingWalletRefs() // since it's hard to track the references to the Wallets, we'll remove local observers each time the list is updated and then reconstruct them ... and hopefully the references locally to those instances get freed or otherwise have the right lifecycle
                    //
                    let wallets = optl_self.wallets()
                    optl_self._startObserving__changeEventsPer(wallets)
                    if (optl_self.ip.mode_all__listUpdated_cb) {
                        optl_self.ip.mode_all__listUpdated_cb(wallets)
                    }
                }
                self.ip.wlc.on(PersistableObjectList_EventName.list_updated, self._fn__list_updated)
                //
                self._startObserving__changeEventsPer(self.wallets())
                //
                break
            }
            default:
            {
                throw new Error("Unhandled .mode")
            }
        }
    }
    private _startObserving__changeEventsPer(wallets: Wallet.Instance[])
    {
        const self = this
        self.observingWalletRefs = wallets.map(w => new WeakRef(w))
        for (let w of wallets) {
            let eventNames = Object.keys(self.ip.obs_fns_by_eventName)
            for (let n of eventNames) {
                w.on(n, self.ip.obs_fns_by_eventName[n])
            }
        }
    }
    private _stopObserving_observingWalletRefs()
    {
        const self = this
        if (!self.observingWalletRefs.length) {
            console.warn("._stopObserving_observingWalletRefs() called but 0 self.observingWalletRefs.length")
        }
        let refs = self.observingWalletRefs
        self.observingWalletRefs = []
        for (let weak_w of refs) {
            let optl_w = weak_w.deref()
            if (!optl_w) { // do we need to worry about removingListeners for a wallet that was GC'd
                console.warn("Looks like the wallet was deleted/GC'd before the WalletObserver could .stop()")
                continue
            }
            let w = optl_w
            let eventNames = Object.keys(self.ip.obs_fns_by_eventName)
            for (let n of eventNames) {
                w!.removeListener(n, self.ip.obs_fns_by_eventName[n])
            }
            // keeping .obs_fns_by_eventName in case .start() is called again
        }
    }
    public teardown()
    {
        const self = this
        if (self._fn__list_updated) {
            self.ip.wlc.removeListener(PersistableObjectList_EventName.list_updated, self._fn__list_updated)
            self._fn__list_updated = undefined
        }
        self._stopObserving_observingWalletRefs()
        self.selectedWalletId = undefined
    }
    //
    //
    public setSelectedWalletId_andStartObserving(_id: string)
    {
        const self = this
        if (self.mode != WalletObserver_Mode.singleSelectable) {
            throw new Error(".setSelectedWalletId_andStartObserving is only to be used in .mode = .singleSelectable")
        }
        self._stopObserving_observingWalletRefs()
        self.selectedWalletId = _id
        self._startObserving__changeEventsPer([ self.selectedWallet()! ])
    }
}