//
// e.g. 
// new TitledTextInputCell({
//     title: "Wallet Label",
//     placeholder: WalletLabels.new_placeholderTextWithRandName()
//     input__changed_fn?: (input_self: TitledTextInputCell, v: string) => void    
// }).init()
//
export namespace WalletLabels
{
    let placeholderNames = [ "Spending Cash", "Savings", "Deposits" ]
    //
    export function new_placeholderTextWithRandName(): string
    {
        return `e.g. ${__new_rand_placeholderName()}`
    }
    export function __new_rand_placeholderName(): string
    {
        return placeholderNames[Math.floor(Math.random() * placeholderNames.length)]
    }
}