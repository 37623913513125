//
//
import PasswordEntry_Presentation, { PasswordEntry_PWNowRequired_TransitionMode } from '@xrds/xrds-libapp-ts/src/Passwords/controllers/app_state__password';
import { LandingView } from './Landing/LandingView';
import StackNavigationView from './Common/NavigationView';
import View_Web from '../View/View.web';
import AppControllers from '@xrds/xrds-libapp-ts/src/Application/AppControllers';
import { themes } from './themes';
import { PasswordEntryModalNavigationView } from './Passwords/PasswordEntryModalNavigationView';
import { AppState_Event } from '@xrds/xrds-libapp-ts/src/Application/app_state___shared';
import { Feed_View } from './Feed/FeedView';
import { App_View_Context } from './App_View_Web';
import { UserIdleController } from '@xrds/xrds-libapp-ts/src/UserIdle/controllers/UserIdleController';
import { Popovers } from './Common/Popover_Base';
import { FooterBar } from './FooterBar';
//
//

export namespace screen
{
    //
    export enum Screen_EventName
    {
    }
    //
    export function attach_root_view_to_DOM(rootView: View_Web)
    {
        if (rootView.had_init_called != true) {
            throw new Error("screenView missing .init() call")
        }
        rootView.viewWillAppear()
        //
        const superlayer = document.body
        rootView.superlayer = superlayer
        superlayer.appendChild(rootView.layer)
        //
        rootView.viewDidAppear()
    }
    //
    export interface Screen_InitParams
    {
        controllers: AppControllers
        pe_p: PasswordEntry_Presentation
        themeC: themes.Controller
        //
        title: string
    }
    export class ScreenView extends View_Web implements Popovers.PopoverPresenter
    {
        footerbar!: FooterBar.View
        rootView!: View_Web
        rootNavigationView(): StackNavigationView { return this.rootView as StackNavigationView }
        //
        userIdleBreakingDetector!: screen.UserIdleBreakingDetector
        //
        landingView!: LandingView
        //
        pwEntryFormModalView!: PasswordEntryModalNavigationView
        //
        ip!: Screen_InitParams
        app_view__ctx!: App_View_Context
        //
        constructor(ip: Screen_InitParams)
        {
            super({ el_name: "div" })
            const self = this
            self.ip = ip
        }
        setup()
        {
            super.setup()
            const self = this
            self.setup_views()
            self.start_observing()
        }
        setup_views()
        {
            const self = this
            {
                const view = self
                view.layer.style.background = '#ff0000'
                view.layer.style.height = "100%"
            }
            { // document
                document.body.style.background = "black"
            }
            {
                let view = new FooterBar.View({ themeC: self.ip.themeC }).init()
                self.footerbar = view
                self.addSubview(view)
            }
            {
                let view = new StackNavigationView({ themeC: self.ip.themeC }).init()
                view.layer.style.height = `calc(100% - ${FooterBar.styles_footerbar.Metrics.h}px)`
                view.layer.style.position = "absolute"
                self.rootView = view
                self.addSubview(view)
            }
            self.app_view__ctx = // aka .ctx
            { // this can be constructed now that self.navigationView exists
                rootNavigationView: self.rootNavigationView()!, 
                controllers: self.ip.controllers,
                themeC: self.ip.themeC,
                popoverPresenter: self
            }
            {
                let view = new PasswordEntryModalNavigationView(self.app_view__ctx, { pe_p: self.ip.pe_p }).init()
                self.pwEntryFormModalView = view
                view.retain() // because we don't want the view to be torn down once it's removed from its superview for the first time - we want it to stay for the lifecycle of self
                // It will observe events and manage presenting itself
            }
            // initial
            let initially__must_display_landing = self.ip.controllers!.walletsListController.LookedUp_IdsOfAllPersistedRecords().ids!.length == 0
            if (initially__must_display_landing) {
                self.rootNavigationView().push(new LandingView(self.app_view__ctx).init()) // this could also be a .reset([..]) call
            } else {
                if (self.ip.pe_p.password_entry_required == false) {  // since it could be undefined too and that'd be a false 'true'
///////// TODO: this is probably never true here so i'm tempted to change this to a throw
                    self.rootNavigationView().push(new Feed_View(self.app_view__ctx).init()) // this could also be a .reset([..]) call
                }
            }
        }
        start_observing()
        {
            const self = this
            let weakSelf = new WeakRef(self)
            //
            self.ip.pe_p.on(AppState_Event.did_update, () => 
            {
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                let pe_p = optl_self.ip.pe_p
                // console.log("pw entry state updated ", optl_self.ip.pe_p)
                if (pe_p.password_entry_required == true) {
                    //
                    // I assume here that a redirivation of the above initially__must_display_landing would be assertable as false here
                    // ... but otherwise it might be nice to converge all this code into a 'configure by resetting state' function
                    let m = pe_p.password_entry_required_pw_trnstn_mode
                    if (m == PasswordEntry_PWNowRequired_TransitionMode.resetStackAndPresent) {
                        // the .pwEntryFormModalView will handle also calling .present on itself
                        // optl_self.rootNavigationView().reset([ 
                        //     new Feed_View(optl_self.app_view__ctx).init() 
                        // ], false /* this false is important: do NOT remove all modals - because the pw entry modal is going to try to present itself too */)
                        //// ^ This is no longer needed since the PW entry view will .reset to Feed anyway
                    }
                } else {
                    if (pe_p.base_screen_is_landing) { // this happens on a DeleteEverything
                        optl_self.rootNavigationView().reset([ 
                            new LandingView(optl_self.app_view__ctx).init()
                        ], true /* DO remove all modals - no PW entry required */)
                        // PS: if this ends up racing with the .dismiss in PasswordEntryModalNavigationView, then just remove that .dismiss and implement modal removal within .reset
                    } else if (pe_p.base_screen_is_feed) {
                        throw new Error("Not expecting optl_self.ip.pe_p.base_screen_is_feed while optl_self.ip.pe_p.password_entry_required != true")
                    }
                }
            })
            //
            window.onkeydown = function(e)
            {
// TODO: do we want/need this?
                if (e.keyCode == 32 && e.target == document.body) { // disable space bar to scroll in document
                    e.preventDefault()
                }
            }
            //
            self.userIdleBreakingDetector = new UserIdleBreakingDetector({ 
                userIdleController: self.ip.controllers.userIdleController
            })
        }
        //
        _popover?: Popovers.View_Base
        present_popover(view: Popovers.View_Base)
        {
            const self = this
            if (self._popover) {
                throw new Error("A popover is already presented")
            }
            self._popover = view // NOTE: not retaining the view
            view.layer.style.position = "absolute" // over everything
            view.layer.style.zIndex = "99999999999" // ok html
            view.layer.style.left = "0"
            view.layer.style.width = "100%"
            view.layer.style.top = "0"
            view.layer.style.height = "100%"
            self.addSubview(view)
        }
        dismiss_popover()
        {
            const self = this
            if (!self._popover) {
                throw new Error("No popover is presented")
            }
            self._popover!.removeFromSuperview() // this releases the view
            self._popover = undefined
        }
    }
}
//
//
export namespace screen
{
    export interface UserIdleBreakingDetector_IP
    {
        userIdleController: UserIdleController
    }
    export class UserIdleBreakingDetector
    {
        ip!: UserIdleBreakingDetector_IP
        //
        constructor(ip: UserIdleBreakingDetector_IP)
        {
            const self = this
            self.ip = ip
            self.setup()
        }
        setup()
        {
            const self = this
            let weakSelf = new WeakRef(self)
            let eventNames =
            [ // quite a few of these may be redundant or undesirable but casting a wide net for now..
                "click",
                "dblclick",
                "drag",
                "dragend",
                "dragenter",
                "dragleave",
                "dragover",
                "dragstart",
                //
                "drop",
                //
                "mousedown",
                "mouseup",
                
                //
                "keydown",
                "keypress",
                "keyup",
                //

                "scroll",
                "touchcancel",
                "touchend",
                "touchmove",
                "touchstart",
                "wheel",
                //
                "focus",
                "focusin",
                "focusout",
                //
                //

                // these generate too many events and are not necessarily intentionally targeted at interacting with the app:
                // "mouseenter",
                // "mouseleave",
                // "mousemove",
                // "mouseout",
                // "mouseover",
                // "resize",
            ]
            for (let name of eventNames) {
                document.addEventListener(name, () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }    
                    optl_self.ip.userIdleController.DidDetectUserActivity()
                })
            }
        }
    }
}
//
//