import { 
    Persistence_Encrypting_EventName, 
    DidDeconstructBootedStateAndClearPassword_Params 
} from '../../Persistable/AppPersistenceController_Encrypting'
import { Wallet_collectionName } from '../../Wallets/models/Wallet'
import { AppState_Base, AppState_Base__Init_Params } from '../../Application/app_state___shared'
//
interface _AppStateStackMember_Params__PasswordEntry
{
    presentation_animated: boolean,
    isForChangingPassword?: boolean // this is actually always going to be false
    isForAuthorizingAppActionOnly?: boolean
    customNavigationBarTitle_orNull?: string|null
    isFromADeconstructBootedState: boolean
}
//
export enum PasswordEntry_PWNowRequired_TransitionMode
{ // this isn't really used 
    resetStackAndPresent, // as in, .reset the entire root nav stack, back to the 1. Feed and 2. PasswordEntryModal
    justPresent // as in, call .navigate/.present to present the PasswordEntryModal
}
//
export class PasswordEntry_Presentation extends AppState_Base
{
    // pass_r: { [key: string]: any } = {}
    pers_r: { [key: string]: any } = {}
    //
    // TODO: move these out - they dont really belong here
    base_screen_is_feed?: boolean 
    base_screen_is_landing?: boolean
    //
    password_entry_required?: boolean
    password_entry_required_pw_trnstn_mode?: PasswordEntry_PWNowRequired_TransitionMode
    password_entry_required_params?: _AppStateStackMember_Params__PasswordEntry
    //
    // Lifecycle
    constructor(init_params: AppState_Base__Init_Params)
    {
        super(init_params)
        const self = this
        //
        let passwordController = init_params.controllers.passwordController
        if (!passwordController) {
            throw new Error("PasswordEntry_Presentation hook expected non-nil passwordController")
        }
        let persistenceController = init_params.controllers.persistenceController
        if (!persistenceController) {
            throw new Error("PasswordEntry_Presentation hook expected non-nil persistenceController")
        }
        //
        self.startObserving()
    }
    public tearDown()
    {
        const self = this
        // console.log("PasswordEntry_Presentation::useEffect[passwordController]::cleanup()")
        // Object.keys(pass_r).forEach((key: string) => {
        //     passwordController.removeListener(key, pass_r[key])
        //     delete pass_r[key] // it wouldn't be needed anymore
        // })
        Object.keys(self.pers_r).forEach((key: string) => {
            self.init_params.controllers.persistenceController.removeListener(key, self.pers_r[key])
            delete self.pers_r[key] // it wouldn't be needed anymore
        })        
    }
    //
    // Setup
    startObserving()
    {
        // console.log("PasswordEntry_Presentation::useEffect[passwordController]")
        const self = this
        self.pers_r = {}
        //
        let persistenceController = self.init_params.controllers.persistenceController
        let passwordController = self.init_params.controllers.passwordController
        //
        // PasswordController
        // pass_r[Password_EventName.SingleObserver_getUserToEnterExistingPasswordWithCB] = (
        //     isForChangePassword, isForAuthorizingAppActionOnly, 
        //     customNavigationBarTitle_orNull, 
        //     // enterPassword_cb // this is held onto by the passwordController
        // ) => {
            // if (isForChangePassword && isForAuthorizingAppActionOnly) {
            //     throw "Illegal: isForChangePassword && isForAuthorizingAppActionOnly"
            // }
            // const shouldAnimateToNewState = isForChangePassword || isForAuthorizingAppActionOnly
            // { // no longer need to hang onto the callback for when the form is submitted as the pw controller does this - just call passwordController.RespondAsSingleObserver_CallStored_EnterExistingPassword or .RespondAsSingleObserver_CallStored_EnterNewPassword
            //     // self.enterPassword_cb = enterPassword_cb
            // }
            // let routeName = shouldAnimateToNewState ? "PasswordEntryModal" : "PasswordEntryModal_NoAnim"
            // navigate(routeName, {
            //     isForChangingPassword: isForChangePassword,
            //     isForAuthorizingAppActionOnly: isForAuthorizingAppActionOnly,
            //     customNavigationBarTitle_orNull: customNavigationBarTitle_orNull
            // })
        // }
        // pass_r[Password_EventName.SingleObserver_getUserToEnterNewPasswordAndTypeWithCB] = (isForChangePassword, enterPasswordAndType_cb) =>
        // {
        //     console.log("TODO implement passwordentry_presentation .SingleObserver_getUserToEnterNewPasswordAndTypeWithCB")

            // if (self.view === null || typeof self.view === 'undefined') {
            //     self.view = self._new_passwordEntryView()
            // }
            // self.view.GetUserToEnterNewPasswordAndTypeWithCB(
            //     self.root_tabBarViewAndContentView,
            //     isForChangePassword,
            //     enterPasswordAndType_cb
            // )
        // }
        //
        // PersistenceController
        self.pers_r[Persistence_Encrypting_EventName.WillDeconstructBootedStateAndClearPassword] = async (any_params) => {}
        self.pers_r[Persistence_Encrypting_EventName.DidDeconstructBootedStateAndClearPassword] = async (any_params) =>
        {

            // console.log(".DidDeconstructBootedStateAndClearPassword params", any_params)
            //
            // waiting til 'Did' so that OnceBooted trigger will be able to compare state -after- deconstructing booted state
            let params = any_params as DidDeconstructBootedStateAndClearPassword_Params
            if (params.isForADeleteEverything == true) { // nothing to do except present the landing 
                self.base_screen_is_feed = false
                self.base_screen_is_landing = true
                //
                self.password_entry_required = false
                self.password_entry_required_pw_trnstn_mode = undefined
                self.password_entry_required_params = undefined
                //
                self._did_update()
            } else {
                /*await */persistenceController.postSetup__appDataJSON_initFromFile() // trigger reboot (load file leading to pw load) - but do not await - but then block on the pw controller booting as follows:
                let needs = await passwordController.OnceBooted_needsInitialUnlockRequest()
                if (needs == false) {
                    throw new Error("[PasswordEntry_Presentation::useEffect/.on(.DidDeconstructBootedStateAndClearPassword)]: Expected password controller to require initial unlock request")
                }
                let hasWalletsToUnlock = persistenceController.givenStoreLoaded_hasRecordsToDecryptInCollection(Wallet_collectionName)
                if (hasWalletsToUnlock == false) {
                    console.log("Did deconstruct booted state on user idle and rebuild it but not presenting PW unlock for decrypt since no Wallets remain")
                    return
                }
                self.base_screen_is_feed = true
                self.base_screen_is_landing = false 
                //
                self.password_entry_required = true
                self.password_entry_required_pw_trnstn_mode = PasswordEntry_PWNowRequired_TransitionMode.resetStackAndPresent
                self.password_entry_required_params = 
                {
                    presentation_animated: false,
                    isForChangingPassword: false, // this will actually always be false - it can even be removed
                    isForAuthorizingAppActionOnly: false,
                    customNavigationBarTitle_orNull: null,
                    isFromADeconstructBootedState: true
                }
                //
                self._did_update()
            }
        }
        //
        // start observing each
        // Object.keys(pass_r).forEach((key: string) => {
        //     passwordController.on(key, pass_r[key])
        // })
        Object.keys(self.pers_r).forEach((key: string) => {
            persistenceController.on(key, self.pers_r[key])
        })
    }
    public async setup() 
    {
        const self = this
        //
        let persistenceController = self.init_params.controllers.persistenceController
        let passwordController = self.init_params.controllers.passwordController
        //
        // Launch / root view initial load (provided this state's init is placed high enough near the top of the main's setup): initial unlock trigger 
        let onceForAwait = async () => 
        {
            let needs = await passwordController.OnceBooted_needsInitialUnlockRequest()
            if (needs) {
                let hasWalletsToUnlock = persistenceController.givenStoreLoaded_hasRecordsToDecryptInCollection(Wallet_collectionName)
                if (hasWalletsToUnlock == false) {
                    console.log("Initial load revealed an encrypted store that needs unlock but without wallets to unlock - so, leaving the landing screen visible for now - password unlock will be displayed inline")
                    return
                }
                self.base_screen_is_feed = undefined
                self.base_screen_is_landing = undefined
                //
                self.password_entry_required = true
                self.password_entry_required_pw_trnstn_mode = PasswordEntry_PWNowRequired_TransitionMode.justPresent
                self.password_entry_required_params = 
                {
                    presentation_animated: false,
                    isForChangingPassword: false, // this will actually always be false - it can even be removed
                    isForAuthorizingAppActionOnly: false,
                    customNavigationBarTitle_orNull: null,
                    isFromADeconstructBootedState: false // because it's the first screen load
                }
                //
                self._did_update()
            }
        }
        onceForAwait()
    }
}
export default PasswordEntry_Presentation;