import { monero_amount_format_utils__instance, BigInteger, JSBigInt } from '@xrds/monero-utils-ts';
import { MoneroWalletScanStatus, Monero_Persistable_RecvOrSpentOutput_Common, WalletScannerClient_Base, _new__formatted_pct_scanned } from '@xrds/payments-client-ts/src/monero-wallet-clients/WalletScannerClient_Base';
import { n_confs_of_tx } from '@xrds/payments-client-ts/src/monero-wallet-clients/monero_tx_parsing';
import { Currency } from '../../Currencies/models/constants';
import AppWalletMetadataClient, { Metadata_XmrTxDestDescr, XmrTxMetadata } from './AppWalletMetadataClient';
import { Monero_RecvdOutput, Monero_SpentOutput } from '@xrds/payments-client-ts/src/monero-wallet-clients/WalletScannerClient_Base'
import { err_str__fabricated__unknownErrorFallback } from '@xrds/isomorphic-fetch-ts/fetch'
import { Wallet } from './Wallet';


export enum TxsDisplayReadinessStatus
{
    client_not_yet_set_up = 1,
    //
    trmnl_walletBootFailed = 2,
    trmnl_noPubAddrOrInvalidWallet = 3,
    trmnl__not_hasReceivedCompleteTxsSet = 3,
    optlCont__not_hasFinishedScanning = 4,
    optlCont__not_hasOnceFetchedMetadata = 5,
    //
    readyToDisplay = 6
}
export interface DisplayableTxs_RetVal
{ 
    txs: DisplayableTransaction[],
    sumsByToAddr: MetadataSumsSentByToAddr
 }
export interface MetadataSums_ForTx
{ 
    pend: JSBigInt,
    confd: JSBigInt,
    expd: JSBigInt,
    //
    // the following are for convenience - since checking the above for amounts != 0 may not be sufficient by some quirk of the sp_outs having 0 amount (is that not illegal tho)
    any_sp_o__is_expd?: boolean
    any_sp_o__is_confd?: boolean
    any_sp_o__is_pend?: boolean
    //
    tx_timestamp: Date
}
export interface MetadataSumsSent_forToAddr
{
    // to be clear... these are the base amounts for *all* txs for the given to-addr
    pend: JSBigInt,
    confd: JSBigInt,
    expd: JSBigInt,
    //
    // and these are paying-tx-specific
    trues_by_thisToAddrInTxsWHashes: { [key: string]: boolean }
    sums_by_thisToAddrInTxsWHashes: { [key: string]: MetadataSums_ForTx } // slightly ambiguous name but if the amounts are 0, the tx still *was* found in the list..
    //
    dateOfMostRecentTxToThis: Date
}
export interface MetadataSumsSentByToAddr
{
    [toAddr: string] : MetadataSumsSent_forToAddr
}
export interface DisplayableTransaction
{
    date: Date
    //
    height: BigInteger|undefined
    n_confirmations: BigInteger|undefined
    //
    any_expired: boolean
    any_confirmed: boolean
    any_pending: boolean
    //
    recv_outputs: Displayable_Monero_RecvdOutput[]
    spent_outputs: Displayable_Monero_SpentOutput[]
    //
    hash: string
    paymentID?: string
    total_recv_minus_sent: BigInteger, // if you need it
    amount_float_str: string
    fee_float_str?: string
    fee?: BigInteger
    currency: Currency
    secretKey?: string
    toAddr?: string // this is the metadata's primary dest addr
    metadata: XmrTxMetadata // unparsed and raw from the metadata client - a superset of toAddr
}
export enum Displayable_Output_StatusText
{
    EXPIRED = "EXPIRED",
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED"
}
export interface Displayable_Monero_RecvdOutput extends Monero_RecvdOutput
{
    status: Displayable_Output_StatusText
}
export interface Displayable_Monero_SpentOutput extends Monero_SpentOutput
{
    status: Displayable_Output_StatusText
}
function _statusText_for_displayable_o(o: Monero_Persistable_RecvOrSpentOutput_Common): Displayable_Output_StatusText
{
    let is_pending = o.confd !== true || o.unlockd !== true
    let is_marked_rejected = o.expired == true
    let s = is_marked_rejected 
        ? Displayable_Output_StatusText.EXPIRED 
        : (is_pending 
            ? Displayable_Output_StatusText.PENDING 
            : Displayable_Output_StatusText.CONFIRMED
        )
    return s
}
//
export interface MoneroWalletScanStatus_Displayable
{
    start_height?: BigInteger
    scan_height?: BigInteger
    blockchain_height?: BigInteger
    //
    pct_scanned: number
    pct_scanned_displayable_pct_str: string
}
//
export function new_balanceQuickLabelStringForWallet(w: Wallet.Instance, andDisplay_scanProgress: boolean = true, andFormatFullBalancesInsteadOfTruncated: boolean = false): string
{
    // TODO: probably move this to the Wallet class 
    if (w.didFailToBoot_errStr) {
        return "Error: " + w.didFailToBoot_errStr + " - Try reloading"
    }
    // Possibly deprecate this ^ in favor of scanner connection status or move that to here or this to there
    //
    let inProgress = false;
    let dss: MoneroWalletScanStatus_Displayable
    if (andDisplay_scanProgress) {
        let optl_dss = w.lookup__scan_status()
        if (!optl_dss) {
            return "Loading..."
        }
        dss = optl_dss;
        if (!dss.blockchain_height) {
            return "Loading blockchain info..."
        }
        if (!dss.scan_height) { // i mean, this should exist if the blockchain_height does
            return "Loading scan height..."
        }
        // console.log("dss.scan_height" , dss.scan_height)
        // console.log("dss.blockchain_height" , dss.blockchain_height)
        inProgress = dss.scan_height < dss.blockchain_height ? true : false
    }
    let flat_balance = w.wallet_scanner_client?.FlatBalancesFor(w.public_address!)
    if (!flat_balance) {
        return "Loading..."
    }
    let scan_progress_str = andDisplay_scanProgress 
        ? (inProgress && dss!.pct_scanned_displayable_pct_str != "100.00" /* since that can happen and it can be a bit weird in the UI when the server is 1 block behind */ 
            ? ` (Scan progress: ${dss!.pct_scanned_displayable_pct_str}%)` 
            : ''
          )
        : ''
    ;
    let fmt_fn = andFormatFullBalancesInsteadOfTruncated
        ? monero_amount_format_utils__instance.formatMoneyFullSymbol 
        : monero_amount_format_utils__instance.formatMoneySymbol
    ;
    if (flat_balance.will_show_pending) {
        let locked_balance = flat_balance.unlocked_plus_pending_balance.subtract(flat_balance.unlocked_balance)
        // TODO: pending and locked are colloquially combined more as a usability thing in this client - but unlock_time should be checked per output against the blockchain height
        //
        return `${fmt_fn.call(monero_amount_format_utils__instance, flat_balance.unlocked_balance)} spendable; ${fmt_fn.call(monero_amount_format_utils__instance, locked_balance!)} pending` + scan_progress_str
    }
    return `${fmt_fn.call(monero_amount_format_utils__instance, flat_balance.unlocked_balance)}` + scan_progress_str
}
//
export function new__scan_status__from(scan_status: MoneroWalletScanStatus|undefined): MoneroWalletScanStatus_Displayable
{
    if (!scan_status) {
        return {
            start_height: undefined, 
            scan_height: undefined,
            blockchain_height: undefined,
            //
            pct_scanned: 0,
            pct_scanned_displayable_pct_str: "--"
        }
    }
    if (!scan_status.blockchain_height || !scan_status.scan_height) { // though only both or neither should exist
        return {
            start_height: scan_status.start_height,
            scan_height: undefined,
            blockchain_height: undefined,
            //
            pct_scanned: 0,
            pct_scanned_displayable_pct_str: '--'
        }
    }
    let formatted_pct_scanned = _new__formatted_pct_scanned(scan_status)
    //
    return {
        start_height: scan_status.start_height, 
        scan_height: scan_status.scan_height, 
        blockchain_height: scan_status.blockchain_height,
        //
        pct_scanned: formatted_pct_scanned.pct_scanned!,
        pct_scanned_displayable_pct_str: formatted_pct_scanned.pct_scanned_displayable_str!
    }
}
//
export namespace WalletDisplayable_ScanAndTxs
{
    export function new__displayable_txs_ready_status(
        isBootFailed: boolean,
        public_address: string|undefined,
        wallet_scanner_client: WalletScannerClient_Base|undefined
    ): TxsDisplayReadinessStatus
    {
        if (isBootFailed) {
            return TxsDisplayReadinessStatus.trmnl_walletBootFailed
        }
        if (!public_address) {
            return TxsDisplayReadinessStatus.trmnl_noPubAddrOrInvalidWallet
        }
        if (!wallet_scanner_client) {
            return TxsDisplayReadinessStatus.client_not_yet_set_up
        }
        if (wallet_scanner_client.has_received_complete_txs_set_for(public_address!) !== true) {
            return TxsDisplayReadinessStatus.trmnl__not_hasReceivedCompleteTxsSet
        }
    
        // TODO: check scan progress to support .optlCont__not_hasFinishedScanning
        // console.log("TODO: check scan progress for .optlCont__not_hasFinishedScanning in lookup__displayable_txs_ready_status" , )
    
        // TODO: check metadata initial complete set load after subscr and doc fully updated from it .. new event? to support .optlCont__not_hasOnceFetchedMetadata
        // console.log("in lookup__txs_,... TODO: check metadata initial complete set load after subscr and doc fully updated from it .. new event? to support .optlCont__not_hasOnceFetchedMetadata")
    
        return TxsDisplayReadinessStatus.readyToDisplay
    }    
    export function new__if_ready__displayable_txs(
        s: TxsDisplayReadinessStatus,
        public_address: string,
        proper_oper_addr: string, // this sometimes diverges from public_address
        sec__spend_key: string|undefined,
        metadata_client: AppWalletMetadataClient,
        wallet_scanner_client: WalletScannerClient_Base
    ): DisplayableTxs_RetVal { // throws; first check .lookup__txs_export_ready_status for optlCont or ready
        // console.log("new__if_ready__displayable_txs s" , s)
        // console.log("new__if_ready__displayable_txs: TODO: add optl continue display if metadata are not yet initially fetched/available")
        switch (s) {
            case TxsDisplayReadinessStatus.readyToDisplay:
            case TxsDisplayReadinessStatus.optlCont__not_hasFinishedScanning:
            case TxsDisplayReadinessStatus.optlCont__not_hasOnceFetchedMetadata: // TODO: perhaps this should be commented?
            {
                let sumsByToAddr: MetadataSumsSentByToAddr = {}
                let wallet_scanner_client__persister = wallet_scanner_client.persister_by_addr[public_address]!
                let txs = (wallet_scanner_client__persister?.sorted_txs() || []).map(tx => {
                    //
                    let any_expired: boolean = false
                    let any_confirmed: boolean = false
                    let any_pending: boolean = false
                    //
                    let total_recv_minus_sent = new BigInteger(0)
                    // console.log(tx.hash, "tx fee" , tx.fee.toString())
                    for (let recv_o of tx.recv_outputs) {
                        any_expired ||= (recv_o.expired == true)
                        any_confirmed ||= recv_o.confd
                        any_pending ||= recv_o.unlockd != true || !recv_o.confd
                        //
                        // console.log(tx.hash, "recv_o.amount " , recv_o.amount)
                        //
                        total_recv_minus_sent = total_recv_minus_sent.add(new BigInteger(recv_o.amount))
                    }
                    //
                    let for_md_sums_sent_per_to_addr__tx_any_sp_o__is_expd = false
                    let for_md_sums_sent_per_to_addr__tx_any_sp_o__is_confd = false
                    let for_md_sums_sent_per_to_addr__tx_any_sp_o__is_pend = false
                    for (let sp_o of tx.spent_outputs) {
                        let is_expd = (sp_o.expired == true)
                        let is_confd = sp_o.confd
                        let is_pend = sp_o.unlockd != true || !is_confd
                        //
                        any_expired ||= is_expd
                        any_confirmed ||= is_confd
                        any_pending ||= is_pend
                        //
                        // the following, at least, are specific to the spend outs... which is mainly what we care about. Sadly this is probably the best we can do in terms of associating sp_o state with the metadata derived destinations' amounts
                        for_md_sums_sent_per_to_addr__tx_any_sp_o__is_expd ||= is_expd
                        for_md_sums_sent_per_to_addr__tx_any_sp_o__is_confd ||= is_confd
                        for_md_sums_sent_per_to_addr__tx_any_sp_o__is_pend ||= is_pend
                        //
                        // console.log(tx.hash, "sp_o" , sp_o, "is_expd", is_expd, "is_confd", is_confd, "is_pend", is_pend)
                        //
                        total_recv_minus_sent = total_recv_minus_sent.subtract(new BigInteger(sp_o.amount))
                    }
                    //
                    let fee_float_str: string|undefined = tx.fee 
                        ? monero_amount_format_utils__instance.formatMoneyFull(tx.fee) 
                        : undefined
                    //
                    let has_any_pool_outputs = false
                    for (let o of tx.recv_outputs) {
                        if (o.mempool == true) {
                            has_any_pool_outputs = true
                            break
                        }
                    }
                    if (!has_any_pool_outputs) { // can skip checking if it's already true
                        for (let o of tx.spent_outputs) {
                            if (o.mempool == true) {
                                has_any_pool_outputs = true
                                break
                            }
                        }
                    }
                    //
                    let n_confirmations: BigInteger|undefined = undefined
                    if (tx.height !== null && typeof tx.height !== 'undefined' && tx.height.compare(new BigInteger(0)) != 0) {
                        let fabrctd__is_mempool = false // n confs is invariant across the tx record so we only need to know if there's a non-0 height 
                        n_confirmations = n_confs_of_tx( // we'll assume this will not return -1 as we have done the just-above checks
                            fabrctd__is_mempool,  
                            tx.height!, 
                            wallet_scanner_client__persister.scan_status()!.blockchain_height! // PS: should be safe to assume this exists here
                        )
                    }
                    //
                    let tx_metadata: XmrTxMetadata|undefined = undefined
                    if (sec__spend_key && typeof sec__spend_key !== 'undefined') {
                        tx_metadata = metadata_client.xmrTx(proper_oper_addr, tx.hash)
                    }
                    let primary_dest: Metadata_XmrTxDestDescr|undefined = undefined
                    let toAddr: string|undefined = undefined
                    if (tx_metadata && tx_metadata.dests) {
                        primary_dest = tx_metadata.dests.find(d => d.is_primary === true) // I suppose in theory this might be undefined in some other or future app UI
                        toAddr = primary_dest?.addr
                        //
                        for (let d of tx_metadata.dests) {
                            let toAddr = d.addr
                            let toAddr_sums: MetadataSumsSent_forToAddr|undefined = sumsByToAddr[toAddr]
                            if (!toAddr_sums) {
                                toAddr_sums = {
                                    pend: new JSBigInt(0),
                                    confd: new JSBigInt(0),
                                    expd: new JSBigInt(0),
                                    trues_by_thisToAddrInTxsWHashes: {},
                                    sums_by_thisToAddrInTxsWHashes: {},
                                    dateOfMostRecentTxToThis: new Date(0) // monero didn't exist at this time so any timestamp ought to be at least later than this - so it's a good zero value
                                }
                                sumsByToAddr[toAddr] = toAddr_sums
                            }
                            if (tx.timestamp.getTime() > toAddr_sums.dateOfMostRecentTxToThis.getTime()) {
                                toAddr_sums.dateOfMostRecentTxToThis = tx.timestamp
                            }
                            // if (tx.is_incoming_tfer) {
                            //     throw new Error("Expected a tx w a toAddr to not be .is_incoming_tfer")
                            // }
                            if (!toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash]) {
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash] = { // so that even if amounts are 0 by some odd chance, tx existence can still be detected by hash
                                    pend: new BigInteger(0),
                                    confd: new BigInteger(0),
                                    expd: new BigInteger(0),
                                    tx_timestamp: tx.timestamp // should only need to be set once per tx..
                                    // TODO: ^ this is for convenience only, so maybe there'd be a better way to provide all this to the consumers, i.e. in the original MoneroTxs by some other easy lookup mechanism, since they'd have the hash already
                                }
                            }
                            toAddr_sums.trues_by_thisToAddrInTxsWHashes[tx.hash] = true
                            //
                            if (for_md_sums_sent_per_to_addr__tx_any_sp_o__is_expd) {
                                toAddr_sums.expd = toAddr_sums.expd.add(new BigInteger(d.amount))
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].expd = toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].expd.add(new BigInteger(d.amount)) // I'm just going to assume the same tx won't end up in more than one status...?
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].any_sp_o__is_expd = true
                            } else if (for_md_sums_sent_per_to_addr__tx_any_sp_o__is_pend) {
                                toAddr_sums.pend = toAddr_sums.pend.add(new BigInteger(d.amount))
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].pend = toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].pend.add(new BigInteger(d.amount)) // I'm just going to assume the same tx won't end up in more than one status...?
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].any_sp_o__is_pend = true
                            } else if (for_md_sums_sent_per_to_addr__tx_any_sp_o__is_confd) {
                                toAddr_sums.confd = toAddr_sums.confd.add(new BigInteger(d.amount))
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].confd = toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].confd.add(new BigInteger(d.amount)) // I'm just going to assume the same tx won't end up in more than one status...?
                                toAddr_sums.sums_by_thisToAddrInTxsWHashes[tx.hash].any_sp_o__is_confd = true
                            } else {
                                console.warn("Somewhat expected status to be either expd, pend, or confd - for tx with hash ", tx.hash, " with dest ", d)
                            }
                        }
                    }
                    //
                    let displ__recv_outputs: Displayable_Monero_RecvdOutput[] = tx.recv_outputs.map(o => ({
                        ...o,
                        status: _statusText_for_displayable_o(o)
                    }))
                    let displ__spent_outputs: Displayable_Monero_SpentOutput[] = tx.spent_outputs.map(o => ({
                        ...o,
                        status: _statusText_for_displayable_o(o)
                    }))
                    //
                    function final_displayable_paymentId()
                    {
                        if (tx_metadata?.sent_payment_id && typeof tx_metadata?.sent_payment_id !== 'undefined') {
                            return tx_metadata?.sent_payment_id
                        } else if (tx.payment_id && typeof tx.payment_id !== 'undefined') {
                            // now we need to see if we can tell that we want to filter out the payment_id
                            if (has_any_pool_outputs) { // I've opted to check if *any* pool outs exist here rather than if !(any are in the block) - that is basically the behavior of the server before
                                if (tx.spent_outputs.length > 0) {
                                    return undefined // if it's outgoing and mempool then we cannot know the payment_id if we dont already have it in the tx
                                } else {
                                    if (primary_dest?.addr) {
                                        if (primary_dest?.addr != public_address) { // this case is unlikely to ever even be possible given that .payment_id would exist on tx_metadata?.payment_id anyway .. but just in case?
                                            return undefined // basically this is the case that we know public_address sent the transaction and this is erroneously showing up as an outgoing tx because the mempool scanner has some sort of bug related to initial outgoing amounts? unclear if this is even happening anymore now that WalletScannerClient_Base has been enhanced to allow scanner to overwrite client's tx.payment_id
                                        }
                                    }
                                    return tx.payment_id // might be undefined
                                }
                            }
                            return tx.payment_id
                        }
                        return undefined // since it's undefined, return undefined
                    }
                    let displayable_paymentId: string|undefined = final_displayable_paymentId()
                    //
                    return {
                        date: tx.timestamp,
                        //
                        height: tx.height, // may be undefined or BigInteger
                        n_confirmations: n_confirmations,
                        //
                        any_expired: any_expired,
                        any_confirmed: any_confirmed,
                        any_pending: any_pending,
                        //
                        recv_outputs: displ__recv_outputs,
                        spent_outputs: displ__spent_outputs,
                        //
                        hash: tx.hash,
                        paymentID: displayable_paymentId,
                        amount_float_str: tx.full_amount_float_str,
                        total_recv_minus_sent: total_recv_minus_sent,
                        fee_float_str: fee_float_str, // could be undefined
                        fee: tx.fee, // might be nil
                        //
                        currency: Currency.XMR,
                        secretKey: tx_metadata?.tx_sec_key, // could be undefined
                        toAddr: toAddr,
                        //
                        metadata: tx_metadata
                    } as DisplayableTransaction
                })
                return {
                    txs: txs,
                    sumsByToAddr: sumsByToAddr
                }
            }
            default:
                console.warn("called new__if_ready__displayable_txs but their status was not ready")
                return {
                    txs: [],
                    sumsByToAddr: {}
                }
        }
    }
}

export const formatDate__displayableTransaction = (date: Date) => 
{

// TODO: consider displaying relative date in parens.. using e.g. date-fns https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore

    return Intl.DateTimeFormat(
        navigator.language, 
        {
            year: 'numeric', weekday: 'short', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric", hour12: true
        }
    ).format(date)
}