//
//
const { v4: uuidV4 } = require('uuid')
//
type DocumentId = string;
type CollectionName = string;
const new_DocumentId = function(): DocumentId { return uuidV4() };
type DocumentJSON = {[index: string]: any};
//
//
export { 
    DocumentId,
    new_DocumentId,
    DocumentJSON,
    CollectionName
};