//
//
import { WalletBeingAdded_Params } from '@xrds/xrds-libapp-ts/src/Wallets/models/WalletsListController'
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { SpecificCommon_LinkButtonCells } from '../Common/SpecificCommon_LinkButtonCells'
import { Feed_View } from '../Feed/FeedView'
import { EnterExistingPassword_Base_View } from '../Passwords/EnterExistingPassword_Base_View'
import { BigInteger } from '@xrds/monero-utils-ts'
import { _LandingComponents } from './_LandingComponents'
import { themes } from '../themes'
//
export interface EnterExistingPassword_AndLogIn_View_Addtl_IP
{
    add_pending_wallet_params: WalletBeingAdded_Params
    walletName: string|null
    customServerURL?: string
    privateServerAuthToken?: string
    optl__start_height?: BigInteger
    initial__inbox_and_metadata__disable_remote_connectivity: boolean
}
//
export class EnterExistingPassword_AndLogIn_View extends EnterExistingPassword_Base_View
{
    ip!: EnterExistingPassword_AndLogIn_View_Addtl_IP
    //
    constructor(ctx: App_View_Context, ip: EnterExistingPassword_AndLogIn_View_Addtl_IP)
    {
        super(ctx, {
            isForChangePW: false,
            promptTitle: "It looks like you already have a password set",
            promptDescription: "Please enter your password to continue."
        })
        const self = this
        self.ip = ip
    }
    _overridable_setup_views__after_inputs(): void
    {
        const self = this
        
        let container_view = new View_Web({}).init() // paddingLeft: 15, marginTop: 14
        {
            let view = container_view
            // view.layer.style.marginTop = TODO
            view.layer.style.paddingLeft = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
            view.layer.style.paddingRight = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
        }
        // if (self.ip.customServerURL) {
        //     let view = _LandingComponents.new__cell_details_scannerURL(self.ctx.themeC, self.ip.customServerURL)
        //     view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })            container_view.addSubview(view)
        // }
        // if (self.ip.privateServerAuthToken) {
        //     let view = _LandingComponents.new__cell_details_scannerAuthCode(self.ctx.themeC, self.ip.privateServerAuthToken)
        //     view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })            container_view.addSubview(view)
        //     container_view.addSubview(view)
        // }
        self.addSubview(container_view)
    }
    _overridable_setup_views__after_primaryCTA()
    {
        const self = this
        //
        let container_view = new View_Web({}).init() // paddingLeft: 15, marginTop: 14
        {
            let view = container_view
            // view.layer.style.marginTop = TODO

            view.layer.style.paddingLeft = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
            view.layer.style.paddingRight = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_forgotPassword(
                self.ctx.themeC, 
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController
            )
            container_view.addSubview(view)
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_deleteEverything(
                self.ctx.themeC, 
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController,
                true
            )
            container_view.addSubview(view)
        }
        self.addSubview(container_view)
    }
    //
    // Delegation - Overrides
    override async overridable_didEnterCorrectPassword_fn(enteredPassword: string)
    {
        const self = this
        self.__mark_form_as_currently_submitting() // temporarily disable back button, cta, etc - since we cant cancel requests yet
        //
        // self.ctx.controllers.userIdleController.TemporarilyDisable_userIdle()
        // // Temporarily disabled and using a larger minimum idle timer instead; If the app goes idle when the walletListController call comes back, the biz logic ought to be able to handle that instead
        let r = await self.ctx.controllers.walletsListController.WhenBooted_GivenPW_AddWalletWithParams({
            add_pending_wallet_params: self.ip.add_pending_wallet_params,
            walletLabel: self.ip.walletName, 
            customServerURL: self.ip.customServerURL,
            privateServerAuthToken: self.ip.privateServerAuthToken,
            optl__start_height: self.ip.optl__start_height,
            initial__inbox_and_metadata__disable_remote_connectivity: self.ip.initial__inbox_and_metadata__disable_remote_connectivity
        })
        // // self.ctx.controllers.userIdleController.ReEnable_userIdle()
        //
        if (/*r.did_user_cancel_pw_entry || */r.err_str) {
            self.__bail_from_form_submission_with({ err_str: r.err_str })
            return
        }
        //
        // Note: not setting the state back to enabled here bc the user is finished with this screen
        //
        self.isFormSubmitting = false // allow actual dismissal of the form again
        self.configureWith_formSubmittingState({})
        //
        // we know that this was not presented in a modal since the password already exists but the app was not yet unlocked - which means we got here via the Landing process somehow - basically, probably because the first /login call failed despite the password having been entered
        self.ctx.rootNavigationView.reset([ // clear 'back' stack - now that we've landed 
            new Feed_View(self.ctx).init()
        ], true /* DO remove all modals */)
    }
    //
    //
    override navigationBar_title(): string
    {
        return "Enter Password"
    }
}