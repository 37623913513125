import EventEmitter from 'events'
import { DocumentJSON, new_DocumentId, CollectionName } from './Documents'
import AppPersistenceController_Encrypting from './AppPersistenceController_Encrypting';
//
enum Events_PersistableObject
{
    willBeDeleted = "willBeDeleted",
    wasDeleted = "wasDeleted"
}
//
class PersistableObject extends EventEmitter
{
    persistenceController!: AppPersistenceController_Encrypting
    //
    _id?: string
    insertedAt_date?: Date
    //
    didFailToInitialize_flag?: boolean
    didFailToBoot_flag?: boolean
    didFailToBoot_errStr?: string|null
    //
    constructor(
        persistenceController: AppPersistenceController_Encrypting,
        saved_dict: DocumentJSON | undefined // may be undefined if inserting/creating a new object rather than reading
    ) {
        super()
        //
        const self = this
        //
        self.persistenceController = persistenceController
        //
        if (typeof saved_dict !== 'undefined' && saved_dict) {
            self._id = saved_dict["_id"] as string
            //
            let insertedAt_date_JSONString = saved_dict["insertedAt_date"];
            if (insertedAt_date_JSONString) {
                if (typeof insertedAt_date_JSONString !== 'string') {
                    throw 'insertedAt_date was not a string'
                }
                self.insertedAt_date = new Date(insertedAt_date_JSONString)
            }
            self._overridable_hydrateFrom_saved_dict(saved_dict)
        }
    }
    willDeconstructBootedStateAndClearPassword()
    {
        const self = this
        self._overridable_willDeconstructBootedStateAndClearPassword()
    }
    __overridable_shared_deconstructBootedState__notDeletingIdOrInsertedAtDate()
    {
    }
    _overridable_hydrateFrom_saved_dict(saved_dict)
    {
    }
    _overridable_willDeconstructBootedStateAndClearPassword()
    {
        const self = this
        self.__overridable_shared_deconstructBootedState__notDeletingIdOrInsertedAtDate()
    }
    //
    // Accessors
    collectionName(): CollectionName
    {
        throw "You must override PersistableObject.collectionName()"
    }
    async new_plaintext_dictRepresentationBase64String(): Promise<string>
    { // throws
        const self = this
        let dict = self.new_dictRepresentation() // plaintext
        let str = JSON.stringify(dict)

        return str
    }
    _overridable_shouldEncryptAndDecrypt()
    {
        return true
    }
    new_dictRepresentation(): DocumentJSON
    {
        const self = this
        var dict: DocumentJSON = {}
        dict["_id"] = self._id!
        if (self.insertedAt_date && typeof self.insertedAt_date !== 'undefined') {
            dict["insertedAt_date"] = self.insertedAt_date!.toJSON()
        }
        //
        // Note: Override this method and add data you would like encrypted – but inherit base by calling on super 
        return dict
    }
    //
    // Accessors - Persistence state
    shouldInsertNotUpdate(): boolean
    {
        const self = this
        //
        return self._id == null || typeof self._id === 'undefined'
    }
    //
    // Imperatives - Saving
    async saveToDisk(): Promise<{ err_str: string | undefined }>
    {
        const self = this
        if (self.shouldInsertNotUpdate()) {
            return await self._saveToDisk_insert()
        }
        return await self._saveToDisk_update()
    }
    // For these, we presume consumers/parents/instantiators have only created this wallet if they have gotten the password
    async _saveToDisk_insert(): Promise<{ err_str: string | undefined }> // -> err_str?
    {
        const self = this
        if (self._id != null && typeof self._id !== 'undefined') {
            throw new Error(`Expected nil _id in _saveToDisk_insert`)
        }
        // only generate _id here after checking shouldInsertNotUpdate since that relies on _id
        self._id = new_DocumentId() // generating a new UUID
        // and since we know this is an insertion, let's any other initial centralizable data
        self.insertedAt_date = new Date()
        // and now that those values have been placed, we can generate the dictRepresentation
        try {
            let str = await self.new_plaintext_dictRepresentationBase64String()
            let ret = await self.persistenceController.NotDuringChangePW_EncryptingStoreInterface_WriteStringFor( // this will automatically handle encrypting the data at rest
                self.collectionName(), self._id!, 
                str,
                true // do encrypt
            )
            if (ret.err_str != null && typeof ret.err_str !== 'undefined') {
                console.error(`[Persistable/PersistableObject] Error while saving new object: ${ret.err_str!}`)
                return { err_str: ret.err_str }
            } else {
                // console.log("[Persistable/PersistableObject] Saved new ", self)
                return { err_str: undefined } // no error
            }
        } catch (e) {
            let err_str = (e as Error).message // TODO: localize
            console.error(`[Persistable/PersistableObject] Caught error while saving new object: ${err_str}`)
            return { err_str: err_str } // TODO? possibly change saveToDisk() -> String? to saveToDisk() throws
        }
    }
    async _saveToDisk_update(): Promise<{ err_str: string | undefined }>
    {
        const self = this
        if (self._id == null || typeof self._id === 'undefined') {
            throw new Error(`Expected non-nil _id in _saveToDisk_update`)
        }
        try {
            let str = await self.new_plaintext_dictRepresentationBase64String()
            let ret = await self.persistenceController.NotDuringChangePW_EncryptingStoreInterface_WriteStringFor(
                self.collectionName(),
                self._id!,
                str,
                true // do encrypt
            )
            if (ret.err_str != null && typeof ret.err_str !== 'undefined') {
                console.error(`[Persistable/PersistableObject] Error while saving update: ${ret.err_str!}`)
                return { err_str: ret.err_str }
            } else {
                // console.log("[Persistable/PersistableObject] Saved update to", self.collectionName())
                return { err_str: undefined }
            }
        } catch (e) {
            let err_str = (e as Error).message // TODO: localize
            console.error(`[Persistable/PersistableObject] Caught error while saving update to object: ${err_str}`)
            return { err_str: err_str } // TODO? possibly change saveToDisk() -> String? to saveToDisk() throws
        }
    }
    //
    async delete(): Promise<{ err_str: string | undefined }>
    {
        const self = this
        self.__overridable_shared_deconstructBootedState__notDeletingIdOrInsertedAtDate() // this is critical - and is named so consumers know not to delete _id or the insertedAt_date since this calling function relies upon those values being present - and since they ought not to compromise any privacy/security
        //
        if (self.insertedAt_date == null || typeof self.insertedAt_date === 'undefined' || self._id == null || typeof self._id === 'undefined') {
            console.warn(`[Persistence/PersistableObject] Asked to delete but had not yet been saved.`)
            // posting notifications so UI updates, e.g. to pop views etc
            self.emit(Events_PersistableObject.willBeDeleted)
            self.emit(Events_PersistableObject.wasDeleted)
            return { err_str: undefined } // no error
        }
        // assert(self._id != null)
        self.emit(Events_PersistableObject.willBeDeleted)
        let ret = await self.persistenceController.RemoveValsFor(
            self.collectionName(),
            [ self._id! ]
        )
        if (ret.err_str != null && typeof ret.err_str !== 'undefined') {
            console.error(`[Persistence/PersistableObject] Error while deleting object: ${ret.err_str}`)
            return { err_str: ret.err_str }
        }
        console.info("[Persistence/PersistableObject] Deleted", self)
        // NOTE: handlers of this should dispatch async so err_str can be returned -- it would be nice to post this on next-tick but self might have been released by then
        self.emit(Events_PersistableObject.wasDeleted)
        //
        return { err_str: undefined }
    }
}
export {
    PersistableObject, 
    Events_PersistableObject
};