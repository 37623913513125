//
//
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { AbstractThemed } from './AbstractThemed'
import { LinkButton } from './LinkButtonCell'
import { StyledRadio_Control } from './StyledRadio_Control'
import { _LabelRowContainer, _LabelRowContainer_EventName, _LabelRowContainer_Props, _LabelRowContainer_WrappingMode, _LabelRowContainer_labels_Props } from './_LabelRowContainer'
//
export namespace AbstractControl
{
    export enum LocationWithinTitledCell
    {
        underTitle = "underTitle", // default for some cell variants
        leftOfAccessoryRadio = "leftOfAccessoryRadio" // within the title bar, incidentally, but is configurable to flow to next line as necessary
    }
    //
    export interface Base_Cell_InitParams
    {
        themeC: themes.Controller
        _labelRow_wrappingMode?: _LabelRowContainer_WrappingMode
        contextInUI?: themes.ElementContextInUI
        labelRow_titleLabel_appearance?: LinkButton.Appearance
        accessoryRadio_roleWithinUICell?: StyledRadio_Control.RadioControlRoleWithinUICell
    }
    //
    export class Base_Cell extends AbstractThemed.View
    {
        _ac_base_ip!: AbstractControl.Base_Cell_InitParams
        //
        rootContentsContainer!: View_Web
        //
        _labelRow!: _LabelRowContainer
        lookup_accessoryRadio_selectedValue(): StyledRadio_Control.ButtonValue|undefined // proxying this function 
        {
            return this._labelRow.accessoryRadio ? this._labelRow.accessoryRadio.selectedValue() : undefined // Note lack of visibility and interactivity checks; and no optl unwrap or check
        }
        //
        _primaryInputContainer!: View_Web
        //
        _primaryInputContainer_subcontainer_0!: View_Web
        //
        //
        constructor(ip: AbstractControl.Base_Cell_InitParams)
        {
            super({ })
            const self = this
            self._ac_base_ip = ip
        }
        setup(): void 
        {
            super.setup()
            const self = this
            let weakSelf = new WeakRef(self)
            let themeC = self._ac_base_ip.themeC
            {
                let view = self
            }
            {
                let view = new View_Web({}).init()
                view.layer.style.minHeight = themeC.current.layouts.cellGroup_row_min_h + "px" // at least, when within form context..?
                view.layer.style.display = "flex"
                view.layer.style.flexDirection = 'column' // note: column, here, because we are stacking items vertically in the rootContentsContainer
                view.layer.style.alignItems = 'center'
                view.layer.style.verticalAlign = 'middle'
                view.layer.style.justifyContent = 'center' // align towards the middle of the cell, vertically
                self.addSubview(view)
                self.rootContentsContainer = view
            }
            { 
                let view = new _LabelRowContainer({ 
                    themeC: themeC, 
                    wrappingMode: self._ac_base_ip._labelRow_wrappingMode, // may be undefined
                    contextInUI: self._ac_base_ip.contextInUI,
                    accessoryRadio_roleWithinUICell: self._ac_base_ip.accessoryRadio_roleWithinUICell,
                    postTitleButtonText_tapped_fn: () =>
                    {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return
                        }
                        optl_self._postTitleButtonText_tapped() // proxy to instance method to allow overrides
                    }
                }).init()
                view.on(_LabelRowContainer_EventName.accessoryRadio_changed, (v) => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self._accessoryRadio_changed(v) // proxy to instance method to allow overrides
                })
                self._labelRow = view
                self.rootContentsContainer.addSubview(view)
            }
            {
                let view = new View_Web({}).init()
                view.layer.style.width = "100%" // take up whole line ( is this styling needed ? ); though this can be overridden and rewritten
                view.layer.style.display = "flex"
                view.layer.style.flexDirection = 'row'
                view.layer.style.alignItems = 'center'
                view.layer.style.justifyContent = 'space-between'
                self._primaryInputContainer = view
                self.rootContentsContainer.addSubview(view)
            }
            {
                let view = new View_Web({}).init()
                view.layer.style.flexGrow = "10" // rather than width=100% so that there can be siblings of view
                view.layer.style.display = "flex" 
                view.layer.style.flexDirection = 'row'
                view.layer.style.alignItems = 'center'
                switch (self._ac_base_ip.contextInUI) {
                    case themes.ElementContextInUI.SplashBody_Mini:
                        view.layer.style.justifyContent = "center"
                        break
                }
                self._primaryInputContainer_subcontainer_0 = view
                self._primaryInputContainer.addSubview(view)
            }
        }
        //
        set_props_labelRow(_p: { // merely here for convenience
            title: string|null|undefined,
            postTitleButtonText: string|undefined|null,
            tooltipText: string|null|undefined
        }) {
            const self = this
            let props = _p as _LabelRowContainer_labels_Props
            if (self._ac_base_ip.labelRow_titleLabel_appearance) {
                props.title_appearance = self._ac_base_ip.labelRow_titleLabel_appearance
            }
            self._labelRow.set_props({ labels: props })
        }
        //
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            self._labelRow.setEnabled(isEnabled) // in case it has a checkbox or buttons
            // override but call on super
        }
        //
        // Overridable - Delegation
        _accessoryRadio_changed(selectedValue: StyledRadio_Control.ButtonValue)
        {
            const self = this
            //// Overridable but uncomment this to call:
            //// super._accessoryRadio_changed(selectedValue)
        }
        _postTitleButtonText_tapped()
        {
            const self = this
            //// Overridable but uncomment this to call:
            //// super._postTitleButtonText_tapped()
        }
    }
}