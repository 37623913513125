import { themes } from "../themes"
import StringSelect_Cell_View from "./StringSelect_Cell"
import { StringSelect_Item } from "./_SelectInput"
//
let settableValues__by__option_valueStrs =
{
    "XOR": "XOR",
    "XADD": "XADD",
    "Keccak (Old)": "Keccak (Old)"
}
export function seed_encryption_method_int_code__from__dropdown_str(
    codes: any, // TODO: ret type of monero_core_bridge_instance.seed_encryption_method_int_codes
    str: string
): number /* kv type of ret val of monero_core_bridge_instance.seed_encryption_method_int_codes */ {
    if (str == "XOR") {
        return codes.XOR
    } else if (str == "XADD") {
        return codes.XADD
    } else if (str == "Keccak (Old)") {
        return codes.KECCAK_OLD
    } else {    
        throw new Error("Unrecognized seed_encryption_method_int_code__from__dropdown_str arg of " + str)
    }
}
//
export namespace MnemonicCodingMethod
{
    export function new_selectCell(args: {
        themeC: themes.Controller,
        did_change_value__fn: (Vs: string[]) => void
    }): StringSelect_Cell_View {
        let view = new StringSelect_Cell_View({
            themeC: args.themeC,
            title: "Encryption Method",
            did_change_value__fn: args.did_change_value__fn,
            tooltipText: null
        }).init()
        configureWith_options(view, undefined)
        //
        return view
    }
    export function reset_selectedValue(
        selectCell: StringSelect_Cell_View
    ) {
        configureWith_options(selectCell, undefined)
    }
    export function configureWith_options( // this function is exported/exposed commonly in order to allow consumers to reset the selected value
        selectCell: StringSelect_Cell_View,
        valueOf_selected: string|undefined // if you set undefined, the first value will be selected
    )  {
        let option_valueStrs = Object.keys(settableValues__by__option_valueStrs)
        let items: StringSelect_Item[] = []
        let isFirst = true
        for (let str of option_valueStrs) {
            items.push({
                value: settableValues__by__option_valueStrs[str],
                label: str,
                is_selected: (typeof valueOf_selected === 'undefined' 
                    ? isFirst 
                    : settableValues__by__option_valueStrs[str] === valueOf_selected)
            })
            isFirst = false
        }
        selectCell.set_props__stringSelect({
            items: items
        })
    }
}