export enum Currency
{
  XMR = 'XMR',
  USD = 'USD',
  AUD = 'AUD',
  BTC = 'BTC',
  ETH = 'ETH',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  RUB = 'RUB',
  ZAR = 'ZAR',
};
export let ALL_SEND_OR_RECV_CURRENCIES =
[
    Currency.XMR,
    Currency.BTC,
    Currency.ETH
]
export let ALL_EXCH_RATES_KNOWN_CURRENCIES = [
  Currency.XMR,
  Currency.USD,
  Currency.AUD,
  Currency.BTC,
  Currency.BRL,
  Currency.CAD,
  Currency.CHF,
  Currency.CNY,
  Currency.EUR,
  Currency.GBP,
  Currency.HKD,
  Currency.INR,
  Currency.JPY,
  Currency.KRW,
  Currency.MXN,
  Currency.NOK,
  Currency.NZD,
  Currency.SEK,
  Currency.SGD,
  Currency.TRY,
  Currency.RUB,
  Currency.ZAR,
];