//
//
import View_Web from '../../View/View.web'
import { themes } from '../themes';
import { LargeCTAButtonsGroup } from './LargeCTAButtonsGroup';
import { Popovers } from './Popover_Base';
//
export interface LargeCTAButtonsActionSheet_InitParams extends Popovers.View_Base_InitParams
{
}
//
export class LargeCTAButtonsActionSheet extends Popovers.View_Base
{
    //
    declare ip: LargeCTAButtonsActionSheet_InitParams
    declare _contentView: LargeCTAButtonsGroup.View
    //
    constructor(ip: LargeCTAButtonsActionSheet_InitParams)
    {
        super(ip)
        //
        const self = this
        self.ip = ip
    }
    setup(): void {
        super.setup()
        //
        const self = this
    }
    override _new_popoverContentView(): View_Web
    {
        const self = this
        let v = new LargeCTAButtonsGroup.View({ 
            themeC: self.ip.themeC,
            contextInUI: themes.ElementContextInUI.FormControl_outsideOfCellGroup
        }).init()
        let h = self.ip.themeC.current.layouts.row_margin_h
        v.layer.style.margin = `auto ${h}px 40px ${h}px`
        //
        return v
    }
    //
    setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled) // we enable/disable the curtainView in the same way
        self._contentView.setEnabled(isEnabled)
    }
    //
    //
    set_props(args: {
        descs: LargeCTAButtonsGroup.Button_Desc[]
    }) {
        const self = this
        super.set_props(args) // not that there is anything to do there
        self._contentView.set_props({ descs: args.descs })
    }
}