import View_Web from "../../View/View.web"
import { css_rules } from "../css_rules"
import { styles_theme, themes } from "../themes"
import { _LabelRowContainer, _LabelRowContainer_EventName } from "./_LabelRowContainer"
import { RadioMemberView } from "./_RadioMember"

export namespace styles_radio
{
    export enum ClassNames
    {
        radiomember = "r-m",
        selected = "sel",
        //
        grouping_group = "g-g",
        group__left_cap = "g-c-l",
        group__right_cap = "g-c-r",
        //
        roleWithinUICell_primary = "rwuic-1",
        roleWithinUICell_secondary = "rwuic-2",
        roleWithinUICell_tertiary = "rwuic-3"

    }
    //
    export let namespaceName: css_rules.injectionNamespaceName = "radio"
// TODO: we need to support being able to remove an injected stylesheet - and then probably be able to inject the 'dark/light' them before 'light/dark' on a toggle
    export let generatorFn: css_rules.cssRules_generatorFn_type = (optl_themeC) =>
    {
        let themeC = optl_themeC!
        return [
`label.${ClassNames.radiomember} {
    border-radius: 6px;
    color: ${themeC.current.colors.font_light};
    display: inline-block;
    text-align: center;

    font-family: ${themes.styles_fonts.FontFamilies.mono_regular};
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;

    box-sizing: border-box;
    height: 38px;
    line-height: 22px;
}`, 
// ^^ 1. inline-block is so that padding causes parent to obtain child min height
// ^^ 2. box-sizing + height so that border displays on the inside of the box
// ^^ 3. because we manually specify the font-family, we also include the text-rendering corrections
//
`label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_primary} {
    padding: 6px 14px 0 14px;
    font-size: 18px;
    font-weight: 400;
    min-width: 48px;
    background-color: ${themeC.current.colors.section_bg_2};
    border: 1px solid ${themeC.current.colors.section_bg_2};
    border-right-color: ${themeC.current.colors.section_bg_1};
}`,
`:last-of-type > label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_primary} {
    border-right-color: ${themeC.current.colors.section_bg_2};
}`,
`:last-of-type > label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_primary}.${ClassNames.selected} {
    border-right-color: ${themeC.current.colors.radioMember_fill};
}`,
//
`label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_secondary} {
    padding: 7px 8px 0 8px;
    border: 1px solid ${themeC.current.colors.radioMember_fill};
    font-size: 16px;
    font-family: ${themes.styles_fonts.FontFamilies.mono_bold};
    font-weight: 500;
    min-width: 45px;
}`,
`label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_tertiary} {
    padding: 4px 8px 0 8px;
    border: 1px solid ${themeC.current.colors.radioMember_fill};
    font-size: 12px;
    font-family: ${themes.styles_fonts.FontFamilies.mono_bold};
    font-weight: 500;
    min-width: 45px;
    height: 32px;
}`,
//
`label.${ClassNames.radiomember}:disabled {
    cursor: default;
}`,
`label.${ClassNames.radiomember}.${ClassNames.selected} {
    background-color: ${themeC.current.colors.radioMember_fill};
    color: ${themeC.current.colors.font_absoluteLightest};
}`,
`label.${ClassNames.radiomember}.${ClassNames.selected}.${ClassNames.roleWithinUICell_primary} {
    border: 1px solid ${themeC.current.colors.radioMember_fill};
    background: ${themeC.current.colors.section_bg_2};
}`,
`label.${ClassNames.radiomember}.${ClassNames.selected}.${ClassNames.roleWithinUICell_secondary} {
    background: ${themeC.current.colors.radioMember_fill};
    font-weight: 500;
}`,
`label.${ClassNames.radiomember}.${ClassNames.selected}.${ClassNames.roleWithinUICell_tertiary} {
    background: ${themeC.current.colors.radioMember_fill};
}`,
//
//
`label.${ClassNames.radiomember}.${ClassNames.selected}:hover {
    cursor: pointer; 
}`,
`label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_primary}:hover,
label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_secondary}:hover,
label.${ClassNames.radiomember}.${ClassNames.roleWithinUICell_tertiary}:hover {
    border: 1px solid ${themeC.current.colors.button_bg_hover__main};
}`,
`label.${ClassNames.radiomember}.${ClassNames.grouping_group} {
    border-radius: 0;
}`,
`label.${ClassNames.radiomember}.${ClassNames.grouping_group}.${ClassNames.group__left_cap} {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}`,
`label.${ClassNames.radiomember}.${ClassNames.grouping_group}.${ClassNames.group__right_cap} {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}`

        ]
    }
}

export namespace StyledRadio_Control
{
    export type ButtonValue = any|undefined // undefined can be used for the None option
    export interface MemberDesc
    {
        value: ButtonValue
        explct_title?: string // if not the ButtonValue itself
    }
    //
    export interface Button_Props
    {
        desc: MemberDesc
        pressed_fn: (desc: MemberDesc) => void
    }
    //
    export enum VisualGrouping
    {
        apart = 1,
        group = 2
    }
    export enum RadioControlRoleWithinUICell // this will change the appearance of the control per the design - related to things like ctl btn bg etc
    {
        primary = 1,
        secondary = 2,
        tertiary = 3
    }
    //
    export interface Control_IP
    {
        themeC: themes.Controller
        //
        grouping?: VisualGrouping // defaults to .apart
        roleWithinUICell?: RadioControlRoleWithinUICell // defaults to .primary
    }
    //

    export enum Control_EventName
    {
        memberDifftThanSelectedValueTapped = "memberDifftThanSelectedValueTapped"  // (selectedValue: ButtonValue) => void
    }


    export class Control extends View_Web
    {
        //
        _radiobase_ip!: Control_IP
        //
        leftAdjacent_titleView?: View_Web
        memberViews: RadioMemberView[] = [] // TODO: layers?
        rightAdjacent_titleView?: View_Web
        //
        selectedValue(): StyledRadio_Control.ButtonValue|undefined { 
            const self = this
            for (let m of self.memberViews) {
                if (m.input_layer().checked) {
                    return m.input_layer().value
                }
            }
            return undefined // this is actually possible since selectedValue could be ready before any options are set
        }
        //
        //
        constructor(ip: Control_IP)
        {
            css_rules.lazy_injectRuleStrs(styles_radio.namespaceName, styles_radio.generatorFn, ip.themeC)
            //
            super({ el_name: "div" })
            const self = this
            self._radiobase_ip = ip
        }
        setup(): void {
            super.setup()
            const self = this
            {
                const view = self
                view.layer.style.display = "flex"
                view.layer.style.flexDirection = "row" // TODO: modulate this based on layout direction
                view.layer.style.justifyContent = "flex-start"
                view.layer.style.alignItems = 'center'
                view.layer.style.flexWrap = "wrap" // responsive small display support
            }
        }
        //
        //
        _new_titleView(): View_Web
        {
            const self = this
            let themeC = self._radiobase_ip.themeC
            let view = new View_Web({}).init()
            view.layer.style.textTransform = "uppercase"
            view.layer.style.color = themeC.current.colors.font_absoluteLightest
            view.layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_bold)
            view.layer.style.fontWeight = "600"
            view.layer.style.fontSize = "15px"
            //
            return view
        }
        //
        //
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            for (let v of self.memberViews) {
                v.setEnabled(isEnabled)
            }
        }
        //
        _lastKnown_selectedValue: StyledRadio_Control.ButtonValue|null = null
        emit_memberDifftThanSelectedValueTapped(memberView_value: StyledRadio_Control.ButtonValue)
        {
            const self = this
            self.emit(StyledRadio_Control.Control_EventName.memberDifftThanSelectedValueTapped, memberView_value)
        }
        //
        set_props(props: {
            leftAdjacent_title: string|null|undefined,
            //
            members: StyledRadio_Control.MemberDesc[]|undefined, // undefined means don't change 
            selectedValue: StyledRadio_Control.ButtonValue|undefined // undefined means don't change
            //
            rightAdjacent_title: string|null|undefined // undefined means dont change; null means delete
        }) {
            const self = this
            let weakSelf = new WeakRef(self)
            //
            if (typeof props.leftAdjacent_title !== 'undefined') {
                if (props.leftAdjacent_title != null) {
                    if (!self.leftAdjacent_titleView) {
                        let v = self._new_titleView()
                        self.leftAdjacent_titleView = v
                        if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                            v.setEnabled(false)
                        }
                        v.layer.style.marginRight = "8px"
                        self.insertSubview(v, 0)
                    }
                    self.leftAdjacent_titleView!.layer.innerHTML = props.leftAdjacent_title
                } else {
                    if (self.leftAdjacent_titleView) {
                        self.leftAdjacent_titleView.removeFromSuperview()
                        self.leftAdjacent_titleView = undefined
                    }
                }
            }
            //
            let displayable_selectedValue: ButtonValue
            //
            let did_set_lastKnown_selectedValue = false
            if (typeof props.selectedValue !== 'undefined') {
                if (self._lastKnown_selectedValue !== props.selectedValue) {
                    did_set_lastKnown_selectedValue = true
                }
                self._lastKnown_selectedValue = props.selectedValue
                displayable_selectedValue = props.selectedValue
            } else { // otherwise, if it wasn't specified.. attempt to use the last one?
                displayable_selectedValue = self.selectedValue()!/*NOTE: this unwrap was placed here naively and quickly - it may cause a crash*/ // if any members exist, re-query the selected value from the DOM then. .. alternatively MAYBE we could use self._lastKnown_selectedValue but it doesnt seem totally optimal... if no members exist, and no props.selectedValue exists, maybe we should just pick the new member 0's value? ... NOTE: if we do update this code to do that, make sure to also update _lastKnown_selectedValue immediately with the new value
            }
            //
            if (typeof props.members !== 'undefined') {
                self.memberViews.forEach((v) => v.removeFromSuperview()) // this could be optimized
                // ^- this .releases the view
                self.memberViews = []
                //
                if (props.members !== null) {
                    let subviewIdxOf_rightAdjacent_titleView: number|undefined
                    if (self.rightAdjacent_titleView && self.rightAdjacent_titleView.superview/*this would exist though*/) {
                        for (let [i, v] of self.rightAdjacent_titleView.superview.subviews.entries()) {
                            if (v.isEqualToView(self.rightAdjacent_titleView)) {
                                subviewIdxOf_rightAdjacent_titleView = i
                            }
                        }
                    }
                    for (let [i, m] of props.members.entries()) {
                        let is_the_selected_member = m.value == displayable_selectedValue
                        let is_the_last_member = i == props.members.length - 1
                        //
                        let view = new RadioMemberView({
                            themeC: self._radiobase_ip.themeC,
                        }).init()
                        if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                            view.setEnabled(false)
                        }
                        view.set_props({
                            label: m.explct_title ? m.explct_title : ""+m.value
                        })
                        view.set_input_params__radio({ // it will automatically set its own .id with this
                            radio_group_name: self.uuid,
                            value: m.value,
                            checked: is_the_selected_member
                        })
                        if (is_the_selected_member) {
                            view.labelView.layer.classList.add(styles_radio.ClassNames.selected)
                        }
                        view.labelView.layer.classList.add(styles_theme.ClassNames.button)
                        view.labelView.layer.classList.add(styles_theme.ClassNames.interactive_ctl__control)
                        view.labelView.layer.classList.add(styles_theme.ClassNames.interactive_ctl__decoration)
                        view.labelView.layer.classList.add(styles_radio.ClassNames.radiomember)
                        if (self._radiobase_ip.roleWithinUICell == RadioControlRoleWithinUICell.primary) {
                            view.labelView.layer.classList.add(styles_radio.ClassNames.roleWithinUICell_primary)
                        } else if (self._radiobase_ip.roleWithinUICell == RadioControlRoleWithinUICell.secondary) {
                            view.labelView.layer.classList.add(styles_radio.ClassNames.roleWithinUICell_secondary)
                        } else if (self._radiobase_ip.roleWithinUICell == RadioControlRoleWithinUICell.tertiary) {
                            view.labelView.layer.classList.add(styles_radio.ClassNames.roleWithinUICell_tertiary)
                        } else {
                            throw new Error("Unhandled .roleWithinUICell")
                        }
                        if (self._radiobase_ip.grouping == VisualGrouping.group) {
                            view.labelView.layer.classList.add(styles_radio.ClassNames.grouping_group)
                            if (i == 0) {
                                view.labelView.layer.classList.add(styles_radio.ClassNames.group__left_cap)
                            }
                            if (is_the_last_member) {
                                view.labelView.layer.classList.add(styles_radio.ClassNames.group__right_cap)
                            }
                        } else {
                            if (!is_the_last_member) {
                                view.layer.style.marginRight = "10px"
                            }
                        }
                        //
                        view.inputView.layer.style.position = "fixed"
                        view.inputView.layer.style.opacity = "0"
                        view.inputView.layer.style.pointerEvents = "none"
                        // ^-- https://stackoverflow.com/a/22462740
                        // // rather than: // view.inputView.layer.style.visibility = "hidden"
                        //
                        view.changed_fn = (memberView, selectedValue) => {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            let memberView_value = memberView.value()
                            //
                            if (optl_self._lastKnown_selectedValue == null || memberView_value != optl_self._lastKnown_selectedValue) {
                                optl_self._lastKnown_selectedValue = memberView_value // might as well set it here to prevent repeated clicks from being considered different
                                optl_self.emit_memberDifftThanSelectedValueTapped(memberView_value)
                                //
                                optl_self.set_props({ // to re-render with selected state
                                    selectedValue: memberView_value,
                                    members: undefined,
                                    rightAdjacent_title: undefined,
                                    leftAdjacent_title: undefined
                                })
                            }
                        }
                        self.memberViews.push(view) 
                        view.setEnabled(self._isEnabled) // handling _isEnabled here as well since the view may in theory have been disabled - however unlikely the need for that is
                        if (typeof subviewIdxOf_rightAdjacent_titleView !== 'undefined') {
                            self.insertSubview(view, subviewIdxOf_rightAdjacent_titleView! + i) // insert before the right adjacent title view, if it exists
                        } else {
                            self.addSubview(view)
                        }
                    }
                }
            } else {
                if (typeof props.selectedValue !== 'undefined') { // re-render selection changes
                    for (let m of self.memberViews) {
                        let is_the_selected_member = m.input_layer().value == props.selectedValue
                        if (is_the_selected_member) {
                            m.labelView.layer.classList.add(styles_radio.ClassNames.selected)
                        } else {
                            m.labelView.layer.classList.remove(styles_radio.ClassNames.selected)
                        }
                        m.set_input_params__radio({ // this ought not to be needed but just in case the selectedValue change comes without a .members , i.e. from next to the .memberDifftThanSelectedValueTapped above .. probably wont hurt unless it leads to a cycle somehow
                            radio_group_name: undefined, // no change
                            value: undefined, // no change
                            checked: is_the_selected_member
                        })
                    }
                    if (did_set_lastKnown_selectedValue) {
                        // then fabricate an .emit here since this modification to the .selected value won't render, otherwise -- since we've set the 'last known' selected value above!! this is to prevent a cyclic re-render from .changed_fn()
                        self.emit_memberDifftThanSelectedValueTapped(self._lastKnown_selectedValue)
                    }
                }
            }
            //
            if (typeof props.rightAdjacent_title !== 'undefined') {
                if (props.rightAdjacent_title != null) {
                    if (!self.rightAdjacent_titleView) {
                        let v = self._new_titleView()
                        self.rightAdjacent_titleView = v
                        if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                            v.setEnabled(false)
                        }
                        v.layer.style.marginLeft = "8px"
                        self.addSubview(v) // after everything
                    }
                    self.rightAdjacent_titleView!.layer.innerHTML = props.rightAdjacent_title
                } else {
                    if (self.rightAdjacent_titleView) {
                        self.rightAdjacent_titleView!.removeFromSuperview()
                        self.rightAdjacent_titleView = undefined
                    }
                }
            }
        }
    }
}
