//
//
interface BootableObject
{
    hasBooted: boolean; // initial value should be set to false
    _onceBooted_resolveFns: Array<(value: unknown|undefined) => void>; // initial value should be set to an empty array
}
//
// Runtime - Imperatives - Private - Deferring until booted
async function Bootable_onceBooted(self: BootableObject)
{
    return /*await TODO: do we need this await here?*/new Promise((resolve, reject) => 
    {  // ready to execute
        if (self.hasBooted == true) {
            resolve(undefined)
        }
        self._onceBooted_resolveFns.push(resolve)
    })
}

async function Bootable_setBooted(self: BootableObject)
{
    if (self.hasBooted == true) {
        throw "code fault: _setBooted called while self.hasBooted=true"
    }
    console.log("Set booted to true on", self.constructor.name)
    self.hasBooted = true
    let resolveFns_length = self._onceBooted_resolveFns.length
    for (var i = 0 ; i < resolveFns_length ; i++) {
        let resolve = self._onceBooted_resolveFns[i]
        setTimeout(() => {
            resolve(undefined) // so it's on 'next tick'
        })
    }
    self._onceBooted_resolveFns = [] // flash for next time
}

// TODO: factor de-boot code here


export { Bootable_setBooted, Bootable_onceBooted }