//
//
import { themes } from "../themes"
import { AbstractControl } from "./AbstractTitledControlCell"
import { TitledTextInputCell, TitledTextInputCell_InitParams } from "./TitledTextInputCell"
//
export interface PrivateServerAuthTokenInputCell_InitParams
{
    themeC: themes.Controller
}
//
export default class PrivateServerAuthTokenInputCell extends TitledTextInputCell
{
    constructor(ip: PrivateServerAuthTokenInputCell_InitParams)
    {
        super({ 
            ...ip,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            //
            placeholder: "XYZ123", 
            includeAccessory_checkbox: true,
            checkboxControlsInputHiding: true,
            checkbox__checked: false,
            accessory_checkbox_leftSideTitle: null, // show no label title there
            accessory_checkbox_valLabel_false: 'Off',
            accessory_checkbox_valLabel_true: 'On',
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio
        })
        const self = this
    }
    setup()
    {
        const self = this
        super.setup()
        self.set_props_labelRow({
            title: "Scanner - Auth Code", 
            postTitleButtonText: undefined,
            tooltipText: "If you're using a private server, you can supply an authentication token here that the server admin provisioned for you."
        })
    }
    //
    // TODO: input validation etc
}
