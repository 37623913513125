//
//
import { Currency } from '@xrds/xrds-libapp-ts/src/Currencies/models/constants'
import { themes } from '../themes'
import { LinkButton, LinkButtonUsage } from './LinkButtonCell'
import { TitledTextInputCell, TitledTextInputCell__set_props__args } from './TitledTextInputCell'
import { TextInput_InputType } from './_TextInput'
import { AbstractControl } from './AbstractTitledControlCell'
import { Icon_Symbolic } from './Icon_Symbolic'
//
export interface PaymentIDInputCell_IP
{
    themeC: themes.Controller
    monero_core_bridge_instance: any // TODO: type
    //
    input__changed_fn?: (v: string) => void
}
export interface PaymentIDInputCell__set_props__args extends TitledTextInputCell__set_props__args {
    paymentID?: {
        to_ccy: Currency
    }
}
//
export class PaymentIDInputCell extends TitledTextInputCell
{
    _ccy: Currency = Currency.XMR
    //
    private _isButtonPressed_addPID: boolean = false
    isButtonPressed_addPID(): boolean { return this._isButtonPressed_addPID }
    //
    _inner_ip!: PaymentIDInputCell_IP
    //
    button_addPaymentID!: LinkButton._Row
    //
    //
    constructor(_inner_ip: PaymentIDInputCell_IP)
    {
        super({
            themeC: _inner_ip.themeC,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            //
            includeAccessory_checkbox: false,
            // input:
            placeholder: "A specific payment ID",
            inputType: TextInput_InputType.text,
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio,
            input__changed_fn: (_self: TitledTextInputCell, v: string) => { // perhaps just pass 
                let self = _self as PaymentIDInputCell
                if (self._inner_ip.input__changed_fn) {
                    self._inner_ip.input__changed_fn(v)
                }
            },
            // input__focused_fn: (_self: TitledTextInputCell) => {
            //     let self = _self as ToAddrInputCell
            // },
            // input__debounced_changed_fn?: (v: string) => void
            input__revert_value_on_blur_to__fn: (_self: TitledTextInputCell) => 
            {
                let self = _self as PaymentIDInputCell

                return undefined // do not modify
            },
            //
            postTitleButtonText_tapped_fn: (_self: TitledTextInputCell) => 
            {
                let self = _self as PaymentIDInputCell
                // this is really the 'generate one' button
                self.generate_and_configureWith_new_paymentId()
            }
        })
        const self = this
        self._inner_ip = _inner_ip

    }
    setup(): void
    {
        super.setup()
        const self = this
        self.set_props_labelRow({
            title: "Enter a payment ID or",
            postTitleButtonText: "GENERATE ONE",
            tooltipText: null
        })
        let themeC = self._inner_ip.themeC
        let weakSelf = new WeakRef(self)
        {
            let view = new LinkButton._Row({
                themeC: themeC,
                uiContext: themes.ElementContextInUI.FormControl_withinCellGroup,
                pressed_fn: () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.set_isButtonPressed_addPID() 
                }
            }).init()
            view.layer.style.width = "100%" // to fix the alignment issue since we're using a ._Row directly
            view.set_props({
                items: [
                    { _pkey: "icon", symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.plus }, buttonUsage: LinkButtonUsage.default },
                    { _pkey: "btn", label: "ADD PAYMENT ID", buttonUsage: LinkButtonUsage.default }
                ]
            })
            self.button_addPaymentID = view
            self.rootContentsContainer.addSubview(view)
        }
        //
        // hide input to start with
        self.unset_isButtonPressed_addPID()
    }
    //
    //
    set_isButtonPressed_addPID()
    {
        const self = this
        self._isButtonPressed_addPID = true
        self.configure_subview_visibility()
    }
    public unset_isButtonPressed_addPID()
    {
        const self = this
        self._isButtonPressed_addPID = false
        self.configure_subview_visibility()
    }
    //
    configure_subview_visibility()
    {
        const self = this
        if (self._ccy != Currency.XMR) {
            self.button_addPaymentID.hide()
            self._labelRow.hide()
            self._primaryInputContainer.hide()
            //
            return
        }
        if (self._isButtonPressed_addPID) {
            self.button_addPaymentID.hide()
            self._labelRow.show()
            self._primaryInputContainer.show()
        } else {
            self.button_addPaymentID.show()
            self._labelRow.hide()
            self._primaryInputContainer.hide()
        }
    }
    //
    generate_and_configureWith_new_paymentId()
    {
        const self = this
        self.setInputValue(self._inner_ip.monero_core_bridge_instance.new_payment_id())
    }
    //
    set_props(args: PaymentIDInputCell__set_props__args) {
        const self = this
        if (args.paymentID) {
            let props = args.paymentID
            self._ccy = props.to_ccy
            if (props.to_ccy != Currency.XMR) {
                self._isButtonPressed_addPID = false
                self.setInputValue("") // clear 
            } else {
                // wait for configure below
            }
            self.configure_subview_visibility()
        }
        super.set_props(args) // and *also* call .set_props in case there are any base props to set
    }
    //
    //
    setEnabled(isEnabled: boolean): void 
    {
        super.setEnabled(isEnabled) // parent handles setEnabled on the _labelRow
        const self = this
        self.button_addPaymentID.setEnabled(isEnabled)
    }
}