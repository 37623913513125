//
//
import AppControllers from "@xrds/xrds-libapp-ts/src/Application/AppControllers";
import View_Web, { View_Web__Options } from "../View/View.web";
import StackNavigationView, { ModalView, NavigableView } from "./Common/NavigationView";
import { Popovers } from "./Common/Popover_Base";
import { themes } from "./themes";
//
export interface App_View_Context
{ // NOTE: This interface is not intended to be extended/inherited-from
    rootNavigationView: StackNavigationView
    controllers: AppControllers
    themeC: themes.Controller
    popoverPresenter: Popovers.PopoverPresenter
}
export default class App_View_Web extends View_Web implements NavigableView, ModalView
{
    ctx!: App_View_Context
    //
    //
    // NavigableView and ModalView
    stackNavigationView?: StackNavigationView 
    modalParentView?: StackNavigationView 
    //
    /*overridable*/enableCellsSystem(): boolean { return false } // override this and return true to get the cells system
    cellsContainerView!: View_Web // available to be instantiated by subclass if desired
    _hasScrolledOnceAtRuntimeToCellsByPkey: { [key: string]: boolean } = {}
    //
    reconstitutable_scrollTop?: number // this *can* be set sometimes by the StackNavigator for purposes of reconstituting the scroll when the view is needed again but before it's been torn down
    record_reconstitutable_scrollTop_forNavigatorPopOrRemoval() { 
        const self = this
        self.reconstitutable_scrollTop = self.navigableView_scrollableView().layer.scrollTop
    }
    actually_reconstitute_reconstitutable_scrollTop()
    {
        const self = this
        if (self.reconstitutable_scrollTop === null || typeof self.reconstitutable_scrollTop === 'undefined') {
            throw new Error("Expected self.reconstitutable_scrollTop by time actually_reconstitute_reconstitutable_scrollTop() is called")
        }
        self.navigableView_scrollableView().layer.scrollTop = self.reconstitutable_scrollTop
        self.reconstitutable_scrollTop = undefined // may as well zero this since we shouldnt need *that* value anymore
    }
    scrollableView(): View_Web
    {
        const self = this
        if (self.enableCellsSystem()) {
            return self.cellsContainerView
        } else {
            return self
        }
    }
    navigableView_scrollableView(): View_Web {
        return this.scrollableView()
    }
    //
    //
    constructor(ctx: App_View_Context, opts: View_Web__Options)
    {
        super(opts)
        const self = this
        self.ctx = ctx
    }
    //
    // Interface - NavigableView - Overridable
    navigationBar_title(): string|undefined
    {
        return undefined
    }
    navigationBar_hidden(): boolean
    {
        return false
    }
    navigationBar_isEnabled_backButton(): boolean
    {
        return true
    }
    navigationBar_isEnabled_leftSideAccessory(): boolean
    {
        return true
    }
    navigableView_willGoBackFrom()
    {
        const self = this
    }
    navigableView_leftSideAccessoryView(): View_Web|null { return null } // i suppose i could make the function itself optl 
    navigableView_rightSideAccessoryView(): View_Web|null { return null } // i suppose i could make the function itself optl
}