import View_Web from "../../View/View.web"

//
export interface StringSelect_Item
{
    label: string
    value: string
    //
    is_selected?: boolean // undefined -> false
}
export interface _SelectInput_IP
{
    is_multiselect?: boolean

    did_change_value__fn: (Vs: string[]) => void
}
//
export function are_all_multiselect_values_selected(sorted__selected_values: string[], sorted__options: string[]): boolean
{ 
    let n_selected_values = sorted__selected_values.length
    if (n_selected_values == 0) {
        return false
    }
    let n_options = sorted__options.length
    if (n_selected_values < n_options) { // normally a != would suffice here but we cant assume that n_selected_values < n_options
        return false
    }
    // so if n_selected_values equal to (or greater than) n options, let's compare sorted val for sorted val
    // we do this since the options may no longer correspond to the selected values themselves
    for (var i = 0 ; i < n_options ; i++) {
        if (sorted__options[i] != sorted__selected_values[i]) {
            return false
        }
    }
    return true
}


export class _SelectInput extends View_Web
{
    //
    items: StringSelect_Item[]|null = null
    //
    label!: View_Web
    select!: View_Web
    select_layer(): HTMLSelectElement
    { // TODO: possibly factor this into a _SelectInput
        return this.layer as HTMLSelectElement
    }
    single_selected_value(): string
    { // for convenience
        const self = this
        if (self._si_ip.is_multiselect == true) {
            console.trace()
            throw new Error("single_selected_value() exists for convenience and should not be called on a _SelectInput which is .is_multiselect")
        }
        return self.selected_values()[0]
    }
    selected_values(): string[]
    {
        const self = this
        var result: string[] = []
        var options = self.select_layer() && self.select_layer().options
        for (let i = 0 ; i < options.length ; i++) {
            let opt = options[i]
            if (opt.selected) {
                result.push(opt.value)
            }
        }
        return result
    }
    are_all_multivalues_selected(): boolean
    { // NOTE: do not call this if the layer hasnt been created yet
        const self = this
        if (self._si_ip.is_multiselect != true) {
            throw new Error("are_all_multivalues_selected() exists for convenience and should not be called on a _SelectInput which is !.is_multiselect")
        }
        return are_all_multiselect_values_selected( // alternatively i could now just check .items for all .is_selected
            self.selected_values(), 
            (self.items||[]).map(i => i.value)
        )
    }
    // selected_value(): string
    // {
    //     return this.select_layer().value
    // }
    //
    _si_ip!: _SelectInput_IP
    constructor(ip: _SelectInput_IP)
    {
        super({ el_name: "select" })
        const self = this
        self._si_ip = ip
    }
    setup(): void
    {
        super.setup()
        const self = this
        let weakSelf = new WeakRef(self)
        //
        if (self._si_ip.is_multiselect == true) {
            self.select_layer().multiple = true
        }
        self.select_layer()/*now that self.select is set*/.addEventListener("change", () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self._si_ip.did_change_value__fn(optl_self.selected_values())
        })
    }
    //
    set_props(args: {
        items: StringSelect_Item[]
        // Perhaps later, in an AbstractSelect_Cell
            // selectedObject: any|undefined,
            // optionFromObject_fn: optionFromObject_fn__type,
            // objectFromValue_fn: (val: any) => any,
            // selectableObjects: any[],
            // selectValueChangedToObject_fn: (selectableObj: any|undefined) => void,
            // selectValueChangedToValue_fn: (val: any|undefined) => void,    
    }) {
        const self = this
        // console.trace()
        self.removeAllSubviews() // because i'm lazy... for now
        //
        // TODO: this could be optimized against e.g. redundant round trips by storing and exact-diffing self._items which'd be ez to write
        //
        for (let [_, item] of args.items.entries()) {
            let view = new View_Web({ el_name: "option" }).init()
            // view._lifecycleObject_wants_debug_log__retain = true
            // view._lifecycleObject_wants_debug_log__release = true
            // view._lifecycleObject_wants_debug_log__teardown = true
            if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                view.setEnabled(false)
            }
            let o = (view.layer as HTMLOptionElement);
            o.value = item.value
            o.innerText = item.label
            if (item.is_selected == true) {
                o.selected = true//"selected"
// TODO: this might not work. (why is this different now ..? doesn't bode well. maybe we need .setAttribute(..))
// TODO: ^ this can probably be removed now - seems to be working - but it might not work if called before addSubview? probably is working though
            }
            self.addSubview(view) // using a View mostly because i'm lazy and want .removeAllSubviews() .. and at least this will slightly ease porting later to alt back ends if ever
        }
        self.items = args.items
    }
    //
    //
    setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled)
        //
        self.select_layer().disabled = isEnabled != true
    }

}