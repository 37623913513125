import View_Web from "../../View/View.web";
import { themes } from "../themes";
import { AbstractControl } from "./AbstractTitledControlCell";
import SeparatorLineView from "./SeparatorLineView";
import { _LabelRowContainer, _LabelRowContainer_WrappingMode } from "./_LabelRowContainer";
import { StringSelect_Item, _SelectInput } from "./_SelectInput";
//
//
export interface StringSelect_Cell_IP
{
    themeC: themes.Controller
    //
    title: string
    is_multiselect?: boolean
    tooltipText: string|null
    //
    did_change_value__fn: (values: string[]) => void
}
//
export default class StringSelect_Cell_View extends AbstractControl.Base_Cell
{
    //
    //
    label!: View_Web
    select!: _SelectInput
    single_selected_value(): string
    {
        return this.select.single_selected_value()
    }
    selected_values(): string[]
    {
        return this.select.selected_values()
    }
    are_all_multivalues_selected(): boolean
    {
        return this.select.are_all_multivalues_selected()
    }
    //
    //
    _ss_ip!: StringSelect_Cell_IP
    //
    constructor(_ss_ip: StringSelect_Cell_IP)
    {
        super({ 
            themeC: _ss_ip.themeC, 
            _labelRow_wrappingMode: _LabelRowContainer_WrappingMode.wrap 
        })
        //
        const self = this
        self._ss_ip = _ss_ip
    }
    setup(): void
    {
        super.setup()
        //
        const self = this
        let themeC = self._ss_ip.themeC
        let weakSelf = new WeakRef(self)
        self.set_props_labelRow({
            title: self._ss_ip.title,
            postTitleButtonText: null,
            tooltipText: self._ss_ip.tooltipText
        })
        {
            let view = new _SelectInput({
                is_multiselect: self._ss_ip.is_multiselect,
                did_change_value__fn: (to_Vs: string[]) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self._ss_ip.did_change_value__fn(to_Vs)
                }
            }).init()
            // view._lifecycleObject_wants_debug_log__teardown = true
            // view._lifecycleObject_wants_debug_log__retain = true
            // view._lifecycleObject_wants_debug_log__release = true
            self.select = view
            //
            view.layer.style.maxWidth = `${self._ac_base_ip.themeC.current.layouts.stringSelect_minWidth}px`
            view.layer.style.width = "100%" // very small screen support
            //
            self._labelRow.insertSubviewToLeftOfAccessoryRadio(view)
        }
        //
        // if (this._ss_ip.display_separator) {
        //     let view = new SeparatorLineView({ themeC: themeC }).init()
        //     self.addSubview(view)
        // }
    }
    //
    set_props__stringSelect(args: {
        items: StringSelect_Item[]
        // Perhaps later, in an AbstractSelect_Cell
            // selectedObject: any|undefined,
            // optionFromObject_fn: optionFromObject_fn__type,
            // objectFromValue_fn: (val: any) => any,
            // selectableObjects: any[],
            // selectValueChangedToObject_fn: (selectableObj: any|undefined) => void,
            // selectValueChangedToValue_fn: (val: any|undefined) => void,    
    }) {
        const self = this
        self.select.set_props({
            items: args.items
        })
    }
    //
    setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled)
        //
        self.select.setEnabled(isEnabled)
    }
}