import View_Web from "../../View/View.web"
import { screen } from "../ScreenView"
import { themes } from "../themes"
//
export namespace Popovers
{
    export interface Popover
    {
    }
    export interface PopoverPresenter
    {
        _popover?: Popovers.View_Base
        present_popover(view: Popovers.View_Base)
        dismiss_popover()        
    }
}
//
//
export namespace Popovers
{
    export interface View_Base_InitParams
    {
        themeC: themes.Controller
        popoverPresenter: PopoverPresenter // screenView is a PopoverPresenter
    }
    //
    export class View_Base extends View_Web implements Popover
    {
        //
        ip!: Popovers.View_Base_InitParams
        //
        _curtainView!: View_Web
        _contentView!: View_Web
        //
        constructor(ip: Popovers.View_Base_InitParams)
        {
            super({ el_name: "div" })
            //
            const self = this
            self.ip = ip
        }
        setup()
        {
            super.setup()
            //
            const self = this
            let weakSelf = new WeakRef(self)
            {
                let view = self
                // This grid stuff makes the _curtainView and _contentView overlaid as if they were position: absolute
                view.layer.style.display = "grid"
                view.layer.style.gridTemplateColumns = "1fr"
            }
            {
                let view = new View_Web({ el_name: "a" }).init()
                view.layer.style.display = "block"
                view.layer.style.width = "100%"
                view.layer.style.height = "100%"
                view.layer.style.backgroundColor = "rgba(0, 0, 0, 0.6)"
                view.layer.style.gridRowStart = "1"
                view.layer.style.gridColumnStart = "1"
                view.layer.style.cursor = "default"
                view.layer.addEventListener("click", (e) => 
                {
                    e.preventDefault() // not that there would be one
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (optl_self._isEnabled != true) {
                        return // ignoring 
                    }
                    optl_self.cancel()
                    //
                    return false
                })
                self._curtainView = view
                self.addSubview(view)
            }
            {
                let view = self._new_popoverContentView()
                view.layer.style.gridRowStart = "1"
                view.layer.style.gridColumnStart = "1"
                self._contentView = view
                self.addSubview(view)
            }
        }
        _new_popoverContentView(): View_Web
        {
            throw new Error("Override and implement this to set up your popover content view here prior to your call of .set_props(..)")
        }
        public teardown(): void 
        {
            super.teardown()
            const self = this
        }
        //
        //
        present()
        {
            const self = this
            self.ip.popoverPresenter.present_popover(self)
        }
        dismiss()
        {
            const self = this
            self.ip.popoverPresenter.dismiss_popover() // hopefully it's still the same popover? we could pass self and compare .uuid
        }
        //
        //
        cancel()
        {
            const self = this
            self.dismiss()
        }
        //
        // Imperatives - Overridable
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            //
            // if needed.. override and call upon your popover content view, but ensure you call this on super
        }
        set_props(args: {})
        {
            const self = this
            // if needed, override and use for configuration (adding your own args.*)
        }
    }
}