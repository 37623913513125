
import { App_View_Context } from '../App_View_Web'
import { CreatePassword_Base_View, CreatePassword_Base_View_InitParams } from '../Passwords/CreatePassword_Base_View'
//
export interface CreatePassword_ForChangePassword_View_InitParams extends CreatePassword_Base_View_InitParams
{
    enteredExistingPassword: string
}
//
export class CreatePassword_ForChangePassword_View extends CreatePassword_Base_View
{
    constructor(ctx: App_View_Context, ip: CreatePassword_ForChangePassword_View_InitParams)
    {
        super(ctx, { // .base_ip
            educationalTitle: "Please enter a new password",
            existingPassword_ifChanging: ip.enteredExistingPassword 
        })
        const self = this
    }
    //
    //
    override async overrideable_didSetFirstTimePassword_fn(
    ) {
        const self = this
        //
        // we know that this was not presented in a modal since the password did not yet exist
        // self.ctx.rootNavigationView.dismiss_topModal() // hopefully this is not going to ever conflict with any other modal that might be presented - shouldn't, since this should be on top
        // NOTE: changed to the following which is a little less globally referential instead
        self.stackNavigationView!.modalParentView!.dismiss_topModal()
        // ^ we know that this is the correct structure since the local stackNavigationView is presented modally - though it would be nice to do this in a more rigorous manner
    }
    //
    //
    navigationBar_title(): string
    {
        return "Change Password"
    }
}
//




