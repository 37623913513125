import FilePersister_Base from '@xrds/xrds-libapp-ts/src/Persistable/FilePersister_Base'
import { v4 as uuidV4 } from 'uuid'
//
//
namespace AdHocWorkers
{
    export const new_workerFromClass = (workerClassRef) => {
        // console.log("new_workerFromClass", workerClassRef.name, "to worker");
        const workerFactory = (self, workerClass) => {
            const worker = new workerClass();
            self["onmessage"] = worker.onMessage.bind(worker);
        }
        return new Worker(URL.createObjectURL(new Blob(
            [`${workerClassRef}
            (${workerFactory}) 
            (this,${workerClassRef.name});`],
            {type: "application/javascript"}
        )))
    }
}
//
export enum OPFSWorker_cmds
{
    removeItemWithFileKey = "removeItemWithFileKey",
    readAllFileKeys = "readAllFileKeys",
    read_fileWithKey = "read_fileWithKey",
    write_dataToFileKey = "write_dataToFileKey"
}
//
class OPFSWorker 
{
    OPFSWorker_cmds = 
    {
        removeItemWithFileKey: "removeItemWithFileKey",
        readAllFileKeys: "readAllFileKeys",
        read_fileWithKey: "read_fileWithKey",
        write_dataToFileKey: "write_dataToFileKey"
    }
    //
    _is_setup_complete: boolean = false
    buffered_events: any[] = []
    //
    directoryHandle!: FileSystemDirectoryHandle
    //
    constructor(
    ) {
        const self = this
        // console.log("[OPFSWorker/constructor]")
        let weakSelf = new WeakRef(self)
        self.setup().then(async () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                console.warn("[OPFSWorker/setup.then] !optl_self; bailing.")
                return
            }
            let buffered_events = optl_self.buffered_events
            optl_self._is_setup_complete = true // prevent further buffering
            // console.log("~~~> [OPFSWorker] done with setup")
            optl_self.buffered_events = [] // flash to free memory
            for (let event of buffered_events) {
                optl_self._really_handle_onMessage(event)
            }
        })
    }
    async setup(
    ) { // overridable
        // console.log("[OPFSWorker] setup")
        const self = this
        self.directoryHandle = await navigator.storage.getDirectory();

        // const root = await navigator.storage.getDirectory();
        // const fileHandle = await root.getFileHandle('Untitled.txt', { create: true });
        // const accessHandle = await (fileHandle as any).createSyncAccessHandle();

    }
    onMessage(
        event: any
    ) { // override
        const self = this
        if (self._is_setup_complete) {
            self._really_handle_onMessage(event)
        } else {
            self.buffered_events.push(event) // will be picked up on setup
        }
    }
    sendToParent(payload: any)
    {
        postMessage(payload)
    }
    //
    // Accessors - 

    async fileHandleFromName(
        filename: string,
        create?: boolean|undefined // defaults to false
    ): Promise<FileSystemFileHandle|null> {
        const self = this
        try {
            return await self.directoryHandle.getFileHandle(filename, { create: create });
        } catch (e) {
            console.log("e" , e)
            // TODO: make sure this is NotFound
            return null
        }
    }
    //
    // Delegate
    async _really_handle_onMessage(event: any)
    {
        const self = this
        let data = event.data
        // console.log("~~~~~~~~~> [OPFSWorker/_really_handle_onMessage] ", data)
        let cmd = data.cmd
        let uuid_for_resp = data.uuid
        function _resp_with(ret: any) {
            self.sendToParent({
                uuid: uuid_for_resp,
                from_cmd: cmd,
                ret: ret
            })
        }
        if (cmd == self.OPFSWorker_cmds.removeItemWithFileKey) {
            let fileKey = data.fileKey
            self.directoryHandle.removeEntry(fileKey)
            //
            // since this is an async op that may be awaited by the caller, resp here
            _resp_with({})
        } else if (cmd == self.OPFSWorker_cmds.readAllFileKeys) {
            let strs: string[] = []
            for await (let [name, handle] of (self.directoryHandle as any).values()) {
                strs.push(name)
            }       
            // 
            _resp_with({
                strs: strs
            })
        } else if (cmd == self.OPFSWorker_cmds.read_fileWithKey) {
            let fileKey = data.fileKey
            //
            const fileHandle = await self.fileHandleFromName(fileKey)
            if (!fileHandle) {
                _resp_with({
                    str: null
                })
                return
            }
// @ts-ignore
            const accessHandle = await fileHandle.createSyncAccessHandle()
            //
            let fileSize = accessHandle.getSize()
            let dataView = new DataView(new ArrayBuffer(fileSize))
            accessHandle.read(dataView, { at: 0 })
            let decoder = new TextDecoder()
            let str = decoder.decode(dataView)
            accessHandle.close()
            //
            _resp_with({
                str: str
            })
        } else if (cmd == self.OPFSWorker_cmds.write_dataToFileKey) {
            let fileKey = data.fileKey
            let contentString = data.contentString
            //
            const fileHandle = await self.fileHandleFromName(fileKey, true/*do create*/)
            if (!fileHandle) {
                _resp_with({
                    str: null
                })
                return
            }
            console.log("Write: " + contentString)
// @ts-ignore
            const accessHandle = await fileHandle.createSyncAccessHandle()
            const encoder = new TextEncoder()
// @ts-ignore
            const encodedData = encoder.encode(contentString)
            accessHandle.write(encodedData, { at: 0 })
            accessHandle.flush()
            accessHandle.close()
            //
            // since this is an async op that may be awaited by the caller, resp here
            _resp_with({})
        } else {
            throw new Error("Unrecognized .cmd")
        }
    }
}
//
//
export interface FilePersister_OPFS_InitParams
{
}
export default class FilePersister_OPFS extends FilePersister_Base
{
    init_params!: FilePersister_OPFS_InitParams
    //
    pending_resp_fn_by_uuid: { [uuid: string]: (e: any) => void } = {}
    //
    worker!: Worker
    //
    constructor(init_params: FilePersister_OPFS_InitParams)
    {
        super()
        //
        const self = this
        self.init_params = init_params
    }
    async setup()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        self.worker = AdHocWorkers.new_workerFromClass(OPFSWorker);
        self.worker.addEventListener("message", (e: any) => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                console.warn("[OPFSWorker/worker.onmessage] !optl_self; bailing.")
                return
            }
            let data = e.data
            let uuid = data.uuid
            let fn = optl_self.pending_resp_fn_by_uuid[uuid]!
            delete optl_self.pending_resp_fn_by_uuid[uuid]
            fn(e)
        })
    }
    //
    // Imperatives
    async cmdAndAwaitResp(cmd: string, set_args: (p: any) => void): Promise<any|null>
    {
        const self = this
        let weakSelf = new WeakRef(self)
        return new Promise((res, rej) => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                console.warn("!optl_self; bailing.")
                return
            }
            let weak_optl_self = new WeakRef(optl_self)
            let uuid = uuidV4()
            optl_self.pending_resp_fn_by_uuid[uuid] = (event) => {
                let optl_weak_optl_self = weak_optl_self.deref()
                if (!optl_weak_optl_self) {
                    console.warn("!optl_weak_optl_self; bailing.")
                    return
                }
                console.log("res..")
                res(event.data.ret)
            }
            let message = { cmd: cmd, uuid: uuid }
            set_args(message)
            optl_self.worker.postMessage(message)
        })
    }
    //
    // For Subclassers - Required Overrides
    async _overridable_removeItemWithFileKey(fileKey: string)
    {
        const self = this
        /*return */await self.cmdAndAwaitResp(
            OPFSWorker_cmds.removeItemWithFileKey,
            (p) => {
                p.fileKey = fileKey
            }
        )
    }
    async _overridable_readAllFileKeys(): Promise<string[]>
    {
        const self = this
        let ret = await self.cmdAndAwaitResp(
            OPFSWorker_cmds.readAllFileKeys,
            (p) => {
            }
        )
        // console.log("_overridable_readAllFileKeys ret", ret)

        return ret!.strs
    }
    async _overridable_read_fileWithKey(fileKey: string): Promise<string|null>
    {
        const self = this
        let ret = await self.cmdAndAwaitResp(
            OPFSWorker_cmds.read_fileWithKey,
            (p) => {
                p.fileKey = fileKey
            }
        )
        // console.log("_overridable_read_fileWithKey ret", ret)

        return ret!.str
    }
    async _overridable_write_dataToFileKey(fileKey: string, contentString: string): Promise<{ err_str: string | undefined }>
    { // NOTE: throws
        const self = this
        //
        await self.cmdAndAwaitResp(
            OPFSWorker_cmds.write_dataToFileKey,
            (p) => {
                p.fileKey = fileKey
                p.contentString = contentString
            }
        )
        //
        return { err_str: undefined }
    }
}