//
//
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { NavigationBar } from '../Common/NavigationBarView'
import { SpecificCommon_LinkButtonCells } from '../Common/SpecificCommon_LinkButtonCells'
import { EnterExistingPassword_Base_View } from '../Passwords/EnterExistingPassword_Base_View'
import { themes } from '../themes'
import { CreatePassword_ForChangePassword_View } from './CreatePassword_ForChangePassword_View'
//
export interface EnterExistingPassword_ForChangePassword_View_Addtl_IP
{
}
//
export class EnterExistingPassword_ForChangePassword_View extends EnterExistingPassword_Base_View
{
    ip!: EnterExistingPassword_ForChangePassword_View_Addtl_IP
    //
    constructor(ctx: App_View_Context, ip: EnterExistingPassword_ForChangePassword_View_Addtl_IP)
    {
        super(ctx, {
            isForChangePW: true,
            largeCTAButtonTitle: "Next", // since this is in the first step of a process
            //
            promptTitle: "Please verify your existing password.",
            promptDescription: null
        })
        const self = this
        self.ip = ip
    }
    _overridable_setup_views__after_primaryCTA(): void
    {
        const self = this
        //
        let container_view = new View_Web({}).init() // paddingLeft: 15, marginTop: 14
        {
            let view = container_view
            // view.layer.style.marginTop = TODO
            view.layer.style.paddingLeft = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
            view.layer.style.paddingRight = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_h}px`
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_forgotPassword(
                self.ctx.themeC, 
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController
            )
            container_view.addSubview(view)
        }
        {
            let view = SpecificCommon_LinkButtonCells.new_linkButtonCell_deleteEverything(
                self.ctx.themeC, 
                themes.ElementContextInUI.SplashBody_Mini,
                self.ctx.controllers.persistenceController,
                true
            )
            container_view.addSubview(view)
        }
        self.addSubview(container_view)
    }
    //
    //
    //
    // Delegation - Overrides
    override async overridable_didEnterCorrectPassword_fn(enteredPassword: string)
    {
        const self = this
        //
        self.isFormSubmitting = false // allow actual dismissal of the form again
        self.configureWith_formSubmittingState({})
        //
        let v = new CreatePassword_ForChangePassword_View(self.ctx, {
            enteredExistingPassword: enteredPassword
        }).init()
        self.stackNavigationView!/*this is the modal itself which is a StackNavigationView*/.push(v)
    }
    //
    //
    navigationBar_title(): string
    {
        return "Change Password"
    }
    navigableView_leftSideAccessoryView(): View_Web|null
    {
        const self = this
        let weakSelf = new WeakRef(self)
        //
        return NavigationBar.new_base_barButtonView({ 
            themeC: self.ctx.themeC, 
            innerHTML: "Cancel", 
            clicked_fn: (e) => {
                e.preventDefault()
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return
                }
                optl_self.stackNavigationView!.modalParentView!.dismiss_topModal() // is this better than telling the rootNavigationView to .dismiss_topModal()?
                //
                return false
            }
        })
    }
}