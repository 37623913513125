//
//
import View_Web from '../../View/View.web'
import { themes } from '../themes'
import { Icon_Symbolic } from './Icon_Symbolic'
import { InformativeIcon } from './InformativeIcon'
import { LinkButton, LinkButtonOrTextSpanItem, LinkButtonUsage } from './LinkButtonCell'
import { StyledRadio_Control } from './StyledRadio_Control'
//
export enum _LabelRowContainer_EventName
{ // this is so that consumers dont have to track subviews that are internal to _LabelRowContainer_EventName
    accessoryRadio_changed = "accessoryRadio_changed"
}
export enum _LabelRowContainer_WrappingMode
{
    nowrap, // the default
    wrap
}
//
//
export interface _LabelRowContainer_Props
{
    labels?: _LabelRowContainer_labels_Props
    accessoryRadio?: _LabelRowContainer_accessoryRadio_Props
}
export interface _LabelRowContainer_labels_Props
{ 
    title: string|null|undefined, 
    title_appearance?: LinkButton.Appearance,
    //
    preTitle_iconType?: InformativeIcon.IconType | null | undefined,
    preTitle_symbolicIcon_props?: Icon_Symbolic.Props | null | undefined,
    //
    postTitleButtonText: string|null|undefined,
    postTitleButtonText_appearance?: LinkButton.Appearance,
    //
    tooltipText: string|undefined|null
}
export interface _LabelRowContainer_accessoryRadio_Props
{ // using this method is important since the accessoryRadio might need to be set up by calling this method first
    leftAdjacent_title: string|null|undefined,
    members: StyledRadio_Control.MemberDesc[]|null|undefined, // null means delete the toggle; undefined means don't change 
    selectedValue: StyledRadio_Control.ButtonValue|undefined,
    rightAdjacent_title: string|null|undefined,
}


export interface _LabelRowContainer_InitParams
{
    themeC: themes.Controller
    //
    contextInUI?: themes.ElementContextInUI, // defaults to FormControl
    wrappingMode?: _LabelRowContainer_WrappingMode
    accessoryRadio_roleWithinUICell?: StyledRadio_Control.RadioControlRoleWithinUICell
    //
    // TODO: move these out to set_props_…
    // includeAccessory_checkbox?: boolean // defaults to false
    // checkbox__checked?: boolean // defaults to false 
    // checkbox__changed__fn?: (isChecked: boolean) => void 
    //
    postTitleButtonText_tapped_fn?: () => void
}
//
export class _LabelRowContainer extends View_Web
{
    //
    ip!: _LabelRowContainer_InitParams
    //
    titleLabel!: LinkButton._Row // this allows us to also place buttons in the title
    //
    rightSide_container!: View_Web
    //
    accessoryRadio?: StyledRadio_Control.Control
    selectedValue_accessory(): StyledRadio_Control.ButtonValue|undefined { 
        return this.accessoryRadio 
            ? this.accessoryRadio!.selectedValue() 
            : undefined 
    }
    //
    constructor(ip: _LabelRowContainer_InitParams)
    {
        super({ el_name: "div" })
        const self = this
        self.ip = ip
    }
    setup(): void 
    {
        super.setup()
        const self = this
        let themeC = self.ip.themeC
        let optl_contextInUI = self.ip.contextInUI
        let contextInUI = optl_contextInUI
        if (!optl_contextInUI || typeof optl_contextInUI == 'undefined') {
            contextInUI = themes.ElementContextInUI.FormControl_withinCellGroup // default
        } else {
            contextInUI = optl_contextInUI!
        }
        {
            let view = self
            view.layer.style.display = "flex"
            view.layer.style.flexDirection = "row"
            view.layer.style.flexWrap = self.ip.wrappingMode === _LabelRowContainer_WrappingMode.nowrap ? "nowrap" : "wrap"  // default to 'wrap'
            view.layer.style.gap = "10px"
            view.layer.style.width = "100%"
            view.layer.style.alignItems = "center"
            //
            switch (contextInUI) {
                case themes.ElementContextInUI.FormControl_withinCellGroup:
                case themes.ElementContextInUI.totallyCustomContext:
                {
                    view.layer.style.paddingLeft = `${themeC.current.layouts.form_label_within_cell_padding_left}px`
                    view.layer.style.justifyContent = "space-between"
                    break
                }
                case themes.ElementContextInUI.FeedCellHeader:
                {
                    view.layer.style.justifyContent = "center"
                    break
                }

                case themes.ElementContextInUI.AppFooter:
                {
                    view.layer.style.height = `auto`
                    break
                }    
            
                case themes.ElementContextInUI.CellGroupHeader_small_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_normal_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_large_withinCellGroup:
                case themes.ElementContextInUI.CellGroupHeader_large_outsideOfCellGroup:
                {
                    view.layer.style.height = `auto`
                    view.layer.style.justifyContent = "space-between" // i.e. left align this title, and let other elements be pushed to the right
                    break
                }
                case themes.ElementContextInUI.SplashHeader:
                case themes.ElementContextInUI.SplashHeader_Mini:
                case themes.ElementContextInUI.SplashBody_Mini:
                {
                    view.layer.style.height = `auto`
                    view.layer.style.justifyContent = "center"
                    break
                }
                case themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup:
                {
                    view.layer.style.height = `auto`
                    view.layer.style.justifyContent = "left"
                    break
                }
            }
        }
        {
            let view = new LinkButton._Row({
                themeC: themeC,
                uiContext: contextInUI, // enables configuring style
                pressed_fn: self.ip.postTitleButtonText_tapped_fn ? self.ip.postTitleButtonText_tapped_fn :  () => {} // since it's required by LinkButtonCell
            }).init()
            self.titleLabel = view
            self.addSubview(view)
        }
        {
            let view = new View_Web({ el_name: "div" }).init()
            view.layer.style.display = "flex"
            view.layer.style.flexDirection = "row"
            view.layer.style.flexWrap = "wrap" // we do want these to wrap
            view.layer.style.gap = "14px"
            // view.layer.style.justifyContent = "space-between"
            self.rightSide_container = view
            self.addSubview(view)
        }
    }
    //
    insertSubviewToLeftOfAccessoryRadio(
        view: View_Web
    ) {
        const self = this
        self.rightSide_container.insertSubview(view, 0)
        if (!self._isEnabled) {
            view.setEnabled(false)
        }
    }
    //
    //
    _last_preTitle_iconType: InformativeIcon.IconType | null | undefined = null
    _last_preTitle_symbolicIcon_props: Icon_Symbolic.Props | null | undefined = null
    _last_tooltipText: string | null = null
    //
    set_props(args: _LabelRowContainer_Props) {
        const self = this
        if (args.labels) {
            let props = args.labels
            let items: LinkButtonOrTextSpanItem[] = []
            if (typeof props.preTitle_iconType === 'undefined') { // undefined -> preserve
                if (typeof self._last_preTitle_iconType !== 'undefined' && self._last_preTitle_iconType !== null) {
                    items.push({
                        _pkey: "preTitle_icon",
                        label: undefined,
                        iconType: self._last_preTitle_iconType!,
                        buttonUsage: undefined
                    })
                }
            } else if (props.preTitle_iconType !== null) {
                items.push({
                    _pkey: "preTitle_icon",
                    label: undefined,
                    iconType: props.preTitle_iconType!,
                    buttonUsage: undefined
                })
            }
            self._last_preTitle_iconType = props.preTitle_iconType
            //
            //
            if (typeof props.preTitle_symbolicIcon_props === 'undefined') { // undefined -> preserve
                if (typeof self._last_preTitle_symbolicIcon_props !== 'undefined' && self._last_preTitle_symbolicIcon_props !== null) {
                    items.push({
                        _pkey: "preTitle_symbol",
                        label: undefined,
                        symbolicIcon_props: self._last_preTitle_symbolicIcon_props!,
                        buttonUsage: undefined
                    })
                }
            } else if (props.preTitle_symbolicIcon_props !== null) {
                items.push({
                    _pkey: "preTitle_symbol",
                    label: undefined,
                    symbolicIcon_props: props.preTitle_symbolicIcon_props!,
                    buttonUsage: undefined
                })
            }
            self._last_preTitle_symbolicIcon_props = props.preTitle_symbolicIcon_props
            //
            if (props.title !== null) {
                items.push({
                    _pkey: "title",
                    label: props.title,
                    buttonUsage: undefined,
                    appearance: props.title_appearance
                })
            }
            if (props.postTitleButtonText === null) { 
            } else {
                items.push({ 
                    _pkey: "postTitleButtonText",
                    label: props.postTitleButtonText,  // .label can be undefined
                    buttonUsage: LinkButtonUsage.default,
                    appearance: props.postTitleButtonText_appearance,
                })
            }
            if (props.tooltipText === null || typeof props.tooltipText !== 'undefined') {
                self._last_tooltipText = props.tooltipText // must record this
            } else {
                // then it's undefined - meaning, don't change the existing self._last_tooltipText
            }
            if (self._last_tooltipText !== null) {
                items.push({
                    _pkey: "tooltipText",
                    label: "?",
                    buttonUsage: LinkButtonUsage.default,
                    tooltipText: self._last_tooltipText,
                    appearance: LinkButton.Appearance.med_enclosedDecorated
                })
            }
            //
            self.titleLabel.set_props({
                items: items
            })
        }
        let weakSelf = new WeakRef(self)
        if (args.accessoryRadio) {
            let props = args.accessoryRadio
            if (typeof props.members !== 'undefined') {
                if (props.members != null) {
                    if (!self.accessoryRadio) {
                        let view = new StyledRadio_Control.Control({
                            themeC: self.ip.themeC,
                            grouping: StyledRadio_Control.VisualGrouping.group,
                            roleWithinUICell: self.ip.accessoryRadio_roleWithinUICell || StyledRadio_Control.RadioControlRoleWithinUICell.secondary
                        }).init()
                        if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                            view.setEnabled(false)
                        }
                        self.accessoryRadio = view
                        view.on(StyledRadio_Control.Control_EventName.memberDifftThanSelectedValueTapped, (v) =>
                        { // rumor is that we dont have to remove this listener if no other references to it exist
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            optl_self.emit(_LabelRowContainer_EventName.accessoryRadio_changed, v)
                        })
                        self.rightSide_container.addSubview(view) // always 'self.addSubview' since we use insertSubview(other_views, 0) to keep those other views to the left of self.accessoryRadio
                    }
                } else {
                    if (self.accessoryRadio) {
                        self.accessoryRadio.removeFromSuperview()
                        self.accessoryRadio = undefined
                    }
                }
            }
            if (self.accessoryRadio) {
                self.accessoryRadio!.set_props({
                    leftAdjacent_title: props.leftAdjacent_title,
                    members: props.members === null ? [] : props.members,
                    selectedValue: props.selectedValue,
                    rightAdjacent_title: props.rightAdjacent_title
                })
            }
        }
    }
    //
    //
    setEnabled(isEnabled: boolean)
    {
        const self = this
        super.setEnabled(isEnabled)
        for (let subv of self.rightSide_container.subviews) { // there may be multiple - and the accessoryRadio is in this as well
            subv.setEnabled(isEnabled)
        }
        self.titleLabel.setEnabled(isEnabled) // since it's actually a LinkButtonCell now
    }
}