//
//
import { compatible_code_from_mnemonic_language } from '@xrds/payments-client-ts/src/monero-wallet-clients/MoneroMnemonicLanguages'
import View_Web from '../../View/View.web'
import { App_View_Context } from '../App_View_Web'
import { ComposableComponentFormPage } from '../Common/ComposableComponentFormPage'
import CustomWalletScannerInputCell from '../Common/CustomWalletScannerInputCell'
import { InlineWarningTextCell } from '../Common/InlineWarningTextCell'
import { styles_clearfix } from '../Common/styles_clearfix'
import { TitledEducationalTextCell } from '../Common/TitledEducationalTextCell'
import { TitledTextInputCell } from '../Common/TitledTextInputCell'
import WalletLanguageSelect_Cell_View, { WalletLanguage } from '../Common/WalletLanguageSelectCell'
import { Feed_View } from '../Feed/FeedView'
import { CreatePassword_AndLogin_View } from './CreatePassword_AndLogIn_View'
import { EnterExistingPassword_AndLogIn_View } from './EnterExistingPassword_AndLogIn_View'
import { NavigationBar } from '../Common/NavigationBarView'
import { WalletBeingAdded_Params } from '@xrds/xrds-libapp-ts/src/Wallets/models/WalletsListController'
import PrivateServerAuthTokenInputCell from '../Common/PrivateServerAuthTokenInputCell'
import { _LandingComponents } from './_LandingComponents'
import { TextInput_InputType } from '../Common/_TextInput'
import { TitledDetailsDescriptionCell } from '../Common/TitledDetailsDescriptionCell'
import { MnemonicCodingMethod, seed_encryption_method_int_code__from__dropdown_str } from '../Common/MnemonicCodingMethodSelectCell'
import StringSelect_Cell_View from '../Common/StringSelect_Cell'
import { AbstractControl } from '../Common/AbstractTitledControlCell'
import { styles_theme, themes } from '../themes'
import { AbstractThemed } from '../Common/AbstractThemed'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { LargeCTAButtonsGroup } from '../Common/LargeCTAButtonsGroup'
import { _LabelRowContainer } from '../Common/_LabelRowContainer'
//
//
//
//
//
export interface CreateNewWallet_View_InitParams
{
    is_within_onboarding: boolean
}
//
//
export class CreateNewWallet_View extends ComposableComponentFormPage
{
    ip!: CreateNewWallet_View_InitParams
    add_pending_wallet_params!: WalletBeingAdded_Params
    //
    input__useCustomServer!: TitledTextInputCell
    input__privateServerAuthToken!: TitledTextInputCell
    //
    view__metadata_serverURL!: TitledTextInputCell
    //
    mnemonicDisplayView!: View_Web
    view_actualDecryptedMnemonic!: TitledDetailsDescriptionCell 
    select__mnemonicEncryptionMethod!: StringSelect_Cell_View
    //
    mnemonicTitle_labelRow!: _LabelRowContainer
    //
    select__walletLanguage!: WalletLanguageSelect_Cell_View
    input__encryptMyMnemonic!: TitledTextInputCell
    cached_selectedLanguage!: string
    //
    constructor(ctx: App_View_Context, ip: CreateNewWallet_View_InitParams)
    {
        super(ctx)
        const self = this
        self.ip = ip
    }
    setup(): void 
    {
        super.setup()
        const self = this
        //
        self.cached_selectedLanguage = WalletLanguage.INITIAL_LANGUAGE
        self.generate__add_new_wallet_params()
        //
        self.setup_views()
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self)
        {
            let view = new TitledEducationalTextCell({ 
                themeC: self.ctx.themeC, 
                contextInUI: themes.ElementContextInUI.PageHeaderOrText_outsideOfCellGroup
            }).init()
            view.set_props({
                title: "Your New Wallet's Information",
                description: "Important: Write down the exact secret words you see in the box below!"
                + "\n\nYou MUST privately save the following secret \"mnemonic\". These unique, secret words are the only way you can regain access to your wallet if you log out. Anyone with these plaintext words can spend your wallet's funds or access your data, so it's best not to store these words in plain-text anywhere. Your seed or spend keys are of course also never sent to any server by Crossroads, so we cannot help you recover them."
                + "\n\nFor added safety, you can store your written mnemonic using the passphrase stealth-encryption below, in which case you'll want to write down the encrypted words instead of the plaintext."
                , isText_userSelectable: true // some users might want to try to translate these instructions
            })
            self.addSubview(view)
        }
        {
            let view = new WalletLanguageSelect_Cell_View({ 
                themeC: self.ctx.themeC,
                selectedLanguage: self.cached_selectedLanguage,
                did_change_value__fn: (l: string) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.set_props({ language: l })
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.select__walletLanguage = view
            self.addSubview(view)
        }
        {
            let view = new TitledDetailsDescriptionCell({
                themeC: self.ctx.themeC,
                allowTextSelect_description: true,
                includeAccessory_checkbox: false
            }).init()
            view.set_props({
                title: "Your Actual Mnemonic in Plain Text",
                description: "",
                tooltipText: "As long as you write down the encrypted words when you make your handwritten backup, then you don't need to back up these words, even though the app will use the plaintext mnemonic for logging into your wallet."
            })
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            view.hide() // initially
            self.view_actualDecryptedMnemonic = view
            self.addSubview(view)
        }
        {
            let view = new TitledTextInputCell({ 
                themeC: self.ctx.themeC,
                contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
                placeholder: "Encrypt With Passphrase…",
                inputType: TextInput_InputType.secureText,
                includeAccessory_checkbox: true,
                checkboxControlsInputHiding: true,
                checkbox__checked: false, 
                accessory_checkbox_leftSideTitle: null, // show no label title there
                accessory_checkbox_valLabel_false: 'Off',
                accessory_checkbox_valLabel_true: 'On',
                primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio,
                input__changed_fn: () => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.configureWith_wallet_andEncryption() // might as well
                },
                _accessoryRadio_changed: (isChecked: boolean) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (isChecked) {
                        optl_self.select__mnemonicEncryptionMethod.show()
                        optl_self.view_actualDecryptedMnemonic.show()
                    } else {
                        optl_self.select__mnemonicEncryptionMethod.hide()
                        MnemonicCodingMethod.reset_selectedValue(optl_self.select__mnemonicEncryptionMethod)
                        optl_self.view_actualDecryptedMnemonic.hide()
                        //
                        optl_self.input__encryptMyMnemonic.clear_input() // so that when it's shown again, the same pw isn't still present (especially since it can be highly sensitive)
                    }
                    AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
                    optl_self.configureWith_wallet_andEncryption()
                }
            }).init()
            view.set_props_labelRow({
                title: "Stealth-Encrypt Mnemonic For Handwritten Copy", 
                postTitleButtonText: undefined,
                tooltipText: "This stealth encryption of your mnemonic can be useful if you store your mnemonic in hard-copy form that requires the same number of words as your actual mnemonic, or, if you want to make your encrypted mnemonic look like the real one. You can even send money to the encrypted one and monitor it separately as a minor security failsafe."
                + "<br/><br/>If you use this feature, we recommend a very strong passphrase, and to make absolutely sure never to lose, publicly share, or reuse that passphrase. The encryption of the mnemonic words will also only be as secure as your passphrase strength."
                +"<br/><br/>The mnemonic encryption algorithms that Crossroads makes available here were ported from the code originally found on xmr.llcoins.net."
            })
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.input__encryptMyMnemonic = view
            self.addSubview(view)
        }
        {
            let view = MnemonicCodingMethod.new_selectCell({
                themeC: self.ctx.themeC,
                did_change_value__fn: (Vs: string[]) => 
                {
                    let optl_self = weakSelf.deref() // critical so as not to cause a strong ref cycle
                    if (!optl_self) {
                        return
                    }
                    optl_self.configureWith_wallet_andEncryption()
                }
            })
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            view.hide() // initially
            //
            self.select__mnemonicEncryptionMethod = view
            self.addSubview(view)
        }


        //
        // TODO: probably factor these two, especially once fully themed
        
        {
            //
            let row = new AbstractThemed.View({ }).init()
            row.layer.style.display = "block" // take up whole line
            row.layer.style.paddingTop = `${self.ctx.themeC.current.layouts.cellGroup_row_padding_top}px`
            row.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.addSubview(row)



            {
                let view = new _LabelRowContainer({ // mainly using this for the styling
                    themeC: self.ctx.themeC,
                    contextInUI: themes.ElementContextInUI.CellGroupHeader_normal_withinCellGroup
                }).init()
                view.layer.style.marginLeft = `${self.ctx.themeC.current.layouts.text_display_cell_padding_h}px`
                self.mnemonicTitle_labelRow = view
                row.addSubview(view)
            }
    



            

            //
            let view = new View_Web({ }).init()
            view.layer.style.padding = "16px 20px" // TODO: themeC?
            view.layer.style.color = self.ctx.themeC.current.colors.font_lightest
            view.layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_regular)
            view.layer.style.fontSize = `${self.ctx.themeC.current.typography.new_mnemonic_display__font_size}px`
            view.layer.classList.add(styles_clearfix.ClassNames.selectable_text) // a css class appears to be a good idea to use to override the injected clearfix rule with -webkit etc 
            // custom group cell theme styling:
            view.layer.classList.add(styles_theme.ClassNames.cellGroup_row) // required base class
            view.layer.classList.add(styles_theme.ClassNames.cellGroup_bg_2__withEndCaps) // actual specific variant
            // 
            self.mnemonicDisplayView = view
            row.addSubview(view)
        }






        {
            let view = _LandingComponents.new__cell_educational_selectScanner(self.ctx.themeC)
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            self.addSubview(view)
        }
        {
            let view = new CustomWalletScannerInputCell({ themeC: self.ctx.themeC }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.input__useCustomServer = view
            self.addSubview(view)
        }
        {
            let view = new PrivateServerAuthTokenInputCell({ themeC: self.ctx.themeC }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.input__privateServerAuthToken = view
            self.addSubview(view)
        }
        {
            let view = _LandingComponents.new_metadata_infoHeaderCell(self.ctx.themeC)
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            self.addSubview(view)
        }
        {
            let v = _LandingComponents.new__cell_view_metadata_remoteBackups(self.ctx.themeC)
            v.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.view__metadata_serverURL = v
            self.addSubview(v)
        }
        {
            let view = _LandingComponents.new__cell_titlededuc_agree_to_terms({ themeC: self.ctx.themeC })
            self.addSubview(view)
        }
        {
            let view = new LargeCTAButtonsGroup.View({ 
                themeC: self.ctx.themeC,
                layoutDirection: LargeCTAButtonsGroup.FlowDirection.col,
                contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            view.set_props({
                descs: [
                    {
                        title: "Add Wallet",
                        symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.plus },
                        pressed_fn: async () => 
                        {
                            let optl_self = weakSelf.deref()
                            if (!optl_self) {
                                return
                            }
                            await optl_self._did_press_primaryCTA()
                        }
                    }
                ]
            })
            self.button__primaryCTA = view
            self.addSubview(view)
        }
        {
            let view = new InlineWarningTextCell({ 
                themeC: self.ctx.themeC,
                uiContext: themes.ElementContextInUI.FormControl_outsideOfCellGroup
            }).init()
            self.warningText = view
            self.addSubview(view)
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self.configureWith_wallet_andEncryption()
        self._didFinishSettingUpFormViews() // must call this manually in each Form implementor
    }
    //
    // Imperatives - Overrides
    configureWith_formSubmittingState(args: {
    }) {
        const self = this
        //
        let isEnabled = self.isFormSubmitting != true
        //
        self.select__walletLanguage.setEnabled(isEnabled)
        //
        self.input__useCustomServer.setEnabled(isEnabled)
        self.input__privateServerAuthToken.setEnabled(isEnabled)
        self.view__metadata_serverURL.setEnabled(isEnabled)
        //
        self.select__mnemonicEncryptionMethod.setEnabled(isEnabled)
        self.input__encryptMyMnemonic.setEnabled(isEnabled)
        //
        super.configureWith_formSubmittingState(args) // modulates primaryCTA etc
    }
    //
    // Accessors - Overrides
    _isDisabled__button_primaryCTA(): boolean
    {
        const self = this
        if (super._isDisabled__button_primaryCTA()) {
            return true
        }
        // gonna allow submission here since it lets us provide user feedback about missing fields
        //
        return false
    }
    override navigationBar_title(): string|undefined
    {
        return "Create New Wallet"
    }
    override navigationBar_isEnabled_leftSideAccessory(): boolean
    { // PS: disabling Back while form submitting due to no support for canceling network requests currently
        const self = this
        //
        return self.isFormSubmitting == true ? false : true
    }
    navigableView_leftSideAccessoryView(): View_Web|null
    {
        const self = this
        if (self.ip.is_within_onboarding) { // not checking whether this is within a modal because this might not be presented yet
            return null // allow the back button instead
        }
        let weakSelf = new WeakRef(self)
        //
        return NavigationBar.new_base_barButtonView({ 
            themeC: self.ctx.themeC, 
            innerHTML: "Cancel", 
            clicked_fn: (e) => {
                e.preventDefault()
                let optl_self = weakSelf.deref()
                if (!optl_self) {
                    return false
                }
                if (optl_self.stackNavigationView!.navigationBar.leftSideAccessoryView!._isEnabled != true) {
                    return false
                }
                optl_self.stackNavigationView!.modalParentView!.dismiss_topModal() // is this better than telling the rootNavigationView to .dismiss_topModal()?
                //
                return false
            }
        })
    }
    //
    //
    generate__add_new_wallet_params(
    ) {
        const self = this
        if (self.add_pending_wallet_params) {
            let seed = self.add_pending_wallet_params.wallet.initParam_newlyGeneratedWalletDescription!.sec_seed_string
            self.add_pending_wallet_params.wallet = self.ctx.controllers.walletsListController.New_Wallet_WithAlreadyKnownSeed_NoLoginBoot_NoListAdd(
                seed, self.cached_selectedLanguage
            ).walletInstance! // TODO? though, it's probably not necessary to regenerate the entire wallet instance, even though it hasn't booted yet - could just set the mnemonic and language directly onto the initParam_newlyGeneratedWalletDescription
        } else {
            const locale_code = compatible_code_from_mnemonic_language(self.cached_selectedLanguage)
            const ret = self.ctx.controllers.walletsListController.New_NewlyGeneratedWallet_NoLoginBoot_NoListAdd(locale_code)
            self.add_pending_wallet_params =
            {
                wallet: ret.walletInstance!,
                is_newly_generated: true
            }
        }
    }
    actual_usable_mnemonic(): {
        main_mnemonicDisplayView_text: string,
        whileEncryptionEnabled_actualPlaintextMnemonic_text: string,
        is_encr_enabled: boolean,
        err_or_feedback_str?: string
    } {
        const self = this
        let err_or_feedback_str: string|undefined = undefined // to be finalized below
        let mnemonic_string = self.add_pending_wallet_params.wallet.initParam_newlyGeneratedWalletDescription!.mnemonic_string
        let main_mnemonicDisplayView_text;
        let whileEncryptionEnabled_actualPlaintextMnemonic_text;
        let is_encr_enabled = self.input__encryptMyMnemonic.lookup_checkbox_isChecked()!
        if (is_encr_enabled) {
            whileEncryptionEnabled_actualPlaintextMnemonic_text = mnemonic_string
            //
            let passphrase = self.input__encryptMyMnemonic.lookup_input_value()
            if (passphrase) {
                let monero_core_bridge_instance = self.ctx.controllers.monero_core_bridge_instance
                let crypting_method_str = self.select__mnemonicEncryptionMethod.single_selected_value()
                let crypting_method_code = seed_encryption_method_int_code__from__dropdown_str(
                    monero_core_bridge_instance.seed_encryption_method_int_codes(),
                    crypting_method_str
                )
                let decrypted_seed;
                try {
                    decrypted_seed = monero_core_bridge_instance.encrypted_seed_string(
                        self.add_pending_wallet_params.wallet.initParam_newlyGeneratedWalletDescription!.sec_seed_string, 
                        passphrase,
                        crypting_method_code
                    );
                    const encrypted_mnemonic = monero_core_bridge_instance.mnemonic_from_seed(
                        decrypted_seed, self.cached_selectedLanguage
                    )
                    main_mnemonicDisplayView_text = encrypted_mnemonic
                } catch (decrypt_e) { // in this case, actually show the error
                    err_or_feedback_str = ""+decrypt_e 
                    main_mnemonicDisplayView_text = err_or_feedback_str // in this case, actually show the error
                }
            } else {
                main_mnemonicDisplayView_text = "(Awaiting your encryption passphrase)"
                err_or_feedback_str = main_mnemonicDisplayView_text
            }
        } else {
            whileEncryptionEnabled_actualPlaintextMnemonic_text = "" // not applicable - best to clear for security purposes
            //
            main_mnemonicDisplayView_text = mnemonic_string
        }
        return {
            main_mnemonicDisplayView_text,
            whileEncryptionEnabled_actualPlaintextMnemonic_text,
            is_encr_enabled,
            err_or_feedback_str
        }
    }
    configureWith_wallet_andEncryption()
    {
        const self = this
        let m = self.actual_usable_mnemonic()
        self.mnemonicTitle_labelRow.set_props({ labels: {
            title: m.is_encr_enabled 
                ? "Secretly Copy These (Encrypted) Words:" 
                : "Secretly Copy These Words:",
            postTitleButtonText: null,
            tooltipText: null
        } })
        self.mnemonicDisplayView.layer.innerText = m.main_mnemonicDisplayView_text
        self.view_actualDecryptedMnemonic.set_props({
            title: undefined,
            description: m.whileEncryptionEnabled_actualPlaintextMnemonic_text,
            tooltipText: null
        })
    }
    //
    // Delegation - Form - Overrides
    async _did_press_primaryCTA()
    {
        const self = this
        //
        let walletsListController = self.ctx.controllers.walletsListController
        // let userIdleController = self.ctx.controllers.userIdleController
        let passwordController = self.ctx.controllers.passwordController
        //
        let m = self.actual_usable_mnemonic()
        if (m.err_or_feedback_str) {
            self.__bail_from_form_submission_with({ err_str: m.err_or_feedback_str })
            return    
        }
        //
        let walletName = null 
        let isToggled_useCustomServer = self.input__useCustomServer.lookup_checkbox_isChecked()
        let customServerURL = isToggled_useCustomServer ? self.input__useCustomServer.lookup_input_value() : undefined // i'm just sending this to undefined if the toggle is not on so as not to have to duplicate it below
        //
        let isToggled_privateServerAuthToken = self.input__privateServerAuthToken.lookup_checkbox_isChecked()
        let privateServerAuthToken = isToggled_privateServerAuthToken ? self.input__privateServerAuthToken.lookup_input_value() : undefined
        //
        let initial__inbox_and_metadata__disable_remote_connectivity = self.view__metadata_serverURL.lookup_checkbox_isChecked()! == false
        //
        if (isToggled_useCustomServer) { // "
            if (!customServerURL || typeof customServerURL == 'undefined') {
                self.__bail_from_form_submission_with({ err_str: "Please enter or de-select Custom Scanner Server URL." })
                return    
            }
        }
        if (isToggled_privateServerAuthToken) { // "
            if (!privateServerAuthToken || typeof privateServerAuthToken == 'undefined') {
                self.__bail_from_form_submission_with({ err_str: "Please enter or de-select \"Scanner - Auth Code\"." })
                return    
            }
        }
        //
        self.__mark_form_as_currently_submitting() // mark as currently busy - so user cannot go back during log in
        //
        if (passwordController.GivenBooted_HasUserSavedAPassword() == false) {
            self.isFormSubmitting = false
            self.configureWith_formSubmittingState({})
            //
            self.ctx.rootNavigationView.push(new CreatePassword_AndLogin_View(self.ctx, {
                add_pending_wallet_params: self.add_pending_wallet_params,
                walletName, 
                customServerURL: customServerURL,
                privateServerAuthToken,
                initial__inbox_and_metadata__disable_remote_connectivity
            }).init())
            return
        }
        if (passwordController.HasUserEnteredValidPasswordYet() == false) { // allow the next screen to log the wallet in
            self.isFormSubmitting = false
            self.configureWith_formSubmittingState({})
            //
            self.ctx.rootNavigationView.push(new EnterExistingPassword_AndLogIn_View(self.ctx, {
                add_pending_wallet_params: self.add_pending_wallet_params,
                walletName, 
                customServerURL,
                privateServerAuthToken,
                initial__inbox_and_metadata__disable_remote_connectivity
            }).init())
            return
        }
        // app is already unlocked
        // // userIdleController.TemporarilyDisable_userIdle()
        // Temporarily disabled and using a larger minimum idle timer instead; If the app goes idle when the walletListController call comes back, the biz logic ought to be able to handle that instead
        let r = await walletsListController.WhenBooted_GivenPW_AddWalletWithParams({
            add_pending_wallet_params: self.add_pending_wallet_params,
            walletLabel: walletName, 
            customServerURL: customServerURL,
            privateServerAuthToken: privateServerAuthToken,
            optl__start_height: undefined, // since we know here already there's not going to be a start height specified - it's a newly created wallet so it'll get the head height
            initial__inbox_and_metadata__disable_remote_connectivity: initial__inbox_and_metadata__disable_remote_connectivity
        })
        // // userIdleController.ReEnable_userIdle()
        //
        // console.log("~~> r", r)
        //
        if (/*r.did_user_cancel_pw_entry || */r.err_str) {
            self.__bail_from_form_submission_with({ err_str: r.err_str })
            return
        }
        self.isFormSubmitting = false // re-enable 'back'
        self.configureWith_formSubmittingState({}) // this is somewhat redundant of a call here since it will also happen on the page being removed from the nav stack - does beforeRemove always get triggered even when no longer on display in the nav stack?
        //
        // Note: not setting the state back to enabled here bc the user is finished with this screen
        if (self.ip.is_within_onboarding) {
            self.ctx.rootNavigationView.reset([ // clear 'back' stack - now that we've landed 
                new Feed_View(self.ctx).init()
            ], true /* DO remove all modals */)
        } else {
            self.stackNavigationView!.modalParentView!.dismiss_topModal() // dismiss containing modal - because, since password was already entered and app was already unlocked, it means this was presented in a modal - and especially since is_within_onboarding (this is to handle an edge case where the initial login fails but password entry succeeds, then the user ends up pressing back to end up here and hits log in again)
        }
    }
    //
    // Delegation - Internal
    set_props(props: { language: string })
    { // regenerate wallet and set new state
        const self = this
        //
        self.cached_selectedLanguage = props.language
        self.generate__add_new_wallet_params()
        self.configureWith_wallet_andEncryption()
    }
    //
    // Delegation - NavigableView
    navigableView_willGoBackFrom()
    { // PS: different from viewWillDisappear!!
        const self = this
        //
    }
}