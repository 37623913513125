//
//
import View_Web from '../../View/View.web'
import { styles_theme, themes } from '../themes'
import { PropSettable } from './DisplayableListRow'
//
export interface InlineWarningTextCell_InitParams
{
    themeC: themes.Controller
    uiContext?: themes.ElementContextInUI
}
export interface InlineWarningTextCell__set_props__args extends PropSettable.base__set_props__args
{ 
    title?: string, 
    text: string
}
//
//
export class InlineWarningTextCell extends View_Web
{
    //
    ip!: InlineWarningTextCell_InitParams
    //
    uiContext(): themes.ElementContextInUI {
        const self = this
        //
        return self.ip.uiContext || themes.ElementContextInUI.FormControl_outsideOfCellGroup
    }
    //
    constructor(ip: InlineWarningTextCell_InitParams)
    {
        super({ el_name: "div" })
        const self = this
        self.ip = ip
    }
    setup(): void
    {
        super.setup()
        const self = this
        let themeC = self.ip.themeC
        {
            let view = self
            view.hide()
            //
            view.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h
            //
            view.layer.style.marginTop = `${themeC.current.layouts.cellGroup_parent_margin_top}px`
            //
            view.layer.style.paddingLeft = `${themeC.current.layouts.cellGroup_row_padding_h}px`
            view.layer.style.paddingRight = `${themeC.current.layouts.cellGroup_row_padding_h}px`
            //
            view.layer.style.backgroundColor = themeC.current.colors.warning__inline__bg
            view.layer.style.border = `0.5px solid ${themeC.current.colors.warning__inline__border}`
            view.layer.style.borderRadius = `5px` // TODO: themeC?
            //
            switch (self.uiContext()) {
                case themes.ElementContextInUI.SplashHeader_Mini:
                case themes.ElementContextInUI.SplashHeader:
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.maxWidth = "450px"
                    view.layer.style.boxSizing = "border-box"
                    //
                    view.layer.style.marginLeft = "auto"
                    view.layer.style.marginRight = "auto"
                    break
                default:
                    view.layer.classList.add(styles_theme.ClassNames.form_row)
                    break
            }
        }
    }
    //
    set_props(args: InlineWarningTextCell__set_props__args): void
    {
        const self = this
        let themeC = self.ip.themeC
        self.removeAllSubviews() // if any
        //
        if (args.title) {
            let view = new View_Web({ el_name: "span" }).init()
            if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                view.setEnabled(false)
            }
            view.layer.style.color = themeC.current.colors.warning__inline__text
            view.layer.innerText = args.title
            view.layer.style.fontWeight = "bold"
            view.layer.style.fontFamily = themeC.current.typography.warning__inline__fontFamily
            switch (self.uiContext()) {
                case themes.ElementContextInUI.SplashHeader_Mini:
                case themes.ElementContextInUI.SplashHeader:
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.fontSize = "18px"
                    view.layer.style.lineHeight = "26px"
                    break
                default:
                    view.layer.style.fontSize = "16px"
                    view.layer.style.lineHeight = "24px"
                    break
            }
            self.addSubview(view)
        }
        {
            let view = new View_Web({ el_name: "span" }).init()
            if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                view.setEnabled(false)
            }
            view.layer.style.color = themeC.current.colors.warning__inline__text
            view.layer.innerText = args.text
            view.layer.style.fontFamily = themeC.current.typography.warning__inline__fontFamily
            switch (self.uiContext()) {
                case themes.ElementContextInUI.SplashHeader_Mini:
                case themes.ElementContextInUI.SplashHeader:
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.fontSize = "18px"
                    view.layer.style.lineHeight = "26px"
                    break
                default:
                    view.layer.style.fontSize = "16px"
                    view.layer.style.lineHeight = "24px"
                    break
            }
            view.layer.style.userSelect = "text" // allowing this here for UX
            view.layer.style.webkitUserSelect = "text"
            self.addSubview(view)
        }
    }
}