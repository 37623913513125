//
//
import { themes } from "../themes"
import { AbstractControl } from "./AbstractTitledControlCell"
import { TitledTextInputCell, TitledTextInputCell_InitParams } from "./TitledTextInputCell"
//
export interface CustomWalletScannerInputCell_InitParams
{
    themeC: themes.Controller
}
//
export default class CustomWalletScannerInputCell extends TitledTextInputCell
{
    constructor(ip: CustomWalletScannerInputCell_InitParams)
    {
        super({ 
            ...ip,
            contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
            placeholder: "https://api.my-wallet-scanner.com", 
            includeAccessory_checkbox: true,
            checkboxControlsInputHiding: true,
            checkbox__checked: false,
            accessory_checkbox_leftSideTitle: null, // show no label title there
            accessory_checkbox_valLabel_false: 'Off',
            accessory_checkbox_valLabel_true: 'On',
            primaryHidden_displayStaticValue: "https://lws.xrds.app:443",
            primaryInput_location: AbstractControl.LocationWithinTitledCell.leftOfAccessoryRadio
        })
        const self = this
    }
    setup()
    {
        const self = this
        super.setup()
        self.set_props_labelRow({
            title: "Use My Own Wallet Scanner", 
            postTitleButtonText: undefined,
            tooltipText: "For increased privacy in theory, you can specify a custom Monero lightwallet server (LWS) URL here, or leave as-is to use our complementary scanning service."
            + "<br/><br/>Our infrastructure is designed to carefully store your view key in order to scan in the background; we don't share or access your data for anything but support, and welcome the use of custom or local scanning to alleviate load from our system for other users. (Local scanning via monerod coming soon.)"
        })
    }
    //
    // TODO: input validation etc
}
