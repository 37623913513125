//
//
import View_Web from '../../View/View.web'
import { styles_theme, themes } from '../themes';
import { AbstractThemed } from './AbstractThemed';
import { CTAButton } from './CTAButton';
import { Icon_Symbolic } from './Icon_Symbolic';
import { InformativeIcon } from './InformativeIcon';
import { LargeCTA } from './LargeCTAButton';
//
export namespace LargeCTAButtonsGroup
{
    export enum FlowDirection
    {
        col,
        row
    }
    //
    export interface InitParams
    {
        themeC: themes.Controller
        contextInUI?: themes.ElementContextInUI // this is defaulted in a member prop - internal code in this file should not do not read this val directly
        //
        layoutDirection?: FlowDirection // defaults to .col
    }
    //
    export interface Button_Desc
    {
        title: string
        //
        variant?: CTAButton.Variant
        arrangement?: CTAButton.ArrangementAndGeometry
        //
        is_default?: boolean // only one should have this set to true
        //
        iconType?: InformativeIcon.IconType|undefined|null 
        symbolicIcon_props?: Icon_Symbolic.Props|undefined|null
        //
        is_enabled?: boolean // defaults to true
        pressed_fn?: () => void
    }
    //
    //
    export interface SetProps_Args
    {
        descs: Button_Desc[]
    }
    //
    export class View extends AbstractThemed.View
    {
        //
        declare ip: InitParams
        contextInUI!: themes.ElementContextInUI
        //
        _descs: Button_Desc[] = []
        //
        constructor(ip: InitParams)
        {
            super({ el_name: "div" })
            //
            const self = this
            self.ip = ip
            self.contextInUI = self.ip.contextInUI || themes.ElementContextInUI.FormControl_outsideOfCellGroup
        }
        setup()
        {
            super.setup()
            const self = this
            let view = self
            if (self.ip.layoutDirection == FlowDirection.row) { // now that the layer actually exists..
                view.layer.classList.add(styles_theme.ClassNames.centerAlignFlexRow_wrapping_responsiveFallback)
            }
            switch (self.contextInUI) {
                case themes.ElementContextInUI.SplashBody_Mini:
                    view.layer.style.justifyContent = "center"
                    view.layer.style.marginTop = "16px"
                    break
                case themes.ElementContextInUI.SplashHeader:
                    view.layer.style.justifyContent = "center"
                    view.layer.style.marginTop = "44px"
                    break
                case themes.ElementContextInUI.FormControl_withinCellGroup:
                    view.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h - but this class will get added by the groupCell styling anyway
                    break
                case themes.ElementContextInUI.FormControl_outsideOfCellGroup:
                    view.layer.style.marginTop = `${self.ip.themeC.current.layouts.cellGroup_parent_margin_top}px`
                    view.layer.classList.add(styles_theme.ClassNames.form_row) // this confers h margins of .row_margin_h
                    break
                default:
                    throw new Error("Unhandled self.contextInUI")
            }
        }
        public teardown(): void 
        {
            super.teardown()
            const self = this
        }
        //
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            let i = 0;
            for (let v of self.subviews) {
                v.setEnabled(self._descs[i].is_enabled == false ? false : isEnabled)
                i += 1
            }
        }
        //
        //
        set_props(args: SetProps_Args)
        {
            const self = this
            self.removeAllSubviews()
            //
            self._descs = args.descs // hanging onto these for runtime interactivity config
            //
            let themeC = self.ip.themeC
            let has_seen_a_default = false
            for (let [i, desc] of args.descs.entries()) {
                if (desc.is_default == true) {
                    if (has_seen_a_default) {
                        throw new Error("Only one button description may have .is_default within a given list of button descriptions.")
                    }
                    has_seen_a_default = true
                }
                let view!: View_Web;
                if (desc.variant == CTAButton.Variant.textOnly) {
                    view = new View_Web({}).init()
                    view.layer.innerText = desc.title
                    view.layer.style.fontFamily = ""+themeC.current.typography.largeCTAButton_intraBtnText__font_family
                    view.layer.style.fontWeight = "300"
                    switch (self.contextInUI) {
                        case themes.ElementContextInUI.SplashHeader:
                            view.layer.style.fontSize = "22px"
                            break
                        default:
                            view.layer.style.fontSize = "22px"
                            break
                    }
                    view.layer.style.color = themeC.current.colors.font_lighter
                    view.layer.style.alignSelf = "center"
                    view.layer.style.padding = "0 16px"
                } else {
                    let pressed_fn = desc.pressed_fn ? desc.pressed_fn : () => {}
                    view = new LargeCTA._Button({
                        themeC: themeC,
                        title: desc.title,
                        variant: desc.variant || CTAButton.Variant.main,
                        arrangement: desc.arrangement,
                        iconType: desc.iconType,
                        symbolicIcon_props: desc.symbolicIcon_props,
                        //
                        pressed_fn: pressed_fn 
                    }).init()
                    if (!self._isEnabled) { // ensure interactivity configuration survives reconstruction of subviews
                        view.setEnabled(false)
                    } else if (desc.is_enabled == false) {
                        view.setEnabled(false)
                    }
                    if (self.ip.layoutDirection == FlowDirection.row) {
                        view.layer.style.flex = "1" // i suppose we could still keep this even if we werent using .row but it might cause issues and this makes the reason for its usage clearer
                        view.layer.style.alignSelf = "center" // this is actually for responsive support on the *parent* which degrades to column based rendering for .row layout! kinda confusing. 
                        view.layer.style.width = "100%"
                    } else {
                        if (args.descs.length > 0 && i < args.descs.length - 1) {
                            view.layer.style.marginBottom = "14px"
                        }
                    }
                }
                self.addSubview(view)
            }
        }
    }
}