//
//
import View_Base from 'src/View/View_Base'
import View_Web from '../../View/View.web'
import App_View_Web, { App_View_Context } from '../App_View_Web'
import { DisplayableList, DisplayableListRow, PropSettable } from './DisplayableListRow'
import { AbstractThemed } from './AbstractThemed'
import { IndexIntervals } from '@xrds/monero-utils-ts'
//
function new_rethemekey_fromOptl(cellTheme: PropSettable.base__set_cellTheme__args | undefined): string
{
    if (!cellTheme) {
        return '-' // all that matters here is that nil cellTheme -> some constant (and unique!) key
    }
    // all that matters in the following is that this ordering is deterministic
    //
    return `${cellTheme.p}-${cellTheme.isInEmbeddedGroup}`
}
//
export namespace DisplayableList_EphemState
{
    export interface Store_IP
    {
        set_needs_reload__fn: () => void // this is for the list - i.e. implement this by calling .reload on the list view
    }
    //
    //
    let equals_fn__primitives: (was_v: any, to_v: any) => boolean = (was_v: any, to_v: any) => {
        return was_v == to_v
    }
    //
    type ArrayOfPrimitives = (string|number|null)[]
    let equals_fn__array_of_primitives: (
        optl__was_v: ArrayOfPrimitives|null, 
        optl__to_v: ArrayOfPrimitives|null
    ) => boolean = (
        optl__was_v: ArrayOfPrimitives|null,
        optl__to_v: ArrayOfPrimitives|null
    ) => {
        if (optl__was_v && !optl__to_v) {
            return false
        }
        if (!optl__was_v && optl__to_v) {
            return false
        }
        let was_v = optl__was_v!
        let to_v = optl__to_v!
        if (was_v.length !== to_v.length) {
            return false
        }
        return was_v!.every((el, i) => {
            return el === to_v![i]
        })
    }
    //
    //
    export class Store_Base
    {
        ip!: Store_IP
        constructor(_ip: Store_IP) {
            this.ip = _ip
        }
        //
        //
        // Interface - ChatroomEphemState
        any_by_finalkey: { [key: string]: any } = {}
        //
        public set__any_by_finalkey__primitive_v( // convenience
            pkey: string,
            to: any|null,
            do_reload?: boolean,/*default true*/
            //
            optl__did_change_value__before_optl_reload_fn?: () => void
        ) {
            const self = this
            return self.set__any_by_finalkey(pkey, to, equals_fn__primitives, do_reload, optl__did_change_value__before_optl_reload_fn)
        }
        public set__any_by_finalkey__array_of_primitives( // convenience
            pkey: string,
            to: ArrayOfPrimitives|null,
            do_reload?: boolean,/*default true*/
            //
            optl__did_change_value__before_optl_reload_fn?: () => void
        ) {
            const self = this
            return self.set__any_by_finalkey(
                pkey, 
                to, 
                equals_fn__array_of_primitives, 
                do_reload, 
                optl__did_change_value__before_optl_reload_fn
            )
        }
        set_list_needs_reload()
        {
            const self = this
            self.ip.set_needs_reload__fn()
        }
        //
        public set__any_by_finalkey(
            pkey: string, 
            to: any|null, 
            equals_fn: (was_v: any, to_v: any) => boolean, // used when to is non-null; prevent non terminating recursive .reload()
            do_reload?: boolean,/*default true*/
            optl__did_change_value__before_optl_reload_fn?: () => void
        ) {
            const self = this
            let changed = false
            if (to === null) {
                if (self.any_by_finalkey[pkey] === null) {
                    throw new Error("Expected non-null this.any_by_finalkey[msg_uuid]")
                }
                if (typeof self.any_by_finalkey[pkey] !== 'undefined') {
                    changed = true
                    delete self.any_by_finalkey[pkey]
                }
            } else {
                if (typeof self.any_by_finalkey[pkey] == 'undefined' || !equals_fn(to, self.any_by_finalkey[pkey])) { 
                    changed = true
                    self.any_by_finalkey[pkey] = to
                }
            }
            // console.log("set__any_by_finalkey ", pkey, to, changed, do_reload)
            if (changed) {
                if (optl__did_change_value__before_optl_reload_fn) {
                    optl__did_change_value__before_optl_reload_fn()
                }
                if (do_reload != false) {
                    self.set_list_needs_reload()
                }
            }
        }
    }
}
//
export class DisplayableListPage extends App_View_Web
{
    _userScrolled: boolean = false
    //
    //
    
    //
    // Ephemeral State impl
    ephem_state!: DisplayableList_EphemState.Store_Base // NOTE: This currently is specced only to be non-nil when .enableCellsSystem()
    _new__set_needs_reload__fn() // convenience for subclassers overriding new__ephem_state() 
    {
        const self = this
        let weakSelf = new WeakRef(self) // key so as not to cause a strong ref cycle
        return () => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self.reload()
        }
    }
    _new__base_ip__for__ephem_state() // convenience for subclassers
    {
        const self = this
        return {
            set_needs_reload__fn: self._new__set_needs_reload__fn()
        }
    }
    new__ephem_state(): DisplayableList_EphemState.Store_Base {
        const self = this
        return new DisplayableList_EphemState.Store_Base(self._new__base_ip__for__ephem_state())
    }
    //
    //
    //
    //
    listPage_scrollableView(): View_Web { // overridable
        if (this.enableCellsSystem()) {
            return this.cellsContainerView
        } else {
            return this
        }
    }
    //
    constructor(ctx: App_View_Context)
    {
        super(ctx, { el_name: "div" })
    }
    setup(): void 
    {
        super.setup()
        const self = this
        {
            const view = self
            view.layer.style.background = self.ctx.themeC.current.colors.page_bg
        }
        {
            if (self.enableCellsSystem()) {
                {
                    self.ephem_state = self.new__ephem_state()
                }
                {
                    let view = self
                    view.layer.style.display = "flex"
                    view.layer.style.flexDirection = 'column'
                    view.layer.style.overflow = "auto"
                }
                {
                    let view = new View_Web({}).init()
                    view.layer.style.flex = '1'
                    view.layer.style.alignSelf = 'auto'
                    view.layer.style.justifyContent = 'center'
                    view.layer.style.overflowY = "scroll"
                    view.layer.style.zIndex = "98" // we need to give it *some* value so siblings can have a higher (or even lower) values
                    self.cellsContainerView = view
                    self.addSubview(view)
                }
            } else {
                const view = self
                view.layer.style.overflow = "scroll"
            }
        }
        let scrollableView = self.scrollableView()
        scrollableView.layer.style.paddingTop = `${self.ctx.themeC.current.layouts.cellGroup_page_margin_top}px`
        scrollableView.layer.style.paddingBottom = `${self.ctx.themeC.current.layouts.cellGroup_page_margin_btm}px`
        scrollableView.layer.style.boxSizing = "border-box" // so that this padding doesnt mess w the height and create a bug in bottom pad of scrollable
    }
    //
    // Imperatives - Scrolling
    onNextTick_scrollToBtm(animated: boolean)
    {
        const self = this
        let weakSelf = new WeakRef(self) // key so as not to cause a strong ref cycle
        setTimeout(() => {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self._scrollToBtm(animated)
        })
    }
    //
    _scrollToBtm(animated: boolean)
    {
        const self = this
        let scrollableView = self.scrollableView()
        let n_subviews = self.scrollableView().subviews.length
        if (n_subviews == 0) {
            console.warn("Not scrolling to bottom since there are messages in the list")
            return
        }
        let lastChild = scrollableView.subviews[n_subviews - 1]
        // console.trace()
        // console.log("scroll to lastChild" , lastChild, "at", (n_subviews - 1))
        self.cellsContainerView.layer.scrollTo({
            top: self.cellsContainerView.layer.scrollHeight, // lastChild.layer.offsetTop,
            behavior: animated ? "smooth" : undefined // TODO: "auto" or perhaps "instant" instead of undefined?
        })
        // self._scrollToSubview(lastChild, animated, "end") // PS: this has been removed in favor of the above .scrollTo so as to account for bottom padding in scrollableView
    }
    _scrollToSubview(
        subview: View_Base<HTMLElement>, 
        animated: boolean,
        position: ScrollLogicalPosition = "nearest" // end, nearest, start, center
    ) {
        const self = this
        // console.log("scroll into view: ", subview)
        subview.layer.scrollIntoView({
            block: position,
            inline: "nearest",
            behavior: animated ? "smooth" : undefined // TODO: 'instant' instead of undefined?
        })
    }

    // 
    // Interface - DisplayableListPage - Cells support
    new_sorted_rows(): DisplayableList.NewRowSetRetVals // consumers who desire cell support: override and implement this
    {
        const self = this
        if (self.enableCellsSystem() != true) {
            throw new Error("new_sorted_rows() is only supported under true self.enableCellsSystem()")
        }
        return { ordered_rows: [] }
    }
    //
    //
    // _live_ordered_pkeys: string[] = []
    _live_cells__by__pkeys: { [key: string]: View_Web } = {}
    _live_cell_reconfkeys__by__pkeys: { [key: string]: string } = {}
    //
    was_first_reload: boolean = true
    //
    reload()
    {
        const self = this
        if (!self.enableCellsSystem()) {
            throw new Error("Currently, DisplayableListPage::reload only works with .enableCellsSystem() of true")
        }
        // console.log(self, self.uuid, " reload.. with pre-existing subviews:", self.cellsContainerView.subviews.map(sv => (sv.constructor.name + " " + sv.uuid)))
        // console.trace()
        //
        // preserve scrollTop for reconstitution after cells rebuild
        let prior_scrollTop = self.cellsContainerView.layer.scrollTop
        let scrollWasAtBottom = self.cellsContainerView.layer.scrollHeight - self.cellsContainerView.layer.scrollTop - self.cellsContainerView.layer.clientHeight < 1 // https://stackoverflow.com/questions/876115/how-can-i-determine-if-a-div-is-scrolled-to-the-bottom
        //
        let rows = self.new_sorted_rows()
            // TODO: for every newly generated list of components, do a diff on the primary keys first before generating all the specs again
            // basically, generate list of pkeys ONLY as first run - and only generate the renderItems for the ones where the pkey has actually changed! this might work fine as long as the pkey encodes the whole relevant state - the idea is that ephemeral state does not need to trigger re-renders, so the ephem state doesnt need to be in the pkeys
            // ... because generating the pkeys is cheaper than generating the specs over and over


        // let to_row_pkeys: { [key: string]: boolean } = {}
        // console.log("to ordered rows pkeys", rows.ordered_rows.map(r => r.row_pkey))
        // for (let r of rows.ordered_rows) {
        //     if (to_row_pkeys[r.row_pkey] || typeof to_row_pkeys[r.row_pkey] !== 'undefined') {
        //         throw new Error("Duplicate pkey discovered: " + r.row_pkey)
        //     }
        //     to_row_pkeys[r.row_pkey] = true
        // }
        // // PS: currently, that ^ only exists for assertion / development assistance / code sanity assurance purposes
        // console.log("to_row_pkeys", Object.keys(to_row_pkeys))

        //
        // PS: it's a minor visual enhancement to put as much of the re-rendering together as possible
        let to_cells: View_Web[] = []
        let to__live_cells__by__pkeys: { [key: string]: View_Web } = {}
        let to__live_cell_reconfkeys__by__pkeys: { [key: string]: string } = {}
        let to__live_cell_rethemekeys__by__pkeys: { [key: string]: string } = {}
        let to__live_cell_rows__by__pkeys: { [key: string]: DisplayableListRow.CellSpec__BaseParams } = {}
        for (let r of rows.ordered_rows) {
            let c = self._live_cells__by__pkeys[r.row_pkey]
            if (!c || typeof c === 'undefined') {
                c = self.new_cellViewFor(r)
                c.list_row_pkey = r.row_pkey // PS: I add this in case the cell itself needs to be identified later - but currently this is no longer used
                //
                // debug facilities:
                // c._lifecycleObject_wants_debug_log__release = true
                // c._lifecycleObject_wants_debug_log__teardown = true
            }
            to__live_cells__by__pkeys[r.row_pkey] = c
            to__live_cell_reconfkeys__by__pkeys[r.row_pkey] = r.row_reconfkey
            to__live_cell_rethemekeys__by__pkeys[r.row_pkey] = new_rethemekey_fromOptl(r.cellTheme)
            to__live_cell_rows__by__pkeys[r.row_pkey] = r // merely for lookup of props for possible reconf
            to_cells.push(c)
        }
        //
        let pruned_cell_row_pkeys: { [key: string]: boolean } = {}
        let prior__live_cells__by__pkeys = Object.keys(self._live_cells__by__pkeys)
        let do_reconf_with_props_on_surviving_preexisting_cells__by__pkeys: { [key: string]: PropSettable.base__set_props__args } = {}
        let do_reconf_with_cellThemeOrNull_on_surviving_preexisting_cells__by__pkeys: { [key: string]: PropSettable.base__set_cellTheme__args|null } = {}
        for (let prior__live_cell__pkey of prior__live_cells__by__pkeys) {
            let optl__to__live_cell = to__live_cells__by__pkeys[prior__live_cell__pkey]
            if (!optl__to__live_cell || typeof optl__to__live_cell === 'undefined') {
                pruned_cell_row_pkeys[prior__live_cell__pkey] = true
                // PS: Actual subview removal code is now handle within .setOrderedSubviews by subview uuid
            } else { // then the view will survive / is still live - do we need to reconfigure it?
                let to__row = to__live_cell_rows__by__pkeys[prior__live_cell__pkey]! // would expect that to exist
                let prior_reconfkey = self._live_cell_reconfkeys__by__pkeys[prior__live_cell__pkey]!
                if (prior_reconfkey != to__row.row_reconfkey) {
                    do_reconf_with_props_on_surviving_preexisting_cells__by__pkeys[prior__live_cell__pkey] = (to__row as DisplayableListRow.CellSpec__base_with_props).props
                }
                let prior_rethemekey = self._live_cell_reconfkeys__by__pkeys[prior__live_cell__pkey]!
                let to__rethemekey = new_rethemekey_fromOptl(to__row.cellTheme)
                if (prior_rethemekey != to__rethemekey) {
                    do_reconf_with_cellThemeOrNull_on_surviving_preexisting_cells__by__pkeys[prior__live_cell__pkey] = to__row.cellTheme!
                }
            }
        }
        //
        { // The actual re-render, and 'live_' cached vals updates
            // PS: I'm updating the caches now FIRST because the actual set ordered subviews or reconf may trigger a subsequent update and we don't want to there have stale data
            // console.log("was self._live_cell_reconfkeys__by__pkeys " , JSON.stringify(self._live_cell_reconfkeys__by__pkeys, null, '  '))
            self._live_cells__by__pkeys = to__live_cells__by__pkeys // keeps things simple this way to account for deleted pkeys - but waiting til after subviews removed for good measure?
            self._live_cell_reconfkeys__by__pkeys = to__live_cell_reconfkeys__by__pkeys
            // console.log("to__live_cell_reconfkeys__by__pkeys " , JSON.stringify(to__live_cell_reconfkeys__by__pkeys, null, '  '))
            //
            self.cellsContainerView.setOrderedSubviews(to_cells) // this is written to be optimally performant
            { // reconf support
                let reconf_cells_w_pkeys = Object.keys(do_reconf_with_props_on_surviving_preexisting_cells__by__pkeys)
                for (let surviving_cell_pkey of reconf_cells_w_pkeys) {
                    let live_cell = to__live_cells__by__pkeys[surviving_cell_pkey] as unknown as PropSettable.ViewIface // bit gross but...
                    let to_props = do_reconf_with_props_on_surviving_preexisting_cells__by__pkeys[surviving_cell_pkey]
                    // console.log("reconfiguring surviving cell ", live_cell, " with ", to_props)
                    live_cell.set_props(to_props)
                    //
                    let optl__to_cellTheme_orNull = do_reconf_with_cellThemeOrNull_on_surviving_preexisting_cells__by__pkeys[surviving_cell_pkey]
                    if (typeof optl__to_cellTheme_orNull !== 'undefined') { // i.e. either null or non-null,non-undefined
                        live_cell.set_cellTheme(optl__to_cellTheme_orNull)
                    }
                }
            }
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self.listPage_scrollableView()) // since group members have changed 
        //
        // 
        if (scrollWasAtBottom) { // this is a little trick I'm adding to cause the scroll to pin to the bottom if the newly added cell we just scrolled to (on a just-prior render) has its internal contents updated on e.g. failed to send - which would not trigger a scroll to bottom but would change the location of the bottom position
            self.cellsContainerView.layer.scrollTop = self.cellsContainerView.layer.scrollHeight // re-derive scrollTop as the full scroll height
            // TODO: Alternatively, we could honestly just call _scrollToBottom - that might be better anyway
        } else {
            self.cellsContainerView.layer.scrollTop = prior_scrollTop // reconstitute 
        }
        //
        self.did_reload_cells(rows, self.was_first_reload)
        self.was_first_reload = false
    }
    //
    //
    /*overridable but call on super */did_reload_cells(rows: DisplayableList.NewRowSetRetVals, was_first_reload: boolean)
    {
        const self = this

        if (typeof rows.II_of_to_be_runtime_scrolledTo_oncePerPkey !== 'undefined') {
            if (IndexIntervals.upperOf(rows.II_of_to_be_runtime_scrolledTo_oncePerPkey).toJSValue() >= self.cellsContainerView.subviews.length) {
                console.error("!!! Expected scrollable row but the max idx was out of bounds") 
                // possibly allow fallthrough here
            } else {
                let lower_I = IndexIntervals.lowerOf(rows.II_of_to_be_runtime_scrolledTo_oncePerPkey!).toJSValue()
                let upper_I = IndexIntervals.upperOf(rows.II_of_to_be_runtime_scrolledTo_oncePerPkey!).toJSValue()
                for (let i = lower_I ; i <= upper_I ; i++) {
                    let cell = self.cellsContainerView.subviews[i]
                    let pkey = cell.list_row_pkey!
                    if (self._hasScrolledOnceAtRuntimeToCellsByPkey[pkey] != true) {
                        if (i == upper_I) { // for now. I need to fix this up
                            self._scrollToSubview(cell, true, "nearest")
                            self._hasScrolledOnceAtRuntimeToCellsByPkey[pkey] = true
                        }
                    }
                }
            }
        } 

    }

    // override new_pkeyForCellRow(dataMember: )
    // maybe data member conforms to an interface of some sort? probably not needed

    // override new_cellRowFor(pkey or index... whichever)

    
    // overridable
    new_cellViewFor(item: DisplayableListRow.CellSpec__BaseParams): View_Web
    {
        const self = this
        //
        return DisplayableList.new_componentFrom(item, self.ctx.themeC)
    }
    //
    // Accessors - DisplayableListPage - Convenience lookups 
    lookup_liveCellsWith(
        pkey_root: string, 
        t: DisplayableListRow.Cell_Type
    ): View_Web[] {
        const self = this
        let Vs: View_Web[] = []
        let pkey_prefix = DisplayableList.new_row_pkey_prefix_with(pkey_root, t)
        // TODO: the following is probably pretty inefficient
        let keys = Object.keys(self._live_cells__by__pkeys)
        for (let k of keys) {
            if (k.indexOf(pkey_prefix) == 0) {
                Vs.push(self._live_cells__by__pkeys[k])
            }
        }
        //
        return Vs
    }
}